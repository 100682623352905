import { IFulfillment } from 'app/features/Cashback/Creation/Fulfillment/types';
import { CashbackType, ICashbackCreative, ICriteria, IRuleCriterion, IJourneyCreative } from 'app/features/Cashback/types';
import { IPaymentCeiling, ICampaignAttribute } from 'app/features/CashbackPromoManagement/types';

import * as types from './types';

const updateCreativeType = (payload: { type: CashbackType }) => ({
  type: types.UPDATE_CREATIVE_TYPE,
  payload,
});

const updateCashbackField = (payload: ICashbackCreative) => ({
  type: types.UPDATE_CASHBACK_FIELD,
  payload,
});

const updateCashbackCalculationField = (payload: Record<string, string>) => ({
  type: types.UPDATE_CASHBACK_CALCULATION_FIELD,
  payload,
});

const udpateCashbackCalculationType = (payload: Record<string, string>) => ({
  type: types.UPDATE_CASHBACK_CALCULATION_TYPE,
  payload,
});

const updateCashbackCriteria = (payload: ICriteria) => ({
  type: types.UPDATE_CASHBACK_CRITERIA,
  payload,
});

const updateCashbackFulfillment = (payload: IFulfillment) => ({
  type: types.UPDATE_CASHBACK_FULFILLMENT,
  payload,
});

const updateCashbackFulfillmentType = (payload: string) => ({
  type: types.UPDATE_CASHBACK_FULFILLMENT_TYPE,
  payload,
});

const updateCashbackStackCeiling = (payload: IPaymentCeiling) => ({
  type: types.UPDATE_CASHBACK_STACK_CEILING,
  payload,
});

const updateCashbackPercentageType = (payload: string) => ({
  type: types.UPDATE_CASHBACK_PERCENTAGE_TYPE,
  payload,
});

const updateCashbackPaymentMethods = (payload: any) => ({
  type: types.UPDATE_CASHBACK_PAYMENT_METHODS,
  payload,
});

const addJourneyStage = () => ({
  type: types.ADD_JOURNEY_STAGE,
});

const deleteJourneyStage = ({ stageNum }: { stageNum: number }) => ({
  type: types.DELETE_JOURNEY_STAGE,
  payload: { stageNum },
});

const exchangeJourneyStageOrder = ({ stageNum1, stageNum2 }: { stageNum1: number; stageNum2: number }) => ({
  type: types.EXCHANGE_JOURNEY_STAGE_ORDER,
  payload: { stageNum1, stageNum2 },
});

const updateJourneyCalculationType = (payload: { stageNum: string; calculationType: string; stackCeiling: IPaymentCeiling }) => ({
  type: types.UPDATE_JOURNEY_CALCULATION_TYPE,
  payload,
});

const updateJourneyCalculationField = (payload: { stageNum: string; [payload: string]: any }) => ({
  type: types.UPDATE_JOURNEY_CALCULATION_FIELD,
  payload,
});

const updateJourneyStackCeiling = (payload: { stageNum: string; [payload: string]: any }) => ({
  type: types.UPDATE_JOURNEY_STACK_CEILING,
  payload,
});

const updateJourneyFulfillmentType = (payload: { fulfillType: string }) => ({
  type: types.UPDATE_JOURNEY_FULFILLMENT_TYPE,
  payload,
});

const updateJourneyFulfillment = (payload: IFulfillment) => ({
  type: types.UPDATE_JOURNEY_FULFILLMENT,
  payload,
});

const updateJourneyReversalStrategy = (payload: { reverseType: string }) => ({
  type: types.UPDATE_JOURNEY_REVERSE,
  payload,
});

const updateJourneyStageAttributes = (payload: {
  stageNum: string;
  customStageAttributes:
    | Array<
        ICampaignAttribute & {
          value: string;
        }
      >
    | null
    | undefined;
}) => ({
  type: types.UPDATE_JOURNEY_STAGE_ATTRIBUTES,
  payload,
});

const updateJourneyEventCriteria = (payload: { stageNum: string } & ICriteria) => ({
  type: types.UPDATE_JOURNEY_EVENT_CRITERIA,
  payload,
});

const updateJourneyUserCriteria = (payload: { userCriteria: string }) => ({
  type: types.UPDATE_JOURNEY_USER_CRITERIA,
  payload,
});

const updateJourneyUserCriteriaJson = (payload: { userCriteriaJson: Array<IRuleCriterion> }) => ({
  type: types.UPDATE_JOURNEY_USER_CRITERIA_JSON,
  payload,
});

const updateJourneyField = (payload: IJourneyCreative) => ({
  type: types.UPDATE_JOURNEY_FIELD,
  payload,
});

export {
  updateCreativeType,
  updateCashbackCriteria,
  updateCashbackField,
  updateCashbackCalculationField,
  updateCashbackFulfillment,
  updateCashbackFulfillmentType,
  udpateCashbackCalculationType,
  updateCashbackStackCeiling,
  updateCashbackPercentageType,
  updateCashbackPaymentMethods,
  addJourneyStage,
  deleteJourneyStage,
  exchangeJourneyStageOrder,
  updateJourneyCalculationType,
  updateJourneyCalculationField,
  updateJourneyStackCeiling,
  updateJourneyFulfillmentType,
  updateJourneyFulfillment,
  updateJourneyReversalStrategy,
  updateJourneyStageAttributes,
  updateJourneyEventCriteria,
  updateJourneyUserCriteria,
  updateJourneyUserCriteriaJson,
  updateJourneyField,
};
