import React from 'react';

import { withRouter } from 'react-router-dom';

import styled from 'styled-components';

import { IStackableGroupCampaign } from 'app/features/CashbackPromoManagement/StackableGroups/types';
import { DraggableList } from 'app/midgarComponents';
import { sc } from 'app/styles';

import { ICashbackBannerCreative } from '../../../Cashback/types';
import { bannerOverviewGetUrl } from '../routeUtils';

type Props = {
  banners: Array<ICashbackBannerCreative>;
  readOnly?: boolean;
  updatePriortiesFn?: (arg0: Array<IStackableGroupCampaign>) => any;
  showArchived: boolean;
};

class BannersList extends React.PureComponent<Props> {
  state = {
    selectedIds: {},
    multiselectIndex: -1,
  };

  openBanner = (bannerId: number) => {
    const { history } = this.props;
    history.push(bannerOverviewGetUrl(bannerId));
  };

  render() {
    const { banners, readOnly, showArchived, updatePriortiesFn } = this.props;

    return (
      <section>
        <FlexTopRow>
          <FlexItem width="60" />
          <FlexItem width="50">ID</FlexItem>
          <FlexItem width="100">Type</FlexItem>
          <FlexItem width="110">Priority</FlexItem>
          <FlexItem width="150">Created Date</FlexItem>
          <FlexItem>Title</FlexItem>
        </FlexTopRow>
        <DraggableList
          identifier="id"
          rows={banners}
          updatePriortiesFn={updatePriortiesFn}
          readOnly={readOnly}
          hideRow={row => !showArchived && new Date(row.endDate) < new Date()}
          hoverCursor="pointer"
          onClickRow={banner => {
            this.openBanner(banner.id);
          }}
          withCheckbox
          withMoveToTop
        >
          {banner => (
            <Flex key={banner.id}>
              {readOnly && (
                <FlexItem>
                  <PlaceHolder />
                </FlexItem>
              )}

              <FlexItem width="50">{banner.id}</FlexItem>
              <FlexItem width="100">{banner.cashbackType}</FlexItem>
              <FlexItem width="110">{banner.priority || 'Not set'}</FlexItem>
              <FlexItem width="150">{banner.startDate.split('T')[0]}</FlexItem>
              <FlexItem data-qa="banner-title">{banner.title}</FlexItem>
            </Flex>
          )}
        </DraggableList>
      </section>
    );
  }
}

export default withRouter(BannersList);

const PlaceHolder = styled.div`
  width: 20px;
`;

const Flex = styled.div`
  display: inline-flex;
  width: 100%;
`;

const FlexTopRow = styled.div`
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid ${sc.greyLight};
  font-weight: bold;
`;

const FlexItem = styled.div<{ width?: string }>`
  border-bottom: none;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  padding: 1rem;
  vertical-align: top;
  font-size: ${sc.fontSizeSmall};
`;
