import { compose, withStateHandlers } from 'recompose';

import { setEquals } from 'app/helpers/RamdaHelpers/helpers';

import { ReadOnlyInputWithMultiSelectDialog } from './ReadOnlyInputWithMultiSelectDialog';

const addStateHandlers = withStateHandlers(
  ({ selection = [] }) => ({
    selection,
    isOpen: false,
  }),

  {
    handleOpen: state => () => ({
      ...state,
      isOpen: true,
    }),

    handleClose:
      (_, { selection: oldValue, onChange }) =>
      newValue => {
        const newSet = new Set(newValue);

        if (!setEquals(newSet, new Set(oldValue))) {
          onChange([...newSet]);

          return {
            isOpen: false,
            selection: [...newSet],
          };
        }

        return {
          isOpen: false,
          selection: oldValue,
        };
      },
  },
);

const enhance = compose(addStateHandlers);

export default enhance(ReadOnlyInputWithMultiSelectDialog);
