import { fetchStackableGroupEvents } from 'app/api/cashbackPromo';
import { DispatchFn } from 'app/types/state';

import * as actions from './actions';

export const getEvents = (groupId: number) => async (dispatch: DispatchFn) => {
  dispatch(actions.getInit());
  try {
    const events: Array<string> = await fetchStackableGroupEvents(groupId);
    dispatch(actions.getSuccess(events));
  } catch (err) {
    dispatch(actions.getError(err));
  }
};
