// See defaultState in './reducer.js' for the shape of the state.
import moment from 'moment';
import { isNotValidNumber, isUndefined, isTrue, isFalse } from 'ramda-adjunct';

import { isNeitherNilNorEmpty, fNot, R } from 'app/helpers/RamdaHelpers/helpers';

const readMe = ({ features }) => features;

const readMeV2 = ({ audience: { audienceRules } }) => audienceRules;

const readCampaign = ({
  campaigns: {
    campaign: { general },
  },
}) => general;
// isLoading: should a loading indicator be shown?
// notFetching: is a fetch already in progress?
const [isLoading, notFetching] = R.map(pred => R.pipe(R.prop('loading'), R.either(isUndefined, pred)), [isTrue, isFalse]);

const getDicts = ({ data: { dicts } }) => dicts;

const getSeed = ({ data: { seed } }) => seed;

const hasData = state => R.all(isNeitherNilNorEmpty, R.map(R.applyTo(state), [getDicts, getSeed]));
const hasNoData = fNot(hasData);

const getError = ({ error }) => error;
const hasError = state => !!getError(state);

const metaData = state => (hasError(state) ? {} : getDicts(state));

const seedData = state => (hasError(state) ? [] : getSeed(state));

const timeUnit = 'hours';
const maxHours = 6;

const isStale = ({ lastUpdate }) => isNotValidNumber(lastUpdate) || moment().diff(moment(lastUpdate), timeUnit) > maxHours;

const shallRefresh = R.both(notFetching, R.anyPass([hasError, hasNoData, isStale]));

const shouldRefresh = storeState => shallRefresh(readMe(storeState));

export { readMe, readMeV2, isLoading, metaData, seedData, shouldRefresh, hasError, getError, readCampaign };
