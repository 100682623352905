import React, { PureComponent } from 'react';

import styled, { css, keyframes } from 'styled-components';

export default class Spinner extends PureComponent<{
  size: number;
  color: string;
  className: string;
}> {
  static defaultProps = {
    size: 20,
    color: '#00b9f5',
    className: '',
  };

  render() {
    const { size, className, color } = this.props;

    const style = {
      width: size,
      height: size,
      borderBottomColor: color,
    };

    return (
      <Container className={className} data-qa="loading">
        <SpinnerInner style={style} />
      </Container>
    );
  }
}

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const animationRule = css`
  ${spinAnimation} 700ms linear infinite
`;

const Container = styled.div`
  text-align: center;
`;

const SpinnerInner = styled.span`
  display: inline-block;
  vertical-align: top;

  width: 20px;
  height: 20px;
  margin: 0 auto;
  border: 2px solid transparent;
  border-radius: 50%;
  border-bottom-color: var(--font-color);

  animation: ${animationRule};
`;
