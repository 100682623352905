import { fetchStackableGroupCampaigns, saveStackableGroupPriorities } from 'app/api/cashbackPromo';
import { IStackableGroupCampaign } from 'app/features/CashbackPromoManagement/types';
import { displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn } from 'app/types/state';

import * as actions from './actions';

export const getCampaignPriorities = (groupId: number, eventName: string) => async (dispatch: DispatchFn) => {
  dispatch(actions.getInit());
  try {
    const campaigns: Array<IStackableGroupCampaign> = await fetchStackableGroupCampaigns(groupId, eventName);
    dispatch(actions.getSuccess(campaigns));
  } catch (err) {
    dispatch(actions.getError(err));
  }
};

export const saveCampaignPriorities =
  (groupId: number, eventName: string, campaigns: Array<IStackableGroupCampaign>) => async (dispatch: DispatchFn) => {
    dispatch(actions.saveStart());
    try {
      const res = await saveStackableGroupPriorities(
        groupId,
        eventName,
        campaigns.map(p => p.id),
      );

      if (res) {
        dispatch(actions.saveSuccess());
        displaySuccess('Saved Priorities');
      } else {
        dispatch(actions.saveError(Error('Failed to save campaign priorities. Check the CMA logs.')));
      }
    } catch (err) {
      dispatch(actions.saveError(err));
    }
  };
