import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import RuleValueEdit from './RuleValueEdit';

interface RuleValueEditWithLoadingProps {
  promise: Promise<unknown>;
  onSuccess?: (result: unknown) => {};
  onFailure?: (error: Error) => {};
}

const RuleValueEditWithLoading: React.FC<RuleValueEditWithLoadingProps> = props => {
  const { promise, onSuccess, onFailure } = props;
  const [isAwaiting, setIsAwaiting] = useState(true);
  const [promiseData, setPromiseData] = useState({});

  useEffect(() => {
    const handleSuccess = (result: unknown) => {
      setIsAwaiting(false);
      const data = onSuccess?.(result) || {};
      setPromiseData(data);
    };

    const handleFailure = (error: Error) => {
      setIsAwaiting(false);
      const data = onFailure?.(error) || {};
      setPromiseData(data);
    };

    if (promise) {
      promise.then(handleSuccess).catch(handleFailure);
    }
  }, [promise, onSuccess, onFailure]);

  if (isAwaiting) {
    return <CircularProgress size={20} />;
  }

  return <RuleValueEdit {...props} {...promiseData} />;
};

export default RuleValueEditWithLoading;
