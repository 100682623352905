import React from 'react';

import styled, { css } from 'styled-components';

import { Checkbox as _Checkbox } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IViewItem } from 'app/types/BannerManagement';

import Actions from './Columns/Actions';
import Schedule from './Columns/Schedule';
import VariantDetails from './Columns/VariantDetails';
import { getScheduleStatus } from './helpers';

type Props = {
  dragHandleProps: Record<string, any>;
  entityId?: number;
  entityType?: string;
  isHighlighted: boolean;
  isSelected: boolean;
  onBind: (arg0: number | null | undefined) => (...args: Array<any>) => any;
  onDelete: (...args: Array<any>) => any;
  onSelect: (...args: Array<any>) => any;
  openMoveModal: (...args: Array<any>) => any;
  openPreviewModal: (...args: Array<any>) => any;
  readOnly?: boolean;
  storefrontId: number;
  timezone: string;
  variantStatus: number;
  viewId: any;
  viewItem: IViewItem;
};

export default class SortableItem extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const { isHighlighted, isSelected, variantStatus } = this.props;

    if (isHighlighted !== nextProps.isHighlighted) {
      return true;
    }

    if (isSelected !== nextProps.isSelected) {
      return true;
    }

    if (variantStatus !== nextProps.variantStatus) {
      return true;
    }

    return false;
  }

  renderVariantTotals() {
    const { viewItem } = this.props;
    const activeVariants = (viewItem.variants || []).filter(x => !!x.status);

    return (
      <span>
        {activeVariants.length} / {(viewItem.variants || []).length}
      </span>
    );
  }

  render() {
    const {
      dragHandleProps,
      viewItem,
      entityId,
      entityType,
      isHighlighted,
      isSelected,
      onBind,
      onDelete,
      onSelect,
      openMoveModal,
      openPreviewModal,
      readOnly,
      storefrontId,
      timezone,
      variantStatus,
      viewId,
    } = this.props;

    const validFrom = viewItem.validFrom || viewItem.valid_from;
    const validUpto = viewItem.validUpto || viewItem.valid_upto;
    const scheduleStatus = getScheduleStatus(validFrom, validUpto);
    const warningMessage = (viewItem.variants || []).find(variant => !!variant.error);
    const firstVariantImage = viewItem.variants?.[0]?.image;
    const imageUrl = (firstVariantImage || '').includes('http') ? firstVariantImage : null;

    return (
      <Container data-view-item-id={viewItem.id} data-qa="SortableItem_viewItem" highlighted={isHighlighted}>
        <FixedCell width={25}>
          <Checkbox name={`sortable-checkbox-${String(viewItem.id)}`} checked={isSelected} onChange={onSelect} />

          <DragHandle {...dragHandleProps}>
            <span />
            <span />
            <span />
          </DragHandle>
        </FixedCell>

        <FixedCell width={130}>
          <PreviewImage width={130} height={75} src={imageUrl} onClick={() => openPreviewModal(viewItem)} />
        </FixedCell>

        <NameCell onClick={() => openPreviewModal(viewItem)}>
          <span>{viewItem.name}</span>
        </NameCell>

        <Cell width={50}>{this.renderVariantTotals()}</Cell>

        <Cell width={90}>
          <span>
            {viewItem.site.map(x => (
              <div key={`site-${x}`}>{x}</div>
            ))}
          </span>
        </Cell>

        <Cell width={90}>
          <span>
            {viewItem.platform.map(x => (
              <div key={`platform-${x}`}>{x}</div>
            ))}
          </span>
        </Cell>

        {viewItem && viewItem.variants && viewItem.variants.length ? (
          <Cell width={250}>
            <VariantDetails
              onOpenPreview={openPreviewModal}
              readOnly={readOnly}
              scheduleStatus={scheduleStatus}
              variantStatus={variantStatus}
              viewItem={viewItem}
            />
          </Cell>
        ) : null}

        <Cell width={25}>
          <Schedule
            status={scheduleStatus}
            timezone={timezone}
            validFrom={validFrom}
            validUpto={validUpto}
            warningMessage={warningMessage}
          />
        </Cell>

        <Cell width={120}>
          <Actions
            entityId={entityId}
            entityType={entityType}
            onBind={onBind}
            onDelete={onDelete}
            onOpenPreview={openPreviewModal}
            openMoveModal={openMoveModal}
            readOnly={readOnly}
            row={viewItem}
            storefrontId={storefrontId}
            viewId={viewId}
          />
        </Cell>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: ${sc.gutterSmall};

  display: flex;
  flex-diraction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  width: 100%;
  border-bottom: 1px solid ${sc.sectionBorderColor};
  background-color: #fff;
  opacity: ${props => (props.inStack ? '0.3' : '1')};

  ${props =>
    props.highlighted
      ? css`
          border: 1px solid ${sc.primary};
          box-shadow: inset 0 0 20px rgba(0, 185, 245, 0.1);
        `
      : ''};

  & .sortable-checkbox {
    padding: 0;
    position: absolute;
    top: ${sc.gutterLarge};
    left: ${sc.gutterSmall};
  }
`;

const Checkbox = styled(_Checkbox)`
  padding: 0;
  position: absolute;
  top: ${sc.gutterLarge};
  left: 18px;
`;

const Cell = styled.div`
  ${props => (props.width ? `width: ${props.width}px;` : '')};
  margin-right: ${sc.gutterSmaller};

  display: flex;
  align-items: center;
  flex-grow: 5;
  flex-shrink: 0;
  flex-basis: auto;

  color: ${sc.subHeadingColor};

  &:last-child {
    margin: 0;
  }
`;

const FixedCell = styled(Cell)`
  flex-grow: 0;
`;

const NameCell = styled(Cell)`
  flex-grow: 25;
  width: 200px;

  & > span {
    font-weight: 600;
  }
`;

const DragHandle = styled.span`
  display: block;
  width: 25px;
  padding: 35px 3px 3px;

  & > span {
    display: block;
    border-bottom: 2px solid ${sc.grey};
    border-radius: 2px;
    margin-bottom: 4px;
  }
`;

const PreviewImage = styled.img`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  border-radius: 3px;
`;
