import React from 'react';

import { connect } from 'react-redux';

import { compose } from 'ramda';

import { withAppConfig } from 'app/decorators';
import { FullTable, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IStorefront } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import { formatTimestamp, genTableColumn } from '../../common/helpers';
import { urlStorefrontEdit } from '../../routeUtils';

type Props = {
  appConfig: IAppConfig;
  entityType?: string;
  msgIfEmpty?: string;
  storefronts: Array<IStorefront>;
  timezone: string;
};

class StorefrontTable extends React.Component<Props> {
  getColumns = () => {
    const { appConfig, timezone } = this.props;

    const { useGACategory, useSellerPanel } = getBannerCreativeConfig(appConfig);

    const renderBooleans = (col, row) => (row[col] === 1 ? <Icon name="checkmark" color={sc.success} /> : null);
    const renderCreatedOn = (_, { created_at }) => (created_at ? formatTimestamp(created_at, timezone) : 'N/A');

    return [
      genTableColumn('ID', 'id'),
      genTableColumn('Name', 'name'),
      ...(useGACategory ? [genTableColumn('GA Category', 'ga_category')] : []),
      ...(useSellerPanel ? [genTableColumn('URL', 'url_key')] : []),
      genTableColumn('Created On', 'created_at', renderCreatedOn),
      ...(useSellerPanel ? [genTableColumn('Status', 'status', renderBooleans)] : []),
      ...(useSellerPanel ? [genTableColumn('Primary', 'is_primary', renderBooleans)] : []),
    ];
  };

  getLinkedRows = () => {
    const { entityType, storefronts } = this.props;
    return storefronts.map(storefront =>
      storefront.id ? { ...storefront, to: urlStorefrontEdit(storefront.id, storefront.entity_associated_with, entityType) } : storefront,
    );
  };

  render() {
    const { msgIfEmpty } = this.props;

    return <FullTable rows={this.getLinkedRows()} columns={this.getColumns()} rowEditable={false} msgIfEmpty={msgIfEmpty} />;
  }
}

const mapStateToProps = ({ user: { timezone } }) => ({ timezone });

export default compose(withAppConfig, connect(mapStateToProps))(StorefrontTable);
