export const api = {
  get: '/campaigns/:id',
  post: '/campaigns/ui',
  getCampaignSize: '/campaigns/:id/size',
  postCampaignSize: '/campaigns/size',
  update: '/campaigns/ui/:id',
  activate: '/campaigns/ui/:id/activate',
  testDelivery: '/campaigns/testDelivery',
  notificationDelivery: '/timeline-notifications/previews',
  setState: '/campaigns/:id/state',
  getMetrics: '/campaigns/:id/metrics',
  postCompositionBased: '/audience/schedule-composition',
};
