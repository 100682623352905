import React from 'react';

import { History } from 'history';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { compose } from 'ramda';
import styled from 'styled-components';

import {
  setSelectedSlotGroupType as actionSetSelectedSlotGroupType,
  updateSelectedSlot as actionUpdateSelectedSlot,
} from 'app/ducks/bannerManagement/binder/actions';
import {
  fetchViewItemLocation as opFetchViewItemLocation,
  fetchViewItemsLocation as opFetchViewItemsLocation,
  getSlotViewItems as opGetSlotViewItems,
} from 'app/ducks/bannerManagement/binder/operations';
import withSlotGroupTypes from 'app/features/BannerManagement/hoc/withSlotGroupTypes';
import { urlViewItemOverview } from 'app/features/BannerManagement/ViewItems/routeUtils';
import { Button as _Button, TextField as _TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISlot, ISlotGroupType } from 'app/types/BannerManagement';
import { Action } from 'app/types/state';
import { findPageFromPriority } from 'app/utilities/pagination';

import SearchedViewItemsModal from './SearchedViewItemsModal';

type Props = {
  entityId?: number;
  entityType?: string;
  fetchViewItemLocation: (viewId: string, params: Record<string, any>) => (...args: Array<any>) => any;
  getSlotViewItems: (slotId: number | null | undefined, params: Record<string, any>, showLoading?: boolean) => (...args: Array<any>) => any;
  history: History;
  setSelectedSlotGroupType: (arg0: ISlotGroupType) => Action;
  slots: Array<ISlot>;
  slotGroupTypes: Array<ISlotGroupType>;
  slotViewItemsPageSize: number;
  storefrontId: number;
  updateSelectedSlot: (arg0: ISlot) => Action;
  viewId: number;
  searchedViewItems: Array<Record<string, any> | null | undefined>;
};

type State = {
  bannerId: string;
  creativeId: string;
  isSearchedViewItemsModalOpen: boolean;
};

class BannerNav extends React.PureComponent<Props, State> {
  state = {
    bannerId: '',
    creativeId: '',
    isSearchedViewItemsModalOpen: false,
  };

  fetchViewItem = () => {
    const { creativeId, bannerId } = this.state;
    const { fetchViewItemLocation, viewId } = this.props;

    const params = bannerId ? { bannerId } : creativeId ? { creativeId } : {};

    return fetchViewItemLocation(viewId, params);
  };

  fetchViewItems = () => {
    const { creativeId, bannerId } = this.state;
    const { fetchViewItemsLocation, viewId } = this.props;

    const params = bannerId ? { bannerId } : creativeId ? { creativeId } : {};

    return fetchViewItemsLocation(viewId, params);
  };

  onFind = async () => {
    const viewItems = await this.fetchViewItems();

    if (viewItems?.length > 1) {
      this.setState({
        isSearchedViewItemsModalOpen: true,
      });
    } else if (viewItems?.length === 1) {
      this.onFindSelect(viewItems[0]);
    }
  };

  onFindSelect = (viewItem: Record<string, any>) => {
    const { getSlotViewItems, setSelectedSlotGroupType, slots, slotGroupTypes, slotViewItemsPageSize, updateSelectedSlot } = this.props;

    if (viewItem) {
      const selectedSlot = slots.find(slot => slot.id === viewItem.slotId);
      const selectedSlotGroupType = slotGroupTypes.find(slotGroupType => slotGroupType.value === viewItem.type);

      if (selectedSlot) {
        updateSelectedSlot(selectedSlot);
        setSelectedSlotGroupType(selectedSlotGroupType);
        getSlotViewItems(selectedSlot.id, {
          type: selectedSlotGroupType.value,
          page: findPageFromPriority(viewItem.priority, slotViewItemsPageSize),
        });
      }
    }
  };

  onOpen = async () => {
    const { entityId, entityType, history, storefrontId, viewId } = this.props;

    const viewItem = await this.fetchViewItem();

    if (viewItem) {
      history.push(urlViewItemOverview(viewItem.id, viewId, storefrontId, entityId, entityType));
    }
  };

  onSearchedViewItemsModalClose = async () => {
    this.setState({
      isSearchedViewItemsModalOpen: false,
    });
  };

  render() {
    const { slots, slotGroupTypes } = this.props;
    const { creativeId, bannerId, isSearchedViewItemsModalOpen } = this.state;

    return (
      <>
        <Container>
          <TextField
            type="number"
            id="creative-id"
            name="creative-id"
            label="Creative ID"
            value={creativeId}
            onChange={ev => this.setState({ creativeId: ev.target.value })}
          />

          <TextField
            type="number"
            id="banner-id"
            name="banner-id"
            label="Banner ID"
            value={bannerId}
            onChange={ev => this.setState({ bannerId: ev.target.value })}
          />

          <Button onClick={this.onOpen} type="secondary">
            Open
          </Button>
          <Button onClick={this.onFind} type="secondary">
            Find
          </Button>
        </Container>
        <SearchedViewItemsModal
          isOpen={isSearchedViewItemsModalOpen}
          onClose={this.onSearchedViewItemsModalClose.bind(this)}
          slots={slots}
          slotGroupTypes={slotGroupTypes}
          onFindSelect={this.onFindSelect}
        />
      </>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder } }) => ({
  slots: binder.view.slots,
  slotViewItemsPageSize: binder.slotViewItemsPageSize,
  searchedViewItems: binder.searchedViewItems,
});

const mapDispatchToProps = {
  fetchViewItemLocation: opFetchViewItemLocation,
  fetchViewItemsLocation: opFetchViewItemsLocation,
  getSlotViewItems: opGetSlotViewItems,
  setSelectedSlotGroupType: actionSetSelectedSlotGroupType,
  updateSelectedSlot: actionUpdateSelectedSlot,
};

export default compose(withRouter, withSlotGroupTypes, connect(mapStateToProps, mapDispatchToProps))(BannerNav);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div,
  & > button {
    margin-left: ${sc.gutterSmaller};
  }
  & > div:first-child,
  & > button:first-child {
    margin-left: 0;
  }
`;

const Button = styled(_Button)``;

const TextField = styled(_TextField)``;
