import React from 'react';

import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import withAppConfig from 'app/decorators/withAppConfig';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import _Button from 'app/midgarComponents/Button';
import Spinner from 'app/midgarComponents/Spinner';
import { sc } from 'app/styles';
import { IAppConfig } from 'configs/apps/types';

import { IDeliveryChannel } from './deliveryChannels';
import { parseRecipients, tooltipText } from './utils';
import Tooltip from '../Tooltip';

type Props = {
  appConfig: IAppConfig;
  buttonText?: string;
  className?: string;
  deliveryChannel: IDeliveryChannel;
  disabled?: boolean;
  handleSend: (recipients: string[]) => Promise<void>;
  label?: string;
  rowsMax?: number;
};

type State = {
  sending: boolean;
  value: string;
};

class TestDelivery extends React.PureComponent<Props, State> {
  state = {
    sending: false,
    value: '',
  };

  static defaultProps = {
    buttonText: 'Send',
    disabled: false,
    label: `Recipient User ID's`,
    rowsMax: 5,
  };

  handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => this.setState({ value: ev.target.value.trim() });

  handleSend = async () => {
    const { deliveryChannel, handleSend } = this.props;
    if (handleSend) {
      const { value } = this.state;
      const recipients = parseRecipients(deliveryChannel, value);
      if (recipients && recipients.length) {
        this.setState({ sending: true });
        try {
          await handleSend(recipients);
          this.setState({ sending: false });
        } catch (err) {
          displayError(`Failed: ${(err as Error).message}`);
        }
      } else {
        displayError('No valid recipients');
      }
    }
  };

  render() {
    const { buttonText, className, deliveryChannel, disabled, label, rowsMax } = this.props;

    const { sending, value } = this.state;

    return (
      <section className={className} data-qa="test-delivery">
        <Tooltip placement="bottom" title={tooltipText(deliveryChannel)}>
          <TextField disabled={disabled} fullWidth label={label} multiline onChange={this.handleChange} maxRows={rowsMax} value={value} />
        </Tooltip>

        <Button disabled={disabled || sending || !value} onClick={this.handleSend}>
          {sending ? <Spinner /> : buttonText}
        </Button>
      </section>
    );
  }
}

export default withAppConfig(TestDelivery);

const Button = styled(_Button)`
  margin-top: ${sc.gutter};
`;
