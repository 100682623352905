import { getCategories } from 'app/api/categories';
import { ICategory } from 'app/types/Category';
import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';
import { getErrorMessage } from './utils';

export const getEngageCategories = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      engageCategories: { loading, engageCategories: currEngageCategories },
    },
  } = getState();

  if (!loading && (!currEngageCategories || !currEngageCategories.length)) {
    dispatch(actions.getEngageCategoriesStart());
    try {
      const engageCategories: Array<ICategory> = await getCategories();
      return dispatch(actions.getEngageCategoriesSuccess(engageCategories));
    } catch (err) {
      const errorStr = getErrorMessage(err as Error);
      return dispatch(actions.getEngageCategoriesFailed(errorStr));
    }
  }
};
