import { Action } from 'app/types/state';

import { State } from './state';
import * as types from './types';

const initialState: State = {
  error: null,
  loading: false,
  permittedStorefronts: null,
  storefronts: null,
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_STOREFRONTS_ERROR:
      return {
        ...initialState,
        error: payload.error,
      };

    case types.GET_STOREFRONTS_START:
      return {
        ...initialState,
        loading: true,
      };

    case types.GET_STOREFRONTS_SUCCESS:
      return {
        ...initialState,
        permittedStorefronts: payload.permittedStorefronts,
        storefronts: payload.storefronts,
      };

    default:
      return state;
  }
};
