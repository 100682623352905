import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { IMedium, IScope } from 'app/features/CashbackPromoManagement/types';
import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';
import { KeyValue, TypedAttributes, Spinner, Icon } from 'app/midgarComponents';
import { isValid as isValidKeyValue } from 'app/midgarComponents/KeyValue/utils';
import { sc } from 'app/styles';

type Props = {
  scope: IScope;
  updateCashbackField: (...args: Array<any>) => any;
  getCampaignAttributes: (arg0: IScope, arg1: IMedium) => any;
  cashbackCreative: Record<string, any>;
};

const MEDIUM = 'cashback';
// const SCOPE = 'campaign';

export default class CustomAttributes extends PureComponent<Props> {
  componentDidMount = () => {
    const { getCampaignAttributes, scope } = this.props;
    getCampaignAttributes(scope, MEDIUM);
  };

  updateCustomAttributes = attributesList => {
    const { updateCashbackField } = this.props;
    updateCashbackField({ customCampaignAttributes: attributesList });
  };

  handleDeleteAttribute = item => {
    const {
      cashbackCreative: { customCampaignAttributes },
      updateCashbackField,
    } = this.props;
    updateCashbackField({
      customCampaignAttributes: customCampaignAttributes.filter(att => att.id !== item.id),
    });
  };

  validate = () => {
    const {
      cashbackCreative: { customCampaignAttributes },
    } = this.props;
    return customCampaignAttributes.every(att => !(!att.optional && isNilOrEmpty(att.value) && isNilOrEmpty(att.defaultValue)));
  };

  render() {
    const {
      cashbackCreative: { additionalAttributes, customCampaignAttributes },
      updateCashbackField,
    } = this.props;
    return (
      <>
        <Section>
          <Label>
            Custom Campaign Attributes
            {customCampaignAttributes &&
              customCampaignAttributes.length &&
              (this.validate() ? (
                <ValidationIcon name="check-circle" size={22} color={sc.success} />
              ) : (
                <ValidationIcon name="warning" size={22} color={sc.warning} />
              ))}
          </Label>

          {customCampaignAttributes !== null ? (
            <>
              <AttributesWrapper>
                <TypedAttributes
                  attributes={customCampaignAttributes}
                  attributeFields={customCampaignAttributes}
                  updateAttributes={this.updateCustomAttributes}
                  handleDeleteAttribute={this.handleDeleteAttribute}
                />
              </AttributesWrapper>
            </>
          ) : (
            <Spinner />
          )}
        </Section>

        <Section>
          <Label>
            Additional Attributes
            {additionalAttributes &&
              additionalAttributes.length > 0 &&
              (isValidKeyValue(additionalAttributes) ? (
                <ValidationIcon name="check-circle" size={22} color={sc.success} />
              ) : (
                <ValidationIcon name="warning" size={22} color={sc.warning} />
              ))}
          </Label>

          <KeyValue updateFn={data => updateCashbackField({ additionalAttributes: data })} keyValueArray={additionalAttributes} />
        </Section>
      </>
    );
  }
}

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${sc.greyLight};
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

const AttributesWrapper = styled.div`
  margin: 1rem 0;
`;

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;
