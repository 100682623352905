import { connect } from 'react-redux';

import { lifecycle, compose } from 'recompose';

import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  tags: selectors.tags(state),
  tagsLoading: selectors.loading(state),
  tagsError: selectors.error(state),
});

const connectToRedux = connect(mapStateToProps, operations);

const fetchTags = lifecycle({
  componentDidMount() {
    this.props.getAllTags();
  },
});

const ConnectedTags = compose(connectToRedux, fetchTags);

interface TagsContainerProps {
  children?(...args: unknown[]): unknown;
}

const TagsContainer = ({ children, ...props }: TagsContainerProps) => <>{children && children(props)}</>;

const ConnectedTagsContainer = ConnectedTags(TagsContainer);

export { ConnectedTags, ConnectedTagsContainer };
