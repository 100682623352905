import buildLookups from 'app/utilities/buildLookups';

import * as types from './types';

const getAll = () => ({
  type: types.GET_ALL_EMAIL_SENDERS,
});

const getAllSuccess = emailSenders => ({
  type: types.GET_ALL_EMAIL_SENDERS_SUCCESS,
  payload: { emailSenderLookups: buildLookups(emailSenders) },
});

const getAllFail = error => ({
  type: types.GET_ALL_EMAIL_SENDERS_FAIL,
  payload: { error },
});

export { getAll, getAllSuccess, getAllFail };
