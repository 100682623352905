import { calculationTypes, currency } from 'app/features/Cashback/constants';
import { ICalculation } from 'app/features/Cashback/Creation/Calculation/types';
import { equalsArray } from 'app/helpers/RamdaHelpers/helpers';

import Entry from './Entry';

type Props = {
  calculation: ICalculation;
  originalCalculation?: Record<string, any>;
};

export default ({ calculation, originalCalculation }: Props) => {
  const { payoutStrategy, stackCeiling } = calculation;
  const { limit } = stackCeiling || {};

  return (
    <section>
      {limit ? (
        <Entry
          title="Global Limit"
          value={`${limit}%`}
          highlight={
            originalCalculation && (originalCalculation?.stackCeiling?.limit || limit) && limit !== originalCalculation?.stackCeiling?.limit
          }
        />
      ) : null}
      <Entry
        title="Calculation Type"
        value={`${payoutStrategy.calculationType}`}
        highlight={
          originalCalculation &&
          (originalCalculation?.payoutStrategy?.calculationType || payoutStrategy.calculationType) &&
          payoutStrategy.calculationType !== originalCalculation?.payoutStrategy?.calculationType
        }
      />

      {payoutStrategy.calculationType === calculationTypes.fixed && (
        <Entry
          title="Amount"
          value={`${payoutStrategy.amount} ${currency}`}
          highlight={
            originalCalculation &&
            (originalCalculation?.payoutStrategy?.amount || payoutStrategy.amount) &&
            payoutStrategy.amount !== originalCalculation?.payoutStrategy?.amount
          }
        />
      )}

      {payoutStrategy.calculationType === calculationTypes.proportional && (
        <section>
          {!isNaN(payoutStrategy?.percentage) && payoutStrategy?.percentage && (
            <Entry
              title="Amount"
              value={`${payoutStrategy.percentage} %`}
              highlight={
                originalCalculation &&
                (originalCalculation?.payoutStrategy?.percentage || payoutStrategy.percentage) &&
                payoutStrategy.percentage !== originalCalculation?.payoutStrategy?.percentage
              }
            />
          )}

          <>
            {payoutStrategy.allowedPaymentMethods?.map(paymentMethod => {
              const originalPaymentMethod = originalCalculation?.payoutStrategy.allowedPaymentMethods?.find(
                method => method.name === paymentMethod.name,
              );

              return (
                <Entry
                  title={paymentMethod.name}
                  value={`${paymentMethod.percentage} %`}
                  highlight={
                    originalCalculation &&
                    (originalPaymentMethod?.percentage || paymentMethod.percentage) &&
                    paymentMethod.percentage !== originalPaymentMethod?.percentage
                  }
                />
              );
            })}
          </>
          <Entry
            title="Maximum"
            value={`${payoutStrategy.ceiling} ${currency}`}
            highlight={
              originalCalculation &&
              (originalCalculation?.payoutStrategy?.ceiling || payoutStrategy.ceiling) &&
              payoutStrategy.ceiling !== originalCalculation?.payoutStrategy?.ceiling
            }
          />

          {!isNaN(payoutStrategy.roundDownToNearestAmount) && payoutStrategy.roundDownToNearestAmount && (
            <Entry
              title="Round down Nearest Amount"
              value={`${payoutStrategy.roundDownToNearestAmount} ${currency}`}
              highlight={
                originalCalculation &&
                (originalCalculation?.payoutStrategy?.roundDownToNearestAmount || payoutStrategy.roundDownToNearestAmount) &&
                payoutStrategy.roundDownToNearestAmount !== originalCalculation?.payoutStrategy?.roundDownToNearestAmount
              }
            />
          )}
        </section>
      )}

      {payoutStrategy.calculationType === calculationTypes.lottery && (
        <>
          <Entry
            title="Payment Methods"
            value={
              payoutStrategy?.allowedPaymentMethods?.reduce((accumulator, currentValue) => {
                return !accumulator ? currentValue.name : accumulator + ', ' + currentValue.name;
              }, '') || 'Not set'
            }
            highlight={
              originalCalculation &&
              !equalsArray(originalCalculation.payoutStrategy?.allowedPaymentMethods, payoutStrategy?.allowedPaymentMethods)
            }
          />

          <Entry
            title="Lottery"
            value={(payoutStrategy.legs || [])
              .map(
                leg =>
                  `${leg.percentPeople} % of people will get ${leg.amount} ${leg.isAmountPercent ? '%' : currency} cashback ${
                    leg.isAmountPercent ? `up to ${leg.ceiling} ${currency}` : ''
                  }`,
              )
              .join('\n')}
            highlight={originalCalculation && !equalsArray(originalCalculation.payoutStrategy?.legs, payoutStrategy.legs)}
          />
        </>
      )}
    </section>
  );
};
