import React from 'react';

import { connect } from 'react-redux';

import ClipboardJS from 'clipboard';
import styled from 'styled-components';

import { saveVariantStatus as opSaveVariantStatus } from 'app/ducks/bannerManagement/binder/operations';
import { Text } from 'app/features/BannerManagement/common/components';
import { Spinner } from 'app/midgarComponents';
import { BaseToggle } from 'app/midgarComponents/Toggle';
import { sc } from 'app/styles';
import { IVariant, IViewItem } from 'app/types/BannerManagement';

import { ScheduleStatus } from '../helpers';

type Props = {
  onOpenPreview: (arg0: IViewItem) => void;
  readOnly?: boolean;
  saveVariantStatus: (viewItem: IViewItem, variant: IVariant, status: boolean) => IViewItem | null | undefined;
  statusLoading: boolean;
  scheduleStatus: number;
  variantStatus: number;
  viewItem: IViewItem;
};

type State = {
  copied: boolean;
};

class VariantDetails extends React.Component<Props, State> {
  clipboard = null;

  clipboardEl = null;

  clipboardTarget = null;

  state = {
    copied: false,
  };

  componentDidMount() {
    if (this.clipboardEl) {
      this.clipboard = new ClipboardJS(this.clipboardEl, {
        target: () => this.clipboardTarget,
      });

      this.clipboard.on('success', () => {
        this.setState({ copied: true });
      });
    }
  }

  componentWillUnmount() {
    if (this.clipboardEl) {
      this.clipboard.destroy();
    }
  }

  onStatusToggle = async (newStatus: boolean) => {
    const { viewItem, saveVariantStatus } = this.props;

    // TODO: Existing code uses only the first variant. Support multiple variants (for India).
    const variant = viewItem.variants[0];

    saveVariantStatus(viewItem, variant, newStatus);
  };

  render() {
    const { copied } = this.state;
    const { onOpenPreview, readOnly, statusLoading, scheduleStatus, variantStatus, viewItem } = this.props;

    const variants = viewItem.variants || [{}]; // TODO: Protect against empty variants. Is this needed? Check the API
    const variant = variants[0];

    return (
      <Container>
        <FieldGroup>
          <Label dark>Banner ID:</Label>

          <Field>
            <span>{variant.catalogBannerId}</span>

            <StatusToggle
              active={!!variantStatus}
              disabled={readOnly || scheduleStatus === ScheduleStatus.Completed}
              onChange={this.onStatusToggle}
            />

            {statusLoading && <Spinner size={12} />}
          </Field>
        </FieldGroup>
        <FieldGroup>
          <Label dark>Type:</Label>

          <Field>{variant.landingPageType}</Field>
        </FieldGroup>
        {variant.itemId ? (
          <FieldGroup>
            <Label dark>Item ID:</Label>

            <Field>{variant.itemId}</Field>
          </FieldGroup>
        ) : (
          <FieldGroup>
            <Label dark>URL:</Label>

            <UrlField
              ref={x => {
                this.clipboardTarget = x;
              }}
            >
              {variant.url}
            </UrlField>

            <UrlAction
              ref={x => {
                this.clipboardEl = x;
              }}
            >
              {copied ? 'Copied!' : 'Copy'}
            </UrlAction>
          </FieldGroup>
        )}
        {viewItem.variantType && (
          <FieldGroup>
            <Label dark>Variant Type:</Label>

            <Field>{viewItem.variantType.name}</Field>
          </FieldGroup>
        )}
        {variants.length > 1 ? <More onClick={() => onOpenPreview(viewItem)}>more..</More> : null}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      binder: { saveVariantStatusLoading },
    },
  },

  { viewItem },
) => {
  // TODO: Existing code uses only the first variant. Support multiple variants (for India).
  const variantId = viewItem.variants?.[0]?.id;

  return {
    statusLoading: !!saveVariantStatusLoading.find(id => id === variantId),
  };
};

const mapDispatchToProps = {
  saveVariantStatus: opSaveVariantStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantDetails);

const Container = styled.div`
  text-align: left;
`;

const Label = styled(Text)`
  display: inline-block;
  vertical-align: top;

  margin-right: ${sc.gutterSmallest};
`;

const FieldGroup = styled.div`
  line-height: 22px;
`;

const Field = styled(Text)`
  display: inline-block;
  vertical-align: top;

  & > div,
  & > span {
    display: inline-block;
    vertical-align: top;
    margin-right: ${sc.gutterSmallest};
  }
`;

const UrlField = styled(Field)`
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const UrlAction = styled.span`
  display: inline-block;
  vertical-align: top;
  padding: 2px 0;
  margin-left: ${sc.gutterSmallest};

  color: ${sc.primary};
  cursor: pointer;
`;

const StatusToggle = styled(BaseToggle)`
  display: inline-block;
  vertical-align: top;
  width: 35px;
  height: 18px;
`;

const More = styled.div`
  cursor: pointer;
  color: ${sc.primary};
`;
