import { findChannelSetup } from 'app/ducks/configStore/utils';
import { IAppConfig, IEmailCreativeConfig } from 'configs/apps/types';
import { channelNameEmail } from 'configs/channels/constants';

export const getEmailCreativeConfig = (appConfig: IAppConfig): IEmailCreativeConfig => {
  const channelSetup = findChannelSetup(appConfig, channelNameEmail) || {};
  const emailCreative = channelSetup.creative || {};
  return {
    ...emailCreative,
    useReplyTo: emailCreative.useReplyTo !== false,
  };
};
