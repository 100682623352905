import React, { PureComponent } from 'react';

import { FormikErrors } from 'formik';
import document from 'global/document';
import { equals } from 'ramda';
import styled, { css } from 'styled-components';

import { sc } from 'app/styles';

import FieldError from '../FieldError';
import Icon from '../Icon';

export type IDropdownOption = Partial<{
  id: any;
  label: string;
  [key: string]: any;
}>;

const getLabel = (value: string | IDropdownOption) => {
  switch (typeof value) {
    case 'string':
      return value;

    case 'object':
      return value.label || value.name;

    default:
      return '';
  }
};

const renderCheckmark = (predicate: boolean) => {
  if (!predicate) {
    return null;
  }

  return <CheckIcon name="checkmark" size={18} color={sc.primary} />;
};

type Props = {
  id?: string;
  size?: string;
  label: string;
  value?: string | IDropdownOption;
  verticalAlign?: 'bottom' | 'top';
  options: ReadonlyArray<string | IDropdownOption>;
  onChange: (option: string | IDropdownOption) => void;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean | string | FormikErrors<{ id: number }>;
};

type State = {
  isOpen: boolean;
  active: boolean;
};

type Ref = { current: null | HTMLElement };

export default class Dropdown extends PureComponent<Props, State> {
  wrapperRef: Ref;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      active: !!props.value,
    };

    this.wrapperRef = React.createRef();

    document.addEventListener('keydown', this.handleKeydown);
    document.addEventListener('mousedown', this.handleOutsideClick);
    document.addEventListener('touchstart', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
    document.removeEventListener('mousedown', this.handleOutsideClick);
    document.removeEventListener('touchstart', this.handleOutsideClick);
  }

  componentDidUpdate() {
    const { active } = this.state;
    const { value } = this.props;

    if (!active && value) {
      this.setState({ active: true }); // eslint-disable-line
    }
  }

  onSelect = (option: string | IDropdownOption) => {
    const { value, onChange } = this.props;

    if (!equals(option, value)) {
      onChange(option);
      this.setState({ active: !!option });
    }
  };

  handleKeydown = (ev: KeyboardEvent) => {
    const ESC = 27;

    if (ev.which === ESC) {
      this.closeDropdown();
    }
  };

  handleOutsideClick = (ev: InputEvent) => {
    const { isOpen } = this.state;

    if (isOpen) {
      const node = this.wrapperRef.current;

      if (node && !node.contains(ev.target)) {
        this.closeDropdown();
      }
    }
  };

  closeDropdown = () => {
    this.setState({ isOpen: false });
  };

  toggleDropdown = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState((prev: State) => ({ isOpen: !prev.isOpen }));
    }
  };

  render() {
    const { isOpen, active } = this.state;
    const { id = '', label, required, value = '', verticalAlign, className, options, disabled, error, size } = this.props;

    return (
      <>
        <Container
          ref={this.wrapperRef}
          className={className}
          onClick={this.toggleDropdown}
          disabled={!!disabled}
          aria-disabled={!!disabled}
          $error={!!error}
          size={size}
          data-qa={`dropdown-${label}`}
          aria-labelledby={`dropdown-${id}`}
          role="button"
          tabIndex="0"
        >
          <SelectedValue data-qa="selected-dropdown-value" label={label}>
            {getLabel(value)}
          </SelectedValue>

          {label && (
            <Label id={`dropdown-${id}`} required={required} $active={active} data-qa={`dropdown-label-${label}`}>
              {label}
            </Label>
          )}

          <ChevronDownIcon name="chevron-down" size={24} color={sc.grey} />

          <Options $open={isOpen} $verticalAlign={verticalAlign} data-qa={`${String(id)}-options`} role="listbox">
            {(options || []).map((option, indx) => {
              let isSelected = value === option;

              if (typeof option === 'object' && typeof value === 'object') {
                isSelected = !!(value && value.id === option.id);
              }

              return (
                <Option
                  key={`dropdown-option-${getLabel(option)}-${indx}`}
                  data-qa={`label-${getLabel(option)}`}
                  onClick={() => this.onSelect(option)}
                  selected={isSelected}
                >
                  {getLabel(option)}
                  {renderCheckmark(isSelected)}
                </Option>
              );
            })}
          </Options>
        </Container>
        <FieldError error={error} />
      </>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: block;
  height: 55px;
  border: 1px solid ${props => (props.$error ? sc.danger : sc.sectionBorderColor)};
  cursor: pointer;
  text-align: left;

  opacity: ${props => (props.disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  width: ${props => (props.size ? `${props.size}px` : '100%')};
`;

export const SelectedValue = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props =>
    props.required
      ? css`
          &:after {
            content: ' *';
          }
        `
      : ''}

  ${props =>
    props.label
      ? css`
          padding: ${sc.gutterLarger} ${sc.gutterSmall} ${sc.gutterSmallest};
        `
      : css`
          display: flex;
          align-items: center;
          padding: 0 ${sc.gutterSmall};
          color: ${sc.headingColor};
        `};

  font-size: ${sc.fontSizeSmall};
  font-weight: bold;
`;

export const Label = styled.label`
  display: block;
  position: absolute;
  top: 50%;
  left: ${sc.gutterSmall};

  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmall};
  transform: translate(0, -50%);
  transition: all 100ms ease-out;

  &:after {
    content: ${props => (props.required ? `' *'` : '')};
  }

  ${props =>
    props.$active
      ? css`
          top: 30%;
          font-size: ${sc.fontSizeSmaller};
        `
      : ''}
`;

export const ChevronDownIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${sc.gutterSmall};
  transform: translate(0, -50%);
`;

export const Options = styled.ul`
  position: absolute;
  ${props => (props.$verticalAlign === 'bottom' ? 'bottom' : 'top')}: 0;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  border: 1px solid ${sc.sectionBorderColor};
  opacity: ${props => (props.$open ? 1 : 0)};
  display: ${props => (props.$open ? 'unset' : 'none')};
  transform: scale(${props => (props.$open ? 1 : 0.98)});
  transition: all 200ms ease-in-out;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);

  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 999;
`;

const Option = styled.li`
  position: relative;
  height: 55px;
  line-height: 55px;
  padding: 0 ${sc.gutterSmall};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: ${sc.fontSizeSmall};
  font-weight: bold;
  background-color: #fff;
  border-bottom: 1px solid ${sc.greyLighter};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${sc.greyLightest};
  }
`;

const CheckIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${sc.gutterSmall};
  transform: translate(0, -50%);
`;
