import { ConnectedEmail } from './ConnectedEmail';
import emailCreative from './reducers';

// import * as emailCreativeOperations from './operations';

export {
  // emailCreativeOperations,
  ConnectedEmail,
};

export default emailCreative;
