import React from 'react';

import { Link } from 'react-router-dom';

import styled from 'styled-components';

import _Icon from 'app/midgarComponents/Icon';
import { sc, media } from 'app/styles';

const getNavItemClass = (item, path) => {
  return (item.relativeMatch && path.indexOf(item.to) === 0) || path === item.to ? 'active' : null;
};

type MenuItem = {
  key: string;
  to: string;
  label: string;
  iconName: string | null | undefined;
  children: Array<MenuItem>;
  relativeMatch: boolean | null | undefined;
  disable: boolean | null | undefined;
};

export class SideNav extends React.Component<
  {
    menuData: Array<MenuItem>;
    path: string;
  },
  {
    expanded: boolean;
  }
> {
  state = {
    expanded: true,
    expandedItems: [],
  };

  renderSubMenu = (items: Array<Record<string, any>>) => {
    const { path } = this.props;

    return items.map(item =>
      item.disable ? null : (
        <NavItem to={item.to} key={item.key} className={getNavItemClass(item, path)} data-qa={item.key}>
          <Label>{item.label}</Label>

          {item.children && item.children.length > 0 ? this.renderSubMenu(item.children) : null}
        </NavItem>
      ),
    );
  };

  expandItem = (event, item) => {
    if (this.hasChildren(item)) {
      const { expandedItems } = this.state;
      const index = expandedItems.indexOf(item.key);
      index === -1 ? expandedItems.push(item.key) : expandedItems.splice(index, 1);
      this.setState({ expanded: true, expandedItems });
      // TODO: stopPropagation() and stopImmediatePropagation() don't prevent propagation in Firefox. Can they be removed?
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();
    }
  };

  renderToggleButton = () => {
    const { expanded } = this.state;
    return (
      <ToggleButton className={expanded ? 'expanded' : 'collapsed'} onClick={this.toggleSideNav}>
        {expanded ? <Icon name="chevron-left" /> : <Icon name="chevron-right" />}
      </ToggleButton>
    );
  };

  toggleSideNav = () => {
    this.setState((prevState: Record<string, any>) => ({
      expanded: !prevState.expanded,
    }));
  };

  hasChildren = ({ children }) => children && children.length > 0;

  isItemExpanded = (item: Record<string, any>): boolean => {
    const { expandedItems } = this.state;
    return expandedItems.indexOf(item.key) !== -1;
  };

  render() {
    const { expanded } = this.state;
    const { path, menuData } = this.props;

    return (
      <SideNavWrapper expanded={expanded} data-qa="side-nav">
        <FixedWrapper>
          {this.renderToggleButton()}
          {menuData.map(item =>
            item.disable ? null : (
              <React.Fragment key={item.key}>
                <NavItem
                  to={this.hasChildren(item) ? '#' : item.to}
                  className={getNavItemClass(item, path)}
                  onClick={event => this.expandItem(event, item)}
                  data-qa={item.key}
                >
                  {item.iconName ? <Icon className="padded" name={item.iconName} /> : <PlaceHolder />}
                  {expanded ? (
                    <Label>
                      <LabelText>{item.label}</LabelText>
                      {this.hasChildren(item) > 0 ? (
                        <NavItemTogggle name={this.isItemExpanded(item) ? 'chevron-up' : 'chevron-down'} />
                      ) : null}
                    </Label>
                  ) : null}
                </NavItem>
                {expanded && this.isItemExpanded(item) ? <SubNavWrapper>{this.renderSubMenu(item.children)}</SubNavWrapper> : null}
              </React.Fragment>
            ),
          )}
        </FixedWrapper>
      </SideNavWrapper>
    );
  }
}

const FixedWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: inherit;
  border-right: 1px solid ${sc.sectionBorderColor};
  background: ${sc.sectionWhiteColor};
  padding-top: 2rem;
  overflow-y: auto;
`;

const LabelText = styled.span`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled(_Icon)`
  margin-right: 1rem;

  &.padded {
    padding-left: 1.5rem;
  }

  & * {
    fill: ${sc.headingColor};
  }
`;

const Label = styled.span`
  text-decoration: none;
  vertical-align: bottom;
`;

const NavItem = styled(Link)`
  padding: 0.75rem 0 0.75rem 0.25rem;
  display: block;
  color: ${sc.headingColor};

  ${media.smallScreen({
    padding: '0.75rem 0 0.75rem 0',
  })}

  & > span {
    ${media.smallScreen({
      'padding-left': '1rem',
    })}
  }

  &.active {
    padding-left: 0;
    border-left: 0.25rem solid ${sc.primary};
    font-weight: bold;
  }

  &.active * {
    color: ${sc.primary};
    fill: ${sc.primary};
  }
`;

const NavItemTogggle = styled(_Icon)`
  position: relative;
  float: right;
  padding-right: 20px;
  font-size: 25px;
`;

const PlaceHolder = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 1rem;
`;

const SideNavWrapper = styled.article`
  position: relative;
  height: 100%;
  background: ${sc.sectionWhiteColor};
  padding: ${props => (props.expanded ? '0 2rem 2rem 0 ' : '0 1rem 2rem 1rem')};
  padding-left: 0;
  padding-top: 0;
  z-index: 9;
  text-align: left;
  width: ${props => (props.expanded ? '260px' : '80px')};
  left: 0;
  font-size: ${sc.fontSizeSmall};
  transition: left 0.65s ease-out;

  ${props =>
    media.smallScreen({
      width: props.expanded ? '260px' : '65px',
    })}
`;

const SubNavWrapper = styled.div`
  min-width: 4rem;
  transition: height 600ms ease-out;

  & > a {
    font-size: ${sc.fontSizeSmall};
    padding-left: 4rem;

    &.active {
      padding-left: 3.8rem;
    }
  }

  & > div {
    padding: 0.75rem;
  }
`;

const ToggleButton = styled.button`
  border: 1px solid ${sc.greyLighter};
  border-right: 0;
  height: 30px;
  width: 40px;
  top: 5px;
  position: absolute;
  right: 0;
  border-radius: 15px 0 0 15px;
`;
