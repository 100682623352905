import React from 'react';

import { connect } from 'react-redux';

import { getRegularCashbackCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { Header, MainPage } from 'app/features/Campaigns/Overview/common/ChannelDetailsComponents';
import { diffObjInArrayWith } from 'app/features/Campaigns/utils';

import Calculation from '../../Overview/Calculation';
import Entry from '../../Overview/Entry';
import EventCriteria from '../../Overview/EventCriteria';
import Fulfillment from '../../Overview/Fulfillment';
import { SectionContainer, SubsectionContainer, SectionHeader, SubsectionHeader } from '../../Overview/OverviewCommonComponents';
import UserCriteria from '../../Overview/UserCriteria';
import { ICashbackCreative } from '../../types';

type Props = {
  cashbackCreative: ICashbackCreative;
  originalCreative: Record<string, any>;
};

class CashbackOverview extends React.PureComponent<Props> {
  render() {
    const { cashbackCreative, originalCreative } = this.props;
    const cashbackPromoBanner = cashbackCreative.cashbackPromoBanner || {};
    const criteria = cashbackCreative.criteria || {};
    const scratchCard = cashbackCreative.calculation.payoutStrategy?.scratchCard || {};
    const originalCreativeScratchCard = originalCreative?.calculation?.payoutStrategy?.scratchCard || {};

    return (
      <MainPage>
        <Header>Cashback</Header>

        <SectionContainer>
          <SectionHeader>Campaign Attributes</SectionHeader>

          <SubsectionContainer>
            {(cashbackCreative.customCampaignAttributes || []).map(({ defaultValue, fieldTitle, id, optional, value }) => (
              <Entry
                key={id}
                title={`${fieldTitle}${optional ? '[optional]' : ''}`}
                value={value || defaultValue || ''}
                highlight={
                  originalCreative &&
                  diffObjInArrayWith(originalCreative.customCampaignAttributes, {
                    findBy: { key: 'id', value: id },
                    additional: { key: 'value', value },
                  })
                }
              />
            ))}
          </SubsectionContainer>

          <SubsectionContainer>
            <SubsectionHeader>Additional Attributes</SubsectionHeader>

            {cashbackCreative.additionalAttributes && cashbackCreative.additionalAttributes.length
              ? cashbackCreative.additionalAttributes.map(({ key, value }) => (
                  <Entry
                    key={`${key}-${value}`}
                    title={key}
                    value={value}
                    highlight={
                      originalCreative &&
                      diffObjInArrayWith(originalCreative.additionalAttributes, {
                        findBy: { key: 'key', value: key },
                        additional: { key: 'value', value },
                      })
                    }
                  />
                ))
              : 'None'}
          </SubsectionContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionHeader>Payout</SectionHeader>

          <Calculation calculation={cashbackCreative.calculation} originalCalculation={originalCreative?.calculation} />

          <Fulfillment fulfillment={cashbackCreative.fulfillment} originalFulfillment={originalCreative?.fulfillment} />
          <Entry
            title="Scratch card expire days"
            value={scratchCard.daysUntilExpires ? scratchCard.daysUntilExpires : 'N/A'}
            highlight={
              originalCreative &&
              (scratchCard?.daysUntilExpires || originalCreativeScratchCard?.daysUntilExpires) &&
              scratchCard?.daysUntilExpires !== originalCreativeScratchCard?.daysUntilExpires
            }
          />

          <SubsectionContainer>
            <SubsectionHeader>Configuration</SubsectionHeader>

            <Entry
              title="Stackable Group"
              value={cashbackCreative.stackableGroup ? cashbackCreative.stackableGroup.name : 'N/A'}
              highlight={
                originalCreative &&
                (cashbackCreative?.stackableGroup || originalCreative?.stackableGroup) &&
                cashbackCreative?.stackableGroup?.name !== originalCreative?.stackableGroup?.name
              }
            />

            <Entry
              title="User Budget Token"
              value={cashbackCreative.userBudgetToken ? cashbackCreative.userBudgetToken.name : 'N/A'}
              highlight={
                originalCreative &&
                (cashbackCreative?.userBudgetToken || originalCreative?.userBudgetToken) &&
                cashbackCreative?.userBudgetToken?.name !== originalCreative?.userBudgetToken?.name
              }
            />

            <Entry
              title="Banner"
              value={cashbackPromoBanner ? cashbackPromoBanner.title : 'N/A'}
              highlight={
                originalCreative &&
                (cashbackCreative?.cashbackPromoBanner || originalCreative?.cashbackPromoBanner) &&
                cashbackCreative?.cashbackPromoBanner?.title !== originalCreative?.cashbackPromoBanner?.title
              }
            />

            {/* TODO: Render selected banner details? */}
          </SubsectionContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionHeader>Criteria</SectionHeader>

          <EventCriteria
            eventType={criteria.eventType}
            eventCriteria={criteria.eventCriteria}
            eventCriteriaJson={criteria.eventCriteriaJson}
            originalCriteria={originalCreative?.criteria}
          />

          <UserCriteria
            userCriteria={criteria.userCriteria}
            userCriteriaJson={criteria.userCriteriaJson}
            originalCriteria={originalCreative?.criteria}
          />
        </SectionContainer>
      </MainPage>
    );
  }
}

export default connect(state => ({
  cashbackCreative: getRegularCashbackCreative(state),
  originalCreative: state.campaigns.campaign.general.originalCampaign?.creative?.regular,
}))(CashbackOverview);
