import moment from 'moment';

import { expiryTypes } from 'app/features/Push2/Creation/Push2Constants';
import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';
import { defaultDateTimeFormat } from 'configs/dateTime';

const required = [
  { title: 'Platforms', value: 'platforms' },
  { title: 'Sender details', value: 'site_id' },
];

const validateContent = content => {
  const contentErrors = [];

  if (isNilOrEmpty(content.title)) {
    contentErrors.push('Push Title is missing');
  }
  if (isNilOrEmpty(content.body)) {
    contentErrors.push('Push Body is missing');
  }
  return contentErrors;
};

export const validateExpiration = content => {
  const minDate = moment().add(1, 'days');
  const expriationErrors = [];
  if (content.expiry_type) {
    if (isNilOrEmpty(content.expiry)) {
      expriationErrors.push('Push notification expriation time is not set');
    } else {
      if (content.expiry_type === expiryTypes.relative && (content.expiry < 1 || Number.isNaN(content.expiry))) {
        expriationErrors.push('Push notification expiration days cannot be less than 1');
      }
      if (
        (content.expiry_type === expiryTypes.date || content.expiry_type === expiryTypes.datetime) &&
        minDate.isAfter(moment(content.expiry, 'YYYY-MM-DDTkk:mm:ssZ').utc())
      ) {
        expriationErrors.push('Push notification expiration date should be at least 1 day apart in future');
      }
    }
  }
  return expriationErrors;
};

const validatePushToChat = state => {
  const { pushToChat, chatCreative } = state;

  if (!!pushToChat) {
    if (!chatCreative || chatCreative.channel_id === undefined || chatCreative.channel_id === '') {
      return ['Push to chat: channel id is required'];
    }
  }

  return [];
};

const validateNotificationExtras = state => {
  const extras = state?.payload?.notification?.extras;
  if (extras && Array.isArray(extras)) {
    for (const extra of extras) {
      if (Object.keys(extra).length === 0) {
        return ['Additional Meta data cannot be empty'];
      }
      if (!extra?.key) {
        return ['Additional Meta data has to include key'];
      }
      if (!extra?.value) {
        return ['Additional Meta data has to include value'];
      }
    }
  }

  return [];
};

const validatePushToNotificationCenter = state => {
  const { pushToNotificationCentre, notificationCreative } = state;

  const now = moment().utc();

  if (!pushToNotificationCentre) {
    return [];
  }

  const { expiry, expiryType } = notificationCreative || {};

  if (!expiryType) {
    return ['Push to notification center: Expiry Type is required'];
  }

  if (expiryType !== 'relative' && !expiry) {
    return ['Push to notification center: expiry date is required'];
  }

  if (expiryType !== 'relative' && !moment(expiry).isValid()) {
    return ['Push to notification center: Invalid expiry date!'];
  }

  if (expiryType !== 'relative' && moment(expiry).isValid() && moment(expiry, 'YYYY-MM-DDTkk:mm:ssZ').utc().isBefore(now)) {
    return [`Push to notification center: Expiry date and time have to be later than ${now.format(defaultDateTimeFormat)} UTC`];
  }

  if (expiryType === 'relative' && expiry < 1) {
    return ['Push to notification center: Relative day must be more than 0'];
  }

  return [];
};

const validatePayload = state => {
  const {
    notification: { content },
  } = state.payload;

  return [
    ...validateContent(content),
    ...validateExpiration(content),
    ...validatePushToNotificationCenter(state),
    ...validatePushToChat(state),
    ...validateNotificationExtras(state),
  ];
};

const validate = state => {
  let errorMessages = [];

  // Validate required fields
  required.forEach(field => (isNilOrEmpty(state[field.value]) ? errorMessages.push(`${field.title} is missing.`) : null));

  // Validate payload
  errorMessages = errorMessages.concat(validatePayload(state));

  const valid = errorMessages.length === 0;
  return {
    ...state,
    valid,
    errorMessages,
  };
};

export default validate;
