import React from 'react';

import DebounceInput from 'app/midgarComponents/DebounceInput';

import Spinner from '../Spinner';
import { SuggestionContainer, TypeaheadWrapper } from './styledComponents';
import { Suggestions } from './Suggestions';
import { TypeaheadProps as Props, TypeaheadState as State } from './types';

export class Typeahead extends React.PureComponent<Props, State> {
  static defaultProps = {
    isPromise: false,
    value: undefined,
  };

  state = {
    suggestions: [],
    open: false,
    loading: false,
    value: undefined,
    left: undefined,
    bottom: undefined,
  };

  componentDidMount() {
    this.keyDownListener = document.addEventListener('keydown', this.handleKeyDown);
    this.mouseDownListener = document.addEventListener('click', this.handleMouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleMouseDown);
  }

  static getDerivedStateFromProps = (nextProps, prevState) =>
    typeof nextProps.value !== 'undefined' && nextProps.value !== prevState.value ? { value: nextProps.value } : null;

  setRef = node => {
    this.nodeRef = node;
  };

  keyDownListener = undefined;

  mouseDownListener = undefined;

  handleChange = v => {
    const { isPromise } = this.props;
    return isPromise ? this.handlePromise(v) : this.handleFunc(v);
  };

  handlePromise = async value => {
    const { typeBase, getSuggestions } = this.props;
    this.setState({ loading: true, open: true });
    const suggestions = await getSuggestions(value, typeBase);
    this.setState({ loading: false, suggestions });
  };

  handleFunc = value => {
    const { getSuggestions, typeBase } = this.props;
    this.setState(s => ({ ...s, suggestions: getSuggestions(value, typeBase), open: true }));
  };

  handleItemSelect = v => {
    const { onItemSelect } = this.props;
    onItemSelect(v);
    this.setState(
      s => ({ ...s, open: false, value: '' }),
      () => this.setState({ value: undefined }),
    );
  };

  handleKeyDown = ({ keyCode }) => keyCode === 27 && this.setState(s => ({ ...s, open: false }));

  handleMouseDown = ({ target }) => this.nodeRef && !this.nodeRef.contains(target) && this.setState(s => ({ ...s, open: false }));

  render() {
    const { value, open, bottom, left, loading, suggestions } = this.state;
    const { name, disabled, debounceTime, placeholder } = this.props;

    return (
      <TypeaheadWrapper className="typeahead" ref={this.setRef}>
        <DebounceInput
          disabled={disabled}
          debounceTime={debounceTime}
          placeholder={placeholder}
          value={value || ''}
          onChange={this.handleChange}
          name={name}
          handleFocus={this.handleChange}
          data-qa="debounce-input"
        />

        {open && (
          <SuggestionContainer bottom={bottom} left={left} data-qa="type-ahead-suggestions">
            {loading ? <Spinner /> : <Suggestions {...this.props} onItemSelect={this.handleItemSelect} suggestions={suggestions} />}
          </SuggestionContainer>
        )}
      </TypeaheadWrapper>
    );
  }
}
