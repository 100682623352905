import styled from 'styled-components';

import { sc } from 'app/styles';

export const Chip = styled.span.attrs(() => ({
  className: 'chip',
}))`
  display: inline-block;
  border-radius: 30px;
  height: 30px;
  padding: 0 1rem;
  font-size: 0.85rem;
  background: ${sc.tertiary};
  line-height: 30px;
  color: #fff;
  cursor: pointer;
`;
