import { connect } from 'react-redux';

import { lifecycle, compose } from 'recompose';

import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  templates: selectors.templates(state),
  templatesById: selectors.byId(state),
  templatesLoading: selectors.loading(state),
  templatesError: selectors.error(state),
});

const connectToRedux = connect(mapStateToProps, operations);

const fetchTemplates = lifecycle({
  componentDidMount() {
    this.props.getTemplates();
  },
});

const ConnectedTemplates = compose(connectToRedux, fetchTemplates);

interface TemplatesContainerProps {
  children?(...args: unknown[]): unknown;
}

const TemplatesContainer = ({ children = null, ...props }: TemplatesContainerProps) => <>{children && children(props)}</>;

const ConnectedTemplatesContainer = ConnectedTemplates(TemplatesContainer);

export { ConnectedTemplates, ConnectedTemplatesContainer };
