import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { Text } from 'app/features/BannerManagement/common/components';
import { genTableColumn } from 'app/features/BannerManagement/common/helpers';
import withWidgetTypes from 'app/features/BannerManagement/hoc/withWidgetTypes';
import { urlView } from 'app/features/BannerManagement/routeUtils';
import { FullTable, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IView } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import EngageStatus from './EngageStatus';

type Props = {
  allSites: Array<string>;
  appConfig: IAppConfig;
  entityId?: number;
  entityType?: string;
  views: Array<IView>;
  readOnly: boolean;
  storefrontId: number;
};

class ViewTable extends PureComponent<Props> {
  getRows = () => {
    const { views } = this.props;
    return views.slice(0, 20);
  };

  getLinkedRows = () => {
    const { entityId, entityType, storefrontId, views } = this.props;

    return views.map((view: IView) => {
      if (view.id && view.managed_by_engage) {
        return {
          ...view,
          to: !view.disabled && urlView(view.id, storefrontId, entityId, entityType),
        };
      }

      return view;
    });
  };

  tableColumns = () => {
    const { allSites, appConfig, readOnly, storefrontId, entityId } = this.props;
    const bannerConfig = getBannerCreativeConfig(appConfig);

    const renderBooleans = (col, row) => (row[col] === 1 ? <Icon name="checkmark" /> : null);

    const renderEngageStatus = (_, { id, managed_by_engage: managedByEngage, supported_by_engage: supportedByEngage }) => (
      <EngageStatus
        entityId={Number(entityId)}
        managedByEngage={managedByEngage}
        readOnly={readOnly}
        storefrontId={storefrontId}
        supportedByEngage={supportedByEngage}
        viewId={id}
      />
    );

    const renderArray = (col, row) => <Text>{row[col].join(', ')}</Text>;

    const renderNameAndID = (_, { id, name }) => (
      <>
        <Text dark>{name}</Text>
        <Text bottom>{`View ID: ${id}`}</Text>
      </>
    );

    const renderWidgetType = (_, { layout }) => layout && <Text>{layout}</Text>;

    const renderPersonalization = (_, { is_recommended }) => is_recommended && <PersonalizationText dark>P</PersonalizationText>; // eslint-disable-line camelcase

    return [
      genTableColumn('Name/Id', 'nameAndId', renderNameAndID),
      ...((allSites || []).length > 1 ? [genTableColumn('Sites', 'site', renderArray)] : []),
      ...(bannerConfig.usePlatformVersion ? [genTableColumn('Platform Version', 'platform_version', renderArray)] : []),
      genTableColumn('Platforms', 'platform', renderArray),
      genTableColumn('Widget Type', 'layout', renderWidgetType),
      ...(bannerConfig.useSellerPanel ? [genTableColumn('Personalization', 'is_recommended', renderPersonalization)] : []),
      ...(bannerConfig.useSellerPanel ? [genTableColumn('Status', 'status', renderBooleans)] : []),
      // TODO: Hide this if !useSellerPanel, but add Delete either here or on the view page
      genTableColumn('Engage?', 'managed_by_engage', renderEngageStatus),
      // ...(bannerConfig.useSellerPanel ? [genTableColumn('Engage?', 'managed_by_engage', renderEngageStatus)] : []),
    ];
  };

  render() {
    return <FullTable rows={this.getLinkedRows()} columns={this.tableColumns()} rowEditable={false} />;
  }
}

export default withWidgetTypes(ViewTable);

const PersonalizationText = styled(Text)`
  color: ${sc.primary};
  font-size: ${sc.fontSize};
`;
