import { connect } from 'react-redux';

import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { getRegularCashbackCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';

import Calculation from '../../../Creation/Calculation';

export const RegularCalculationContainer = connect(
  state => {
    const cashbackCreative = getRegularCashbackCreative(state);
    const {
      calculation: { payoutStrategy },
    } = cashbackCreative;
    return payoutStrategy;
  },
  {
    updateCalculationField: cashbackActions.updateCashbackCalculationField,
    udpateCalculationType: cashbackActions.udpateCashbackCalculationType,
    updatePercentageType: cashbackActions.updateCashbackPercentageType,
    updatePaymentMethods: cashbackActions.updateCashbackPaymentMethods,
  },
)(Calculation);
