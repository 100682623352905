import { fetchLandingPageTypes as v1FetchLandingPageTypes } from 'app/api/bannerManagementV1/viewItems';
import { fetchLandingPageTypes as v2FetchLandingPageTypes } from 'app/api/bannerManagementV2/viewItems';
import bmsVersions from 'app/api/bmsCommon/bmsVersions';
import { ILandingPageType } from 'app/types/BannerManagement';
import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';

export const fetchLandingPageTypes = (bmsVersion: string) => () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      landingPageTypes: { loading, landingPageTypes: prevLandingPageTypes },
    },
  } = getState();

  if (loading || (prevLandingPageTypes || []).length > 0) {
    return;
  }

  dispatch(actions.getLandingPageTypesStart());

  try {
    const useBmsV2 = bmsVersions.useV2(bmsVersion);
    const { viewItemLandingTypes: landingPageTypesByName } = useBmsV2 ? await v2FetchLandingPageTypes() : await v1FetchLandingPageTypes();

    const landingPageTypes: Array<ILandingPageType> = Object.keys(landingPageTypesByName || {}).reduce(
      (acc, name) => [...acc, ...(landingPageTypesByName[name] || [])],
      [],
    );

    return dispatch(actions.getLandingPageTypesSuccess(landingPageTypes));
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getLandingPageTypesFailed(errorStr));
  }
};
