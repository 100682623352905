import React, { PureComponent } from 'react';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import styled from 'styled-components';

import DateTimeUtcField from 'app/midgarComponents/DateTimeUtcField';
import DaysOfWeek from 'app/midgarComponents/DaysOfWeek';
import _Dropdown from 'app/midgarComponents/Dropdown';
import _Input from 'app/midgarComponents/Input';
import StringSetPills from 'app/midgarComponents/TypedAttributes/InputTypes/StringSetPills';

import {
  arrayOperators,
  momentify,
  FIELD_TYPES,
  AGO,
  LAST,
  BETWEEN_TIME_OF_DAY,
  ON_DAY_OF_WEEK,
  FORMAT,
  DATE_TIME_OPERATORS,
} from './helper';
import CsvUpload from './InputTypes/CsvUpload';

type Props = {
  options?: Array<string | number>;
  value: any;
  type: string;
  onChange: (...args: Array<any>) => any;
  operator: string;
  name: string;
  label: string;
};

export default class PredicateInput extends PureComponent<Props> {
  handleTimePickerChange = (ev, fieldName) => {
    const { onChange, value } = this.props;
    onChange({ ...value, [fieldName]: ev });
  };

  render() {
    const { type, value, onChange, options, operator, name, label } = this.props;
    if (type === FIELD_TYPES.dateTime && DATE_TIME_OPERATORS.includes(operator)) {
      return <DateTimeUtcField label={label} name={name} onChange={onChange} required value={value} />;
    }
    if (type === FIELD_TYPES.dateTime && operator === ON_DAY_OF_WEEK) {
      return <DaysOfWeek updateDaysFn={daysList => onChange(daysList.join(','))} selectedDays={value ? value.split(',') : []} />;
    }

    if (type === FIELD_TYPES.dateTime && operator === BETWEEN_TIME_OF_DAY) {
      return (
        <>
          <StyledTimePicker
            style={{ width: 100 }}
            id="time-from"
            name="from"
            showSecond={Boolean(false)}
            format={FORMAT}
            value={momentify(value.from) || null}
            onChange={val => this.handleTimePickerChange(val, 'from')}
            minuteStep={15}
          />

          <And>and</And>
          <StyledTimePicker
            style={{ width: 100 }}
            id="time-to"
            name="to"
            showSecond={Boolean(false)}
            format={FORMAT}
            value={momentify(value.to) || null}
            onChange={val => this.handleTimePickerChange(val, 'to')}
            minuteStep={15}
          />
        </>
      );
    }
    if (type === FIELD_TYPES.dateTime && (operator === AGO || operator === LAST)) {
      return (
        <Flex>
          <Input
            type="number"
            min={1}
            value={value.unitValue}
            onChange={({ target: { value: _value } }) => onChange({ unitValue: _value, unit: value.unit })}
          />

          <Dropdown
            options={['minutes', 'hours', 'days']}
            value={value.unit}
            onChange={newUnit => onChange({ unitValue: value.unitValue, unit: newUnit })}
          />
        </Flex>
      );
    }

    if (options) {
      if (arrayOperators.includes(operator.toLowerCase())) {
        return (
          <StringSetPills
            name={name}
            value={value}
            label={label}
            possibleValues={options.join(',')} // TODO: Make StringsetPills able to handle string or array.
            handleChange={onChange}
          />
        );
      }
      return <Dropdown label={label} options={options} value={value} onChange={onChange} />;
    }

    if (arrayOperators.includes(operator.toLowerCase())) {
      const allowComma = ['in', 'notin'].includes(operator.toLowerCase());
      return (
        <Flex>
          <Input type={type} value={value} onChange={({ target: { value: inputValue } }) => onChange(inputValue)} allowComma={allowComma} />
          <CsvUpload onChange={onChange} />
        </Flex>
      );
    }

    return <Input type={type} value={value} onChange={({ target: { value: inputValue } }) => onChange(inputValue)} />;
  }
}

const Input = styled(_Input)`
  padding: 0 1rem;
  width: 100%;
  height: 55px;
  margin-bottom: 0;
`;

const Dropdown = styled(_Dropdown)`
  min-width: 150px;
`;

const Flex = styled.div`
  display: inline-flex;
  width: inherit;

  & > Dropdown {
    margin-left: 10px;
  }
`;

const And = styled.span`
  padding: 0 1rem;
`;

const StyledTimePicker = styled(TimePicker)`
  & .rc-time-picker-input {
    height: 55px;
    padding: 15px;
  }
`;
