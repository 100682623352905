import { isEmpty } from 'ramda';

import { emptyVariant } from 'app/ducks/bannerManagement/creative/helpers';
import { IAppVersionFilter, IVariant } from 'app/types/BannerManagement';
import { ICategory } from 'app/types/Category';

import { fromApiCategoryWeights, toApiCategoryWeights } from './categoryWeight';
import { fromApiLocationFilter, toLocationFilters } from './location';
import { arrayToIndexedObject } from './utils';
import { IApiVariant } from '../types';

export const fromApiVariant =
  (categories: Array<ICategory>) =>
  (variant: IApiVariant | null | undefined): IVariant => {
    const { alternateImage, categoryWeights2, createdAt, createdBy, image, updatedAt, ...rest } = variant || {};

    return {
      ...emptyVariant(),
      ...rest,
      alternateImageUrl: alternateImage,
      appVersions: arrayToIndexedObject<Partial<IAppVersionFilter>>(variant?.appVersionFilter, { '0': {} }),
      engageCategories: fromApiCategoryWeights(categories, variant?.categoryWeights2),
      imageUrl: image,
      locations: fromApiLocationFilter(variant?.locationFilter),
    };
  };

export const toApiVariant = (variant: IVariant): IApiVariant => {
  const appVersionFilters = Object.keys(variant.appVersions || {})
    .map(appVersionKey => variant.appVersions[appVersionKey])
    .filter(appVersion => !isEmpty(appVersion));

  const categoryWeights = toApiCategoryWeights(variant.engageCategories);

  const locationFilters = toLocationFilters(variant.locations);

  return {
    alternateImage: variant.alternateImageUrl,
    appVersionFilter: appVersionFilters.length ? appVersionFilters : null,
    categoryWeights2: categoryWeights.length ? categoryWeights : null,
    id: variant.id,
    image: variant.imageUrl,
    labelDetails: variant.labelDetails,
    landingPageType: variant.landingPageType,
    latLongId: variant.latLongId,
    locationFilter: locationFilters.length ? locationFilters : null,
    metadata: variant.metadata,
    name: variant.name,
    personalizationMetadata: variant.personalizationMetadata,
    subTitle: variant.subTitle,
    title: variant.title,
    // trackingOnly: !!variant.trackingOnly, // TODO: Not passed to the API. Should it be?
    variantType: variant.variantType,
    widgetType: variant.widgetType,
    [variant.itemId ? 'itemId' : 'url']: variant.itemId || variant.url,
  };
};
