import styled from 'styled-components';

import { sc } from 'app/styles';

export const LoadMoreContainer = styled.div`
  position: relative;
  color: ${sc.primary};
  font-weight: bold;
  padding: 25px;
  text-align: center;
  cursor: pointer;
`;
