import { IUserBudgetToken } from 'app/features/CashbackPromoManagement/UserBudgetTokens/types';

import * as types from './types';
import { IFetch, fetch } from '../../commonStates';

type State = IFetch & {
  userBudgetTokens: Array<IUserBudgetToken>;
};

const initialState = {
  ...fetch,
  userBudgetTokens: [],
};

export default (state: State = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case types.ADD:
      return {
        ...state,
        error: null,
        loading: false,
        userBudgetTokens: [payload.userBudgetToken, ...state.userBudgetTokens],
      };

    case types.GET_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
        userBudgetTokens: [],
      };

    case types.GET_INIT:
      return {
        ...state,
        error: null,
        loading: true,
        userBudgetTokens: [],
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        userBudgetTokens: payload.userBudgetTokens,
      };

    case types.UPDATE:
      return {
        ...state,
        error: null,
        loading: false,
        userBudgetTokens: (state.userBudgetTokens || []).map<IUserBudgetToken>(t =>
          t.id === payload.userBudgetToken.id ? payload.userBudgetToken : t,
        ),
      };

    default:
      return state;
  }
};
