import update from 'immutability-helper';

import { AppState, Action } from 'app/types/state';
import { momentToDateTimeString } from 'app/utilities/date';

import * as types from './types';

const DEFAULT_VIEW_ITEM_PAGE_SIZE = 50;

const initialState = {
  view: {
    view: {
      metadata: {},
    },

    slots: [],
    quotas: [],
  },

  error: undefined,
  loading: false,

  bindError: null,
  bindLoading: false,

  bulkAction: null,
  bulkActionError: null,
  bulkActionInProgress: false,

  viewItem: null,
  viewItemMoving: false,
  viewItemLoading: false,
  saveVariantLoading: false,
  saveViewItemLoading: false,
  spViewLoading: false,
  highlightedViewItem: null,
  searchedViewItems: [],

  slotViewItems: [],
  slotViewItemsPage: 1,
  slotViewItemsPageSize: DEFAULT_VIEW_ITEM_PAGE_SIZE,
  slotViewItemsTotalPages: 1,
  slotViewItemsVersion: null,
  slotViewItemsLoading: false,

  saveVariantStatusLoading: [],
  isPersonalizedSaving: false,

  selectedSlot: {},
  selectedSlotGroupType: null,
  selectedDates: {},
  selectedValidDates: {},
  selectedViewItemId: null,

  orderUpdated: false,
  slotOrderUpdated: false,
  addSlotLoading: false,
  slotDeleting: false,

  autopilot: {
    enabled: false,
    categories: [],
    viewModelTypes: [],
    selectedViewModelType: null,
  },
};

export default (state: AppState = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.UPDATE_SLOT_ORDER: {
      const { from, to } = payload;
      const slots = state.view.slots.slice();
      const fromSlot = { ...slots[from] };

      slots.splice(from, 1);
      slots.splice(to, 0, fromSlot);

      return update(state, {
        view: { slots: { $set: slots } },
        slotOrderUpdated: { $set: true },
      });
    }

    case types.UPDATE_SLOT_ORDER_SUCCESS: {
      return update(state, {
        slotOrderUpdated: { $set: false },
      });
    }

    case types.UPDATE_SLOT_ORDER_ERROR: {
      return update(state, {
        slotOrderUpdated: { $set: false },
      });
    }

    // Save variant
    case types.SAVE_VARIANT_ERROR: {
      return update(state, {
        saveVariantLoading: { $set: false },
        error: { $set: payload.error },
      });
    }

    case types.SAVE_VARIANT_START: {
      return update(state, {
        saveVariantLoading: { $set: true },
      });
    }

    case types.SAVE_VARIANT_SUCCESS: {
      const viewItem = { ...state.viewItem };
      const idx = viewItem.variants && viewItem.variants.findIndex(x => x.id === payload.variant.id);

      if (idx || idx === 0) {
        viewItem.variants[idx] = payload.variant;
      }

      return update(state, {
        viewItem: { $set: viewItem },
        saveVariantLoading: { $set: false },
      });
    }

    // Save Variant Status
    case types.SAVE_VARIANT_STATUS_ERROR: {
      const variantId = payload.variant.id;
      return update(state, {
        saveVariantStatusLoading: { $set: state.saveVariantStatusLoading.filter(id => id !== variantId) },
        error: { $set: payload.error },
      });
    }

    case types.SAVE_VARIANT_STATUS_START: {
      const variantId = payload.variant.id;
      return state.saveVariantStatusLoading.find(id => id === variantId)
        ? state
        : update(state, {
            saveVariantStatusLoading: { $set: [...state.saveVariantStatusLoading, variantId] },
          });
    }

    case types.SAVE_VARIANT_STATUS_SUCCESS: {
      const variantId = payload.variant.id;
      return update(state, {
        saveVariantStatusLoading: { $set: state.saveVariantStatusLoading.filter(id => id !== variantId) },
      });
    }

    case types.UPDATE_VARIANT_STATUS: {
      const slotViewItems = state.slotViewItems.slice();
      const viewItemIdx = slotViewItems?.findIndex(viewItem => viewItem.id === payload.viewItem.id);
      if (viewItemIdx >= 0) {
        const variantIdx = slotViewItems[viewItemIdx].variants?.findIndex(v => v.id === payload.variant.id);
        if (variantIdx >= 0) {
          slotViewItems[viewItemIdx].variants[variantIdx].status = payload.status;
        }
      }

      return update(state, {
        slotViewItems: { $set: slotViewItems },
      });
    }

    // Personalization
    case types.SET_IS_PERSONALIZED_SAVING:
      return update(state, {
        isPersonalizedSaving: { $set: payload.isPersonalizedSaving },
      });

    case types.SET_IS_PERSONALIZED:
      return update(state, {
        view: {
          view: {
            isPersonalized: { $set: payload.isPersonalized },
          },
        },
      });

    // View item
    case types.FETCH_VIEW_ITEM_START: {
      return update(state, {
        viewItemLoading: { $set: true },
      });
    }

    case types.FETCH_VIEW_ITEM_SUCCESS: {
      const {
        viewItem: { startDate, endDate, validFrom, validUpto },
      } = payload;
      return update(state, {
        viewItem: { $set: payload.viewItem },
        selectedDates: {
          startDate: { $set: startDate },
          endDate: { $set: endDate },
          validFrom: { $set: validFrom },
          validUpto: { $set: validUpto },
        },

        viewItemLoading: { $set: false },
      });
    }

    case types.FETCH_VIEW_ITEM_ERROR: {
      return update(state, {
        viewItem: { $set: null },
        viewItemLoading: { $set: false },
        error: { $set: payload.error },
      });
    }

    // Forms
    case types.SET_VIEWITEM_FIELD:
      return update(state, {
        viewItem: {
          [payload.field]: { $set: payload.value },
        },
      });

    case types.SAVE_VIEWITEM_START:
      return update(state, {
        saveViewItemLoading: { $set: true },
      });

    case types.SAVE_VIEWITEM_SUCCESS: {
      let selectedSlot = { ...state.selectedSlot };
      const slots = state.view.slots.slice();

      if (selectedSlot.id !== payload.slotId) {
        const idxFrom = slots.findIndex(x => x.id === state.selectedSlot.id);
        const idxTo = slots.findIndex(x => x.id === payload.slotId);

        if (idxFrom > -1) {
          slots[idxFrom].itemCount--;
        }

        if (idxTo > -1) {
          selectedSlot = slots[idxTo];
          selectedSlot.itemCount++;
        }
      }

      return update(state, {
        view: { slots: { $set: slots } },
        selectedSlot: { $set: selectedSlot },
        error: { $set: null },
        saveViewItemLoading: { $set: false },
      });
    }

    case types.SAVE_VIEWITEM_ERROR:
      return update(state, {
        error: { $set: payload.error },
        saveViewItemLoading: { $set: false },
      });

    // Views
    case types.GET_VIEW:
      return {
        ...initialState,
        loading: true,
      };

    case types.GET_VIEW_SUCCESS: {
      const { view, slotId } = payload;
      let selectedSlot = view.slots && view.slots.length ? view.slots[0] : {};

      if (slotId) {
        selectedSlot = view.slots.find(x => x.id === slotId) || selectedSlot;
      }

      return update(state, {
        loading: { $set: false },
        view: {
          view: { $set: view },
          slots: { $set: view.slots },
        },

        selectedSlot: { $set: selectedSlot },
      });
    }

    case types.GET_SP_VIEW_START: {
      return update(state, {
        spViewLoading: { $set: true },
      });
    }

    case types.GET_SP_VIEW_SUCCESS: {
      const { view, spView } = payload;
      const mergedView = { ...view, isPersonalized: !!spView.is_recommended };

      return update(state, {
        view: { view: { $set: mergedView } },
        spViewLoading: { $set: false },
      });
    }

    case types.GET_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.SET_SELECTED_SLOT_GROUP_TYPE: {
      return update(state, {
        selectedSlotGroupType: { $set: payload.slotGroupType },
      });
    }

    case types.RESET_DATES: {
      return update(state, {
        selectedDates: { $set: {} },
      });
    }

    case types.SET_SELECTED_DATE: {
      const { field, value } = payload;
      return update(state, {
        selectedDates: {
          [field]: { $set: momentToDateTimeString(value) },
        },
      });
    }

    // Bind creative
    case types.SET_SELECTED_VIEW_ITEM_ID: {
      return update(state, {
        selectedViewItemId: { $set: payload.id },
      });
    }

    case types.BIND_CREATIVE_START: {
      return update(state, {
        bindLoading: { $set: true },
      });
    }

    case types.BIND_CREATIVE_SUCCESS: {
      const { slotId } = payload;
      const selectedSlot = { ...state.selectedSlot };
      const slots = state.view.slots.slice();
      const idx = slots.findIndex(x => x.id === slotId);

      if (idx > -1) {
        slots[idx].itemCount++; // eslint-disable-line no-plusplus
        selectedSlot.itemCount++; // eslint-disable-line no-plusplus
      }
      return update(state, {
        bindError: { $set: null },
        bindLoading: { $set: false },
        view: { slots: { $set: slots } },
        selectedSlot: { $set: selectedSlot },
      });
    }

    case types.BIND_CREATIVE_ERROR: {
      return update(state, {
        bindError: { $set: payload.error },
        bindLoading: { $set: false },
      });
    }

    // Slots
    case types.UPDATE_SLOT_STATUS_SUCCESS: {
      const { slotId, status } = payload;
      const selectedSlot = { ...state.selectedSlot };
      const slots = state.view.slots.slice();
      const idx = slots.findIndex(x => x.id === slotId);

      if (idx > -1) {
        selectedSlot.status = status ? 1 : 0;
        slots[idx].status = status ? 1 : 0;
      }

      return update(state, {
        selectedSlot: { $set: selectedSlot },
        view: { slots: { $set: slots } },
      });
    }

    case types.UPDATE_SLOT_OVERRIDE_SUCCESS: {
      const { slotId, override } = payload;
      const selectedSlot = { ...state.selectedSlot };
      const slots = state.view.slots.slice();
      const idx = slots.findIndex(x => x.id === slotId);

      if (idx > -1) {
        selectedSlot.overridden = override;
        slots[idx].overridden = override;
      }

      return update(state, {
        selectedSlot: { $set: selectedSlot },
        view: { slots: { $set: slots } },
      });
    }

    case types.DELETE_SLOT_START:
      return update(state, {
        slotDeleting: { $set: true },
      });

    case types.DELETE_SLOT_SUCCESS: {
      const { slotId } = payload;
      const slots = state.view.slots.slice();
      const idx = slots.findIndex(x => x.id === slotId);

      if (idx > -1) {
        slots.splice(idx, 1);
      }
      return update(state, {
        slotDeleting: { $set: false },
        view: { slots: { $set: slots } },
        selectedSlot: { $set: slots[0] ? slots[0] : {} },
      });
    }
    case types.DELETE_SLOT_FAILED: {
      return update(state, {
        slotDeleting: { $set: false },
      });
    }
    case types.ADD_SLOT:
      return update(state, {
        addSlotLoading: { $set: true },
      });

    case types.ADD_SLOT_SUCCESS: {
      const { slots } = payload;
      const sortedSlots = slots.sort((a, b) => a.priority - b.priority);

      return update(state, {
        addSlotLoading: { $set: false },
        view: { slots: { $set: sortedSlots } },
        selectedSlot: { $set: state.selectedSlot.id ? state.selectedSlot : sortedSlots[0] },
        error: { $set: null },
      });
    }

    case types.ADD_SLOT_FAILED:
      return update(state, {
        addSlotLoading: { $set: false },
        error: { $set: payload.error },
      });

    case types.UPDATE_SELECTED_SLOT: {
      return update(state, {
        selectedSlot: { $set: payload.selectedSlot },
      });
    }

    case types.GET_SLOT_VIEW_ITEMS: {
      return update(state, {
        slotViewItemsLoading: { $set: true },
      });
    }

    case types.GET_SLOT_VIEW_ITEMS_SUCCESS: {
      const pageSize = state.slotViewItemsPageSize;

      return update(state, {
        orderUpdated: { $set: false },
        slotViewItems: { $set: payload.viewItems },
        slotViewItemsPage: { $set: payload.page || 1 },
        slotViewItemsTotalPages: { $set: Math.ceil(payload.totalRows / pageSize) },
        slotViewItemsVersion: { $set: payload.version },
        slotViewItemsLoading: { $set: false },
      });
    }

    case types.GET_SLOT_VIEW_ITEMS_FAILED: {
      return update(state, {
        slotViewItemsLoading: { $set: false },
        error: { $set: payload.error },
      });
    }

    case types.SET_SLOT_VIEW_ITEMS_PAGE: {
      return update(state, {
        slotViewItemsPage: { $set: payload.page },
      });
    }

    case types.SET_SLOT_VIEW_ITEMS_PAGE_SIZE: {
      return update(state, {
        slotViewItemsPageSize: { $set: payload.pageSize },
      });
    }

    case types.REORDER_VIEW_ITEMS: {
      return update(state, {
        slotViewItems: { $set: payload.viewItems },
        orderUpdated: { $set: true },
      });
    }

    case types.RESET_VIEW_ITEM: {
      return update(state, {
        viewItem: { $set: initialState.viewItem },
        selectedDates: { $set: initialState.selectedDates },
        viewItemLoading: { $set: false },
      });
    }

    case types.MOVE_AFTER_BANNER_ID_START: {
      return update(state, {
        viewItemMoving: { $set: true },
      });
    }

    case types.MOVE_AFTER_BANNER_ID_SUCCESS: {
      const { priority } = payload;
      const viewItem = priority ? { id: 'move-after-banner-id', priority } : state.highlightedViewItem;

      return update(state, {
        viewItemMoving: { $set: false },
        highlightedViewItem: { $set: viewItem },
      });
    }

    case types.MOVE_AFTER_BANNER_ID_ERROR: {
      return update(state, {
        viewItemMoving: { $set: false },
      });
    }

    case types.FETCH_VIEW_ITEM_LOCATION_SUCCESS: {
      return update(state, {
        highlightedViewItem: { $set: payload.viewItem },
      });
    }

    case types.FETCH_VIEW_ITEMS_LOCATION_SUCCESS: {
      return update(state, {
        searchedViewItems: { $set: [...payload.viewItems] },
      });
    }

    case types.RESET_HIGHLIGHTED_VIEW_ITEM: {
      return update(state, {
        highlightedViewItem: { $set: null },
      });
    }

    case types.UPDATE_VIEW_FIELD: {
      const { field } = payload;
      return update(state, {
        view: {
          view: { $merge: field },
        },
      });
    }

    case types.RESET_VIEW: {
      return update(state, {
        view: {
          view: { $set: {} },
        },
      });
    }

    case types.SAVE_VIEW_FAILED:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    case types.SAVE_VIEW_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case types.SAVE_VIEW_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case types.BULK_VIEW_ITEM_ACTION_CLEAR_ERROR:
      return update(state, {
        bulkActionError: { $set: null },
      });

    case types.BULK_VIEW_ITEM_ACTION_ERROR:
      return update(state, {
        bulkAction: { $set: payload.bulkAction },
        bulkActionError: { $set: payload.error },
        bulkActionInProgress: { $set: false },
      });

    case types.BULK_VIEW_ITEM_ACTION_START:
      return update(state, {
        bulkAction: { $set: payload.bulkAction },
        bulkActionError: { $set: null },
        bulkActionInProgress: { $set: true },
      });

    case types.BULK_VIEW_ITEM_DELETE_SUCCESS: {
      const { ids, version } = payload;
      const slotViewItems = state.slotViewItems.filter(item => !ids.includes(item.id));

      return update(state, {
        bulkAction: { $set: payload.bulkAction },
        bulkActionError: { $set: null },
        bulkActionInProgress: { $set: false },
        slotViewItems: { $set: slotViewItems },
        slotViewItemsVersion: { $set: version },
      });
    }

    case types.BULK_VIEW_ITEM_MOVE_SUCCESS: {
      const { ids } = payload;
      const slotViewItems = state.slotViewItems.filter(item => !ids.includes(item.id));

      return update(state, {
        bulkAction: { $set: payload.bulkAction },
        bulkActionError: { $set: null },
        bulkActionInProgress: { $set: false },
        slotViewItems: { $set: slotViewItems },
      });
    }

    default:
      return state;
  }
};
