import Table from '@material-ui/core/Table';

import Paper from 'app/midgarComponents/Paper';

import { Body } from './Body';
import { Header } from './Header';

type Props = {
  classes: Record<string, any>;
};

export const EditableTable = (props: Props) => {
  const {
    classes: { tableContainer, tableWrapper, table },
  } = props;

  return (
    <Paper className={tableContainer}>
      <section className={tableWrapper}>
        <Table className={table}>
          <Header {...props} />
          <Body {...props} />
        </Table>
      </section>
    </Paper>
  );
};
