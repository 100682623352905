import { stringify } from 'query-string';

import {
  ICampaignAttribute,
  IPaymentCeiling,
  IPromoEvent,
  IStackableGroup,
  IStackableGroupCampaign,
  IUserBudgetToken,
} from 'app/features/CashbackPromoManagement/types';
import { get, post, put } from 'app/utilities/http';

import endpoints from './endpoints';
import { fromApiBanner, fromApiBanners, toApiBanner } from './mappers';
import { ICashbackBannerCreative } from '../../features/Cashback/types';

export const CampaignAttributesScope = {
  RegularBanner: 'banner',
  JourneyBanner: 'journey_banner',
  JourneyStage: 'journey_stage',
  JourneyCampaign: 'journey_campaign',
};

export const fetchCampaignAttributes = async (scope: string, medium: string): Promise<Array<ICampaignAttribute>> =>
  get(endpoints.campaignAttributes(scope, medium));

export const fetchAllCashbackBanners = async (query: Record<string, string>): Promise<Array<ICashbackBannerCreative>> => {
  const banners = await get(
    Object.keys(query).length > 0 ? `${endpoints.getAllCashbackBanners}?${stringify(query)}` : `${endpoints.getAllCashbackBanners}`,
  );

  return fromApiBanners(banners);
};

export const fetchCashbackBanner = async (bannerId: number): Promise<ICashbackBannerCreative> => {
  const banner = await get(endpoints.getCashbackBanner(bannerId));
  return fromApiBanner(banner);
};

export const saveCashbackBanner = (banner: ICashbackBannerCreative): Promise<{ id: number }> =>
  post(endpoints.postCashbackBanner, toApiBanner(banner));

export const saveJourneyBanner = (banner: ICashbackBannerCreative): Promise<{ id: number }> =>
  post(endpoints.postJourneyBanner, toApiBanner(banner));

export const updateCashbackBanner = (banner: ICashbackBannerCreative, bannerId: number): Promise<{ id: number }> =>
  put(`${endpoints.postCashbackBanner}/${bannerId}`, toApiBanner(banner, false));

export const updateJourneyBanner = (banner: ICashbackBannerCreative, bannerId: number): Promise<{ id: number }> =>
  put(`${endpoints.postJourneyBanner}/${bannerId}`, toApiBanner(banner, false));

export const updateCashbackBannerPriorties = async (
  bannerMappings: Array<{ id: number; priority: number }>,
): Promise<Array<ICashbackBannerCreative>> => put(endpoints.putCashbackBannersPriorties, bannerMappings);

export const fetchStackableGroups = async (): Promise<Array<IStackableGroup>> => get(endpoints.getStackableGroups);

export const fetchStackableGroupEvents = (groupId: number): Promise<Array<string>> => get(endpoints.getStackableGroupEvents(groupId));

export const fetchStackableGroupCampaigns = (groupId: number, event: string): Promise<Array<IStackableGroupCampaign>> =>
  get(endpoints.getStackableGroupCampaigns(groupId, event)).then(({ campaigns }) => campaigns);

export const saveStackableGroupPriorities = (groupId: number, event: string, campaignIds: Array<number>): Promise<{}> =>
  post(endpoints.postStackableGroupPriorities(groupId, event), { campaignIds });

export const fetchUserBudgetTokens = async (): Promise<Array<IUserBudgetToken>> => get(endpoints.getUserBudgetTokens);

export const postUserBudgetToken = async (token: IUserBudgetToken): Promise<{ id: number }> => post(endpoints.postUserBudgetToken, token);

export const fetchPaymentCeilings = async (): Promise<Array<IPaymentCeiling>> => get(endpoints.getPaymentCeilings);

export const putPaymentCeiling = async (ceiling: IPaymentCeiling): Promise<IPaymentCeiling> =>
  put(endpoints.putPaymentCeiling(ceiling.id), ceiling);

export const fetchAllPromoEvents = async (): Promise<Array<IPromoEvent>> => get(endpoints.getAllPromoEvents);

export const fetchCustomerPromoEvent = async (): Promise<Array<IPromoEvent>> => get(endpoints.getCustomerPromoEvent);
