import React from 'react';

const defaultValue = `
<html>
  <head></head>
  <body></body>
</html>
`;

interface HTMLEditorProps {
  value?: string;
  change(...args: unknown[]): unknown;
  classes: {
    editor?: string;
  };
}

export const HTMLEditor = ({ value = defaultValue, change, classes }: HTMLEditorProps) => (
  <textarea className={classes.editor} value={value} onChange={change} />
);
