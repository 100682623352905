import { fetchVariantTypes as apiFetchVariantTypes } from 'app/api/bannerManagementV2/viewItems';
import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';

export const fetchVariantTypes = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      variantTypes: { loading, variantTypes: prevVariantTypes },
    },
  } = getState();

  if (loading || (prevVariantTypes || []).length > 0) {
    return;
  }

  dispatch(actions.getVariantTypesStart());

  try {
    const variantTypes = await apiFetchVariantTypes();
    return dispatch(actions.getVariantTypesSuccess(variantTypes));
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getVariantTypesFailed(errorStr));
  }
};
