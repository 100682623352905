import { ReactNode } from 'react';

import Typography from '@material-ui/core/Typography';

type CardProps = {
  classes: Partial<{
    default: string;
    primary: string;
    body: string;
    header: string;
    label: string;
  }>;

  cardStyle?: 'default' | 'primary';
  children: ReactNode;
  label: string | ((...args: Array<any>) => any);
  style?: Record<string, any>;
  actions?: ReactNode;
};

export const Card = ({ classes, cardStyle = 'default', children, label, style = {}, actions }: CardProps) => (
  <section className={cardStyle === 'default' ? classes.default : classes.primary} style={style}>
    <header className={classes.header}>
      {typeof label === 'string' ? (
        <Typography variant="subtitle1" className={classes.label}>
          {label}
        </Typography>
      ) : (
        label(classes)
      )}

      {actions}
    </header>
    <section className={classes.body}>{children}</section>
  </section>
);
