import React from 'react';

import styled, { css, keyframes } from 'styled-components';

import { sc } from 'app/styles';

export default class ProgressBar extends React.PureComponent<{
  width?: number;
  height?: number;
  color?: string;
}> {
  render() {
    const { width, height, color } = this.props;
    return (
      <Container width={width} height={height}>
        <ProgressBarInner color={color} />
      </Container>
    );
  }
}

const progressAnimation = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;
const animation = css`
  ${progressAnimation} 2s linear;
`;
const Container = styled.section`
  width: ${props => props.width || 78}px;
  height: ${props => props.height || 5}px;
  border-radius: 3.5px;
  background-color: ${sc.sectionBorderColor};
  overflow: hidden;
`;

const ProgressBarInner = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  background-color: ${props => props.color || sc.success};
  animation: ${animation};
`;
