import { fetch } from 'app/ducks/commonStates';

import * as types from './types';

const initState = {
  ...fetch,
  allIds: [],
  byId: {},
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case types.GET_FEATURES:
      return {
        ...initState,
        loading: true,
        error: null,
      };

    case types.GET_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...payload.featuresLookups,
        ...payload.paged,
      };

    case types.GET_FEATURES_FAIL:
      return {
        ...state,
        loading: false,
        allIds: [],
        byId: {},
        error: payload.error,
      };

    default:
      return state;
  }
};
