import { IBannerCreative } from 'app/types/BannerManagement';
import { ICategory } from 'app/types/Category';
import { ISuggestion } from 'app/types/utils';

import { IApiBannerCreative } from '../types';
import { arrayToIndexedObject } from './utils';
import { fromApiVariant, toApiVariant } from './variant';

const fromApiPlatform = (platform: string): ISuggestion => ({
  id: platform.toLowerCase(),
  name: platform.toUpperCase(),
});

const fromApiSite = (site: string): ISuggestion => ({
  id: site.toLowerCase(),
  name: site,
});

export const fromApiCreative = (categories: Array<ICategory>, apiCreative: IApiBannerCreative): IBannerCreative => {
  const { createdAt, createdBy, isAd, showOnlyOnBoost, updatedAt, variants: apiVariants, ...passThrough } = apiCreative;

  const variants = (apiVariants || []).map(fromApiVariant(categories));

  return {
    ...passThrough,
    creativeType: apiCreative.variants?.length > 1 ? 'Group' : 'Individual',
    platform: (apiCreative.platform || []).map(fromApiPlatform),
    site: (apiCreative.site || []).map(fromApiSite),
    variants: arrayToIndexedObject(variants),
  };
};

export const toApiCreative = (creative: IBannerCreative): IApiBannerCreative => {
  const variantKeys = Object.keys(creative.variants || {});

  const apiVariants = variantKeys.map((variantKey: string) => {
    const variant = creative.variants[variantKey];
    return toApiVariant(variant);
  });

  const { platform, site, subtitle, title, variants, ...passThrough } = creative;

  return {
    ...passThrough,
    platform: creative?.platform.length ? creative.platform.map<string>(x => x.name) : [], // TODO: Cannot be empty
    site: creative?.site.length ? creative.site.map<string>(x => x.name) : [], // TODO: Cannot be empty
    ...(subtitle?.length ? { subtitle } : {}),
    ...(title?.length ? { title } : {}),
    variants: apiVariants,
  };
};
