import { stringify } from 'query-string';

import { IQueryParams } from 'app/types/IQueryParams';
import * as fetch from 'app/utilities/http';

import endpoints from './endpoints';

export const post = async tag => fetch.post(endpoints.post, tag);

export const getAll = async () => fetch.get(endpoints.getAll);

export const getV2Tags = (params: IQueryParams) => fetch.get(endpoints.getV2Tags.replace(':params', stringify(params)));
