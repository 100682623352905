import { Action } from 'app/types/state';

import * as types from './types';

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const startFetch = () => ({
  type: types.FETCH_STATES_START,
});

export const fetchStatesSuccess = (stateValues: Array<string>): Action => {
  const states = stateValues.map(x => capitalize(x));

  return {
    type: types.FETCH_STATES_SUCCESS,
    payload: { states },
  };
};

export const fetchStatesError = (error: Error): Action => ({
  type: types.FETCH_STATES_FAIL,
  payload: { error },
});

export const searchStart = () => ({
  type: types.SEARCH_START,
});

export const searchSuccess = (bannerIds: Array<number>, bannerDebugs: Record<string, any>): Action => ({
  type: types.SEARCH_SUCCESS,
  payload: { bannerIds, bannerDebugs },
});

export const searchError = (error: Error): Action => ({
  type: types.SEARCH_FAIL,
  payload: { error },
});
