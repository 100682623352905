import { flatten } from 'ramda';

import { IDeliveryChannel, deliveryChannels } from './deliveryChannels';
export const msgDeliverySuccess = ({ status: { attemptedRecipients } }: { status: { attemptedRecipients: number } }): string =>
  `Number of Test Emails Delivered: ${attemptedRecipients}`;

export const msgDeliveryFailed = (error: Error): { error: string; errorDetails: string } => ({
  error: 'Delivery of Test Email Failed',
  errorDetails: error.message,
});

export const parseMultiline = (validator: (arg0: string) => boolean, s?: string): Array<string> =>
  s
    ? flatten(s.split('\n').map(line => line.split(',')))
        .map(item => item.trim())
        .filter(item => validator(item))
    : [];

export const parseRecipients = (deliveryChannel: IDeliveryChannel, rawRecipients: string): Array<string> => {
  return parseMultiline(deliveryChannels[deliveryChannel].validator, rawRecipients);
};

export const tooltipText = (deliveryChannel: IDeliveryChannel): string => {
  const tooltip = deliveryChannels[deliveryChannel].tooltipText;

  if (typeof tooltip === 'string') {
    return tooltip;
  }

  if (typeof tooltip === 'function') {
    return tooltip(undefined);
  }

  return '';
};
