import update from 'immutability-helper';

import { BmsError } from 'app/api/bannerManagementV2/types';
import { IBannerCreative, IVariant } from 'app/types/BannerManagement';
import { ICategory } from 'app/types/Category';
import { Action, ActionType } from 'app/types/state';

import activeViewItemsReducer from './activeViewItems';
import { State as ActiveViewState } from './activeViewItems/reducers';
import { emptyForm, emptyVariant, populateForm, populateFormVariants } from './helpers';
import {
  AddEngageCategoryGroupPayload,
  AddVariantGroupPayload,
  RemoveVariantGroupPayload,
  UpdateFieldPayload,
  UpdateVariantsFieldPayload,
  DeleteEngageCategoryGroupPayload,
  AddAppVersionGroupPayload,
  DeleteAppVersionGroupPayload,
  UpdateVariantsLocationsFieldPayload,
  UpdateVariantsEngageCategoriesFieldPayload,
  UpdateVariantsAppVersionsFieldPayload,
  UpdateVariantsLabelFieldPayload,
  UpdateVariantsMetadataPayload,
  UpdateVariantsPersonalizationMetadataPayload,
  UpdateDuplicateMetadataFoundPayload,
} from './payloadTypes';
import * as types from './types';

type State = {
  error: BmsError | null | undefined;
  engageCategories?: Array<ICategory>;
  fetchCreativeLoading: boolean;
  form: Omit<IBannerCreative, 'name' | 'platform' | 'site'>;
  formCloning: boolean;
  duplicateMetadataKeyFound: boolean;
  isSaving?: boolean;
  activeViewItems?: ActiveViewState;
};

const initialState: State = {
  error: null,
  fetchCreativeLoading: false,
  form: emptyForm(),
  formCloning: false,
  duplicateMetadataKeyFound: false,
};

const creativeReducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.RESET_CREATIVE_FORM as ActionType:
      return update(state, {
        form: { $set: emptyForm() },
      });

    case types.UPDATE_CREATIVE_FORM_FIELD as ActionType: {
      const { field, value } = payload as UpdateFieldPayload;

      return update(state, {
        form: {
          [field]: { $set: value },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_ADD_VARIANT_GROUP as ActionType: {
      const { newIndex } = payload as AddVariantGroupPayload;

      return update(state, {
        form: {
          variants: {
            [newIndex]: { $set: emptyVariant() },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_REMOVE_VARIANT_GROUP as ActionType: {
      const { index } = payload as RemoveVariantGroupPayload;
      const variants = { ...state.form.variants };
      delete variants[index];

      return update(state, {
        form: {
          variants: { $set: variants },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_ADD_ENGAGE_CATEGORY_GROUP as ActionType: {
      const { index, newIndex } = payload as AddEngageCategoryGroupPayload;

      return update(state, {
        form: {
          // @ts-expect-error - TS is able to catch variant types but cant identify $set here
          variants: {
            [index]: {
              engageCategories: {
                [newIndex]: { $set: {} },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_DELETE_ENGAGE_CATEGORY_GROUP as ActionType: {
      const { variantIndex, categoryIndex } = payload as DeleteEngageCategoryGroupPayload;
      const categories = { ...state.form.variants[variantIndex].engageCategories };
      delete categories[categoryIndex];

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              engageCategories: { $set: categories },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_ADD_APP_VERSION_GROUP as ActionType: {
      const { index, newIndex } = payload as AddAppVersionGroupPayload;

      return update(state, {
        form: {
          // @ts-expect-error - TS is able to catch variant types but cant identify $set here
          variants: {
            [index]: {
              appVersions: {
                [newIndex]: { $set: {} },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_DELETE_APP_VERSION_GROUP as ActionType: {
      const { variantIndex, versionIndex } = payload as DeleteAppVersionGroupPayload;
      const appVersions = { ...state.form.variants[variantIndex].appVersions };
      delete appVersions[versionIndex];

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              appVersions: { $set: appVersions },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_FIELD as ActionType: {
      const { index, field, value } = payload as UpdateVariantsFieldPayload;

      const updatedVariant = { ...(state.form.variants?.[index] || {}) };

      if (field === 'landingPageType') {
        delete updatedVariant.url;
        delete updatedVariant.itemId;
      }

      // @ts-expect-error - TS doesn't know that field is already of type keyof IVariant
      updatedVariant[field] = value;

      return update(state, {
        form: {
          variants: {
            [index]: { $set: updatedVariant },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LOCATIONS_FIELD as ActionType: {
      const { field, value, index } = payload as UpdateVariantsLocationsFieldPayload<keyof IVariant['locations']>;

      const variants = state.form.variants[index] || {};
      let updatedLocations: IVariant['locations'] = {};

      if (variants.locations) {
        updatedLocations = { ...variants.locations };
      }

      if (field) {
        updatedLocations[field] = value;
      }

      return update(state, {
        form: {
          variants: {
            [index]: {
              locations: { $set: updatedLocations },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_ENGAGE_CATEGORIES_FIELD as ActionType: {
      const { field, value, index, catIndex } = payload as UpdateVariantsEngageCategoriesFieldPayload;

      const categories = state.form.variants[index].engageCategories || {};
      const category = categories[catIndex];
      // @ts-expect-error - TS doesn't know that field is already of type keyof ICreativeCategoryWeight
      category[field] = value;

      return update(state, {
        form: {
          variants: {
            [index]: {
              engageCategories: {
                [catIndex]: { $set: category },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_APP_VERSIONS_FIELD as ActionType: {
      const { value, index, versionIndex, field } = payload as UpdateVariantsAppVersionsFieldPayload;

      const variants = state.form.variants[index] || {};
      const appVersions = variants.appVersions ? variants.appVersions : {};
      let appVersion = appVersions[versionIndex];

      if (!appVersion) {
        // @ts-expect-error - TS doesn't know that field is already of type keyof IAppVersionFilter
        appVersion = { [field]: value };
        appVersions[versionIndex] = appVersion;

        return update(state, {
          form: {
            variants: {
              [index]: {
                appVersions: { $set: appVersions },
              },
            },
          },
        });
      }

      // @ts-expect-error - TS doesn't know that field is already of type keyof IAppVersionFilter
      appVersion[field] = value;

      return update(state, {
        form: {
          variants: {
            [index]: {
              appVersions: {
                [versionIndex]: { $set: appVersion },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LABEL_FIELD as ActionType: {
      const { field, value, index } = payload as UpdateVariantsLabelFieldPayload;

      let labelDetails = { ...state.form.variants[payload.index].labelDetails };

      if (!value || !Object.keys(value).length) {
        if (field === 'label') {
          labelDetails = {};
        } else {
          delete labelDetails[field];
        }
      } else {
        labelDetails[field] = value;
      }

      return update(state, {
        form: {
          // @ts-expect-error - TS is able to catch variant types but cant identify $set here
          variants: {
            [index]: {
              labelDetails: { $set: labelDetails },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_METADATA as ActionType: {
      const { variantIndex, metadata } = payload as UpdateVariantsMetadataPayload;

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              metadata: { $set: metadata },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_PERSONALIZATION_METADATA as ActionType: {
      const { variantIndex, personalizationMetadata } = payload as UpdateVariantsPersonalizationMetadataPayload;

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              personalizationMetadata: { $set: personalizationMetadata },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_DUPLICATE_METADATA_FOUND as ActionType: {
      const { duplicateMetadataFound } = payload as UpdateDuplicateMetadataFoundPayload;

      return update(state, {
        duplicateMetadataKeyFound: { $set: duplicateMetadataFound },
      });
    }

    // Save creative
    case types.SAVE_CREATIVE_START as ActionType: {
      return update(state, {
        isSaving: { $set: true },
      });
    }

    case types.SAVE_CREATIVE_SUCCESS as ActionType: {
      return update(state, {
        isSaving: { $set: false },
        error: { $set: null },
        form: { $set: initialState.form },
      });
    }

    case types.SAVE_CREATIVE_ERROR as ActionType: {
      return update(state, {
        isSaving: { $set: false },
        error: { $set: payload.error },
      });
    }

    // Fetch creative
    case types.FETCH_CREATIVE as ActionType: {
      return update(state, {
        fetchCreativeLoading: { $set: true },
      });
    }

    case types.FETCH_CREATIVE_SUCCESS as ActionType: {
      return update(state, {
        fetchCreativeLoading: { $set: false },
        form: { $set: payload.creative },
        error: { $set: null },
      });
    }

    case types.FETCH_CREATIVE_ERROR as ActionType: {
      return update(state, {
        fetchCreativeLoading: { $set: false },
        form: { $set: initialState.form },
        error: { $set: payload.error },
      });
    }

    case types.POPULATE_CREATIVE_FORM_VARIANTS as ActionType: {
      // viewItem is actually either a viewItem or a bannerCreative
      const { engageCategories, viewItem } = payload;
      const populatedVariants = populateFormVariants(viewItem.variants, {
        categories: engageCategories,
      });

      return update(state, {
        form: {
          // @ts-expect-error - TS is able to catch variant types but cant identify $set here
          variants: { $set: populatedVariants },
          populated: { $set: true },
        },
      });
    }

    case types.POPULATE_CREATIVE_FORM_START as ActionType: {
      return update(state, {
        formCloning: { $set: true },
      });
    }

    case types.POPULATE_CREATIVE_FORM_SUCCESS as ActionType: {
      const { creative } = payload;
      const form = populateForm(creative, {
        new: true,
        categories: state.engageCategories,
      });

      return update(state, {
        form: { $set: form },
        formCloning: { $set: false },
      });
    }

    case types.POPULATE_CREATIVE_FORM_ERROR as ActionType: {
      return update(state, {
        formCloning: { $set: false },
      });
    }

    default:
      return state;
  }
};

export default (state: State = initialState, { type, payload }: Action) => {
  const activeViewItems = activeViewItemsReducer(state.activeViewItems, { type, payload });

  const nextState = {
    ...state,
    activeViewItems,
  };

  return creativeReducer(nextState, { type, payload });
};
