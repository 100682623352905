import React from 'react';

import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

import { addComponentPropsIf } from 'app/helpers/ComponentHelpers/helpers';
import { isNeitherNilNorEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

const ASC = 'asc';
const DESC = 'desc';

const sortHandler = (handleSort, currentSortOrder, currentSortColumn, newSortColumn, sorter) => event => {
  const newSortOrder = currentSortColumn === newSortColumn ? (currentSortOrder === ASC ? DESC : ASC) : ASC;

  handleSort(newSortOrder, newSortColumn, sorter, event);
};

interface SortableTableHeaderProps {
  columns: object[];
  sortOrder?: string;
  sortColumn?: string;
  handleSort?(...args: unknown[]): unknown;
  canExpandRow: boolean;
  classes: Record<string, string>;
  style?: Record<string, string>;
}

export const SortableTableHeader = ({
  columns,
  sortOrder = ASC,
  sortColumn = '',
  handleSort,
  canExpandRow,
  classes,
  style = {},
}: SortableTableHeaderProps) => (
  <TableHead>
    <TableRow>
      {R.map(({ key, label, onSort, isNumeric, style: headerStyle }) => {
        // withPadding,
        const canSort = isNeitherNilNorEmpty(handleSort);
        const isSorted = sortColumn === key;

        return (
          <TableCell
            key={key}
            align={isNumeric ? 'right' : 'inherit'}
            className={classes.tableCell}
            {...addComponentPropsIf(isNeitherNilNorEmpty(headerStyle), { style: headerStyle })}
            // padding={withPadding ? 'default' : 'none'}
            // padding="default"
            {...addComponentPropsIf(isNeitherNilNorEmpty(style), {
              style,
            })}
            {...addComponentPropsIf(canSort, {
              sortDirection: isSorted ? sortOrder : false,
            })}
          >
            {canSort ? (
              <TableSortLabel active={isSorted} direction={sortOrder} onClick={sortHandler(handleSort, sortOrder, sortColumn, key, onSort)}>
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        );
      }, columns)}
      {canExpandRow && <TableCell />}
    </TableRow>
  </TableHead>
);
