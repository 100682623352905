import { withHandlers } from 'recompose';

import * as fetch from 'app/utilities/http';

const api = {
  post: '/files/images/upload',
};

export const ApiImageUpload = withHandlers({
  apiFileUpload: () => async file => {
    const formData = new FormData();
    formData.append('file', file);
    return fetch.fileUpload(api.post, formData);
  },
});
