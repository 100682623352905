import React from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { eventSchemaOperations } from 'app/ducks/cashbackPromo/eventSchema';
import { Button, Icon, Input, Page, PageHeader, PageMain, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

import { IPromoEvent } from './types';

type Props = {
  promoEvents: Array<IPromoEvent>;
  getAllEvents: (...args: Array<any>) => any;
};

type State = {
  newPromoEvent: IPromoEvent | null | undefined;
  expandedEventIdx: number | null | undefined;
};

const emptyEventField = {
  name: '',
  fieldTypeId: 'int',
  possibleValues: [],
  fieldSource: 'Enrich',
  customerId: false,
  transactionTimestamp: false,
  transactionAmount: false,
};

const initialEventSchema = {
  name: '',
  promoEventFields: [
    {
      name: 'customerID',
      fieldTypeId: null,
      possibleValues: [],
      fieldSource: '',
      customerId: true,
      eventId: 1,
    },

    {
      name: 'transactionTimeStamp',
      fieldTypeId: null,
      possibleValues: [],
      fieldSource: '',
      transactionTimestamp: true,
      eventId: 2,
    },
  ],
};

// const fieldSourceTypes = ['in Event', 'Enrich'];
// const fieldTypes = ['int', 'string'];

class EventSchema extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newPromoEvent: null,
      expandedEventIdx: null,
    };
  }

  componentDidMount = () => {
    const { getAllEvents } = this.props;
    getAllEvents();
  };

  handleCreateNew = () => {
    this.setState({
      newPromoEvent: initialEventSchema,
    });
  };

  handleChange = event => {
    const { newPromoEvent } = this.state;
    const {
      target: { value },
    } = event;
    this.setState({
      newPromoEvent: {
        ...newPromoEvent,
        name: value,
      },
    });
  };

  saveEvent = () => {
    console.log('Save this');
  };

  addField = () => {
    const { newPromoEvent } = this.state;
    this.setState({
      newPromoEvent: {
        ...newPromoEvent,
        promoEventFields: [...newPromoEvent.promoEventFields, emptyEventField],
      },
    });
  };

  renderField = item => (
    <FieldRow key={item.id} data-qa="field-row">
      {item.name}
      {item.fieldSource}
      {item.fieldTypeId}
      {item.possibleValues}
    </FieldRow>
  );

  renderInputField = item => <></>; // REF: PP-161370 - broken feature, commenting for now
  // <FieldRow data-qa="new-field-row" key={item.id}>
  //   <SmallInput data-qa="field-name" value={item.name} placeholder="Field name" name="name" />
  //   <Dropdown data-qa="field-source" label="Field Source" value={item.fieldSource} options={fieldSourceTypes} />
  //   <Dropdown data-qa="field-type" label="Field Type" value={item.fieldTypeId} options={fieldTypes} />
  //   <SmallInput data-qa="possible-values" value={item.possibleValues} placeholder="Possible Values in csv" name="possibleValues" />
  //   <Checkbox
  //     name="transactionAmount"
  //     label="Is Transaction Amount"
  //     value={item.transactionAmount}
  //     checked={item.transactionAmount}
  //     onChange={() => console.log('change transaction boolean')}
  //   />
  // </FieldRow>

  renderNewPromoEventForm = () => {
    const { newPromoEvent } = this.state;
    return (
      <EventRow>
        <Input value={newPromoEvent.name} placeholder="Enter name of the event" name="name" onChange={this.handleChange} />
        {this.renderInputFields(newPromoEvent)}
        <ActionWrapper>
          <LinkButton onClick={this.addField} data-qa="add-more-fields">
            <Icon name="add" />
            Add More Fields
          </LinkButton>
          <Button type="secondary" onClick={this.saveEvent}>
            Save
          </Button>
        </ActionWrapper>
      </EventRow>
    );
  };

  isReadOnly = field => field.customerId || field.transactionTimestamp;

  renderInputFields = promoEvent => (
    <FieldsWrapper>
      {promoEvent.promoEventFields.map(field => (this.isReadOnly(field) ? this.renderField(field) : this.renderInputField(field)))}
    </FieldsWrapper>
  );

  renderFields = promoEvent => <FieldsWrapper data-qa="fields-row">{promoEvent.promoEventFields.map(this.renderField)}</FieldsWrapper>;

  renderEvent = promoEvent => {
    const { expandedEventIdx } = this.state;
    return (
      <EventRow
        data-qa={`event-row-${promoEvent.name}`}
        key={promoEvent.id}
        onClick={() => this.setState({ expandedEventIdx: expandedEventIdx === promoEvent.id ? null : promoEvent.id })}
      >
        {promoEvent.name}
        {expandedEventIdx === promoEvent.id ? this.renderFields(promoEvent) : null}
      </EventRow>
    );
  };

  render() {
    const { promoEvents } = this.props;
    const { newPromoEvent } = this.state;
    return (
      <Page>
        <PageHeader title="Events Schema Registry">
          <Button onClick={this.handleCreateNew} disabled={!!newPromoEvent} data-qa="add-new-button">
            Add New
          </Button>
        </PageHeader>
        <PageMain>
          <EventsWrapper>
            {newPromoEvent && this.renderNewPromoEventForm()}
            {promoEvents ? promoEvents.map(this.renderEvent) : <Spinner />}
          </EventsWrapper>
        </PageMain>
      </Page>
    );
  }
}

EventSchema = connect(
  ({
    cashbackPromoConfig: {
      eventSchema: { promoEvents },
    },
  }) => ({ promoEvents }),
  {
    getAllEvents: eventSchemaOperations.getAllEvents,
  },
)(EventSchema);

export { EventSchema };
const EventRow = styled.div`
  border-bottom: 1px solid ${sc.greyLighter};
  padding: 1rem 0.5rem;
`;

const EventsWrapper = styled.div`
  padding: 0;
  margin: 1rem;
  width: calc(100% - 5rem);
  min-width: 600px;
  border: 1px solid ${sc.greyLighter};
  border-bottom: none;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
`;

const FieldRow = styled.div`
  display: flex;
  padding: 1rem 5px;
  border: 1px solid ${sc.greyLightest};
  border-bottom: none;
`;

const FieldsWrapper = styled.div`
  background: ${sc.greyLighter};
`;

const LinkButton = styled(Button)`
  background: none;
  border: none;
  border-bottom: 1px solid ${sc.primary};
  color: ${sc.primary};
`;

const ActionWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
`;

// const SmallInput = styled(Input)`
//   padding: 1rem 5px;
// `;
