import { isArray } from 'ramda-adjunct';

import { newCache, lru } from 'app/helpers/CacheHelpers/lru';
import { R } from 'app/helpers/RamdaHelpers/helpers';

let cache = newCache();

const hasMoreThan = size => R.compose(R.lt(size), R.length);

const maxNumOfCacheItems = 10;
const minNumOfItems = 500;

const testPass = R.allPass([isArray, hasMoreThan(minNumOfItems)]);

const retrieve = lru({
  testPass,
  maxNumOfItems: maxNumOfCacheItems,
});

const get = (key, fetch) => {
  const [result, updated] = retrieve(key, fetch, cache);

  cache = updated;

  return result;
};

export { get };
