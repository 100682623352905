import { fetchViewItemsForCreative } from 'app/api/bannerManagementV2/creatives';
import { DispatchFn } from 'app/types/state';

import * as actions from './actions';

export const fetchActiveViewItems = (creativeId?: number) => async (dispatch: DispatchFn) => {
  if (creativeId) {
    dispatch(actions.fetchActiveViewItemsStart());

    try {
      const { viewItems } = await fetchViewItemsForCreative(creativeId);
      dispatch(actions.fetchActiveViewItemsSuccess(viewItems));
    } catch (err) {
      dispatch(actions.fetchActiveViewItemsError(err));
    }
  }
};
