import React from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { ConfirmModal } from 'app/midgarComponents';

import ErrorContainer from './ErrorContainer';
import { IBulkActionError } from './types';

type Props = {
  bulkActionError?: IBulkActionError;
  bulkActionInProgress: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmDeleteModal = ({ bulkActionError, bulkActionInProgress, onClose, onConfirm }: Props) => (
  <ConfirmModal confirmText="Remove" disabled={bulkActionInProgress} isOpen onClose={onClose} onConfirm={onConfirm} title="Bulk Remove">
    <Container>
      {bulkActionInProgress ? (
        'Removing...'
      ) : bulkActionError ? (
        <ErrorContainer bulkActionError={bulkActionError} />
      ) : (
        'Remove the selected banners?'
      )}
    </Container>
  </ConfirmModal>
);

const mapStateToProps = ({ bannerManagement: { binder } }) => ({
  bulkActionError: binder.bulkActionError,
  bulkActionInProgress: binder.bulkActionInProgress,
});

export default connect(mapStateToProps)(ConfirmDeleteModal);

const Container = styled.div`
  min-height: 5rem;
`;
