import styled from 'styled-components';

import { sc } from 'app/styles';

export const Tooltip = styled.div`
  display: none;
  padding: 1rem;
  position: relative;
  border: 1px solid ${sc.greyLighter};
  height: 80px;
  width: fit-content;
  min-width: 250px;
  max-width: 400px;
  text-align: center;
  line-height: 1.5rem;
  box-shadow: 5px 5px ${sc.greyLightest};
  background: ${sc.greyLighter};
  color: black;
  font-size: ${sc.fontSizeSmaller};
  margin-left: 2rem;
  border-radius: 20px;

  &::after {
    content: '';
    position: absolute;
    left: -30px;
    top: 30px;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 20px solid ${sc.greyLighter};
    clear: both;
    font-weight: bolder;
    transform: rotate(90deg);
  }
`;
