import React, { PureComponent } from 'react';

import styled from 'styled-components';

import HTMLWithPreview from 'app/components/HTMLWithPreview';
import { ICampaignAttributeValue } from 'app/features/CashbackPromoManagement/types';
import DateField from 'app/midgarComponents/DateField';
import DateTimeEpochField from 'app/midgarComponents/DateTimeEpochField';
import _Dropdown from 'app/midgarComponents/Dropdown';
import TextField from 'app/midgarComponents/TextField';

import StringSetPills from './InputTypes/StringSetPills';

const FieldType = {
  date: 'date',
  // dateTime: 'datetime_8601', // TODO
  double: 'double',
  epochMsec: 'datetime_epoch',
  html: 'html',
  int: 'int',
  string: 'string',
  stringSet: 'stringset',
};

type Props = {
  attribute: ICampaignAttributeValue;
  className?: string;
  handleChange: (arg0: ICampaignAttributeValue) => any;
  readOnly?: boolean;
  shouldValidate?: boolean;
};

export default class TypedAttributeItem extends PureComponent<Props> {
  changeValue = (value: any) => {
    const { attribute, handleChange } = this.props;
    handleChange({
      ...attribute,
      value,
    });
  };

  onChange = ({ target: { value } }) => this.changeValue(value);

  onBlur = ({ target: { value } }) => this.changeValue(value.trim());

  render() {
    const { attribute, className, readOnly, shouldValidate } = this.props;

    const {
      customCampaignFieldType,
      defaultValue,
      fieldName,
      fieldTitle,
      id,
      optional,
      possibleValues,
      value: rawValue,
      placeholder,
    } = attribute;

    const value = (rawValue !== null && rawValue !== undefined ? String(rawValue) : defaultValue) || '';
    const label = fieldTitle || fieldName;
    const error = shouldValidate && !optional && !value;

    const fieldType = (customCampaignFieldType.name || '').toLowerCase();
    if (fieldType === FieldType.html) {
      return (
        <HTMLWithPreview
          className={className}
          content={value}
          error={error}
          isUploadImage={false}
          label={`${label} (HTML)`}
          name={fieldName}
          readOnly={readOnly}
          required={!optional}
          setContent={this.changeValue}
          placeholder={placeholder}
        />
      );
    }

    if (fieldType === FieldType.date) {
      return (
        <DateField
          className={className}
          disabled={readOnly}
          error={error}
          handleSelect={this.changeValue}
          id={String(id)}
          label={label}
          name={fieldName}
          required={!optional}
          value={value}
        />
      );
    }

    if (fieldType === FieldType.epochMsec) {
      return (
        <DateTimeEpochField
          className={className}
          disabled={readOnly}
          error={error}
          id={String(id)}
          label={label}
          name={fieldName}
          onChange={this.changeValue}
          required={!optional}
          value={typeof rawValue === 'number' ? rawValue : Number(rawValue)}
        />
      );
    }

    if (fieldType === FieldType.stringSet) {
      return (
        <StringSetPills
          className={className}
          error={error}
          handleChange={this.changeValue}
          name={fieldName}
          label={label}
          readOnly={readOnly}
          required={!optional}
          possibleValues={possibleValues}
          value={value}
        />
      );
    }

    if (possibleValues) {
      return (
        <Dropdown
          disabled={readOnly}
          label={label}
          value={value}
          options={possibleValues.split(',')}
          required={!optional}
          onChange={this.changeValue}
          error={error}
        />
      );
    }

    const type = fieldType === FieldType.double || fieldType === FieldType.int ? 'number' : undefined;
    const isUnsupportedType = !Object.values(FieldType).includes(fieldType);

    return (
      <TextField
        className={className}
        disabled={readOnly}
        error={error}
        name={fieldName}
        onChange={this.onChange}
        onBlur={this.onBlur}
        label={`${label} ${isUnsupportedType ? `[Enter a value of type "${fieldType}"]` : ''}`}
        required={!optional}
        type={type}
        value={value}
        placeholder={placeholder}
      />
    );
  }
}

const Dropdown = styled(_Dropdown)`
  margin: 1rem 0;
`;
