import { ComponentType } from 'react';

import { compose, withProps } from 'recompose';

import { getLists, getPath } from 'app/utilities/cascadingLists';

import ListSelectableGroup from '../ListSelectableGroup';

const addProps = withProps(({ srcLists = [], selectedListId, selectedItemId }: Props) => {
  const selected = getPath(srcLists, selectedListId, selectedItemId);
  return {
    selected,
    lists: getLists(srcLists, selected),
  };
});

interface ListItem {
  id: number | string;
  name: string;
  parentId?: number | string;
}

interface List {
  id: number | string;
  name: string;
  items: ListItem[];
}

interface Props {
  selectedListId: number | string;
  selectedItemId: number | string;
  srcLists: List[];
  handleClick?: (selectedListId: number | string, selectedItemId: number | string) => void;
}

const enhance = compose<Props, Props>(addProps);

const EnhancedComponent = enhance(ListSelectableGroup as ComponentType<Props>);

export default EnhancedComponent;
