import TablePagination from '@material-ui/core/TablePagination';
import { compose, defaultProps, mapProps, withHandlers } from 'recompose';

const enhance = compose(
  withHandlers({
    onChangePage:
      ({ setPage }) =>
      (ev, page) =>
        setPage(page),
    onChangeRowsPerPage:
      ({ setRowsPerPage }) =>
      ev =>
        setRowsPerPage(ev.target.value),
    labelDisplayedRows:
      () =>
      ({ from, to, count }) =>
        count < Number.POSITIVE_INFINITY ? `Showing: ${from}-${to} of ${count}` : `Showing: ${from}-${to}`,
  }),

  mapProps(({ setPage, setRowsPerPage, ...otherProps }) => otherProps),
  defaultProps({
    count: Number.POSITIVE_INFINITY,
  }),
);

export default enhance(TablePagination);
