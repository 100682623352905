import React from 'react';

import { connect } from 'react-redux';

import { compose } from 'ramda';
import styled from 'styled-components';

import { inject, withAppConfig } from 'app/decorators';
import { creativeActions } from 'app/ducks';
import withEngageCategories from 'app/features/BannerManagement/hoc/withEngageCategories';
import { Autocomplete, TextField, Icon as _Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action } from 'app/types/state';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

type Props = {
  addEngageCategoryGroup: (index: number, newIndex: number) => Action;
  appConfig: IAppConfig;
  categoryKey: string;
  categoryValue: string;
  categoryWeight: string;
  deleteEngageCategoryGroup: (variantIndex: number, categoryIndex: number) => Action;
  engageCategories: Array<Record<string, any>>;
  isLast: boolean;
  readOnly: boolean;
  shouldValidate: boolean;
  trackingOnly: boolean;
  updateVariantsField: (index: number, field: string, value: any) => Action;
  variantCategoryFieldHasError: (variantKey: string, categoryKey: string, fieldName: string) => boolean;
  variantKey: string;
  updateVariantsEngageCategoriesField: (index: number, catIndex: number, field: string, value: any) => Action;
};

class CategoryGroup extends React.PureComponent<Props> {
  componentDidMount() {
    const { appConfig } = this.props;
    const { useAutopilot } = getBannerCreativeConfig(appConfig);
    if (!useAutopilot) {
      const { updateVariantsField, variantKey } = this.props;
      updateVariantsField(Number(variantKey), 'trackingOnly', true);
    }
  }

  onSelectCategory = (suggestion: Record<string, any>) => {
    const { categoryKey, variantKey, updateVariantsEngageCategoriesField } = this.props;

    updateVariantsEngageCategoriesField(variantKey, categoryKey, 'category', suggestion);
    updateVariantsEngageCategoriesField(variantKey, categoryKey, 'value', suggestion.name);
  };

  toggleAutopilotOverride = () => {
    const { trackingOnly, updateVariantsField, variantKey } = this.props;

    updateVariantsField(variantKey, 'trackingOnly', !trackingOnly);
  };

  render() {
    const {
      addEngageCategoryGroup,
      categoryKey,
      categoryValue,
      categoryWeight,
      deleteEngageCategoryGroup,
      engageCategories,
      isLast,
      readOnly,
      shouldValidate,
      trackingOnly,
      updateVariantsEngageCategoriesField,
      variantCategoryFieldHasError,
      variantKey,
    } = this.props;

    const hasAutopilotError = false;
    const engageCategorySuggestions = engageCategories
      .sort((a, b) => a.level - b.level)
      .map(x => ({
        id: x.id,
        name: `${x.level} - ${x.name}`,
      }));

    return (
      <Container>
        <InlineField>
          <Autocomplete
            disabled={readOnly}
            error={hasAutopilotError || (shouldValidate && variantCategoryFieldHasError(variantKey, categoryKey, 'value'))} // Redux uses 'value' instead of 'category'
            id="engage-category"
            label="Category"
            name="engage-category"
            onSelect={suggestion => this.onSelectCategory(suggestion)}
            required
            suggestions={engageCategorySuggestions}
            value={categoryValue || ''}
          />
        </InlineField>

        <WeightField>
          <TextField
            disabled={readOnly}
            error={shouldValidate && variantCategoryFieldHasError(variantKey, categoryKey, 'weight')}
            id="weight"
            label="Weight (Max 100)"
            name="weight"
            onChange={ev => updateVariantsEngageCategoriesField(Number(variantKey), Number(categoryKey), 'weight', ev.target.value)}
            required
            type="number"
            value={categoryWeight || ''}
          />
        </WeightField>

        {isLast ? (
          !trackingOnly && (
            <Icon
              size={25}
              name="add"
              onClick={() => !readOnly && addEngageCategoryGroup(Number(variantKey), Number(categoryKey) + 1)}
              disabled={readOnly}
            />
          )
        ) : (
          <Icon
            size={20}
            name="remove"
            onClick={() => !readOnly && deleteEngageCategoryGroup(Number(variantKey), Number(categoryKey))}
            disabled={readOnly}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      binder,
      creative: { form },
    },
  },

  { categoryKey, variantKey },
) => ({
  categoryValue: form.variants[variantKey].engageCategories[categoryKey].value,
  categoryWeight: form.variants[variantKey].engageCategories[categoryKey].weight,
  trackingOnly: !!form.variants[variantKey].trackingOnly,
});

const mapDispatchToProps = {
  addEngageCategoryGroup: creativeActions.addEngageCategoryGroup,
  deleteEngageCategoryGroup: creativeActions.deleteEngageCategoryGroup,
  updateVariantsField: creativeActions.updateVariantsField,
  updateVariantsEngageCategoriesField: creativeActions.updateVariantsEngageCategoriesField,
};

export default compose(
  withAppConfig,
  withEngageCategories,
  connect(mapStateToProps, mapDispatchToProps),
  inject({ ManagementCreativeActions: creativeActions }),
)(CategoryGroup);

const InlineField = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 49%;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
`;

const Container = styled.div`
  position: relative;
  margin-bottom: ${sc.gutter};

  & > ${InlineField} {
    width: 75%;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const WeightField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 18%;
  margin-right: 2%;
`;

const Icon = styled(_Icon)`
  position: absolute;
  top: 50%;
  right: 0;

  color: ${sc.subHeadingColor};
  padding: ${sc.gutterSmallest};
  transform: translate(0, -50%);

  &:hover {
    color: ${props => !props.disabled && sc.primary};
    cursor: pointer;
  }
`;
