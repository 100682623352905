import React, { Component, lazy, Suspense } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import loadable from '@loadable/component';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Spinner } from 'app/midgarComponents';
import * as RoutesConfig from 'configs/routes';
import { getSelectedTenant } from 'configs/user';

import ConnectedFeatures from './/ducks/features/Features';
import { getTenantConfig as _getTenantConfig } from './ducks/configStore/actions';
import { getUserInfo as _getUserInfo } from './ducks/user/actions';
import * as BannerManagement from './features/BannerManagement';
import {
  CashbackBannerOverview,
  CashbackBannersReporting,
  EventSchema,
  PaymentCeilings,
  StackableGroupsOverview,
  StackableGroupsReporting,
  UserBudgetTokens,
} from './features/CashbackPromoManagement';
import Layout from './features/Layout';
import { ProtectedRoute } from './ProtectedRoute';
import { Normalize } from './styles';
import theme from './theme';
import * as permissions from '../configs/permissions';

const CampaignOverview = loadable(() => import('./features/Campaigns/Overview'));
const SegmentOverview = loadable(() => import('./features/Segments/Overview'));
const SegmentLanding = loadable(() => import('./features/Segments/Reporting'));
const MasterPlan = loadable(() => import('./features/MasterPlan'));
const SegmentCreation = loadable(() => import('./features/Segments/New'));
const SegmentOverview2 = loadable(() => import('./features/Segments2/Overview'));
const SegmentLanding2 = loadable(() => import('./features/Segments2/Reporting'));
const SegmentCreation2 = loadable(() => import('./features/Segments2/New'));
const CampaignReporting = loadable(() => import('./features/Campaigns/Reporting'));
const CampaignCreation = loadable(() => import('./features/Campaigns/New'));
const FeatureReporting = loadable(() => import('./features/Features/Reporting'));
const EmailTemplatesReporting = loadable(() => import('./features/Templates/Email/Reporting'));
const EmailTemplateCreatePage = loadable(() =>
  import('./features/Templates/Email/TemplatePage').then(module => ({ default: module.CreatePage })),
);
const EmailTemplateEditPage = loadable(() =>
  import('./features/Templates/Email/TemplatePage').then(module => ({ default: module.EditPage })),
);
const BannerDebugRouteHandler = loadable(() => import('./features/BannerDebug/RootRouteHandler'));
const UserReporting = loadable(() => import('./features/Users/Reporting'));
const NewUser = loadable(() => import('./features/Users/New'));
const Channels = loadable(() => import('./features/Channels'));
const Landing = loadable(() => import('./Landing'));
const Unauthorized = loadable(() => import('./Unauthorized'));
const Notsupported = loadable(() => import('./Notsupported'));
const ErrorMetricsChart = lazy(() => import('./ErrorMetrics'));

const mapTheme = createTheme(theme);

const mapDispatchToProps = {
  getTenantConfig: _getTenantConfig,
  getUserInfo: _getUserInfo,
};

type Props = {
  getTenantConfig: (arg0: string | null | undefined) => (...args: Array<any>) => any;
  getUserInfo: () => (...args: Array<any>) => any;
  history: History;
};

type State = {
  isLoading: boolean;
};

class App extends Component<Props, State> {
  state = {
    isLoading: true,
  };

  async componentDidMount() {
    const { getTenantConfig, getUserInfo } = this.props;
    await getUserInfo();
    await getTenantConfig(getSelectedTenant());

    this.setState(state => ({ isLoading: false }));
  }

  render() {
    const { history } = this.props;
    const { isLoading } = this.state;
    if (isLoading) {
      return <Spinner />;
    }
    return [
      <Normalize key="normalize" />,
      <ToastContainer key="toastContainer" />,
      <ConnectedFeatures key="features" />,
      <MuiThemeProvider theme={mapTheme} key="app">
        <ConnectedRouter history={history}>
          <Layout>
            <Switch>
              <ProtectedRoute
                requiredPermissions={[
                  ...permissions.campaignCreationPermissions,
                  ...permissions.campaignDraftPermissions,
                  ...permissions.campaignApprovalPermissions,
                ]}
                path={RoutesConfig.CAMPAIGNS_OVERVIEW}
                component={CampaignOverview}
              />

              <ProtectedRoute
                requiredPermissions={[...permissions.campaignCreationPermissions, ...permissions.campaignDraftPermissions]}
                path={RoutesConfig.CAMPAIGNS_CREATE}
                component={CampaignCreation}
              />

              <ProtectedRoute
                requiredPermissions={permissions.campaignReadPermissions}
                path={RoutesConfig.CAMPAIGNS_OVERVIEW_ID}
                component={CampaignOverview}
              />

              <ProtectedRoute
                requiredPermissions={permissions.campaignReadPermissions}
                path={RoutesConfig.CAMPAIGNS}
                exact
                component={CampaignReporting}
              />

              <ProtectedRoute
                requiredPermissions={permissions.masterplanReadPermissions}
                path={RoutesConfig.MASTERPLAN}
                exact
                component={MasterPlan}
              />

              <ProtectedRoute
                requiredPermissions={[...permissions.segmentCreationPermissions, ...permissions.segmentUploadPermissions]}
                path={RoutesConfig.SEGMENTS_CREATION}
                component={SegmentCreation}
              />

              <ProtectedRoute
                path={RoutesConfig.SEGMENTS_OVERVIEW}
                requiredPermissions={permissions.segmentReadPermissions}
                component={SegmentOverview}
              />

              <ProtectedRoute
                requiredPermissions={permissions.segmentReadPermissions}
                path={RoutesConfig.SEGMENTS_LANDING}
                exact
                component={SegmentLanding}
              />

              <ProtectedRoute
                requiredPermissions={[...permissions.segmentCreationPermissions, ...permissions.segmentUploadPermissions]}
                path={RoutesConfig.SEGMENTS_CREATION_2}
                component={SegmentCreation2}
              />

              <ProtectedRoute
                path={RoutesConfig.SEGMENTS_OVERVIEW_2}
                requiredPermissions={permissions.segmentReadPermissions}
                component={SegmentOverview2}
              />

              <ProtectedRoute
                requiredPermissions={permissions.segmentReadPermissions}
                path={RoutesConfig.SEGMENTS_LANDING_2}
                exact
                component={SegmentLanding2}
              />

              <Route path={RoutesConfig.FEATURES} exact component={FeatureReporting} />

              <ProtectedRoute
                requiredPermissions={permissions.templateReadPermissions}
                exact
                path={RoutesConfig.CHANNELS_EMAIL_TEMPLATE_REPORTING}
                component={EmailTemplatesReporting}
              />

              <ProtectedRoute
                requiredPermissions={permissions.templateCreationPermissions}
                path={RoutesConfig.CHANNELS_EMAIL_TEMPLATE_CREATION}
                exact
                component={EmailTemplateCreatePage}
              />

              <ProtectedRoute
                requiredPermissions={permissions.templateCreationPermissions}
                path={RoutesConfig.CHANNELS_EMAIL_TEMPLATE_EDIT}
                component={EmailTemplateEditPage}
              />

              <ProtectedRoute
                requiredPermissions={permissions.bannerDebugReadPermissions}
                path={RoutesConfig.CHANNELS_BANNER_DEBUG}
                exact
                component={BannerDebugRouteHandler}
              />

              <Route
                exact
                path={RoutesConfig.CHANNELS_BANNER_DEBUG_DEFAULT}
                render={() => <Redirect to={RoutesConfig.CHANNELS_BANNER_DEBUG_UPCOMING} />}
              />

              <Route exact path={RoutesConfig.CHANNELS} component={Channels} />

              <ProtectedRoute requiredPermissions={permissions.usersEditPermissions} path={RoutesConfig.USERS_NEW} component={NewUser} />
              <ProtectedRoute requiredPermissions={permissions.usersEditPermissions} path={RoutesConfig.USERS_EDIT} component={NewUser} />
              <ProtectedRoute requiredPermissions={permissions.usersEditPermissions} path={RoutesConfig.USERS_COPY} component={NewUser} />
              <Route path={RoutesConfig.USERS} component={UserReporting} />

              <ProtectedRoute
                exact
                path={RoutesConfig.BANNERS_BINDER}
                component={BannerManagement.BannerView}
                requiredPermissions={permissions.bannerManagementReadPermissions}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.BANNERS_BINDER_ITEM_OVERVIEW}
                component={BannerManagement.BannerViewItemOverview}
                requiredPermissions={permissions.bannerManagementReadPermissions}
              />

              <ProtectedRoute
                exact
                requiredPermissions={permissions.bannerManagementReadPermissions}
                path={RoutesConfig.BANNERS_CREATIVE}
                component={BannerManagement.BannerCreativesReport}
              />

              <ProtectedRoute
                // TODO: add banner management readonly page
                requiredPermissions={[...permissions.bannerManagementEditPermissions, ...permissions.bannerManagementReadPermissions]}
                path={RoutesConfig.BANNERS_CREATIVE_EDIT}
                component={BannerManagement.BannerCreativeEdit}
              />

              <ProtectedRoute
                requiredPermissions={[...permissions.bannerManagementEditPermissions, ...permissions.bannerManagementReadPermissions]}
                path={RoutesConfig.BANNERS_STOREFRONTS_EDIT}
                component={BannerManagement.BannerStorefrontOverview}
              />

              <ProtectedRoute
                requiredPermissions={permissions.bannerManagementEditPermissions}
                path={RoutesConfig.BANNERS_CREATIVE_NEW}
                component={BannerManagement.BannerCreativeNew}
              />

              <Route exact path={RoutesConfig.BANNERS_STOREFRONTS_REPORT} component={BannerManagement.BannerStorefronts} />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_BANNER_NEW}
                component={CashbackBannerOverview}
                requiredPermissions={permissions.allCashbackManagementPermissions}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_BANNER_OVERVIEW}
                component={CashbackBannerOverview}
                requiredPermissions={permissions.allCashbackManagementPermissions}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_BANNERS}
                component={CashbackBannersReporting}
                requiredPermissions={permissions.allCashbackManagementPermissions}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_EVENT_SCHEMAS}
                component={EventSchema}
                requiredPermissions={[permissions.CASHBACK_MANAGEMENT_EVENT_SCHEMA_CREATE]}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_GLOBAL_LIMITS}
                component={PaymentCeilings}
                requiredPermissions={permissions.allCashbackManagementPermissions}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_STACKABLE_GROUP}
                component={StackableGroupsOverview}
                requiredPermissions={permissions.allCashbackManagementPermissions}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_STACKABLE_GROUPS}
                component={StackableGroupsReporting}
                requiredPermissions={permissions.allCashbackManagementPermissions}
              />

              <ProtectedRoute
                exact
                path={RoutesConfig.CASHBACK_MANAGEMENT_USER_BUDGET_TOKENS}
                component={UserBudgetTokens}
                requiredPermissions={permissions.allCashbackManagementPermissions}
              />

              <Route path="/unauthorized" exact component={Unauthorized} />
              <Route path="/not-supported" exact component={Notsupported} />
              <Route
                path="/error-metrics"
                exact
                render={() => (
                  <Suspense fallback={<Spinner />}>
                    <ErrorMetricsChart />
                  </Suspense>
                )}
              />

              <Route path="/" exact component={Landing} />
            </Switch>
          </Layout>
        </ConnectedRouter>
      </MuiThemeProvider>,
    ];
  }
}

export default connect(null, mapDispatchToProps)(App);
