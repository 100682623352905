import { fetch } from 'app/ducks/commonStates';
import { MasterplanPrioritiesState } from 'app/types/MasterPlan';

import * as types from './types';

const initialState: MasterplanPrioritiesState = {
  ...fetch,
  date: null,
  locked: false,
  manualPriorities: [],
  medium: null,
  systemPriorities: [],
  trigger: null,
  systemPreview: false,
};

export default (state: MasterplanPrioritiesState = initialState, { type, payload }: { type: string; payload: Record<string, any> }) => {
  switch (type) {
    case types.GET_PRIORITIES:
      return {
        ...initialState,
        loading: true,
      };

    case types.GET_PRIORITIES_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
        loading: false,
      };

    case types.GET_PRIORITIES_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    // case types.LOCK_MASTERPLAN:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case types.LOCK_MASTERPLAN_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     locked: true,
    //   };
    // case types.LOCK_MASTERPLAN_ERROR:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: payload.error,
    //   };
    case types.SAVE_PRIORITIES:
      return {
        ...state,
        loading: true,
      };

    case types.SAVE_PRIORITIES_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.SAVE_PRIORITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.UPDATE_PRIORITIES:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
