import React from 'react';

import { connect } from 'react-redux';

import * as cashbackCreativeActions from 'app/ducks/campaigns/campaign/cashbackCreative/actions';
import { getJourneyFulfillment } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';

import { Fulfillment } from '../../Creation/Fulfillment';

const updateFulfillment = state => ({
  fulfillment: getJourneyFulfillment(state),
});

const mapDispatchToProps = {
  updateJourneyFulfillment: cashbackCreativeActions.updateJourneyFulfillment,
  updateJourneyFulfillmentType: cashbackCreativeActions.updateJourneyFulfillmentType,
};

const FulfillmentContainer = connect(
  updateFulfillment,
  mapDispatchToProps,
)(props => {
  const { fulfillment, updateJourneyFulfillment, updateJourneyFulfillmentType } = props;
  return (
    <Fulfillment
      {...fulfillment}
      updateFulfillment={updateJourneyFulfillment}
      updateFulfillmentType={payload => updateJourneyFulfillmentType({ fulfillType: payload })}
    />
  );
});

export default FulfillmentContainer;
