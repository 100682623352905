import React from 'react';

import styled from 'styled-components';

import { getSchema } from 'app/api/schemaRegistry';
import { withAppConfig } from 'app/decorators';
import { Separator } from 'app/features/BannerManagement/common/components';
import { Spinner, TypedAttributes } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISchema, ISchemaField, ISchemaFieldWithValue } from 'app/types/Schema';
import { IAppConfig } from 'configs/apps/types';

import { asAttribute, asField } from './utils';
import Metadata from '../Metadata';

type Props = {
  appConfig: IAppConfig;
  data?: Record<string, string>;
  namespace: string;
  namespaceId: string;
  variantKey: string;
  readOnly?: boolean;
  shouldValidate?: boolean;
  updateField?: (arg0: ISchemaFieldWithValue) => any;
  updateFields?: (arg0: Array<ISchemaFieldWithValue>) => any;
  updateMetadata: (variantIndex: number, metadata: Record<string, string>) => void;
};

type State = {
  error: Error | null | undefined;
  fields: Array<ISchemaField> | null | undefined;
  loading: boolean;
};

class Schema extends React.PureComponent<Props, State> {
  state = {
    error: null,
    fields: null,
    loading: true,
  };

  async getSchemaAndSetValues(namespace: string, namespaceId: string) {
    if (!this.props.appConfig?.enableSchemaRegistry) {
      this.setState({
        error: null,
        fields: null,
        loading: false,
      });

      return;
    }

    try {
      const schema: ISchema = await getSchema(namespace, namespaceId);
      const fields: Array<ISchemaField> = Array.isArray(schema) ? schema[0].fields : schema.fields || [];
      this.setState({
        error: null,
        fields,
        loading: false,
      });
    } catch (err) {
      const schemaExistsError = err.toString().indexOf('404') !== 0 ? null : err.toString();

      this.setState({
        error: schemaExistsError,
        fields: null,
        loading: false,
      });
    }
  }

  componentDidMount() {
    const { namespace, namespaceId, updateField, updateFields } = this.props;

    if (!updateField && !updateFields) {
      console.error('Schema: At least one of updateField or updateFields must be supplied'); // eslint-disable-line no-console
    }

    this.getSchemaAndSetValues(namespace, namespaceId);
  }

  componentDidUpdate(prevProps: Props) {
    const { namespace, namespaceId } = this.props;

    if (prevProps.namespaceId !== namespaceId) {
      this.getSchemaAndSetValues(namespace, namespaceId);
    }
  }

  render() {
    const { data, readOnly, shouldValidate, updateField, updateFields, updateMetadata, variantKey } = this.props;

    const { error, fields, loading } = this.state;
    const fieldsWithValues: Array<ISchemaFieldWithValue> = (fields || []).map(field => ({
      ...field,
      value: (data || {})[field.fieldName],
    }));

    if (loading) return <Spinner />;

    if (error) return <ErrorContainer>{error}</ErrorContainer>;

    if (!error && !fields) {
      return <Metadata tite="Metadata" metadata={data} variantKey={variantKey} updateMetadata={updateMetadata} />;
    }

    return (
      <>
        <Separator>
          <span>Metadata</span>
        </Separator>

        <TypedAttributes
          attributes={(fieldsWithValues || []).map(asAttribute)}
          readOnly={readOnly}
          shouldValidate={shouldValidate}
          updateAttribute={attrValue => updateField && updateField(asField(attrValue))}
          updateAttributes={attrValues => updateFields && updateFields(attrValues.map(asField))}
        />
      </>
    );
  }
}

export default withAppConfig(Schema);

const ErrorContainer = styled.pre`
  width: fit-content;
  margin-bottom: 2rem;
  padding: 1rem 0;
  font-family: monospace;
  color: ${sc.danger};
`;
