import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { cashbackActions, cashbackOperations } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { getJourneyCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';
import { TypedAttributes, Spinner, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';

type Props = {
  stageId: string;
  updateJourneyStageAttributes: (...args: Array<any>) => any;
  getJourneyStageAttributes: (stageNum: string) => any;
  journeyCreative: Record<string, any>;
};

class CustomStageAttributes extends PureComponent<Props> {
  componentDidMount = () => {
    const { getJourneyStageAttributes, stageId } = this.props;
    getJourneyStageAttributes(stageId);
  };

  updateJourneyStageAttributes = attributesList => {
    const { updateJourneyStageAttributes, stageId } = this.props;
    updateJourneyStageAttributes({
      customStageAttributes: attributesList,
      stageNum: stageId,
    });
  };

  handleDeleteAttribute = item => {
    const {
      journeyCreative: { stages },
      updateJourneyStageAttributes,
      stageId,
    } = this.props;
    const { customStageAttributes } = stages[stageId];
    updateJourneyStageAttributes({
      customStageAttributes: customStageAttributes.filter(att => att.id !== item.id),
    });
  };

  validate = () => {
    const {
      journeyCreative: { stages },
      stageId,
    } = this.props;
    const { customStageAttributes } = stages[stageId];
    return customStageAttributes.every(att => !(!att.optional && isNilOrEmpty(att.value) && isNilOrEmpty(att.defaultValue)));
  };

  render() {
    const {
      journeyCreative: { stages },
      stageId,
    } = this.props;
    const { customStageAttributes } = stages[stageId];
    return (
      <>
        <Section>
          <Label>
            Custom Stage Attributes
            {customStageAttributes?.length &&
              (this.validate() ? (
                <ValidationIcon name="check-circle" size={22} color={sc.success} />
              ) : (
                <ValidationIcon name="warning" size={22} color={sc.warning} />
              ))}
          </Label>

          {customStageAttributes !== null ? (
            <>
              <AttributesWrapper>
                <TypedAttributes
                  attributes={customStageAttributes}
                  attributeFields={customStageAttributes}
                  updateAttributes={this.updateJourneyStageAttributes}
                  handleDeleteAttribute={this.handleDeleteAttribute}
                />
              </AttributesWrapper>
            </>
          ) : (
            <Spinner />
          )}
        </Section>
      </>
    );
  }
}

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${sc.greyLight};
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

const AttributesWrapper = styled.div`
  margin: 1rem 0;
`;

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;

const StageAttributesContainer = connect(state => ({ journeyCreative: getJourneyCreative(state) }), {
  updateJourneyStageAttributes: cashbackActions.updateJourneyStageAttributes,
  getJourneyStageAttributes: cashbackOperations.getJourneyStageAttributes,
})(CustomStageAttributes);

export default StageAttributesContainer;
