import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import ListSelectable from '../ListSelectable';

interface ListSelectableGridColumnProps {
  selectedItemId?: number | string;
  listId?: number | string;
  listName?: string;
  items?: {
    id?: number | string;
    name?: string;
  }[];
  colWidth?: 1 | 2 | 3 | 4 | 6;
  handleClick?(...args: unknown[]): unknown;
  scrollIntoViewIfNeeded?: boolean;
}

export const ListSelectableGridColumn = ({
  selectedItemId = null,
  listId = null,
  listName = null,
  items = [],
  colWidth = 3,
  handleClick = null,
  scrollIntoViewIfNeeded = false,
}: ListSelectableGridColumnProps) => (
  <Grid item key={listId} xs={colWidth}>
    <Typography variant="subtitle1" align="center" gutterBottom>
      {listName}
    </Typography>
    <ListSelectable selectedId={selectedItemId} items={items} handleClick={handleClick} scrollIntoViewIfNeeded={scrollIntoViewIfNeeded} />
  </Grid>
);
