import React from 'react';

import { Button, ListItemIcon, ListItem, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, Done as DoneIcon } from '@material-ui/icons';

import { getItem } from 'app/utilities/idAndName';
const getName = (id, items) => {
  const item = id !== null && id !== undefined ? getItem(id, items) : undefined;
  return item ? item.name : undefined;
};

export interface ButtonMenuProps {
  id?: string;
  selectedId: number | string;
  items: {
    id: number | string;
    name: string;
  }[];
  handleClick(...args: unknown[]): unknown;
  handleSelect(...args: unknown[]): unknown;
  handleClose(...args: unknown[]): unknown;
  classes?: {
    root?: string;
    button?: string;
    menu?: string;
    menuItem?: string;
  };
}

export const ButtonMenu = ({
  id = 'buttonMenu',
  selectedId,
  items,
  menu,
  handleClick,
  handleSelect,
  handleClose,
  classes = {},
}: ButtonMenuProps) => (
  <span className={classes.root}>
    <Button aria-owns={menu ? id : undefined} aria-haspopup="true" onClick={handleClick} className={classes.button} data-qa="button-menu">
      <ArrowDropDownIcon className={classes.arrowDropDownIcon} />
      <Typography className={classes.countryName}>{getName(selectedId, items)}</Typography>
    </Button>
    <Menu id={id} anchorEl={menu} data-qa={'menu'} open={Boolean(menu)} onClose={handleClose} className={classes.menu}>
      {items.map(item => (
        <MenuItem
          key={item.id}
          onClick={handleSelect(item.id)}
          className={classes.menuItem}
          data-qa={`menu-item-${item.name}`}
          component="div"
        >
          <ListItem>
            {item.id === selectedId && (
              <ListItemIcon>
                <DoneIcon />
              </ListItemIcon>
            )}

            <ListItemText
              style={{
                paddingLeft: item.id === selectedId ? '0px' : '56px',
              }}
              selected={item.id === selectedId}
              inset
              primary={item.name}
            />
          </ListItem>
        </MenuItem>
      ))}
    </Menu>
  </span>
);
