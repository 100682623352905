import { connect } from 'react-redux';

import { lifecycle, compose } from 'recompose';

import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  categories: selectors.categories(state),
  categoriesLoading: selectors.loading(state),
  categoriesError: selectors.error(state),
});

const connectToRedux = connect(mapStateToProps, operations);

const fetchCategories = lifecycle({
  componentDidMount() {
    this.props.getAllCategories();
  },
});

const ConnectedCategories = compose(connectToRedux, fetchCategories);

interface CategoriesContainerProps {
  children?(...args: unknown[]): unknown;
}

const CategoriesContainer = ({ children, ...props }: CategoriesContainerProps) => <>{children && children(props)}</>;

const ConnectedCategoriesContainer = ConnectedCategories(CategoriesContainer);

export { ConnectedCategories, ConnectedCategoriesContainer };
