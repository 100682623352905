const ROOT = 'map/bannerManagement/creative';

export const FETCH_CREATIVE = `${ROOT}/FETCH_CREATIVE`;
export const FETCH_CREATIVE_SUCCESS = `${ROOT}/FETCH_CREATIVE_SUCCESS`;
export const FETCH_CREATIVE_ERROR = `${ROOT}/FETCH_CREATIVE_ERROR`;

export const SAVE_CREATIVE_START = `${ROOT}/SAVE_CREATIVE_START`;
export const SAVE_CREATIVE_SUCCESS = `${ROOT}/SAVE_CREATIVE_SUCCESS`;
export const SAVE_CREATIVE_ERROR = `${ROOT}/SAVE_CREATIVE_ERROR`;

export const RESET_CREATIVE_FORM = `${ROOT}/RESET_CREATIVE_FORM`;

export const POPULATE_CREATIVE_FORM_START = `${ROOT}/POPULATE_CREATIVE_FORM_START`;
export const POPULATE_CREATIVE_FORM_SUCCESS = `${ROOT}/POPULATE_CREATIVE_FORM_SUCCESS`;
export const POPULATE_CREATIVE_FORM_ERROR = `${ROOT}/POPULATE_CREATIVE_FORM_ERROR`;

export const POPULATE_CREATIVE_FORM_VARIANTS = `${ROOT}/POPULATE_CREATIVE_FORM_VARIANTS`;

export const UPDATE_CREATIVE_FORM_FIELD = `${ROOT}/UPDATE_CREATIVE_FORM_FIELD`;
export const UPDATE_CREATIVE_FORM_ADD_VARIANT_GROUP = `${ROOT}/UPDATE_CREATIVE_FORM_ADD_VARIANT_GROUP`;
export const UPDATE_CREATIVE_FORM_ADD_ENGAGE_CATEGORY_GROUP = `${ROOT}/UPDATE_CREATIVE_FORM_ADD_ENGAGE_CATEGORY_GROUP`;
export const UPDATE_CREATIVE_FORM_DELETE_ENGAGE_CATEGORY_GROUP = `${ROOT}/UPDATE_CREATIVE_FORM_DELETE_ENGAGE_CATEGORY_GROUP`;
export const UPDATE_CREATIVE_FORM_ADD_APP_VERSION_GROUP = `${ROOT}/UPDATE_CREATIVE_FORM_ADD_APP_VERSION_GROUP`;
export const UPDATE_CREATIVE_FORM_DELETE_APP_VERSION_GROUP = `${ROOT}/UPDATE_CREATIVE_FORM_DELETE_APP_VERSION_GROUP`;

export const UPDATE_CREATIVE_FORM_REMOVE_VARIANT_GROUP = `${ROOT}/UPDATE_CREATIVE_FORM_REMOVE_VARIANT_GROUP`;
export const UPDATE_CREATIVE_FORM_SET_VARIANTS_FIELD = `${ROOT}/UPDATE_CREATIVE_FORM_SET_VARIANTS_FIELD`;
export const UPDATE_CREATIVE_FORM_SET_VARIANTS_LABEL_FIELD = `${ROOT}/UPDATE_CREATIVE_FORM_SET_VARIANTS_LABEL_FIELD`;
export const UPDATE_CREATIVE_FORM_SET_VARIANTS_LOCATIONS_FIELD = `${ROOT}/UPDATE_CREATIVE_FORM_SET_VARIANTS_LOCATIONS_FIELD`;
export const UPDATE_CREATIVE_FORM_SET_VARIANTS_ENGAGE_CATEGORIES_FIELD = `${ROOT}/UPDATE_CREATIVE_FORM_SET_VARIANTS_ENGAGE_CATEGORIES_FIELD`;
export const UPDATE_CREATIVE_FORM_SET_VARIANTS_APP_VERSIONS_FIELD = `${ROOT}/UPDATE_CREATIVE_FORM_SET_VARIANTS_APP_VERSIONS_FIELD`;
export const UPDATE_CREATIVE_FORM_SET_VARIANTS_METADATA = `${ROOT}/UPDATE_CREATIVE_FORM_SET_VARIANTS_METADATA`;
export const UPDATE_CREATIVE_FORM_SET_VARIANTS_PERSONALIZATION_METADATA = `${ROOT}/UPDATE_CREATIVE_FORM_SET_VARIANTS_PERSONALIZATION_METADATA`;

export const UPDATE_CREATIVE_FORM_SET_DUPLICATE_METADATA_FOUND = `${ROOT}/UPDATE_CREATIVE_FORM_SET_DUPLICATE_METADATA_FOUND`;
