import React from 'react';

import styled from 'styled-components';

import { ISearchDefs } from 'app/hocs/withReporting';
import _Dropdown, { IDropdownOption } from 'app/midgarComponents/Dropdown';
import Icon from 'app/midgarComponents/Icon';
import { sc, media } from 'app/styles';

import TextField from '../TextField';

type Props = {
  searchPhrase?: string;
  selectedSearchType?: string;
  searchTypes: ISearchDefs;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  className?: string;
};

type State = {
  focusComponent?: 'phrase' | 'type' | null;
};

const asDropdownOptions = (searchDefs: ISearchDefs): Array<IDropdownOption> => searchDefs.map(({ name, label }) => ({ id: name, label }));

export default class SearchBar extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    const { searchTypes, selectedSearchType = '', handleSearchTypeChange } = this.props;

    if (!selectedSearchType) {
      handleSearchTypeChange(searchTypes[0].name);
    }

    const { type } = searchTypes.find(def => def.name === selectedSearchType) || {};
    if (type === 'number') {
      const { pushSearchBar, searchPhrase } = this.props;
      if (searchPhrase && Number.isNaN(Number(searchPhrase))) {
        pushSearchBar({
          searchPhrase: '0',
          selectedSearchType,
        });
      }
    }
  }

  handleClearSearchPhrase = () => {
    const { selectedSearchType, handleSearchPhraseChange, pushSearchBar } = this.props;

    handleSearchPhraseChange('');
    if (selectedSearchType && selectedSearchType.length > 0) {
      pushSearchBar({
        searchPhrase: '',
        selectedSearchType,
      });
    }
  };

  handleInputKeyUp = ({ key }) => {
    if (key === 'Enter' || window.location.pathname.includes('/audience')) {
      const { searchPhrase = '', selectedSearchType, pushSearchBar } = this.props;

      if (selectedSearchType && selectedSearchType.length > 0) {
        // todo: proper fix will include type validation as well
        pushSearchBar({
          searchPhrase: searchPhrase.trim(),
          selectedSearchType,
        });
      }
    }
  };

  handleSearchPhraseChange = ({ target: { value: searchPhrase } }) => {
    const { selectedSearchType = '', searchTypes } = this.props;
    const { type = 'search' } = searchTypes.find(def => def.name === selectedSearchType) || {};
    const { handleSearchPhraseChange } = this.props;
    if (type === 'number' && /[^\d.-]/.test(searchPhrase)) return; // restrict characters for type number
    return handleSearchPhraseChange(searchPhrase);
  };

  handleSearchTypeChange = ({ id: selectedSearchType }) => {
    const { handleSearchTypeChange } = this.props;
    return handleSearchTypeChange(selectedSearchType);
  };

  handlePhraseBlur = () => this.setState({ focusComponent: null });

  handlePhraseFocus = () => this.setState({ focusComponent: 'phrase' });

  handleTypeBlur = () => this.setState({ focusComponent: null });

  handleTypeFocus = () => this.setState({ focusComponent: 'type' });

  render() {
    const { focusComponent } = this.state;
    const { searchPhrase = '', selectedSearchType = '', searchTypes, className } = this.props;
    const { type = 'search' } = searchTypes.find(def => def.name === selectedSearchType) || {};

    const searchTypeOptions = asDropdownOptions(searchTypes);
    const selectedSearchTypeOption = searchTypeOptions.find(t => t.id === selectedSearchType);
    const typeIsActive = focusComponent === 'type';
    const phraseIsActive = focusComponent === 'phrase' || (searchPhrase && searchPhrase.length > 0);

    return (
      <Container className={className}>
        <SearchTypeContainer onFocus={this.handleTypeFocus} onBlur={this.handleTypeBlur} $active={typeIsActive}>
          <Dropdown
            value={selectedSearchTypeOption || {}}
            options={searchTypeOptions}
            onChange={this.handleSearchTypeChange}
            label="Search By..."
          />
        </SearchTypeContainer>

        <SearchPhraseContainer onFocus={this.handlePhraseFocus} onBlur={this.handlePhraseBlur} $active={phraseIsActive}>
          <SearchIcon name="search" />

          <SearchField
            id="SearchBar-phrase"
            name="SearchBar-phrase"
            type={type}
            label={selectedSearchTypeOption ? `Search ${selectedSearchTypeOption.label}` : 'Search Phrase'}
            value={searchPhrase}
            onChange={this.handleSearchPhraseChange}
            onKeyUp={this.handleInputKeyUp}
          />

          {searchPhrase.length === 0 ? undefined : (
            <ClearButton onClick={this.handleClearSearchPhrase}>
              <Icon name="cancel" onClick={this.handleClearSearchPhrase} />
            </ClearButton>
          )}
        </SearchPhraseContainer>
      </Container>
    );
  }
}

const bgColorInactive = sc.sectionBackgroundColor;
const bgColor = ({ $active }) => ($active ? 'white' : bgColorInactive);

const ClearButton = styled.a`
  position: absolute;
  top: 50%;
  right: ${sc.gutterSmaller};
  transform: translate(0, -50%);
  cursor: pointer;
`;

const Container = styled.section`
  width: 100%;
  ${media.largerThanSmallScreen({
    display: 'flex',
  })}
  background-color: ${bgColorInactive};
`;

const Dropdown = styled(_Dropdown)`
  width: 12rem;

  ${media.smallScreen({
    width: '100%',
  })}

  & > span {
    background-color: ${props => bgColor(props)};
  }
`;

// I can't style TextField directly, so style as children of the container
const SearchPhraseContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => bgColor(props)};

  & * {
    background-color: ${props => bgColor(props)};
  }
`;

const SearchField = styled(TextField)`
  width: 100%;
  padding-left: ${sc.gutter};

  & > .ci-text-field-label {
    padding-left: ${sc.gutter};
  }
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: ${sc.gutterSmaller};
  transform: translate(0, -50%);
  z-index: 10;
`;

const SearchTypeContainer = styled.div``;
