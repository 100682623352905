import buildLookups from 'app/utilities/buildLookups';

import * as types from './types';

const getAll = () => ({
  type: types.GET_ALL_CAMPAIGNS,
});

const getAllSuccess = (campaigns, paged = {}) => ({
  type: types.GET_ALL_CAMPAIGNS_SUCCESS,
  payload: { paged, campaignsLookups: buildLookups(campaigns) },
});

const getAllFail = error => ({
  type: types.GET_ALL_CAMPAIGNS_FAIL,
  payload: { error },
});

const selectRow = id => ({
  type: types.SELECT_ROW,
  payload: { id },
});

const setStatus = params => ({
  type: types.SET_STATUS,
  payload: { ...params },
});

const updateBanner = (id, banners) => ({
  type: types.UPDATE_BANNER,
  payload: {
    id,
    banners,
  },
});

const updateScheduling = (id, scheduling) => ({
  type: types.UPDATE_SCHEDULING,
  payload: {
    id,
    scheduling,
  },
});

const loadMore = () => ({
  type: types.LOAD_MORE_CAMPAIGNS,
});

const loadMoreSuccess = (campaigns, paged = {}) => ({
  type: types.LOAD_MORE_CAMPAIGNS_SUCCESS,
  payload: { paged, campaignsLookups: buildLookups(campaigns) },
});

const loadMoreFail = error => ({
  type: types.LOAD_MORE_CAMPAIGNS_FAIL,
  payload: { error },
});

export { getAll, getAllSuccess, getAllFail, selectRow, setStatus, updateBanner, updateScheduling, loadMore, loadMoreSuccess, loadMoreFail };
