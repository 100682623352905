import { ApiImageUpload } from './ApiImageUpload';
import { ImageUploadManager } from './ImageUploadManager';
import { ImageUploadManagerState } from './ImageUploadManagerState';
import { MockImageUpload } from './MockImageUpload';

const asReplacements = fileUploads =>
  fileUploads.filter(u => !u.loading && u.url && u.url.length).map(u => ({ name: u.file.name, url: u.url }));

const replaceImagesHTML = (content, images) => {
  if (!content || !images || !images.length) {
    return content;
  }
  return images.reduce((acc, img) => acc.replace(new RegExp(img.name, 'g'), img.url), content);
};

export { ImageUploadManager, ImageUploadManagerState, ApiImageUpload, MockImageUpload, asReplacements, replaceImagesHTML };
