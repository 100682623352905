import React from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { Separator } from 'app/features/BannerManagement/common/components';
import { sc } from 'app/styles';

import AppVersionGroup from './AppVersionGroup';

type Props = {
  readOnly: boolean;
  variantKey: string;
  variantAppVersions: Record<string, unknown>;
};

class AppVersions extends React.PureComponent<Props> {
  render() {
    const { variantKey, variantAppVersions, readOnly } = this.props;
    const appVersionsKeyArray = Object.keys(variantAppVersions);

    return (
      <Container>
        <Separator>
          <span>App Version</span>
        </Separator>

        {appVersionsKeyArray.map((key: string, idx: number) => (
          <AppVersionGroup
            key={`variants-${variantKey}-appVersion-${key}`}
            variantKey={variantKey}
            appVersionKey={key}
            isLast={idx === appVersionsKeyArray.length - 1}
            readOnly={readOnly}
          />
        ))}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      creative: { form },
    },
  },

  { variantKey },
) => ({
  variantAppVersions: form.variants[variantKey].appVersions,
});

export default connect(mapStateToProps)(AppVersions);

const Container = styled.div`
  position: relative;
  margin-top: ${sc.gutter};
`;
