import { fetchStackableGroups } from 'app/api/cashbackPromo';
import { IStackableGroup } from 'app/features/CashbackPromoManagement/types';
import { DispatchFn } from 'app/types/state';

import * as actions from './actions';

export const getStackableGroups = () => async (dispatch: DispatchFn) => {
  dispatch(actions.getInit());
  try {
    const stackableGroups: Array<IStackableGroup> = await fetchStackableGroups();
    dispatch(actions.getSuccess(stackableGroups));
  } catch (err) {
    dispatch(actions.getError(err));
  }
};
