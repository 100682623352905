import React, { PureComponent, ReactNode } from 'react';

import window from 'global/window';
import { lighten } from 'polished';
import styled from 'styled-components';

import { sc } from 'app/styles';

export default class ImageContainer extends PureComponent<
  {
    width: number;
    height: number;
    imageUrl: string | URL | undefined;
    emptyMessage?: string;
    className?: string;
    onClick?: (...args: Array<any>) => any;
    onView?: () => void;
    children?: ReactNode;
  },
  {
    hovered: boolean;
  }
> {
  state = {
    hovered: false,
  };

  onHover = (hovered: boolean) => {
    const { onView } = this.props;

    if (onView) {
      this.setState({ hovered });
    }
  };

  onClick = (ev: Event) => {
    const { onClick } = this.props;

    if (typeof onClick === 'function') {
      ev.stopPropagation();
      ev.preventDefault();
      onClick(ev);
    }
  };

  onView = (ev: Event) => {
    const { imageUrl, onView } = this.props;
    ev.stopPropagation();
    ev.preventDefault();
    window.open(imageUrl);

    if (onView) {
      onView();
    }
  };

  renderViewAction() {
    const { onView } = this.props;

    if (typeof onView !== 'function') {
      return null;
    }

    return <Action onClick={this.onView}>View</Action>;
  }

  render() {
    const { hovered } = this.state;
    const { width, height, imageUrl, emptyMessage, className, children } = this.props;

    return (
      <Container className={className}>
        {imageUrl ? (
          <PreviewImage
            width={width}
            height={height}
            imageUrl={imageUrl}
            onClick={this.onClick}
            onMouseOut={() => this.onHover(false)}
            onMouseOver={() => this.onHover(true)}
          >
            {children}

            <ImageActions hovered={hovered}>
              <Actions>{this.renderViewAction()}</Actions>
            </ImageActions>
          </PreviewImage>
        ) : (
          <Empty>{emptyMessage || 'Invalid Image'}</Empty>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;

  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const Empty = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${sc.subHeadingColor};
`;

const ImageActions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 100ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.hovered ? 1 : 0)};
  pointer-events: ${props => (props.hovered ? 'auto' : 'none')};
`;

const Actions = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Action = styled.div`
  color: #fff;
  font-size: ${sc.fontSizeSmaller};
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: ${sc.gutterSmaller} ${sc.gutterSmallest};
  border-bottom: 1px solid #fff;
  transition: all 100ms ease-in-out;

  &:hover {
    color: ${lighten(0.2, sc.primary)};
    cursor: pointer;
  }

  &:last-child {
    border: none;
  }
`;

const PreviewImage = styled.div`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? `${props.height}px` : '100%')};

  background-color: #fff;
  background-image: url('${props => props.imageUrl}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
`;
