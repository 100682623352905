import { withStyles } from '@material-ui/core/styles';
import { compose, withHandlers, withProps } from 'recompose';

import { sort } from 'app/utilities/idAndName';

import { ListSelectable } from './ListSelectable';
import { styles } from './styles';

const addHandlers = withHandlers<{ handleClick: (id?: number | string) => void }, {}>({
  handleClick:
    ({ handleClick }) =>
    (id: number | string) =>
    () => {
      if (handleClick) {
        handleClick(id);
      }
    },
});

const doSort = withProps(({ items, sorted }: { items: { name: string }[]; sorted: boolean }) => ({
  items: items && sorted ? sort(items) : items,
}));

const enhance = compose(addHandlers, doSort, withStyles(styles, { withTheme: true }));

export default enhance(ListSelectable);
