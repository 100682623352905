import { fromApiWidgetType } from 'app/api/bannerManagementV2/views/mappers';
import { IView, IWidgetType } from 'app/types/BannerManagement';
import api from 'app/utilities/api';

import { GET_VIEWS, GET_WIDGET_TYPES } from './endpoints';
import { fromApiView } from './mappers';

export const fetchViews = (
  storefrontId: number,
  entityType: string,
  entityId: number,
  query?: Record<string, string>,
): Promise<Array<IView>> =>
  api.get(GET_VIEWS(entityType, entityId, storefrontId), { query }).then(({ views }) => (views || []).map(fromApiView));

export const fetchWidgetTypes = (): Promise<Array<IWidgetType>> =>
  api.get(GET_WIDGET_TYPES).then(({ viewTypes: widgetTypes }) => (widgetTypes || []).map(fromApiWidgetType));
