import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import sc from './config';

const GlobalStyle = createGlobalStyle`
  ${reset}
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
    color: ${sc.bodyColor};
    font-size: ${sc.fontSize};
    font-weight: normal;
  }

  html, body, #app {
    height: 100%;
    width: 100%;
    position: relative;
  }

  html, body, input, textarea, table {
    font-family: ${sc.fontFamily};
    text-rendering: optimizeLegibility;
    letter-spacing: 0.4px;
  }

  body {
    background-color: ${sc.sectionBackgroundColor};
  }

  div {
    margin: 0;
  }

  /* IE10/11 fix */
  article, aside, figure, footer, header, hgroup, main, menu, nav, section {
    display: block;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  p {
    margin-top: 0;
    line-height: ${sc.fontSize};
  }

  input, textarea, button {
    &:focus {
      outline: none;
    }
  }

  h1, h2, h3 {
    font-weight: 800;
    font-style: normal;
    letter-spacing: normal;
  }

  h1 {
    font-size: ${sc.fontSizeLargest};
  }

  h2 {
    font-size: ${sc.fontSizeLarger};
  }

  h3 {
    font-size: ${sc.fontSizeLarge};
  }

  hr {
    border: none;
    border-bottom: 1px solid ${sc.greyLighter};
  }

  .no-scrollbar::-webkit-scrollbar,
  .no-scrollbar::-webkit-scrollbar-track,
  .no-scrollbar::-webkit-scrollbar-thumb {
    display: none;
  }
  
  ${'' /* TODO: the function below needs to be removed and added as a styled component */}
  .binder-dnd-slot {
    margin: ${sc.gutterSmallest};
    display: inline-block;
    border-radius: 5px;
    background-color: transparent;

    &.is-dragging {
      opacity: 0;
    }
  }
`;
export default GlobalStyle;
