import Typography from '@material-ui/core/Typography';
import { branch, renderComponent, withProps, withStateHandlers, compose } from 'recompose';

import { isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

import Table from '../Table';
import { computeRowsPerPageOptions, containedOrFirst, rowsByPage } from '../Table/helpers';

const defaultRowsPerPage = 10;

const addProps = withProps(({ rows = [], rowsPerPage = defaultRowsPerPage }) => ({
  rowsPerPage,
  fullDataset: rows,
  rowsPerPageOptions: computeRowsPerPageOptions(rows.length),
}));

const contains = R.curry((term, columns, row) =>
  R.any(key => `${row[key] || ''}`.toLowerCase().includes(term.toLowerCase()), R.pluck('key', columns)),
);

const matchingColumn = R.propEq('key');

const filterThenSort = (term, columns, rows, sortOrder, sortColumn) => {
  const filtered = isNilOrEmpty(term) ? rows : R.filter(contains(term, columns), rows);

  if (filtered.length < 1) {
    return [];
  }

  const { onSort } = R.find(matchingColumn(sortColumn))(columns) || {};

  if (isNilOrEmpty(onSort)) {
    return filtered;
  }

  return onSort(filtered, sortOrder, sortColumn);
};

const initialState = {
  pageIndex: 0,
  sortOrder: 'asc',
  sortColumn: '',
  isFiltered: false,
};

const addStateHandlers = withStateHandlers(
  ({ fullDataset, rowsPerPageOptions, rowsPerPage: userRowsPerPage }) => {
    const rowsPerPage = containedOrFirst(userRowsPerPage, rowsPerPageOptions);
    const rows = rowsByPage(initialState.pageIndex, rowsPerPage, fullDataset);

    return {
      rowsPerPage,
      rows,
      filteredData: fullDataset,
      rowCount: fullDataset.length,
      ...initialState,
    };
  },
  {
    handleChangePage:
      ({ rowsPerPage, filteredData, ...others }) =>
      (event, pageIndex) => ({
        ...others,
        pageIndex,
        rowsPerPage,
        rows: rowsByPage(pageIndex, rowsPerPage, filteredData),
      }),

    handleChangeRowsPerPage:
      ({ pageIndex, filteredData, ...others }) =>
      ({ target: { value: rowsPerPage } }) => ({
        ...others,
        pageIndex,
        rowsPerPage,
        rows: rowsByPage(pageIndex, rowsPerPage, filteredData),
      }),

    handleSort:
      ({ rowsPerPage, filteredData, ...others }) =>
      (sortOrder, sortColumn, sort) => {
        const newFiltered = sort(filteredData, sortOrder, sortColumn);

        return {
          ...others,
          sortOrder,
          sortColumn,
          pageIndex: 0, // should we reset this??
          filteredData: newFiltered,
          rowCount: newFiltered.length,
          rows: rowsByPage(0, rowsPerPage, newFiltered),
        };
      },
    handleFilter:
      ({ rowsPerPage, sortOrder, sortColumn, ...others }, { columns, fullDataset }) =>
      ({ target: { value } }) => {
        const newFiltered = filterThenSort(value, columns, fullDataset, sortOrder, sortColumn);

        return {
          ...others,
          sortOrder,
          sortColumn,
          pageIndex: 0, // should we reset this??
          filteredData: newFiltered,
          rowCount: newFiltered.length,
          isFiltered: fullDataset.length > newFiltered.length,
          rows: rowsByPage(0, rowsPerPage, newFiltered),
        };
      },
    /*
  handleReset:
  (_, {
    rows = [],
    rowsPerPage = 10,
  }) => () => ({
    rows,
    rowsPerPage,
    ...initialState,
  }),
  */
  },
);

const handleNoData = branch(
  ({ fullDataset = [] }) => fullDataset.length < 1,
  renderComponent(() => (
    <Typography
      variant="subtitle1"
      style={{
        marginTop: '1rem',
        marginLeft: '1rem',
      }}
    >
      <i>* No data found.</i>
    </Typography>
  )),
);

const enhance = compose(addProps, addStateHandlers, handleNoData);

export default enhance(Table);
