import { compose, withStateHandlers } from 'recompose';

import { filterBy } from 'app/helpers/RamdaHelpers/helpers';

import { SingleSelectDialog } from './SingleSelectDialog';
// import { echoLog } from 'app/helpers/DebugHelpers/helpers';

const addStateHandlers = withStateHandlers(
  ({ value, options }) => ({
    value,
    options,
    searchPhrase: '',
    radioGroup: null,
    listContainerHeight: 1,
  }),

  {
    handleRadioGroupRef: () => node => ({
      radioGroup: node,
    }),

    /*
    handleChange:
    () => (event, value) => ({
      value,
    }),
  */
    handleChange:
      () =>
      ({ target: { value } }) => ({
        value,
      }),

    handleEntering:
      ({ radioGroup }) =>
      () => {
        radioGroup.focus();
        return undefined;
      },
    handleClose:
      ({ value: newValue, radioGroup }, { value: oldValue, options, onClose }) =>
      okayed => {
        const value = okayed ? newValue : oldValue;
        onClose(value);

        return {
          value,
          options,
          radioGroup,
          searchPhrase: '',
        };
      },
    handleSearch:
      (_, { options: allOptions }) =>
      ({ target: { value } }) => ({
        options: filterBy(value, allOptions),
        searchPhrase: value,
      }),

    handleContentContainerResize: () => rect => ({
      listContainerHeight: rect.height,
    }),
  },
);

const enhance = compose(addStateHandlers);

export default enhance(SingleSelectDialog);
