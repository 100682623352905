import { withStyles } from '@material-ui/core/styles';
import { compose, withStateHandlers, withProps } from 'recompose';

import { MapStepper } from './Stepper';
import { styles } from './styles';

const applyStyles = withStyles(styles);

const withStepperState = withStateHandlers(({ activeStep = 0 }) => ({ activeStep }), {
  goBack:
    ({ activeStep, ...rest }) =>
    () => ({
      ...rest,
      activeStep: activeStep > 0 ? activeStep - 1 : 0,
    }),

  goNext:
    ({ activeStep }) =>
    () => ({
      activeStep: activeStep + 1,
    }),
});

const setComponent = withProps(({ activeStep, steps }) => ({ Component: steps[activeStep].Component }));

const enhance = compose(applyStyles, withStepperState, setComponent);

export default enhance(MapStepper);
