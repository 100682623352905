import { IVariantType } from 'app/types/BannerManagement';
import { Action } from 'app/types/state';

import { IFetch, baseFetch } from '../utils';
import * as types from './types';

export type State = IFetch & {
  variantTypes: Array<IVariantType>;
};

const initialState: State = {
  ...baseFetch,
  variantTypes: [],
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_VARIANT_TYPES_FAILED:
      return {
        error: payload.error,
        loading: false,
        variantTypes: [],
      };

    case types.GET_VARIANT_TYPES_START:
      return {
        error: null,
        loading: true,
        variantTypes: [],
      };

    case types.GET_VARIANT_TYPES_SUCCESS: {
      return {
        error: null,
        loading: false,
        variantTypes: payload.variantTypes,
      };
    }

    default:
      return state;
  }
};
