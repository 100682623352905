import Button from '@material-ui/core/Button';

interface FeatureButtonProps {
  names?: string[];
  placeholder?: string;
  handleClick(...args: unknown[]): unknown;
  classes: {};
}

export const FeatureButton = ({ names = [], placeholder = 'Choose Feature', handleClick, classes }: FeatureButtonProps) => (
  <>
    {names !== undefined && names.length > 0 ? (
      <Button onClick={handleClick} classes={classes}>
        {names.join(' > ')}
      </Button>
    ) : (
      <Button onClick={handleClick} variant="contained" color="primary">
        {placeholder}
      </Button>
    )}
  </>
);
