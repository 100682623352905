import React from 'react';

import Chip from '@material-ui/core/Chip';

interface ChipsProps {
  items: {
    id?: string | number;
    name?: string;
  }[];
  handleRemoveItem?(...args: unknown[]): unknown;
  classes?: {
    chip?: string;
  };
}

export const Chips = ({ items = [], handleRemoveItem, classes = {} }: ChipsProps) =>
  items.map(item => (
    <Chip
      id={item.id}
      key={item.id}
      label={item.name}
      className={classes.chip}
      onDelete={handleRemoveItem ? () => handleRemoveItem(item.id) : null}
    />
  ));
