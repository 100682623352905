import { fetchWidgetTypes as v1FetchWidgetTypes } from 'app/api/bannerManagementV1/views';
import { fetchWidgetTypes as v2FetchWidgetTypes } from 'app/api/bannerManagementV2/views';
import bmsVersions from 'app/api/bmsCommon/bmsVersions';
import { IWidgetType } from 'app/types/BannerManagement';
import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';

export const getWidgetTypes = (bmsVersion: string) => () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      widgetTypes: { loading, widgetTypes: prevWidgetTypes },
    },
  } = getState();

  if (loading || (prevWidgetTypes || []).length > 0) {
    return;
  }

  dispatch(actions.getWidgetTypesStart());

  try {
    const useBmsV2 = bmsVersions.useV2(bmsVersion);
    const widgetTypes: Array<IWidgetType> = useBmsV2 ? await v2FetchWidgetTypes() : await v1FetchWidgetTypes();
    return dispatch(actions.getWidgetTypesSuccess(widgetTypes));
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getWidgetTypesFailed(errorStr));
  }
};
