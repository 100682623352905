import React from 'react';

import { CircularProgress } from '@material-ui/core';
import _Dropzone from 'react-dropzone';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  disablePreview?: boolean;
  error?: boolean;
  loading?: boolean;
};

export default ({ children, disablePreview = true, error, loading, ...otherProps }: Props) => (
  <Dropzone
    disabled={!!loading}
    disablePreview={disablePreview}
    activeClassName="loading"
    disabledClassName="loading"
    data-qa="upload-file"
    error={Boolean(error).toString()}
    {...otherProps}
  >
    {children}
    {loading && <CircularProgress />}
  </Dropzone>
);

const Dropzone = styled(_Dropzone)`
  padding-top: 3rem;
  text-align: center;
  width: 100%;
  &.loading {
    background-color: #f5f5f5;
  }
`;
