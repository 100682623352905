import buildLookups from 'app/utilities/buildLookups';

import * as types from './types';

const getAll = () => ({
  type: types.GET_ALL_CATEGORIES,
});

const getAllSuccess = categories => ({
  type: types.GET_ALL_CATEGORIES_SUCCESS,
  payload: { categoryLookups: buildLookups(categories) },
});

const getAllFail = error => ({
  type: types.GET_ALL_CATEGORIES_FAIL,
  payload: { error },
});

export { getAll, getAllSuccess, getAllFail };
