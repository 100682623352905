import React, { PureComponent } from 'react';

import styled, { css } from 'styled-components';

import { sc } from 'app/styles';

type Props = {
  id: string;
  name: string;
  label: string;
  value?: string;
  onChange?: (ev: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  error?: boolean;
  className?: string;
  disabled?: boolean;
};

type State = {
  active: boolean;
  focused: boolean;
};

export default class TextArea extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      active: !!props.value,
      focused: false,
    };
  }

  componentDidUpdate() {
    const { active } = this.state;
    const { value } = this.props;

    if (!active && value) {
      this.setState({ active: true }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  onChange = (ev: React.SyntheticEvent<HTMLTextAreaElement, Event>) => {
    const { disabled, onChange } = this.props;

    if (!disabled) {
      onChange?.(ev);
    }
  };

  onFocus = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({ active: true, focused: true });
    }
  };

  onBlur = () => {
    const { value, type } = this.props;

    if (!value || !value.length) {
      return this.setState({
        active: type === 'time',
        focused: false,
      });
    }

    return this.setState({ focused: false });
  };

  render() {
    const { active, focused } = this.state;
    const { id, name, label, required, value, className, error, disabled, ...rest } = this.props;

    return (
      <Container className={className} $focused={focused} $error={!!error} disabled={disabled}>
        <InnerTextarea
          id={id}
          type="text"
          name={name}
          value={value}
          onChange={this.onChange}
          required={!!required}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...rest}
        />

        <Label required={!!required} $active={active}>
          {label}
        </Label>
      </Container>
    );
  }
}

// TODO: Pass height as prop
const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 10rem;
  border: 1px solid ${props => (props.$focused ? sc.primary : props.$error ? sc.danger : sc.sectionBorderColor)};
  transition: border 150ms ease-in-out;

  opacity: ${props => (props.disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const InnerTextarea = styled.textarea`
  width: 100%;
  height: 99%;
  border: none;
  padding: ${sc.gutter} ${sc.gutterSmall} ${sc.gutterSmallest};
  font-size: ${sc.fontSizeSmall};
  font-weight: normal;
`;

const Label = styled.span`
  display: block;
  position: absolute;
  top: ${sc.gutterSmall};
  left: ${sc.gutterSmall};

  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmall};
  transition: all 100ms ease-out;
  pointer-events: none;

  ${props =>
    props.required
      ? css`
          &:after {
            content: ' *';
          }
        `
      : ''}

  ${props =>
    props.$active
      ? css`
          top: ${sc.gutterSmallest};
          font-size: ${sc.fontSizeSmaller};
        `
      : ''}
`;
