import React from 'react';

import styled, { css } from 'styled-components';

import { sc } from 'app/styles';

export default class Checkbox extends React.Component<{
  id?: string;
  name: string;
  value?: any;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (...args: Array<any>) => any;
  label?: string;
}> {
  render() {
    const { id, name, value, checked, disabled, className, onChange, label } = this.props;

    return (
      <Container className={className}>
        <CheckboxInput
          type="checkbox"
          id={id || name}
          name={name}
          disabled={disabled}
          value={value}
          checked={checked}
          onClick={onChange}
          onChange={() => {
            /* no-op */
          }}
        />

        <Label htmlFor={id || name} hasLabel={!!label}>
          <span>
            <span />
          </span>
          {label && <div>{label}</div>}
        </Label>
      </Container>
    );
  }
}

const Container = styled.div`
  line-height: 20px;
`;

const Label = styled.label`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: block;

  & > span:first-child {
    float: left;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-right: ${props => (props.hasLabel ? sc.gutterSmaller : '')};
    line-height: 20px;
    padding: 2px;

    border: 2px solid ${sc.grey};
    border-radius: 4px;
    background-color: #fff;

    text-align: center;
    vertical-align: bottom;
    user-select: none;
    z-index: 1;

    transition: all 100ms ease-out;

    & > span {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      vertical-align: top;
    }
  }

  & > div {
    margin-left: ${sc.gutterSmall};
  }
`;

const CheckboxInput = styled.input`
  display: none;

  ${props =>
    props.checked &&
    css`
      & + label > span:first-child > span {
        background-color: ${sc.primary};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      & + label {
        cursor: default;

        & > div,
        & > span:first-child {
          opacity: 0.5;
        }
      }
    `}

  ${props =>
    !props.checked &&
    !props.disabled &&
    css`
      & + label:hover > span:first-child > span {
        background-color: ${sc.greyLighter};
      }
    `}
`;
