import React, { PureComponent } from 'react';

import Pills from 'app/midgarComponents/Pills';

type Props = {
  className?: string;
  error?: boolean;
  handleChange: (arg0: string) => any;
  name?: string;
  label: string;
  readOnly?: boolean;
  required?: boolean;
  possibleValues?: string;
  value: string;
};

type State = {
  userInputValue: string;
};

export default class StringSetInput extends PureComponent<Props, State> {
  state = {
    userInputValue: '',
  };

  constructSuggestions = (arr: string) =>
    arr && arr.length
      ? arr.split(',').map(item => ({
          id: `${item.trim()}-${Date.now().toString(36)}`,
          name: item.trim(),
        }))
      : [];

  removeHandler = item => {
    const { handleChange, value } = this.props;
    const fieldValuesArray = value.split(',').map(val => val.trim());
    const idx = fieldValuesArray.indexOf(item.name);
    fieldValuesArray.splice(idx, 1);
    handleChange(fieldValuesArray.join(','));
  };

  selectionHandler = item => {
    const { handleChange, value } = this.props;
    const fieldValuesArray = value && value.length ? value.split(',') : [];

    if (item) {
      fieldValuesArray.push(typeof item === 'string' ? item : item.name);
      handleChange(fieldValuesArray.join(','));
      this.setState({ userInputValue: '' });
    }
  };

  changeHandler = ({ target: { value } }) => {
    this.setState({
      userInputValue: value,
    });
  };

  render = () => {
    const { userInputValue } = this.state;

    const { className, error, name, label, readOnly, required, possibleValues, value } = this.props;

    return (
      <Pills
        className={className}
        disabled={readOnly}
        error={error}
        id={name}
        name={name}
        label={label}
        limit={100}
        required={required}
        suggestions={typeof possibleValues === 'string' ? this.constructSuggestions(possibleValues) : possibleValues}
        selected={this.constructSuggestions(value)}
        value={userInputValue || ''}
        onRemove={this.removeHandler}
        onSelect={this.selectionHandler}
        onEnter={this.selectionHandler}
        onChange={this.changeHandler}
      />
    );
  };
}
