import { fetchAllEmailSenders } from 'app/api/senders';

import * as actions from './actions';

const getAllEmailSenders = () => async dispatch => {
  dispatch(actions.getAll());
  try {
    const emailSenders = await fetchAllEmailSenders();
    return dispatch(actions.getAllSuccess(emailSenders));
  } catch (e) {
    return dispatch(actions.getAllFail(e));
  }
};

export { getAllEmailSenders };
