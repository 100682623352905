import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';
import { compose } from 'ramda';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { withAppConfig } from 'app/decorators';
import { binderActions } from 'app/ducks';
import { DateGroup } from 'app/features/BannerManagement/common/components';
import { formatTimestamp } from 'app/features/BannerManagement/common/helpers';
import { MAX_DATE_MOMENT } from 'app/features/BannerManagement/constants';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { Button, DateRangeField, ImageContainer, Page as _Page, PageHeader, PageMain, Spinner, Toggle } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISlotGroupType, IView } from 'app/types/BannerManagement';
import { Action } from 'app/types/state';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

type Props = {
  appConfig: IAppConfig;
  creative: any;
  isSaving: boolean;
  onBind: (...args: Array<any>) => any;
  onReset: (...args: Array<any>) => any;
  selectedDates: any;
  selectedSlot: any;
  selectedSlotGroupType: ISlotGroupType;
  setSelectedDate: (field: string, value: any) => Action;
  timezone: string;
  updateVariantStatus: (...args: Array<any>) => any;
  view: IView;
};

class BindCreative extends PureComponent<Props> {
  componentWillUnmount() {
    const { onReset } = this.props;
    onReset();
  }

  onSelectDateRange = (fromFieldName: string, toFieldName: string) => (range: Array<any>) => {
    const { setSelectedDate } = this.props;

    if (MAX_DATE_MOMENT.isBefore(range[0]) || MAX_DATE_MOMENT.isBefore(range[1])) {
      displayError('Choose date before 2038-01-01');
    } else {
      setSelectedDate(fromFieldName, range[0]);
      setSelectedDate(toFieldName, range[1]);
    }
  };

  render() {
    const {
      appConfig,
      creative,
      isSaving,
      onBind,
      onReset,
      selectedDates,
      selectedSlot,
      selectedSlotGroupType,
      timezone,
      updateVariantStatus,
      view,
    } = this.props;

    const { useSaleDates } = getBannerCreativeConfig(appConfig);

    const hasValidVariant = creative.variants.some(v => v.widgetType === view.widgetType);

    const validFrom = selectedDates.validFrom || moment().hour(0).minute(0).format('YYYY-MM-DD HH:mm');
    const validUpto = selectedDates.validUpto || moment().hour(23).minute(59).format('YYYY-MM-DD HH:mm');

    return (
      <Page>
        <PageHeader title="Bind">
          <section>
            <Button type="secondary" onClick={onReset}>
              Back
            </Button>

            <BindButton disabled={!hasValidVariant} type="primary" onClick={onBind}>
              {isSaving ? <Spinner color="white" /> : 'Bind'}
            </BindButton>
          </section>
        </PageHeader>

        <PageWrapper>
          <Group>
            <SelectedContainer>
              <span>Slot:</span>
              <span>{selectedSlot.id}</span>
            </SelectedContainer>

            <SelectedContainer>
              <span>View Type:</span>
              <span>{selectedSlotGroupType.label}</span>
            </SelectedContainer>
          </Group>

          <Group>
            <DateGroup>
              <DateRangeField
                endValue={validUpto}
                handleSelect={this.onSelectDateRange('validFrom', 'validUpto')}
                id="bind-schedule-date-time-range"
                label="Schedule (Start - End) Date and Time"
                name="bind-schedule-date-time-range"
                startValue={validFrom}
                withTimePicker
              />
            </DateGroup>

            {useSaleDates && (
              <DateGroup>
                <DateRangeField
                  endValue={selectedDates.endDate || ''}
                  handleSelect={this.onSelectDateRange('startDate', 'endDate')}
                  id="bind-sale-date-time-range"
                  label="Sale (Start - End) Date and Time"
                  name="bind-sale-date-time-range"
                  startValue={selectedDates.startDate || ''}
                  withTimePicker
                />
              </DateGroup>
            )}
          </Group>

          {!hasValidVariant && (
            <DrawerError
              defaultExpanded={true}
              error="Widget Type Does Not Match"
              errorDetails={`The selected creative does not have a widget type that matches the view's: ${view.widgetType || 'N/A'}`}
            />
          )}

          {creative.variants.map(variant => (
            <VariantPreview disabled={variant.widgetType !== view.widgetType} key={`variant-${variant.id}`}>
              <ImageContainer emptyMessage="No Image" height={75} imageUrl={variant.image} width={130} />

              <FieldSet>
                <span>{creative.name}</span>
                <span>{`Variant ID: ${variant.id}`}</span>
              </FieldSet>

              <FieldSet>{creative.site.join(', ')}</FieldSet>

              <FieldSet>{variant.widgetType || creative.widgetType}</FieldSet>

              {/* TODO: Check multiple variants when binding. landingPageType is not at the creative level. Probably obsolete code. */}
              <FieldSet>{creative.landingPageType}</FieldSet>

              <FieldSet>
                <span>{formatTimestamp(variant.createdAt, timezone) || 'N/A'}</span>
                <span>{variant.createdBy || 'N/A'}</span>
              </FieldSet>

              <FieldSet>
                <Toggle
                  active={variant.status}
                  disabled={variant.widgetType !== view.widgetType}
                  onChange={(status: boolean) => updateVariantStatus(variant, status)}
                />
              </FieldSet>
            </VariantPreview>
          ))}
        </PageWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder }, user }) => ({
  isSaving: binder.bindLoading,
  selectedDates: binder.selectedDates,
  selectedSlot: binder.selectedSlot,
  selectedSlotGroupType: binder.selectedSlotGroupType,
  timezone: user.timezone,
  view: binder.view.view,
});

const mapDispatchToProps = {
  setSelectedDate: binderActions.setSelectedDate,
};

export default compose(withAppConfig, connect(mapStateToProps, mapDispatchToProps))(BindCreative);

const BindButton = styled(Button)`
  margin-left: ${sc.gutterSmaller};
`;

const FieldSet = styled.div`
  font-size: ${sc.fontSizeSmall};
  color: ${sc.headingColor};
  max-width: 20%;

  & > span {
    display: block;

    &:first-child {
      font-weight: bold;
      margin-bottom: ${sc.gutterSmaller};
    }
  }
`;

const Group = styled.div`
  display: block;
  margin-bottom: ${sc.gutterLarge};
`;

const Page = styled(_Page)`
  height: 100%;
  margin: 0;
`;

const PageWrapper = styled(PageMain)`
  height: calc(100% - 90px);
  padding: ${sc.gutterLarge};
  overflow-y: scroll;
`;

const SelectedContainer = styled.div`
  display: inline-block;
  margin-right: ${sc.gutterSmall};
  font-size: ${sc.fontSizeSmall};

  & > span {
    margin-right: ${sc.gutterSmallest};

    &:nth-child(2) {
      color: ${sc.primary};
      font-weight: bold;
    }
  }
`;

const VariantPreview = styled.div`
  align-items: center;
  border: 1px solid ${sc.greyLighter};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin-bottom: ${sc.gutter};
  padding: ${sc.gutter};
  ${props =>
    props.disabled &&
    `background-color: ${sc.greyLightest};
    & > ${FieldSet} {
      color: ${sc.greyLight};
    }
  `}
`;
