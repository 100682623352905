import React from 'react';

import { useDispatch } from 'react-redux';

import { useFormikContext } from 'formik';

import * as actions from 'app/ducks/segments2/segment/actions';

type Props = {
  submitTrigger: boolean;
  setStoreValidityState: Function;
  formName: string;
};

export default ({ submitTrigger, setStoreValidityState, formName }: Props): null => {
  const dispatch = useDispatch();
  const { submitForm, dirty, isValid, errors, values } = useFormikContext();

  React.useEffect(() => {
    if (formName === 'composedAudience') {
      // eslint-disable-next-line
      const { includedSegments, excludedSegments }: any = values;
      dispatch(
        actions.setAudienceDetails({
          includedSegments,
          excludedSegments,
        }),
      );
    } else {
      dispatch(setStoreValidityState({ [formName]: !isValid }));
    }
  }, [dirty, dispatch, errors, formName, isValid, values, setStoreValidityState, submitForm]);

  React.useEffect(() => {
    if (submitTrigger) submitForm();
  }, [submitTrigger, submitForm]);

  return null;
};
