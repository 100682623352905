import { connect } from 'react-redux';

import { compose, lifecycle } from 'recompose';

import { fetchLandingPageTypes as opFetchLandingPageTypes } from 'app/ducks/bannerManagement/landingPageTypes/operations';
import { mapStateToLandingPageTypes } from 'app/ducks/bannerManagement/landingPageTypes/selectors';
import { DispatchFn } from 'app/types/state';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

const mapDispatchToProps = (dispatch: DispatchFn, { appConfig }: { appConfig: IAppConfig }) => {
  const { bmsVersion } = getBannerCreativeConfig(appConfig);

  return {
    fetchLandingPageTypes: () => dispatch(opFetchLandingPageTypes(bmsVersion)()),
  };
};

export default compose(
  connect(mapStateToLandingPageTypes, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchLandingPageTypes, landingPageTypes, landingPageTypesLoading } = this.props;

      if (!(landingPageTypes || []).length && !landingPageTypesLoading) {
        fetchLandingPageTypes();
      }
    },
  }),
);
