import { fromApiEvents } from 'app/api/schemaRegistry/mappers';
import { ISchema, IEvent } from 'app/types/Schema';
import * as cma from 'app/utilities/http';

import { GET_BY_NAMESPAGE, GET_EVENTS } from './endpoints';

const getData = async (url: string): Promise<any> => {
  const { data, resultInfo } = await cma.get(url);
  if (data !== null) return data;

  // TODO: Check for error status
  const { code, message } = resultInfo;
  throw Error(`${message} [${code}]`);
};

export const getSchemas = (namespace: string): Promise<Array<ISchema>> => getData(GET_BY_NAMESPAGE(namespace));

export const getSchema = (namespace: string, namespaceId: string): Promise<ISchema> => getData(GET_BY_NAMESPAGE(namespace, namespaceId));

export const getEvents = (): Promise<Array<IEvent>> => cma.get(GET_EVENTS).then(fromApiEvents);
