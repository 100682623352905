import React from 'react';

import styled from 'styled-components';

import { Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISchemaFieldWithValue } from 'app/types/Schema';

import Schema from './Schema';

const SCHEMA_NAMESPACE = 'banner-widget-type';

type Props = {
  loading: boolean;
  metadata: Record<string, string>;
  updateMetadata: (variantIndex: number, metadata: Record<string, string>) => void;
  variantKey: string;
  widgetType?: string;
};

export default class SchemaMetadata extends React.PureComponent<Props> {
  updateFields = (fields: Array<ISchemaFieldWithValue>) => {
    const { updateMetadata, variantKey } = this.props;
    updateMetadata(
      variantKey,
      fields.reduce((acc, field) => ({ ...acc, [field.fieldName]: field.value }), {}),
    );
  };

  render() {
    const { metadata, widgetType, loading, updateMetadata, variantKey } = this.props;

    if (!widgetType) {
      return null;
    }

    return (
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Schema
            data={metadata}
            namespace={SCHEMA_NAMESPACE}
            namespaceId={widgetType}
            updateFields={this.updateFields}
            updateMetadata={updateMetadata}
            variantKey={variantKey}
          />
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin-top: ${sc.gutter};
`;
