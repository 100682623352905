import { identity, ifElse, remove, uniq } from 'ramda';
import { compose, withStateHandlers } from 'recompose';

import { ChipInputDialog } from './ChipInputDialog';

const parseInput = str => (str && typeof str === 'string' ? str.split(',') : []);

const mergeValues = (oldValues = [], newValues = [], allowDuplicates = false) =>
  ifElse(() => allowDuplicates, identity, uniq)([...oldValues, ...newValues]).filter(item => item.trim().length > 0);

const addStateHandlers = withStateHandlers(
  ({ values = [] }) => ({
    values,
    searchString: '',
    userInput: '',
  }),

  {
    handleClose:
      ({ values: newValues, userInput }, { values: oldValues = [], onClose, allowDuplicates = false }) =>
      okayed => {
        const pendingValues = parseInput(userInput);
        const values = okayed ? mergeValues(newValues, pendingValues, allowDuplicates) : oldValues;
        onClose(values);
        return {
          values,
          searchString: '',
        };
      },
    handleAdd:
      ({ values }, { allowDuplicates = false }) =>
      input => {
        const addValues = parseInput(input).map(item => item.trim());
        return {
          userInput: '',
          values: mergeValues(values, addValues, allowDuplicates),
        };
      },
    handleDelete:
      ({ values }) =>
      (deleted, index) => ({
        userInput: '',
        values: remove(index, 1, values),
      }),

    handleClear: () => () => ({
      values: [],
      userInput: '',
    }),

    handleInputUpdate: () => userInput => ({ userInput }),
    handleSearch:
      () =>
      ({ target: { value } }) => ({ searchString: value }),
  },
);

const enhance = compose(addStateHandlers);

export default enhance(ChipInputDialog);
