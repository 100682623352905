import React, { useEffect } from 'react';

import { withFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { cashbackTypes } from 'app/features/Cashback/constants';
import { ICampaignAttribute } from 'app/features/CashbackPromoManagement/types';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { TextField, DateTimeUtcField, Dropdown, TypedAttributes, KeyValueNoId, Button as _Button, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

const BaseForm = props => {
  const { values, errors, handleChange, setFieldValue, handleSubmit, cashbackType, userBudgetTokens, readOnly, saving } = props;
  const userBudgetTokenOptions = (userBudgetTokens || []).map(token => ({
    ...token,
    label: token.name,
  }));

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      displayError(`Missing or invalid fields: ${Object.keys(errors).join(',')}`);
    }
  }, [errors]);

  return (
    <section>
      <Section style={{ postion: 'relative' }}>
        <form noValidate onSubmit={handleSubmit}>
          <Row>
            <CoreFields>
              <Row>
                <TextField
                  label="Title"
                  name="title"
                  value={values.title || ''}
                  onChange={handleChange}
                  required
                  disabled={readOnly}
                  error={!!errors.title}
                />
              </Row>

              <Row>
                <DateTimeUtcField
                  disabled={readOnly}
                  error={!!errors.startDate}
                  id="startDate"
                  label="Start Date"
                  name="startDate"
                  onChange={value => setFieldValue('startDate', value)}
                  required
                  value={values.startDate}
                />

                <DateTimeUtcField
                  disabled={readOnly}
                  error={!!errors.endDate}
                  id="endDate"
                  label="End Date"
                  name="endDate"
                  onChange={value => setFieldValue('endDate', value)}
                  required
                  value={values.endDate}
                />
              </Row>
            </CoreFields>
          </Row>

          {cashbackType === cashbackTypes.regular && (
            <Row>
              <Dropdown
                required
                disabled={readOnly}
                name="userBudgetToken"
                value={values.userBudgetToken}
                options={userBudgetTokenOptions}
                label="User Budget Token"
                onChange={value => setFieldValue('userBudgetToken', value)}
                error={errors.userBudgetToken}
              />
            </Row>
          )}

          <Row>
            <TypedAttributes
              attributes={values.attrsWithVals}
              readOnly={readOnly}
              shouldValidate={!!errors.attrsWithVals}
              updateAttribute={updated =>
                setFieldValue(
                  'attrsWithVals',
                  Object.values(
                    values.attrsWithVals.reduce(
                      (acc, curr) => ({
                        ...acc,
                        [curr.id]: curr.id === updated.id ? updated : curr,
                      }),

                      {},
                    ),
                  ).sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)),
                )
              }
            />
          </Row>

          <Section>
            <SectionTitle>Custom</SectionTitle>

            <KeyValueNoId
              keyValueArray={values.additionalAttributes}
              readOnly={readOnly}
              updateFn={value => setFieldValue('additionalAttributes', value)}
            />
          </Section>

          <Button disabled={readOnly} type="submit" data-qa="save-button">
            {saving ? <Spinner /> : 'Save'}
          </Button>
        </form>
      </Section>
    </section>
  );
};

export const BannerForm = withFormik({
  mapPropsToValues: ({ banner, campaignAttributes }) => ({
    title: banner.title,
    startDate: banner.startDate,
    endDate: banner.endDate,
    userBudgetToken: banner && banner.userBudgetToken ? { ...banner.userBudgetToken, label: banner.userBudgetToken.name } : undefined,
    attrsWithVals: (campaignAttributes || []).map((attr: ICampaignAttribute) => {
      const value = banner && banner.customBannerAttributes ? banner.customBannerAttributes[attr.fieldName] : undefined;
      return {
        ...attr,
        ...(value ? { value } : {}),
      };
    }),
    additionalAttributes: banner.additionalAttributes || [],
    priority: banner.priority || null,
  }),

  validationSchema: props => {
    const { campaignAttributes, cashbackType } = props;
    const requiredAttributeFields = campaignAttributes.filter(attr => !attr.optional).map(attr => attr.fieldName);
    const userBudgetTokenSchema = { userBudgetToken: yup.object().required('Required') };
    const schema = yup.object().shape({
      title: yup.string().required('Required'),
      startDate: yup.string().required('Required'),
      endDate: yup.string().required('Required'),
      attrsWithVals: yup.array().of(
        yup.object().shape({
          fieldName: yup.string(),
          value: yup.string().when('fieldName', {
            is: val => requiredAttributeFields.indexOf(val) > -1,
            then: schema => schema.required('Required'),
            otherwise: schema => schema,
          }),
        }),
      ),

      additionalAttributes: yup.array().of(
        yup.object().shape({
          key: yup.string().required('Required'),
          value: yup.string().required('Required'),
        }),
      ),

      ...(cashbackType === cashbackTypes.regular ? userBudgetTokenSchema : {}),
    });

    return schema;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    let mapFormSubmitValues = {
      ...values,
    };

    if (values.userBudgetToken) {
      const { label, ...restUserBudgetToken } = values.userBudgetToken;
      mapFormSubmitValues = {
        ...mapFormSubmitValues,
        userBudgetToken: restUserBudgetToken,
      };
    }
    mapFormSubmitValues = {
      ...mapFormSubmitValues,
      customBannerAttributes: mapFormSubmitValues.attrsWithVals.reduce(
        (acc, curr) =>
          curr.value
            ? {
                ...acc,
                [curr.fieldName]: curr.value,
              }
            : acc,
        {},
      ),
    };

    props.onSubmit(mapFormSubmitValues);
    setSubmitting(false);
  },

  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
})(BaseForm);

const Section = styled.section`
  margin-bottom: ${sc.gutter};
`;

const SectionTitle = styled.h2`
  font-size: ${sc.fontSizeLarge};
  font-weight: ${sc.fontSizeLarge};
  margin-bottom: ${sc.gutterSmaller};
`;

const Row = styled.section`
  display: flex;
  margin-bottom: ${sc.gutterSmaller};
  & > div,
  & > section {
    width: 100%;
    margin-right: ${sc.gutterSmall};
  }
  & > div:last-child,
  & > section:last-child {
    margin-right: 0;
  }
`;

const CoreFields = styled.section`
  flex-grow: 1;
  margin-bottom: -${sc.gutterSmaller};
`;

const Button = styled(_Button)`
  position: absolute;
  top: -3.45rem;
  right: 1rem;
`;
