import React from 'react';

import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import { DateTimeUtcField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { triggerTypes } from 'app/utilities/constants';
import { disablePastDates } from 'app/utilities/disablePastDates';

import Location from '../Location';

type Props = {
  startDate?: string; // "YYYY-MM-dd"
  startTime?: string; // "HH:mm"
  expiryDate?: string; // "YYYY-MM-dd"
  expiryTime?: string; // "HH:mm"
  // TODO: min and minExpiry are missing for CampaignEdit
  min?: string; // "YYYY-MM-dd"
  minExpiry?: string; // "YYYY-MM-dd"
  handleChangeExpiryDateAndTime: (arg0: unknown) => void;
  handleChangeStartDateAndTime?: (arg0: unknown) => void;
  id?: number; // campaignId
  isDisabled: boolean;
  triggerType?: string;
};

export const PartialSchedule = ({
  startDate,
  startTime,
  expiryDate,
  expiryTime,
  min,
  minExpiry,
  handleChangeExpiryDateAndTime,
  handleChangeStartDateAndTime,
  id,
  isDisabled = false,
  triggerType = triggerTypes.recurring,
  ...props
}: Props) => {
  const timestampStartDate = React.useMemo(() => {
    return startDate && startTime ? `${startDate}T${startTime}:00Z` : '';
  }, [startDate, startTime]);

  const timestampExpiryDate = React.useMemo(() => {
    return expiryDate && expiryTime ? `${expiryDate}T${expiryTime}:00Z` : '';
  }, [expiryDate, expiryTime]);

  const domIdPicker = 'PartialScehdule_picker';

  return (
    <Container id={id} data-qa="partial-schedule-time-component">
      <div id={domIdPicker} />

      <DateSection data-qa="start-date-component">
        <Typography>Starts On (JST)</Typography>

        <DateTimeUtcField
          id="startDate"
          label="Start Date"
          name="startDate"
          disabled={isDisabled}
          getCalendarContainer={() => document.getElementById(domIdPicker)}
          onChange={handleChangeStartDateAndTime}
          required
          keepDefaultFormat
          splitOnChangeDate
          value={timestampStartDate}
          disabledDate={disablePastDates}
        />
      </DateSection>

      <DateSection data-qa="end-date-component">
        <Typography>Ends On (JST)</Typography>

        <DateTimeUtcField
          id="expiryDate"
          label="Expiry Date"
          name="expiryDate"
          getCalendarContainer={() => document.getElementById(domIdPicker)}
          onChange={handleChangeExpiryDateAndTime}
          required
          keepDefaultFormat
          splitOnChangeDate
          value={timestampExpiryDate}
          disabledDate={disablePastDates}
        />
      </DateSection>

      {triggerType === triggerTypes.location && <Location {...props} />}
    </Container>
  );
};

const Container = styled.section`
  min-height: 20em;
`;

const DateSection = styled.section`
  margin-bottom: ${sc.gutterLarge};
`;
