import TextField from '@material-ui/core/TextField';

import { isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';

import SingleSelectDialog from '../SingleSelectDialog';
import Tooltip from '../Tooltip';

interface ReadOnlyInputWithSingleSelectDialogProps {
  id: string;
  label: string;
  value?: string | number;
  options: any[];
  handleOpen(...args: unknown[]): unknown;
  handleClose(...args: unknown[]): unknown;
  isOpen: boolean;
  dialogTitle?: string;
  groupName?: string;
  classes: Record<string, string>;
}

export const ReadOnlyInputWithSingleSelectDialog = ({
  id,
  label,
  value,
  options,
  handleOpen,
  handleClose,
  isOpen,
  dialogTitle,
  groupName,
  classes,
}: ReadOnlyInputWithSingleSelectDialogProps) => (
  <>
    <Tooltip title={isNeitherNilNorEmpty(value) ? `${value}` : ''}>
      <TextField
        required
        margin="dense"
        className={classes.textField}
        id={id}
        data-qa="value"
        label={label}
        InputProps={{
          readOnly: true,
        }}
        value={value || ''}
        onClick={handleOpen}
      />
    </Tooltip>
    <SingleSelectDialog
      value={value}
      isOpen={isOpen}
      options={options}
      onClose={handleClose}
      dialogTitle={dialogTitle}
      groupName={groupName}
      classes={classes}
    />
  </>
);
