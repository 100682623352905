import Grid from '@material-ui/core/Grid';

import FilterMenu from 'app/components/FilterMenu';
import { mapIndexed } from 'app/helpers/RamdaHelpers/helpers';

interface BreadcrumbMenuProps {
  labels?: string[];
  breadcrumbState: {
    options: unknown[];
    selected?: unknown;
  }[];
  updatedAt: number;
  equals(...args: unknown[]): unknown;
  idOf(...args: unknown[]): unknown;
  nameOf(...args: unknown[]): unknown;
  descOf(...args: unknown[]): unknown;
  handleSelect(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const BreadcrumbMenu = ({
  labels = [],
  breadcrumbState,
  updatedAt,
  equals,
  idOf,
  nameOf,
  descOf,
  handleSelect,
  classes,
}: BreadcrumbMenuProps) => {
  const commonProps = {
    equals,
    idOf,
    nameOf,
    descOf,
    handleSelect,
    classes,
  };

  return (
    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
      {mapIndexed(
        ({ options, selected }, index) => (
          <FilterMenu
            key={`menu-${updatedAt}-${index}`}
            id={`menu-${index}`}
            label={labels[index] || ''}
            options={options}
            selected={selected}
            groupIndex={index}
            {...commonProps}
          />
        ),

        breadcrumbState,
      )}
    </Grid>
  );
};
