import { searchByPlatformDefiner, searchBySiteDefiner } from 'app/features/BannerManagement/common/withCreativesReporting/search.config';
import { ISearchDef, SearchDefsGenerator } from 'app/hocs/withReporting';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';

const searchParams = {
  platform: 'platform', // Multipick
  platformVersion: 'platformVersion', // Dropdown
  site: 'site', // Multipick
};

const searchByPlatformVersion: ISearchDef = {
  name: searchParams.platformVersion,
  label: 'By Platform Version',
  options: [
    { id: '', label: 'Both' },
    { id: 'S1(Old)', label: 'S1(Old)' },
    { id: 'S2(New)', label: 'S2(New)' },
  ],
};

const searchDefiner: SearchDefsGenerator = ({ allPlatforms, allSites, appConfig }) => {
  const { usePlatformVersion } = getBannerCreativeConfig(appConfig);

  const searchDefs = [searchByPlatformDefiner(allPlatforms)];

  if (usePlatformVersion) {
    searchDefs.push(searchByPlatformVersion);
  }

  if ((allSites || []).length > 1) {
    searchDefs.push(searchBySiteDefiner(allSites));
  }

  return searchDefs;
};

export { searchByPlatformDefiner, searchByPlatformVersion, searchBySiteDefiner, searchDefiner, searchParams };
