import styled from 'styled-components';

import { Dropdown as _Dropdown, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';

export const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;

export const Dropdown = styled(_Dropdown)`
  width: 600px;
  margin: 1rem 1rem 0 0;
  height: 70px;
`;

export const Error = styled.div`
  color: ${sc.danger};
  font-style: italic;
  margin-top: ${sc.gutterSmallest};
`;

export const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

export const Row = styled.div`
  display: inline-flex;
`;

export const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${sc.greyLight};
`;

export const SpinnerWrapper = styled.div`
  width: 250px;
  margin: 1rem 1rem 0 0;
  height: 70px;
  padding: 2rem;
`;
