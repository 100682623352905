import React from 'react';

import { Chip as _Chip, TextField } from '@material-ui/core';
import styled from 'styled-components';

import { sc } from 'app/styles';

import Tooltip from '../Tooltip';

const MAX_CHIPS_IN_TOOLTIP = 20;

type Props = {
  id: string;
  label: string;
  values?: Array<string | number>;
  stringifier: (...args: Array<any>) => any;
  handleOpen: (...args: Array<any>) => any;
  anchorRef?: React.RefObject<HTMLInputElement>;
  fullWidth?: boolean;
  helperText?: string;
  classes: {
    textField: string;
    tooltip: string;
  };
};

export const MultiValueTextField = ({
  id,
  label,
  values = [],
  stringifier,
  handleOpen,
  anchorRef,
  fullWidth = false,
  helperText,
  classes,
}: Props) => (
  <>
    <TextField
      id={id}
      label={label}
      required
      margin="dense"
      className={classes.textField}
      InputProps={{
        readOnly: true,
      }}
      data-qa="rule-multi-textfiled"
      ref={anchorRef}
      value={stringifier(values)}
      onClick={handleOpen}
      fullWidth={fullWidth}
      helperText={helperText}
    />

    {values.length > 0 && (
      <Tooltip title={stringifier(values)}>
        <span>
          {values.slice(0, MAX_CHIPS_IN_TOOLTIP).map(v => (
            <Chip key={v} label={v} />
          ))}

          {values.length >= MAX_CHIPS_IN_TOOLTIP && <TotalItems>... Total Items = {values.length}</TotalItems>}
        </span>
      </Tooltip>
    )}
  </>
);

const Chip = styled(_Chip)`
  margin: ${sc.gutterSmaller};
  border-radius: ${sc.gutterSmaller};
`;

const TotalItems = styled.section`
  white-space: nowrap;
`;
