import { ComponentType } from 'react';

import { connect } from 'react-redux';

import { branch, compose } from 'recompose';

const mapStateToProps = ({ config }) => ({ appConfig: config });

export default compose(branch(({ appConfig }) => appConfig === null || appConfig === undefined, connect(mapStateToProps))) as <
  P extends object,
>(
  component: ComponentType<P>,
) => ComponentType<P>;
