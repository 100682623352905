import React, { PureComponent } from 'react';

import cx from 'classnames';
import styled from 'styled-components';

export default class Icon extends PureComponent<{
  className?: string;
  color?: string;
  name: string;
  onClick?: (ev: Event) => void;
  size?: number;
  style?: Record<string, any>;
  title?: string;
}> {
  static Names: Record<string, string> = {
    campaign: 'campaign',
    calendar: 'calendar',
    time: 'time',
    account: 'account',
    camera: 'camera',
    'camera-add': 'camera-add',
    add: 'add',
    'add-circle-outline': 'add-circle-outline',
    'add-circle': 'add-circle',
    archive: 'archive',
    'arrow-left': 'arrow-left',
    'arrow-down': 'arrow-down',
    'chevron-down': 'chevron-down',
    'chevron-left': 'chevron-left',
    'chevron-right': 'chevron-right',
    'chevron-up': 'chevron-up',
    block: 'block',
    'do-not-disturb': 'do-not-disturb',
    'bookmark-outline': 'bookmark-outline',
    bookmark: 'bookmark',
    call: 'call',
    remove: 'remove',
    cancel: 'cancel',
    chat: 'chat',
    alarm: 'alarm',
    'check-box-outline-blank': 'check-box-outline-blank',
    'check-box': 'check-box',
    'check-circle': 'check-circle',
    cloud: 'cloud',
    'cloud-circle': 'cloud-circle',
    'cloud-outline': 'cloud-outline',
    'cloud-done': 'cloud-done',
    'cloud-download': 'cloud-download',
    'cloud-upload': 'cloud-upload',
    copy: 'copy',
    'folder-new': 'folder-new',
    delete: 'delete',
    description: 'description',
    checkmark: 'checkmark',
    'drag-handle': 'drag-handle',
    edit: 'edit',
    heart: 'heart',
    'heart-outline': 'heart-outline',
    'file-download': 'file-download',
    'file-upload': 'file-upload',
    filters: 'filters',
    flag: 'flag',
    'folder-open': 'folder-open',
    'folder-shared': 'folder-shared',
    'folder-special': 'folder-special',
    folder: 'folder',
    forum: 'forum',
    help: 'help',
    home: 'home',
    sort: 'sort',
    'info-outline': 'info-outline',
    'error-outline': 'error-outline',
    file: 'file',
    launch: 'launch',
    grid: 'grid',
    'lightbulb-outline': 'lightbulb-outline',
    link: 'link',
    'live-help': 'live-help',
    'lock-open': 'lock-open',
    lock: 'lock',
    'mail-outline': 'mail-outline',
    mail: 'mail',
    ellipsis: 'ellipsis',
    map: 'map',
    location: 'location',
    print: 'print',
    refresh: 'refresh',
    'radio-checked': 'radio-checked',
    'radio-empty': 'radio-empty',
    'check-box-fixed': 'check-box-fixed',
    'remove-circle-outline': 'remove-circle-outline',
    'remove-circle': 'remove-circle',
    reply: 'reply',
    report: 'report',
    search: 'search',
    send: 'send',
    settings: 'settings',
    share: 'share',
    cart: 'cart',
    store: 'store',
    sync: 'sync',
    star: 'star',
    'star-outline': 'star-outline',
    'star-half': 'star-half',
    'thumb-down': 'thumb-down',
    'thumb-up': 'thumb-up',
    facebook: 'facebook',
    twitter: 'twitter',
    invisible: 'invisible',
    visible: 'visible',
    attach: 'attach',
    warning: 'warning',
  };

  static defaultProps = {
    size: 20,
  };

  render() {
    const { name, size, color, className, onClick, style, ...rest } = this.props;

    if (!name || !Icon.Names[name]) {
      console.warn(`[Icon] Invalid icon name - '${name}'`); // eslint-disable-line
    }

    return (
      <Container
        {...rest}
        key="container"
        onClick={onClick}
        style={{
          color,
          fontSize: Number(size) + 'px',
          ...style,
        }}
        className={cx('ic-icon', `ic-icon-${name}`, className)}
      />
    );
  }
}

const Container = styled.span`
  display: inline-block;
  font-family: 'paypay-icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  text-align: center;

  &.ic-icon-campaign::before {
    content: '\ue90d';
  }
  &.ic-icon-calendar::before {
    content: '\ue95b';
  }
  &.ic-icon-time::before {
    content: '\ue900';
  }
  &.ic-icon-alarm::before {
    content: '\ue951';
  }
  &.ic-icon-account::before {
    content: '\ue901';
  }
  &.ic-icon-camera::before {
    content: '\ue90f';
  }
  &.ic-icon-camera-add::before {
    content: '\ue902';
  }
  &.ic-icon-add::before {
    content: '\ue905';
  }
  &.ic-icon-add-circle-outline::before {
    content: '\ue903';
  }
  &.ic-icon-add-circle::before {
    content: '\ue904';
  }
  &.ic-icon-archive::before {
    content: '\ue906';
  }
  &.ic-icon-arrow-left::before {
    content: '\ue907';
  }
  &.ic-icon-arrow-down::before {
    content: '\ue908';
  }
  &.ic-icon-chevron-down::before {
    content: '\ue915';
  }
  &.ic-icon-chevron-left::before {
    content: '\ue916';
  }
  &.ic-icon-chevron-right::before {
    content: '\ue917';
  }
  &.ic-icon-chevron-up::before {
    content: '\ue918';
  }
  &.ic-icon-block::before {
    content: '\ue90a';
  }
  &.ic-icon-do-not-disturb::before {
    content: '\ue924';
  }
  &.ic-icon-bookmark-outline::before {
    content: '\ue90b';
  }
  &.ic-icon-bookmark::before {
    content: '\ue90c';
  }
  &.ic-icon-call::before {
    content: '\ue90e';
  }
  &.ic-icon-remove::before {
    content: '\ue919';
  }
  &.ic-icon-cancel::before {
    content: '\ue910';
  }
  &.ic-icon-chat::before {
    content: '\ue911';
  }
  &.ic-icon-check-box-outline-blank::before {
    content: '\ue912';
  }
  &.ic-icon-check-box::before {
    content: '\ue913';
  }
  &.ic-icon-check-circle::before {
    content: '\ue914';
  }
  &.ic-icon-cloud-circle::before {
    content: '\ue91a';
  }
  &.ic-icon-cloud-outline::before {
    content: '\ue91d';
  }
  &.ic-icon-cloud::before {
    content: '\ue91f';
  }
  &.ic-icon-cloud-done::before {
    content: '\ue91b';
  }
  &.ic-icon-cloud-download::before {
    content: '\ue91c';
  }
  &.ic-icon-cloud-upload::before {
    content: '\ue91e';
  }
  &.ic-icon-copy::before {
    content: '\ue920';
  }
  &.ic-icon-folder-new::before {
    content: '\ue921';
  }
  &.ic-icon-delete::before {
    content: '\ue922';
  }
  &.ic-icon-description::before {
    content: '\ue923';
  }
  &.ic-icon-checkmark::before {
    content: '\ue925';
  }
  &.ic-icon-drag-handle::before {
    content: '\ue926';
  }
  &.ic-icon-edit::before {
    content: '\ue927';
  }
  &.ic-icon-heart-outline::before {
    content: '\ue92a';
  }
  &.ic-icon-heart::before {
    content: '\ue92b';
  }
  &.ic-icon-file-download::before {
    content: '\ue92c';
  }
  &.ic-icon-file-upload::before {
    content: '\ue92d';
  }
  &.ic-icon-filters::before {
    content: '\ue92e';
  }
  &.ic-icon-flag::before {
    content: '\ue92f';
  }
  &.ic-icon-folder-open::before {
    content: '\ue930';
  }
  &.ic-icon-folder-shared::before {
    content: '\ue931';
  }
  &.ic-icon-folder-special::before {
    content: '\ue932';
  }
  &.ic-icon-folder::before {
    content: '\ue933';
  }
  &.ic-icon-forum::before {
    content: '\ue934';
  }
  &.ic-icon-help::before {
    content: '\ue935';
  }
  &.ic-icon-home::before {
    content: '\ue936';
  }
  &.ic-icon-sort::before {
    content: '\ue937';
  }
  &.ic-icon-info-outline::before {
    content: '\ue939';
  }
  &.ic-icon-error-outline::before {
    content: '\ue928';
  }
  &.ic-icon-file::before {
    content: '\ue93a';
  }
  &.ic-icon-launch::before {
    content: '\ue93b';
  }
  &.ic-icon-grid::before {
    content: '\ue93c';
  }
  &.ic-icon-lightbulb-outline::before {
    content: '\ue93d';
  }
  &.ic-icon-link::before {
    content: '\ue93e';
  }
  &.ic-icon-live-help::before {
    content: '\ue93f';
  }
  &.ic-icon-lock-open::before {
    content: '\ue940';
  }
  &.ic-icon-lock::before {
    content: '\ue941';
  }
  &.ic-icon-mail-outline::before {
    content: '\ue942';
  }
  &.ic-icon-mail::before {
    content: '\ue943';
  }
  &.ic-icon-ellipsis::before {
    content: '\ue945';
  }
  &.ic-icon-map::before {
    content: '\ue944';
  }
  &.ic-icon-location::before {
    content: '\ue946';
  }
  &.ic-icon-print::before {
    content: '\ue947';
  }
  &.ic-icon-radio-checked::before {
    content: '\ue948';
  }
  &.ic-icon-radio-empty::before {
    content: '\ue949';
  }
  &.ic-icon-refresh::before {
    content: '\ue94a';
  }
  &.ic-icon-check-box-fixed::before {
    content: '\ue938';
  }
  &.ic-icon-remove-circle-outline::before {
    content: '\ue94b';
  }
  &.ic-icon-remove-circle::before {
    content: '\ue94c';
  }
  &.ic-icon-reply::before {
    content: '\ue94d';
  }
  &.ic-icon-report::before {
    content: '\ue94e';
  }
  &.ic-icon-search::before {
    content: '\ue94f';
  }
  &.ic-icon-send::before {
    content: '\ue950';
  }
  &.ic-icon-settings::before {
    content: '\ue952';
  }
  &.ic-icon-share::before {
    content: '\ue951';
  }
  &.ic-icon-cart::before {
    content: '\ue953';
  }
  &.ic-icon-store::before {
    content: '\ue957';
  }
  &.ic-icon-star-outline::before {
    content: '\ue954';
  }
  &.ic-icon-star-half::before {
    content: '\ue955';
  }
  &.ic-icon-star::before {
    content: '\ue956';
  }
  &.ic-icon-sync::before {
    content: '\ue958';
  }
  &.ic-icon-thumb-down::before {
    content: '\ue959';
  }
  &.ic-icon-thumb-up::before {
    content: '\ue95a';
  }
  &.ic-icon-facebook::before {
    content: '\ue929';
  }
  &.ic-icon-twitter::before {
    content: '\ue95c';
  }
  &.ic-icon-invisible::before {
    content: '\ue95d';
  }
  &.ic-icon-visible::before {
    content: '\ue95e';
  }
  &.ic-icon-attach::before {
    content: '\ue909';
  }
  &.ic-icon-warning::before {
    content: '\ue95f';
  }
`;
