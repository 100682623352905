export const getItem = (id: string | undefined, items) => items.find(item => item.id === id);

export const asItems = (ids: string[], allItems) =>
  ids.map(id => getItem(id, allItems)).filter(item => item !== undefined && item !== null);

export const addId = (id: string, ids: string[]) => (ids.includes(id) ? ids : [...ids, id]);

export const removeIds = (ids: string[], allItems) => [...allItems].filter(item => !(ids || []).includes(item.id));

export const sort = (items: Partial<{ name: string }>[]) => [...items].sort((a, b) => (a.name || '').localeCompare(b.name || ''));
