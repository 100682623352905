import { ComponentType } from 'react';

import { withStyles, WithStyles } from '@material-ui/core/styles';
import { compose, withHandlers, withState } from 'recompose';

import { ButtonMenu, ButtonMenuProps } from './ButtonMenu';
import { styles } from './styles';

const addState = withState('menu', 'setMenu', null);

const addHandlers = withHandlers({
  handleClick:
    ({ setMenu }) =>
    ({ target }) =>
      setMenu(target),
  handleClose:
    ({ setMenu }) =>
    () =>
      setMenu(null),
  handleSelect:
    ({ selectedId, handleChangeSelected, setMenu }) =>
    id =>
    () => {
      if (id !== selectedId && handleChangeSelected) {
        handleChangeSelected(id);
      }
      setMenu(null);
    },
});

const enhance = compose(addState, addHandlers, withStyles(styles, { withTheme: true }));

export default enhance(ButtonMenu) as ComponentType<ButtonMenuProps & WithStyles<typeof styles>>;
