import { connect } from 'react-redux';

import { compose } from 'recompose';

import { creativesOperations } from 'app/ducks';
import withReporting from 'app/hocs/withReporting';
import withSearchBar from 'app/hocs/withReporting/withSearchBar';

import { searchBarDefs, searchDefiner } from './search.config';
import withWidgetTypes from '../../hoc/withWidgetTypes';

const mapStateToProps = ({
  bannerManagement: {
    creatives: { creatives, loading, error },
  },

  user: { email: userEmail, timezone },
}) => ({
  bannerCreatives: creatives,
  error,
  loading,
  timezone,
  userEmail,
});

const mapDispatchToProps = {
  getAllPaged: creativesOperations.getAllPaged,
};

const pagingSelector = ({
  bannerManagement: {
    creatives: { paging },
  },
}) => paging;

export default compose(
  withWidgetTypes,
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('getAllPaged', pagingSelector, { searchDefs: searchDefiner }),
  withSearchBar(searchBarDefs),
);

export * from './BannerCreativesReportBody';
