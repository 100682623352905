import React from 'react';

import { connect } from 'react-redux';

import * as CashBackCreativeActions from 'app/ducks/campaigns/campaign/cashbackCreative/actions';
import * as CashBackCreativeSelectors from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { eventSchemaOperations } from 'app/ducks/cashbackPromo/eventSchema';
import * as CashBackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';

import { EnrichFieldsCriteria } from '../../Criteria/EnrichFieldsCriteria';

const mapStateToProps = state => ({
  customerEvent: CashBackPromoConfigSelectors.getCustomerEvent(state),
  criteria: {
    userCriteria: CashBackCreativeSelectors.getJourneyUserCriteria(state),
    userCriteriaJson: CashBackCreativeSelectors.getJourneyUserCriteriaJson(state),
  },
});

const mapDispatchToProps = {
  getCustomerEvent: eventSchemaOperations.getCustomerEvent,
  updateJourneyUserCriteria: CashBackCreativeActions.updateJourneyUserCriteria,
  updateJourneyUserCriteriaJson: CashBackCreativeActions.updateJourneyUserCriteriaJson,
};

const UserCriteria = connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { criteria, customerEvent, getCustomerEvent, updateJourneyUserCriteria, updateJourneyUserCriteriaJson } = props;
  return (
    <EnrichFieldsCriteria
      criteria={criteria}
      customerEvent={customerEvent.customerEvent}
      loadingCustomerEvent={customerEvent.loadingCustomerEvent}
      getCustomerEvent={getCustomerEvent}
      updateCashbackCriteria={payload => {
        updateJourneyUserCriteria({ userCriteria: payload.userCriteria });
        updateJourneyUserCriteriaJson({
          userCriteriaJson: payload.userCriteriaJson,
        });
      }}
    />
  );
});

export default UserCriteria;
