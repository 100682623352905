import styled from 'styled-components';

import Icon from 'app/midgarComponents/Icon';
import { sc } from 'app/styles';

type Props = {
  onClick: () => void;
};

export default ({ onClick }: Props) => (
  <CloseButton onClick={onClick}>
    <Icon name="remove" size={25} />
  </CloseButton>
);

const CloseButton = styled.span`
  display: block;
  padding: ${sc.gutterSmallest};
  cursor: pointer;
  transition: all 100ms linear;

  &:hover {
    color: ${sc.primary};
  }
`;
