import {
  AddAppVersionGroupPayload,
  AddEngageCategoryGroupPayload,
  AddVariantGroupPayload,
  DeleteAppVersionGroupPayload,
  DeleteEngageCategoryGroupPayload,
  FetchCreativeErrorPayload,
  FetchCreativeSuccessPayload,
  PopulateCreativeFormSuccessPayload,
  PopulateFormVariantsPayload,
  RemoveVariantGroupPayload,
  SaveCreativeErrorPayload,
  SaveCreativeSuccessPayload,
  UpdateDuplicateMetadataFoundPayload,
  UpdateFieldPayload,
  UpdateVariantsAppVersionsFieldPayload,
  UpdateVariantsEngageCategoriesFieldPayload,
  UpdateVariantsFieldPayload,
  UpdateVariantsLabelFieldPayload,
  UpdateVariantsMetadataPayload,
  UpdateVariantsPersonalizationMetadataPayload,
} from './payloadTypes';
import * as types from './types';
import { IVariant } from '../../../types/BannerManagement';

export const resetCreativeForm = () => ({
  type: types.RESET_CREATIVE_FORM,
});

export const updateField = (field: UpdateFieldPayload['field'], value: UpdateFieldPayload['value']) => ({
  type: types.UPDATE_CREATIVE_FORM_FIELD,
  payload: { field, value },
});

export const addVariantGroup = (newIndex: AddVariantGroupPayload['newIndex']) => ({
  type: types.UPDATE_CREATIVE_FORM_ADD_VARIANT_GROUP,
  payload: { newIndex },
});

export const removeVariantGroup = (index: RemoveVariantGroupPayload['index']) => ({
  type: types.UPDATE_CREATIVE_FORM_REMOVE_VARIANT_GROUP,
  payload: { index },
});

export const addEngageCategoryGroup = (
  index: AddEngageCategoryGroupPayload['index'],
  newIndex: AddEngageCategoryGroupPayload['newIndex'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_ADD_ENGAGE_CATEGORY_GROUP,
  payload: { index, newIndex },
});

export const deleteEngageCategoryGroup = (
  variantIndex: DeleteEngageCategoryGroupPayload['variantIndex'],
  categoryIndex: DeleteEngageCategoryGroupPayload['categoryIndex'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_DELETE_ENGAGE_CATEGORY_GROUP,
  payload: { variantIndex, categoryIndex },
});

export const addAppVersionGroup = (index: AddAppVersionGroupPayload['index'], newIndex: AddAppVersionGroupPayload['newIndex']) => ({
  type: types.UPDATE_CREATIVE_FORM_ADD_APP_VERSION_GROUP,
  payload: { index, newIndex },
});

export const deleteAppVersionGroup = (
  variantIndex: DeleteAppVersionGroupPayload['variantIndex'],
  versionIndex: DeleteAppVersionGroupPayload['versionIndex'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_DELETE_APP_VERSION_GROUP,
  payload: { variantIndex, versionIndex },
});

export const updateVariantsField = (
  index: UpdateVariantsFieldPayload['index'],
  field: UpdateVariantsFieldPayload['field'],
  value: UpdateVariantsFieldPayload['value'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_FIELD,
  payload: { index, field, value },
});

export const updateVariantsLocationsField = <K extends keyof IVariant['locations']>(
  index: number,
  field: K,
  value: IVariant['locations'][K],
) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LOCATIONS_FIELD,
  payload: { index, field, value },
});

export const updateVariantsEngageCategoriesField = (
  index: UpdateVariantsEngageCategoriesFieldPayload['index'],
  catIndex: UpdateVariantsEngageCategoriesFieldPayload['catIndex'],
  field: UpdateVariantsEngageCategoriesFieldPayload['field'],
  value: UpdateVariantsEngageCategoriesFieldPayload['value'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_ENGAGE_CATEGORIES_FIELD,
  payload: { index, catIndex, field, value },
});

export const updateVariantsAppVersionsField = (
  index: UpdateVariantsAppVersionsFieldPayload['index'],
  versionIndex: UpdateVariantsAppVersionsFieldPayload['versionIndex'],
  field: UpdateVariantsAppVersionsFieldPayload['field'],
  value: UpdateVariantsAppVersionsFieldPayload['value'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_APP_VERSIONS_FIELD,
  payload: { index, versionIndex, field, value },
});

export const updateVariantsLabelField = (
  index: UpdateVariantsLabelFieldPayload['index'],
  field: UpdateVariantsLabelFieldPayload['field'],
  value: UpdateVariantsLabelFieldPayload['value'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LABEL_FIELD,
  payload: { index, field, value },
});

export const updateVariantsMetadata = (
  variantIndex: UpdateVariantsMetadataPayload['variantIndex'],
  metadata: UpdateVariantsMetadataPayload['metadata'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_METADATA,
  payload: { variantIndex, metadata },
});

export const updateVariantsPersonalizationMetadata = (
  variantIndex: UpdateVariantsPersonalizationMetadataPayload['variantIndex'],
  personalizationMetadata: UpdateVariantsPersonalizationMetadataPayload['personalizationMetadata'],
) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_PERSONALIZATION_METADATA,
  payload: { variantIndex, personalizationMetadata },
});

export const updateDuplicateMetadataFound = (duplicateMetadataFound: UpdateDuplicateMetadataFoundPayload['duplicateMetadataFound']) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_DUPLICATE_METADATA_FOUND,
  payload: { duplicateMetadataFound },
});

/**
 * Fetch creative
 */
export const fetchCreative = () => ({
  type: types.FETCH_CREATIVE,
});

export const fetchCreativeSuccess = (creative: FetchCreativeSuccessPayload['creative']) => ({
  type: types.FETCH_CREATIVE_SUCCESS,
  payload: { creative },
});

export const fetchCreativeError = (error: FetchCreativeErrorPayload['error']) => ({
  type: types.FETCH_CREATIVE_ERROR,
  payload: { error },
});

export const saveCreativeError = (error: SaveCreativeErrorPayload['error']) => ({
  type: types.SAVE_CREATIVE_ERROR,
  payload: { error },
});

export const saveCreativeStart = () => ({
  type: types.SAVE_CREATIVE_START,
});

export const saveCreativeSuccess = (res: SaveCreativeSuccessPayload['res']) => ({
  type: types.SAVE_CREATIVE_SUCCESS,
  payload: { res },
});

export const populateFormVariants = (
  view: PopulateFormVariantsPayload['view'],
  viewItem: PopulateFormVariantsPayload['viewItem'],
  engageCategories: PopulateFormVariantsPayload['engageCategories'],
) => ({
  type: types.POPULATE_CREATIVE_FORM_VARIANTS,
  payload: { engageCategories, view, viewItem },
});

export const populateCreativeFormStart = () => ({
  type: types.POPULATE_CREATIVE_FORM_START,
});

export const populateCreativeFormSuccess = (creative: PopulateCreativeFormSuccessPayload['creative']) => ({
  type: types.POPULATE_CREATIVE_FORM_SUCCESS,
  payload: { creative },
});

export const populateCreativeFormError = () => ({
  type: types.POPULATE_CREATIVE_FORM_ERROR,
});
