import React from 'react';

import { connect } from 'react-redux';
import { Link as _Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { compose } from 'ramda';
import styled from 'styled-components';

import { ButtonMenu, UserInfo, ErrorBoundary } from 'app/components';
import withAppConfig from 'app/decorators/withAppConfig';
import { UPDATECONFIGSTORE } from 'app/ducks/configStore/types';
import { SideNav, TopBar } from 'app/midgarComponents';
import { sc, media } from 'app/styles';
import { UserState } from 'app/types/typescript/State';
import { IAppConfig } from 'configs/apps/types';
import { SIGN_OUT } from 'configs/routes';
import { permittedTenants } from 'configs/user';

const isProduction = process.env.NODE_ENV === 'production';

export type INavItem = {
  children?: Array<INavItem>;
  disable?: boolean;
  iconName?: string;
  key: string;
  label: string;
  relativeMatch?: boolean;
  to: string;
};

type Props = {
  appConfig: IAppConfig;
  items: Array<INavItem>;
  children: React.ReactNode;
  user: UserState;
  handleChangeTenant: (...args: Array<any>) => any;
  location: {
    pathname: string;
  };

  classes: {
    userSection: string;
  };
};

type State = {
  showAnnoucements: boolean;
};

class Layout extends React.PureComponent<Props, State> {
  state = {
    showAnnoucements: false,
  };

  componentDidMount() {
    const { appConfig, user } = this.props;
    this.props.dispatchConfigUpdate(appConfig, user);
  }

  render() {
    const { items, children, user, handleChangeTenant, location, appConfig } = this.props;
    const { envTag } = appConfig;

    return (
      <Main>
        <TopBar data-qa="top-bar">
          <Link to="/">
            <LogoPrimary>pai</LogoPrimary>
            <LogoSecondary>Growth Marketing</LogoSecondary>
            {envTag && <EnvTag env={envTag}>{envTag}</EnvTag>}
            <Version>{process.env.REACT_APP_VERSION}</Version>
          </Link>
          <UserSection>
            {!isProduction && <Typography>{process.env.MAP_ENV}</Typography>}
            {permittedTenants(user) && permittedTenants(user).length > 1 && (
              <ButtonMenu
                items={permittedTenants(user).map(name => ({ id: name, name }))}
                selectedId={user.currentTenant}
                handleChangeSelected={handleChangeTenant}
              />
            )}

            <UserInfo name={user.name} picture={user.picture} />
            <LogoutLink href={SIGN_OUT}>Sign out</LogoutLink>
          </UserSection>
        </TopBar>

        <FlexWrapper>
          <SideNav menuData={items} path={location.pathname} />
          <Article>{childrenComponent(children)}</Article>
        </FlexWrapper>
      </Main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchConfigUpdate: (appConfig, user) =>
    dispatch({ type: UPDATECONFIGSTORE, payload: { ...appConfig, isSecureRoom: user.isSecureRoom } }),
});

export default compose(withAppConfig, connect(null, mapDispatchToProps))(Layout);

const childrenComponent = children => <ErrorBoundary>{children}</ErrorBoundary>;
const LogoPrimary = styled.span`
  color: ${sc.primary};
  font-size: ${sc.fontSizeLargest};
  text-transform: uppercase;
  font-weight: 500;
`;

const LogoSecondary = styled.span`
  color: ${sc.secondary};
  font-size: ${sc.fontSizeLarge};
  text-transform: capitalize;
  margin-left: 5px;
  font-weight: 700;
`;

const Version = styled.span`
  font-size: ${sc.fontSizeSmallest};
  margin-left: ${sc.gutterSmallest};
`;

const Article = styled.article`
  position: relative;
  max-width: 2000px;
  border-radius: 2px;
  width: calc(100% - 260px);
  flex-grow: 1;

  ${media.smallScreen({
    width: 'calc(100% - 80px)',
  })}
`;

const FlexWrapper = styled.div`
  margin-top: 56px;
  width: 100vw;
  height: calc(100vh - 56px);
  display: flex;
`;

const EnvTag = styled.div`
  height: 1.3rem;
  margin: 0 0.2rem;
  background-color: ${props => (props.env === 'staging' ? '#eb705f' : props.env === 'performance' ? '#5F6DEB' : '#4CB9DB')};
  color: white;
  font-size: 0.8rem;
  line-height: 1.6;
  padding: 0 0.3rem;
  border-radius: 0.2rem;
`;

const Link = styled(_Link)`
  text-decoration: none;
  display: flex;
  align-items: flex-end;
`;

const LogoutLink = styled.a`
  margin-left: ${sc.gutterLarge};
  font-size: ${sc.fontSizeSmaller};
  color: ${sc.greyDark};
`;

const Main = styled.main`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserSection = styled.section`
  display: flex;
  align-items: center;
`;
