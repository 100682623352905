import { connect } from 'react-redux';

import { compose, lifecycle } from 'recompose';

import { withAppConfig } from 'app/decorators';
import { getWidgetTypes as opGetWidgetTypes } from 'app/ducks/bannerManagement/widgetTypes/operations';
import { getAllPlatforms, getAllSites, mapStateToWidgetTypes } from 'app/ducks/bannerManagement/widgetTypes/selectors';
import { DispatchFn } from 'app/types/state';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

const mapStateToProps = state => {
  const allPlatforms = getAllPlatforms(state);
  const allSites = getAllSites(state);

  return {
    ...mapStateToWidgetTypes(state),
    allPlatforms,
    allSites,
  };
};

const mapDispatchToProps = (dispatch: DispatchFn, { appConfig }: { appConfig: IAppConfig }) => {
  const { bmsVersion } = getBannerCreativeConfig(appConfig);

  return {
    getWidgetTypes: () => dispatch(opGetWidgetTypes(bmsVersion)()),
  };
};

export default compose(
  withAppConfig,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { getWidgetTypes, widgetTypes, widgetTypesLoading } = this.props;

      if (!(widgetTypes || []).length && !widgetTypesLoading) {
        getWidgetTypes();
      }
    },
  }),
);
