import React from 'react';

import { connect } from 'react-redux';

import { compose } from 'ramda';
import styled from 'styled-components';

import { withAppConfig } from 'app/decorators';
import { creativeActions } from 'app/ducks';
import { mapStateToGaTypes } from 'app/ducks/bannerManagement/gaTypes/selectors';
import { getAllPlatforms, getAllSites } from 'app/ducks/bannerManagement/widgetTypes/selectors';
import _WidgetType from 'app/features/BannerManagement/common/WidgetType';
import { showWidgetType } from 'app/features/BannerManagement/utils';
import { Autocomplete as _Autocomplete, Pills as _Pills, TextField as _TextField, Toggle } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IBannerCreative } from 'app/types/BannerManagement';
import { ISuggestion } from 'app/types/utils';
import { getBannerCreativeConfig, LEVEL_CREATIVE } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

const asSuggestion = (name: string): ISuggestion => ({ id: name.toLowerCase(), name });

type Props = {
  allPlatforms: Array<string>;
  allSites: Array<string>;
  appConfig: IAppConfig;
  creativeFieldHasError: (arg0: string) => boolean;
  form: IBannerCreative;
  gaTypes: Array<Record<string, any>>;
  shouldValidate: boolean;
  updateField: (field: string, value: any) => any;
};

type State = {
  platformsValue: string;
  siteValue: string;
};

class Creative extends React.PureComponent<Props, State> {
  state = {
    platformsValue: '',
    siteValue: '',
  };

  creativeConfig = getBannerCreativeConfig(this.props.appConfig);

  addPlatform = (platform: ISuggestion) => {
    const { allPlatforms, form, updateField } = this.props;

    if (platform?.id === 'all') {
      updateField('platform', allPlatforms.map(asSuggestion));
    } else if (!form.platforms?.find(p => p.id === platform?.id)) {
      const platforms = [...(form.platform || []), platform];
      updateField('platform', platforms);
    }
  };

  addSite = (site: ISuggestion) => {
    const { form, updateField } = this.props;

    if (!form.sites?.find(s => s.id === site.id)) {
      const sites = [...(form.site || []), site];
      updateField('site', sites);
    }
  };

  removePlatform = (platform: ISuggestion) => {
    const { form, updateField } = this.props;
    const platforms = form.platform ? form.platform.slice() : [];
    const idx = platforms.findIndex(p => p.id === platform.id);

    if (idx > -1) {
      platforms.splice(idx, 1);
      updateField('platform', platforms);
    }
  };

  removeSite = (site: ISuggestion) => {
    const { form, updateField } = this.props;
    const sites = form.site ? form.site.slice() : [];
    const idx = sites.findIndex(s => s.id === site.id);

    if (idx > -1) {
      sites.splice(idx, 1);
      updateField('site', sites);
    }
  };

  render() {
    const { platformsValue, siteValue } = this.state;

    const { allPlatforms, allSites, creativeFieldHasError, form, gaTypes, shouldValidate, updateField } = this.props;

    const { useShareable, useTitles } = this.creativeConfig;

    const siteSuggestions: Array<ISuggestion> = allSites.map(asSuggestion);
    const platformSuggestions: Array<ISuggestion> = ['ALL', ...allPlatforms].map(asSuggestion);

    return (
      <Container data-qa="banner-creative-root">
        {showWidgetType(this.creativeConfig, LEVEL_CREATIVE) && (
          <WidgetType
            // disabled={readOnly}
            // TODO: Make required
            error={shouldValidate && creativeFieldHasError('widgetType')}
            onChangeWidgetType={(widgetType: string) => updateField('widgetType', widgetType)}
            required
            value={form.widgetType || ''}
          />
        )}

        <TextField
          error={shouldValidate && creativeFieldHasError('name')}
          id="name"
          label="Name"
          name="name"
          onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => updateField('name', ev.target.value)}
          required
          value={form.name || ''}
        />

        <Pills
          error={shouldValidate && creativeFieldHasError('site')}
          id="site"
          label="Site"
          name="site"
          onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ siteValue: ev.target.value })}
          onRemove={this.removeSite}
          onSelect={this.addSite}
          required
          selected={form.site}
          suggestions={siteSuggestions}
          value={siteValue}
        />

        <Pills
          error={shouldValidate && creativeFieldHasError('platform')}
          id="platforms"
          label="Platforms"
          name="platforms"
          onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ platformsValue: ev.target.value })}
          onRemove={this.removePlatform}
          onSelect={this.addPlatform}
          required
          selected={form.platform}
          suggestions={platformSuggestions}
          value={platformsValue}
        />

        {this.creativeConfig.useGACategory && (
          <Autocomplete
            error={shouldValidate && creativeFieldHasError('gaCategory')}
            id="ga"
            label="GA Tracking Category"
            name="ga"
            onSelect={(suggestion: string) => updateField('gaCategory', suggestion)}
            required
            suggestions={gaTypes}
            value={form.gaCategory}
          />
        )}

        {(useShareable || useTitles) && (
          <>
            <Separator />

            {useTitles && (
              <>
                <TextField
                  error={shouldValidate && creativeFieldHasError('title')}
                  id="title"
                  label="Title"
                  name="title"
                  onChange={ev => updateField('title', ev.target.value)}
                  value={form.title || ''}
                />

                <TextField
                  error={shouldValidate && creativeFieldHasError('subtitle')}
                  id="subtitle"
                  label="Sub-Title"
                  name="subtitle"
                  onChange={ev => updateField('subtitle', ev.target.value)}
                  value={form.subtitle || ''}
                />
              </>
            )}

            {useShareable && (
              <>
                <Label>Shareable</Label>
                <Toggle active={!!form.shareable} onChange={status => updateField('shareable', status)} />
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    allPlatforms: getAllPlatforms(state),
    allSites: getAllSites(state),
    ...mapStateToGaTypes(state),
  };
};

const mapDispatchToProps = {
  updateField: creativeActions.updateField,
};

export default compose(withAppConfig, connect(mapStateToProps, mapDispatchToProps))(Creative);

const Autocomplete = styled(_Autocomplete)`
  margin-bottom: ${sc.gutterSmall};
`;

const Container = styled.div`
  width: 40%;
`;

const Label = styled.div`
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmallest};
  text-transform: uppercase;
  margin-bottom: ${sc.gutterSmallest};
`;

const Pills = styled(_Pills)`
  margin-bottom: ${sc.gutterSmall};
`;

export const Separator = styled.hr`
  height: 1px;
  width: 100%;
  margin-bottom: ${sc.gutterSmall};

  background-color: ${sc.greyLighter};
  border: none;
`;

const TextField = styled(_TextField)`
  margin-bottom: ${sc.gutterSmall};
`;

const WidgetType = styled(_WidgetType)`
  margin-bottom: ${sc.gutterSmall};
`;
