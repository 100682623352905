import { isEmpty } from 'ramda';

import { uploadImage } from 'app/api/bannerManagementV2/images';
import { uploadLocationFile } from 'app/api/bannerManagementV2/locations';
import {
  deleteViewItems as apiDeleteViewItems,
  moveAfterBannerId as apiMoveAfterBannerId,
  moveViewItemsToSlot as apiMoveViewItemsToSlot,
} from 'app/api/bannerManagementV2/slots';
import { BmsError } from 'app/api/bannerManagementV2/types';
import {
  fetchViewItem as apiFetchViewItem,
  saveNewViewItem as apiSaveNewViewItem,
  updateVariant,
  updateVariantStatus,
  updateViewItem as apiUpdateViewItem,
} from 'app/api/bannerManagementV2/viewItems';
import { toApiNewViewItem, toApiViewItem } from 'app/api/bannerManagementV2/viewItems/mappers';
import {
  createView,
  deleteViewItems as apiDeleteItemsInView,
  fetchStorefrontProxyView,
  fetchView,
  fetchViewItemByBannerId,
  fetchViewItemByCreativeId,
  updateViewPersonalization,
  updateSlotOrder,
  updateView,
} from 'app/api/bannerManagementV2/views';
import { IFetchViewResult } from 'app/api/bannerManagementV2/views/types';
import { DEFAULT_SLOT_GROUP_TYPE } from 'app/features/BannerManagement/constants';
import { ACTION_DELETE, ACTION_MOVE } from 'app/features/BannerManagement/Views/Overview/SlotContents/BulkActions/constants';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { IBannerCreative, ISlot, IVariant, IViewItem } from 'app/types/BannerManagement';
import { DispatchFn, GetStateFn } from 'app/types/state';
import api from 'app/utilities/api';
import { formatTimestamp } from 'app/utilities/date';
import result from 'app/utilities/result';
import { defaultDateTimeFormat } from 'configs/dateTime';

import * as actions from './actions';

export const fetchViewItem =
  (id: number, redirect?: (...args: Array<any>) => any) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    const {
      user: { timezone },
    } = getState();
    dispatch(actions.fetchViewItemStart());

    try {
      const { startDate, endDate, validFrom, validUpto, ...rest } = await apiFetchViewItem(id);

      const viewItem = {
        startDate: startDate ? formatTimestamp(startDate, timezone, defaultDateTimeFormat) : null,
        endDate: endDate ? formatTimestamp(endDate, timezone, defaultDateTimeFormat) : null,
        validFrom: validFrom ? formatTimestamp(validFrom, timezone, defaultDateTimeFormat) : null,
        validUpto: validUpto ? formatTimestamp(validUpto, timezone, defaultDateTimeFormat) : null,
        ...rest,
      };

      return dispatch(actions.fetchViewItemSuccess(viewItem));
    } catch (err) {
      displayError(err.error && err.error.msg);
      dispatch(actions.fetchViewItemError(err));

      if (typeof redirect === 'function') {
        return redirect();
      }

      return null;
    }
  };

export const getViewById =
  (viewIdRaw: string | number, params: Record<string, any> = {}) =>
  async (dispatch: DispatchFn) => {
    const { redirect, slotId, withSP } = params;
    const viewId: number = typeof viewIdRaw === 'string' ? Number(viewIdRaw) : viewIdRaw;
    dispatch(actions.getViewById());

    try {
      const viewRes: IFetchViewResult = await fetchView(viewId);
      const { slots, view } = viewRes;
      view.slots = (view.slots || slots || []).sort(
        ({ priority: aPriority = Number.MIN_SAFE_INTEGER }, { priority: bPriority = Number.MIN_SAFE_INTEGER }) => aPriority - bPriority,
      );

      dispatch(actions.getViewByIdSuccess(view, slotId));

      if (withSP && view) {
        const { id: viewIdRetrieved, entityId, decoratorId } = view;
        dispatch(actions.getSPViewStart());

        if (viewId && entityId && decoratorId) {
          // The view from Storefront has is_recommended, which overrides that property in the BMS View
          const spView = await fetchStorefrontProxyView('category', entityId, decoratorId, viewIdRetrieved);
          // This action merges is_recommended from storefront view with the main view
          dispatch(actions.getSPViewSuccess(view, spView));
        }
      }

      return null;
    } catch (err) {
      displayError(err.error && err.error.msg);
      dispatch(actions.getViewByIdFailed(err));

      if (typeof redirect === 'function') {
        return redirect();
      }

      return null;
    }
  };

export const bindCreative =
  (creative: IBannerCreative, cb?: (...args: Array<any>) => any) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    const {
      bannerManagement: { binder },
      user,
    } = getState();

    if (!(binder.selectedSlot || {}).id) {
      return displayError('No slot selected');
    }

    dispatch(actions.bindCreativeStart());

    try {
      const viewItem = toApiNewViewItem(creative, binder, user);
      const { id: viewItemId } = await apiSaveNewViewItem(viewItem);

      if (viewItemId) {
        dispatch(actions.bindCreativeSuccess(binder.selectedSlot.id));
        dispatch(
          getSlotViewItems(
            binder.selectedSlot.id,
            {
              type: binder.selectedSlotGroupType.value,
              page: binder.slotViewItemsPage,
            },

            false,
          ),
        );

        displaySuccess('Creative bound successfully');

        if (typeof cb === 'function') {
          return cb();
        }
      }

      return displayError('Unknown error: API returned no value');
    } catch (err) {
      displayError(err.error && err.error.msg);
      return dispatch(actions.bindCreativeError(err));
    }
  };

export const addSlot = (viewId: number) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const { user } = getState();

  dispatch(actions.addSlot());

  try {
    await api.request('POST', '/ec/slot', {
      body: {
        viewId,
        createdBy: user.email,
      },
    });

    const viewRes: IFetchViewResult = await fetchView(viewId);
    displaySuccess('Slot added successully');
    return dispatch(actions.addSlotSuccess(viewRes.slots));
  } catch (err) {
    displayError(err.error && err.error.msg);
    return dispatch(actions.addSlotFailed(err));
  }
};

export const deleteSlot = (slotId: number) => async (dispatch: DispatchFn) => {
  dispatch(actions.deleteSlotStart());
  try {
    const { msg } = await api.request('DELETE', `/ec/slot/${slotId}`);
    dispatch(actions.deleteSlotSuccess(slotId));
    return displaySuccess(msg);
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    dispatch(actions.deleteSlotFailed());
    return displayError(err.error && err.error.msg);
  }
};

export const updateSlotStatus = (slotId: number, status: boolean) => async (dispatch: DispatchFn) => {
  try {
    await api.put(`/ec/slot/${slotId}/status`, {
      body: {
        status: status ? 1 : 0,
      },
    });

    return dispatch(actions.updateSlotStatusSuccess(slotId, status));
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return displayError(err.error && err.error.msg);
  }
};

export const updateSlotOverride = (slotId: number, override: boolean) => async (dispatch: DispatchFn) => {
  try {
    await api.put(`/ec/slot/${slotId}/override`, {
      body: {
        overridden: !!override,
      },
    });

    dispatch(actions.updateSlotOverrideSuccess(slotId, override));
    return displaySuccess('Slot override success');
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return displayError(err.error && err.error.msg);
  }
};

export const getSlotViewItems =
  (slotId: number | null | undefined, params: Record<string, any> = {}, showLoading = true) =>
  async (dispatch: DispatchFn, getState: GetStateFn) => {
    const {
      bannerManagement: {
        binder: { slotViewItemsPageSize },
        slotGroupTypes: { slotGroupTypes },
      },
    } = getState();
    const defaultSlotGroupType = slotGroupTypes ? slotGroupTypes[0] : null;

    if (!slotId) {
      return undefined;
    }

    if (showLoading) {
      dispatch(actions.getSlotViewItems());
    }

    if (!params.type && defaultSlotGroupType) {
      dispatch(actions.setSelectedSlotGroupType(defaultSlotGroupType));
    }

    const page = params.page || 1;
    const type = params.type || (defaultSlotGroupType || {}).value;
    const query = Object.assign(params, {
      type,
      page_size: slotViewItemsPageSize,
      page_no: page,
      // includeTemplate is turned off to exclude banners which are backend-generated templates, for personalised widgets
      includeTemplate: false,
    });

    delete query.page;

    try {
      const { viewItems, totalRows, versions } = await api.get(`/ec/slot/${slotId}/viewitem`, { query });
      const version = versions.find(x => x.type === type) || {};
      return dispatch(actions.getSlotViewItemsSuccess(viewItems, totalRows, version.version, page));
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      displayError(err.error && err.error.msg);
      return dispatch(actions.getSlotViewItemsFailed(err));
    }
  };

export const saveView = (storefrontId: number) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  dispatch(actions.saveViewStart());
  const {
    bannerManagement: {
      binder: {
        view: { view },
      },
    },
  } = getState();
  const isUpdate = !!view.id;

  try {
    // TODO: Can we get storefrontId from the state instead of passing it?
    const res = isUpdate ? await updateView(view.id, view) : await createView(storefrontId, view);
    displaySuccess(res.msg || 'Saved View');
    return dispatch(actions.saveViewSuccess());
  } catch (err) {
    return dispatch(actions.saveViewFailed(err));
  }
};

export const saveViewItem = (viewItemId: number | string) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  if (!viewItemId && !Number.isNaN(Number(viewItemId))) {
    return null;
  }

  const {
    bannerManagement: { binder },
    user,
  } = getState();
  const { viewItem } = binder;

  dispatch(actions.saveViewItemStart());

  try {
    const apiViewItem = toApiViewItem(viewItem, binder, user);
    const res = await apiUpdateViewItem(apiViewItem, Number(viewItemId));
    if (res) {
      displaySuccess(`Successfully updated viewItem ${viewItemId}`);
      return dispatch(actions.saveViewItemSuccess(viewItem.slotId));
    }

    return displayError('Unknown error: API returned no value');
  } catch (err) {
    displayError(err.error && err.error.msg);
    return dispatch(actions.saveViewItemError(err));
  }
};

export const saveVariantStatus =
  (viewItem: IViewItem, variant: IVariant, statusFlag: boolean) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    const status = statusFlag ? 1 : 0;
    const prevStatus = variant.status || 0;

    dispatch(actions.updateVariantStatus(viewItem, variant, status));
    dispatch(actions.saveVariantStatusStart(viewItem, variant));

    try {
      await updateVariantStatus(viewItem, variant, status);

      displaySuccess('Status updated successfully');
      dispatch(actions.saveVariantStatusSuccess(viewItem, variant));

      // TODO: Remove the need to return a value
      const {
        bannerManagement: {
          binder: { viewItem: resViewItem },
        },
      } = getState();
      return resViewItem;
    } catch (err) {
      const bmsError: BmsError = err;
      const { msg } = bmsError?.error || {};

      msg && displayError(msg);
      dispatch(actions.updateVariantStatus(viewItem, variant, prevStatus));
      dispatch(actions.saveVariantStatusError(viewItem, variant, err));
    }
  };

export const saveOrder = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: { binder },
  } = getState();

  if (!binder.selectedSlot) {
    return null;
  }

  try {
    const pageNum = binder.slotViewItemsPage;
    const pageSize = binder.slotViewItemsPageSize;

    await api.put(`/ec/slot/${binder.selectedSlot.id}/viewitem`, {
      body: {
        type: binder.slotViewItems[0].type,
        version: binder.slotViewItemsVersion,
        viewItems: binder.slotViewItems.map((x, index) => ({
          id: x.id,
          priority: (pageNum - 1) * pageSize + (index + 1),
        })),
      },
    });

    dispatch(
      getSlotViewItems(
        binder.selectedSlot.id,
        {
          type: binder.selectedSlotGroupType.value,
          page: pageNum,
        },

        false,
      ),
    );

    return displaySuccess('Order updated successfully');
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return displayError(err.error && err.error.msg);
  }
};

export const moveAfterBannerId =
  (afterBannerId: number, viewItems: Array<IViewItem>) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    if (!afterBannerId || !(viewItems || []).length) {
      return null;
    }

    const {
      bannerManagement: { binder },
    } = getState();

    dispatch(actions.moveAfterBannerIdStart());

    try {
      const { priority } = await apiMoveAfterBannerId(binder.selectedSlot.id, afterBannerId, {
        type: viewItems[0].type || DEFAULT_SLOT_GROUP_TYPE,
        version: binder.slotViewItemsVersion,
        viewItems,
      });

      dispatch(actions.moveAfterBannerIdSuccess(priority));
      displaySuccess('Successfully moved view items');
      return priority;
    } catch (err) {
      dispatch(actions.moveAfterBannerIdError());
      return displayError(err.error && err.error.msg);
    }
  };

export const saveSlotOrder = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      binder: {
        view: { view, slots },
      },
    },
  } = getState();

  if (!view) {
    return null;
  }

  try {
    await updateSlotOrder(
      view.id,
      slots.map((slot: ISlot, idx: number) => ({
        id: slot.id,
        priority: idx + 1,
      })),
    );

    dispatch(actions.updateSlotOrderSuccess());
    return displaySuccess('Order updated successfully');
  } catch (err) {
    dispatch(actions.updateSlotOrderError(err));
    console.error(err); // eslint-disable-line no-console
    return displayError(err.error && err.error.msg);
  }
};

export const deleteViewItems = (ids: Array<number>) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: { binder },
  } = getState();

  if (!ids || !ids.length || !binder.selectedSlot) {
    return null;
  }

  dispatch(actions.bulkViewItemActionStart(ACTION_DELETE));

  try {
    const { version } = await apiDeleteViewItems(
      binder.selectedSlot.id,
      (binder.selectedSlotGroupType || {}).value,
      binder.slotViewItemsVersion,
      ids,
    );

    dispatch(actions.bulkViewItemDeleteSuccess(ids, version));
    return displaySuccess('ViewItems removed successfully');
  } catch (err) {
    dispatch(actions.bulkViewItemActionError(ACTION_DELETE, err));
    return displayError(err.error && err.error.msg);
  }
};

export const deleteViewItemsAcrossSlots = (bannerIds: Array<number>) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      binder: {
        selectedSlot,
        selectedSlotGroupType,
        slotViewItemsPage,
        view: { view },
      },
    },
  } = getState();
  const { id: viewId } = view || {};

  dispatch(actions.bulkViewItemActionStart(ACTION_DELETE));

  try {
    const res = await apiDeleteItemsInView(viewId, bannerIds);
    const version = -1;
    dispatch(actions.bulkViewItemDeleteSuccess(bannerIds, version));
    const { msg } = res || {};
    displaySuccess(msg || 'ViewItems removed successfully');

    // Re-fetch the slot contents to get the latest slotViewItemsVersion
    const { id: slotId } = selectedSlot || {};
    const { value: slotGroupTypeName } = selectedSlotGroupType || {};
    dispatch(getSlotViewItems(slotId, { type: slotGroupTypeName, page: slotViewItemsPage }));
  } catch (err) {
    dispatch(actions.bulkViewItemActionError(ACTION_DELETE, err));
    displayError(err.error && err.error.msg);
  }
};

export const moveViewItems =
  (ids: Array<number>, targetSlotId: number, targetSlotGroupTypeName: string) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    const {
      bannerManagement: { binder },
    } = getState();

    if (!ids || !ids.length || !binder.selectedSlot) {
      return null;
    }

    dispatch(actions.bulkViewItemActionStart(ACTION_MOVE));

    try {
      const { msg } = await apiMoveViewItemsToSlot(ids, targetSlotId, targetSlotGroupTypeName);

      dispatch(actions.bulkViewItemMoveSuccess(ids));
      displaySuccess(msg || 'View items moved successfully');

      // Re-fetch the slot contents to get the latest slotViewItemsVersion
      const { selectedSlot, selectedSlotGroupType, slotViewItemsPage } = binder;
      const { id: slotId } = selectedSlot || {};
      const { value: slotGroupTypeName } = selectedSlotGroupType || {};
      dispatch(getSlotViewItems(slotId, { type: slotGroupTypeName, page: slotViewItemsPage }));
    } catch (err) {
      dispatch(actions.bulkViewItemActionError(ACTION_MOVE, err));
    }
  };

export const saveVariant = (viewItem: IViewItem, key: number) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  if (!viewItem || !viewItem.id) {
    return null;
  }

  const {
    bannerManagement: {
      creative: { form },
    },
  } = getState();

  const variant: IVariant = { ...(form.variants ? form.variants[key] : {}) };

  dispatch(actions.saveVariantStart());

  let imageUrl = variant.imageUrl || null;
  if (variant.imageFile) {
    try {
      const res = await uploadImage(variant.imageFile);
      imageUrl = res.imageUrl;
    } catch (err) {
      displayError(err.error && err.error.msg);
      return dispatch(actions.saveVariantError(err));
    }
  }

  let alternateImageUrl = variant.alternateImageUrl || null;
  if (variant.alternateImageFile) {
    try {
      const res = await uploadImage(variant.alternateImageFile);
      alternateImageUrl = res.imageUrl;
    } catch (err) {
      displayError(err.error && err.error.msg);
      return dispatch(actions.saveVariantError(err));
    }
  }

  let geoData = null;
  if (variant.geoFile) {
    try {
      geoData = await uploadLocationFile(variant.geoFile);
    } catch (err) {
      displayError(err.error && err.error.msg);
      return dispatch(actions.saveVariantError(err));
    }
  }

  // parse locations
  const locationFilter = [];
  if (result('locations.includedCities', variant)) {
    variant.locations.includedCities.map((location: Record<string, any>) =>
      locationFilter.push({
        location: location.name,
        locationType: location.type,
        filterType: 'INCLUDE',
      }),
    );
  }

  if (result('locations.includedStates', variant)) {
    variant.locations.includedStates.map((location: Record<string, any>) =>
      locationFilter.push({
        location: location.name,
        locationType: location.type,
        filterType: 'INCLUDE',
      }),
    );
  }

  if (result('locations.excludedCities', variant)) {
    variant.locations.excludedCities.map((location: Record<string, any>) =>
      locationFilter.push({
        location: location.name,
        locationType: location.type,
        filterType: 'EXCLUDE',
      }),
    );
  }

  if (result('locations.excludedStates', variant)) {
    variant.locations.excludedStates.map((location: Record<string, any>) =>
      locationFilter.push({
        location: location.name,
        locationType: location.type,
        filterType: 'EXCLUDE',
      }),
    );
  }

  // parse engage categories
  const engageCategoriesKeys: Array<string> = variant.engageCategories ? Object.keys(variant.engageCategories) : [];

  const engageCategoryWeights = engageCategoriesKeys
    .filter(categoryKey => {
      const category = variant.engageCategories[categoryKey];
      return !isEmpty(category) && category.weight && !isEmpty(category.category) && category.category.id;
    })
    .map<{ categoryId: number; weight: number }>(categoryKey => ({
      categoryId: variant.engageCategories[categoryKey].category.id,
      weight: Number(variant.engageCategories[categoryKey].weight),
    }));

  // parse appVersions
  const appVersionKeys = variant.appVersions ? Object.keys(variant.appVersions) : [];

  try {
    const updatedVariant = {
      appVersionFilter: appVersionKeys.length ? appVersionKeys.map(appVersionKey => variant.appVersions[appVersionKey]) : null,
      categoryWeights2: engageCategoryWeights.length ? engageCategoryWeights : null,
      id: variant.id,
      image: imageUrl || variant.image,
      alternateImage: alternateImageUrl || variant.alternateImage,
      labelDetails: variant.labelDetails,
      landingPageType: variant.landingPageType,
      latLongId: geoData ? geoData.id : variant.latLongId,
      locationFilter: locationFilter.length ? locationFilter : null,
      metadata: variant.metadata,
      name: variant.name,
      personalizationMetadata: variant.personalizationMetadata,
      trackingOnly: !!variant.trackingOnly,
      [variant.itemId ? 'itemId' : 'url']: variant.itemId || variant.url,
    };

    if (variant.variantType) updatedVariant.variantType = variant.variantType;
    if (variant.widgetType) updatedVariant.widgetType = variant.widgetType;

    await updateVariant(viewItem.id, updatedVariant);

    displaySuccess('Variant Updated');
    dispatch(actions.saveVariantSuccess(updatedVariant));

    return updatedVariant;
  } catch (err) {
    displayError(err.error && err.error.msg);
    return dispatch(actions.saveVariantError(err));
  }
};

export const fetchViewItemLocation =
  (viewId: string, params: Record<string, any> = {}) =>
  async (dispatch: DispatchFn) => {
    if (!viewId) {
      return null;
    }

    if (!params.bannerId && !params.creativeId) {
      return null;
    }

    try {
      const { viewItems } = params.bannerId
        ? await fetchViewItemByBannerId(Number(viewId), params.bannerId)
        : await fetchViewItemByCreativeId(Number(viewId), params.creativeId);

      if (viewItems && viewItems.length) {
        dispatch(actions.fetchViewItemLocationSuccess(viewItems[0]));
        return viewItems[0];
      }

      displayError('No view items found');
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      displayError(err.error && err.error.msg);
      return null;
    }
  };

export const fetchViewItemsLocation =
  (viewId: string, params: Record<string, any> = {}) =>
  async (dispatch: DispatchFn) => {
    if (!viewId) {
      return null;
    }

    if (!params.bannerId && !params.creativeId) {
      return null;
    }

    try {
      const { viewItems } = params.bannerId
        ? await fetchViewItemByBannerId(Number(viewId), params.bannerId)
        : await fetchViewItemByCreativeId(Number(viewId), params.creativeId);

      if (viewItems && viewItems.length) {
        dispatch(actions.fetchViewItemsLocationSuccess(viewItems));
        return viewItems;
      }

      displayError('No view items found');
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      displayError(err.error && err.error.msg);
      return null;
    }
  };

export const togglePersonalization = (isPersonalized: boolean) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      binder: {
        view: { view },
      },
    },
  } = getState();

  if (!view || !view.id) {
    return null;
  }

  dispatch(actions.setIsPersonalizedSaving(true));
  dispatch(actions.setIsPersonalized(isPersonalized));

  try {
    await updateViewPersonalization(view.entityType, view.entityId, view.decoratorId, view.id, isPersonalized);
    // TODO: If BMS is successful, fetch from seller panel, because it may have failed there, and that value trumps BMS.
    displaySuccess('Personalization updated');
  } catch (err) {
    dispatch(actions.setIsPersonalized(!isPersonalized));
    const msg = result('error.msg', err);
    displayError(`Error updating personalization ${msg ? `: ${msg}` : ''}`);
  } finally {
    dispatch(actions.setIsPersonalizedSaving(false));
  }
};

export const updateWidgetConfig = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      binder: {
        view: { view },
      },
    },
  } = getState();

  if (!view || !view.id) {
    return null;
  }

  const { widgetConfig } = view;
  try {
    await updateView(view.id, { widgetConfig });
    return displaySuccess('Widget Config saved');
  } catch (err) {
    const msg = result('error.msg', err);
    console.error(`Error saving view widgetConfig: ${JSON.stringify(err)}`); // eslint-disable-line no-console
    return displayError(`Error saving widget config${msg && `: ${msg}`}`);
  }
};
