import React, { useEffect, useRef } from 'react';

import { Input, Menu, MenuItem, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover/Popover';
import { Search as SearchIcon, DoneAll as SelectionIcon, NotInterested as NotFoundIcon, Close as CloseIcon } from '@material-ui/icons';
import { isPlainObject } from 'ramda-adjunct';

import MultiValueTextField from 'app/components/MultiValueTextField';

const toMenuItem = (
  classes: FilterMultiSelectProps['classes'],
  selections: FilterMultiSelectProps['selections'],
  handleSelect: FilterMultiSelectProps['handleSelect'],
  option: FilterMultiSelectProps['options'][number],
) => {
  const [optionValue, optionLabel] = isPlainObject(option) ? [option.value, option.label] : [option];
  const isSelected = !!optionValue && !!selections && selections.includes(optionValue);
  const text = optionLabel || optionValue;

  return (
    <MenuItem
      key={optionValue}
      value={optionValue}
      selected={isSelected}
      className={isSelected ? classes.selectedItemStyle : classes.nonSelectedItemStyle}
      onClick={event => handleSelect(event, optionValue)}
    >
      {isSelected && (
        <ListItemIcon>
          <SelectionIcon />
        </ListItemIcon>
      )}

      <ListItemText inset disableTypography primary={text} />
    </MenuItem>
  );
};

interface FilterMultiSelectProps {
  id: string;
  options: {
    value?: string;
    label?: string;
  }[];
  label: string;
  selections?: string[];
  stringifier(...args: unknown[]): unknown;
  handleSelect(...args: unknown[]): unknown;
  handleOpen(...args: unknown[]): unknown;
  handleClose(...args: unknown[]): unknown;
  handleFilter(...args: unknown[]): unknown;
  isDropdownOpen: boolean;
  transformOrigin: PopoverOrigin;
  classes: Record<string, string>;
}

export const FilterMultiSelect = ({
  id,
  label,
  options,
  selections = [],
  stringifier,
  handleSelect,
  handleOpen,
  handleClose,
  handleFilter,
  isDropdownOpen,
  transformOrigin,
  classes,
}: FilterMultiSelectProps) => {
  const anchorRef = useRef<HTMLInputElement | null>(null);
  const filterRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (filterRef?.current) filterRef.current.focus();
  }, []);

  return (
    <>
      <MultiValueTextField
        {...{
          id,
          label,
          stringifier,
          handleOpen,
          anchorRef,
          classes: classes as {
            textField: string;
            tooltip: string;
          },
          values: selections,
          tooltipId: `chip-input-tooltip-${id}`,
        }}
      />

      <Menu
        key="dropdown"
        open={isDropdownOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorReference="anchorEl"
        transformOrigin={transformOrigin}
      >
        <div
          // className={classes.actions}
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            // marginRight: '0.8rem',
          }}
        >
          <SearchIcon
            style={{
              marginTop: '1rem',
              marginLeft: '0.8rem',
              marginRight: '0.3rem',
            }}
          />

          <Input
            margin="dense"
            type="search"
            placeholder="Search..."
            className={classes.textField}
            style={{
              minWidth: 200,
            }}
            onChange={handleFilter}
            ref={filterRef}
          />

          <IconButton aria-label="Close" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {options.length < 1 && (
          <ListItem disabled>
            <ListItemIcon>
              <NotFoundIcon />
            </ListItemIcon>
            <ListItemText primary="No item found." secondary="Clear search to see all." style={{ padding: 0 }} />
          </ListItem>
        )}

        {options.length > 0 && options.map(option => toMenuItem(classes, selections, handleSelect, option))}
      </Menu>
    </>
  );
};
