import { withStateHandlers, onlyUpdateForKeys, compose } from 'recompose';

import { isNilOrEmpty, filterBy } from 'app/helpers/RamdaHelpers/helpers';

import { FilterMenu } from './FilterMenu';

const addStateHandlers = withStateHandlers(
  ({ selected, options }) => ({
    selected,
    options,
  }),

  {
    handleFilter:
      (state, { nameOf, options: allOptions }) =>
      ({ target: { value } }) => ({
        ...state,
        options: isNilOrEmpty(value) ? allOptions : filterBy(value, allOptions, nameOf, nameOf),
      }),
  },
);

const enhance = compose(addStateHandlers, onlyUpdateForKeys(['id', 'options', 'selected']));

export default enhance(FilterMenu);
