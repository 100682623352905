import { IStackableGroup } from 'app/features/CashbackPromoManagement/types';

import * as types from './types';
import { IFetch, fetch } from '../../commonStates';

type State = IFetch & {
  stackableGroups: Array<IStackableGroup>;
};

const initialState = {
  ...fetch,
  stackableGroups: [],
};

export default (state: State = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case types.GET_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
        stackableGroups: [],
      };

    case types.GET_INIT:
      return {
        ...state,
        error: null,
        loading: true,
        stackableGroups: [],
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        stackableGroups: payload.stackableGroups,
      };

    default:
      return state;
  }
};
