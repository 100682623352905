import { compose, withStateHandlers } from 'recompose';

import { R } from 'app/helpers/RamdaHelpers/helpers';

import { SubFeatureRuleGroup } from './SubFeatureRuleGroup';

const updateStateWith = (data, { onUpdate }) => {
  onUpdate(data);
  return { data };
};

const addStateHandlers = withStateHandlers(({ data = [] }) => ({ data }), {
  handleChange:
    ({ data }, props) =>
    (change, index) =>
      updateStateWith(R.update(index, change, data), props),
});

const enhance = compose(addStateHandlers);
export default enhance(SubFeatureRuleGroup);
