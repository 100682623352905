import * as fetch from 'app/utilities/http';

import endpoints from './endpoints';

const uploadType = 'location';
const SAMPLE_CSV_DATA = [
  ['latitude', 'longitude', 'radius'],
  ['45.33', '-123.23', '1'],
  ['23.424076', '53.847818', '2'],
  ['33.93911', '67.709953', '3'],
  ['17.060816', '-61.796428', '4'],
  ['18.220554', '-63.068615', '5'],
];

const uploadLatLngCsv = acceptedFiles => {
  const headers = new Headers({ 'Content-Type': 'multipart/form-data' });
  const file = new FormData();
  file.append('file', acceptedFiles[0]);
  file.append('uploadType', uploadType);
  return fetch.fileUpload(endpoints.fileUpload, file, headers);
};

const generateDownloadableUrl = (url: string) => fetch.post(endpoints.getDownloadableUrl, { url });

const getCsvSampleUrl = () => {
  let csv = '';
  SAMPLE_CSV_DATA.forEach(row => {
    csv += row.join(',');
    csv += '\n';
  });
  const url = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  return url;
};

export { uploadLatLngCsv, generateDownloadableUrl, getCsvSampleUrl };
