import styled from 'styled-components';

import { sc } from 'app/styles';

export const TopBar = styled.header`
  background-color: hsl(0, 0%, 100%);
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid ${sc.sectionBorderColor};
`;
