import React from 'react';

import { Collapse, IconButton, TextField } from '@material-ui/core';
import { Delete as DeleteIcon, FileCopy as CloneIcon } from '@material-ui/icons';

import BreadcrumbButton from 'app/components/BreadcrumbButton';
import BreadcrumbMenu from 'app/components/BreadcrumbMenu';
import RuleSelect from 'app/components/RuleSelect';
import RuleValueEdit from 'app/components/RuleValueEdit';
import SubFeatureRuleGroup from 'app/components/SubFeatureRuleGroup';
import { addComponentPropsIfNot } from 'app/helpers/ComponentHelpers/helpers';
import { isNestedType, sortAsc } from 'app/helpers/FeatureMetadataHelpers/common';
import { FeatureLevelLabels, isSubFeature, selectedFeatureReader, selectedFeatureLens } from 'app/helpers/FeatureMetadataHelpers/helpers';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { isNeitherNilNorEmpty, isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

import Tooltip from '../Tooltip';

const newId = R.curry((index, prefix) => `${prefix}-${index}`);

const isOneAndOnlyChoice = (selected, options) => isNeitherNilNorEmpty(selected) && (options || []).length < 2;

const renderDeleteButton = (index, onDelete, classes) => {
  const deleteDisabled = R.isNil(onDelete);
  const deleteButtonText = `Delete Rule ${deleteDisabled ? '(disabled)' : ''}`;

  const deleteButton = (
    <Tooltip title={deleteButtonText}>
      <span>
        <IconButton
          className={classes.button}
          style={{
            margin: 0,
          }}
          aria-label={deleteButtonText}
          disabled={deleteDisabled}
          {...addComponentPropsIfNot(deleteDisabled, { onClick: onDelete })}
        >
          <DeleteIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

  return deleteButton;
};

const renderCloneButton = (index, onClone, classes) => {
  const cloningDisabled = R.isNil(onClone);
  const cloneButtonText = `Clone Rule ${cloningDisabled ? '(disabled)' : ''}`;

  const cloneButton = (
    <Tooltip title={cloneButtonText}>
      <span>
        <IconButton
          className={classes.button}
          style={{
            margin: 0,
          }}
          data-tip={cloneButtonText}
          aria-label={cloneButtonText}
          disabled={cloningDisabled}
          {...addComponentPropsIfNot(cloningDisabled, { onClick: onClone })}
        >
          <CloneIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

  return cloneButton;
};

const updateHandler = R.curry((handleChange, updater, oldState, isUserInput, change) =>
  handleChange(isUserInput, updater(change, oldState)),
);

const emptyPromise = Promise.resolve([]);

interface toRuleItemProps {
  label: string;
  index: number;
  temporalPrefix: string;
  updater(...args: unknown[]): unknown;
  validator(...args: unknown[]): unknown;
  stringifier(...args: unknown[]): unknown;
  options?: string[];
  selected?: string;
  dataType?: string;
  handleChange(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

const toRuleItem = (
  context: toRuleItemProps,
  {
    label,
    index,
    temporalPrefix,
    updater,
    validator,
    stringifier,
    options,
    pathDict,
    originDict,
    isCascadingMenuOpen,
    selected,
    dataType,
    handleChange,
    items: oldState,
    handleFeatureButtonClick,
    onFeatureSelection,
    paths = [],
    desc,
    alias,
    classes,
  },

  selectedFeature,
) => {
  const updateOnChange = updateHandler(handleChange, updater, oldState);
  const getId = newId(index);
  const componentWithContext = a => [{ selected }, a];

  // TODO: improve all these condition detections here - right now they are not very robust.
  // These are run till operator is chosen
  if (isNilOrEmpty(dataType)) {
    if (isSubFeature(label)) {
      const subFeatureGroupId = getId(`${label}-${selectedFeature}`);
      return componentWithContext(
        <SubFeatureRuleGroup
          {...{
            index,
            classes,
            key: subFeatureGroupId,
            id: subFeatureGroupId,
            parentFeature: selectedFeature,
            data: options,
            onUpdate: updateHandler(
              handleChange,
              (change, [mainFeature, subFeature]) => [
                mainFeature,
                {
                  ...subFeature,
                  options: change,
                },
              ],

              oldState,
              false,
            ),
          }}
        />,
      );
    }

    if (isNilOrEmpty(options)) {
      return componentWithContext(
        <BreadcrumbButton
          {...{
            paths,
            pathDict,
            originDict,
            isCascadingMenuOpen,
            alias,
            onFeatureSelection,
            key: 'feature-button',
            description: desc,
            handleClick: handleFeatureButtonClick,
          }}
        />,
      );
    }

    if (isOneAndOnlyChoice(selected, options)) {
      const stableId = getId(label);

      return componentWithContext(
        <TextField
          readOnly
          {...{
            label,
            context,
            margin: 'dense',
            className: classes.textField,
            key: stableId,
            id: stableId,
            value: selected,
            style: { maxWidth: 120 },
          }}
        />,
      );
    }
    const temporalId = getId(`${label}-${temporalPrefix}`);

    return componentWithContext(
      <RuleSelect
        {...{
          label,
          classes,
          options,
          context,
          key: temporalId,
          id: temporalId,
          onChange: updateOnChange(false),
          ...(isNilOrEmpty(selected) ? {} : { selected }),
        }}
      />,
    );
  }

  const stableId = getId(label);

  //this runs after operator is chosen
  return componentWithContext(
    <RuleValueEdit
      {...{
        promise: isNestedType(dataType) ? emptyPromise : R.propOr(emptyPromise, 'promise', options),
        onSuccess: values => ({
          options: sortAsc(values),
        }),

        onFailure: error => {
          // todo: should handle the case when ajax fails and we display too many error messages (for each row/sub-row)
          displayError(`Error retrieving possible values: ${error.message}`);
          R.propOr(
            () => {
              /* no-op */
            },
            'retry',
            options,
          )();

          return { options: undefined };
        },
        label,
        dataType,
        validator,
        stringifier,
        context,
        classes,
        key: stableId,
        id: stableId,
        value: selected,
        onChange: updateOnChange(true),
      }}
    />,
  );
};

const renderRowItems = (
  selectedFeature,
  index,
  items,
  pathDict,
  originDict,
  isCascadingMenuOpen,
  handleChange,
  handleFeatureButtonClick,
  onFeatureSelection,
  classes,
) => {
  const extra = {
    index,
    temporalPrefix: `${Date.now()}`,
    handleChange,
    handleFeatureButtonClick,
    onFeatureSelection,
    classes,
    items,
    pathDict,
    originDict,
    isCascadingMenuOpen,
  };

  const mapper = (context, props) =>
    toRuleItem(
      context,
      {
        ...props,
        ...extra,
      },

      selectedFeature,
    );

  return R.last(R.mapAccum(mapper, {}, items));
};

interface RuleRow2Props {
  index: number;
  items: object[];
  pathDict: Record<string, unknown[]>;
  originDict: Record<string, object>;
  initialOptions: string | {}[];
  inclusivePathsOfFeature(...args: unknown[]): unknown;
  optionsBySelections(...args: unknown[]): unknown;
  idOf(...args: unknown[]): unknown;
  nameOf(...args: unknown[]): unknown;
  descOf(...args: unknown[]): unknown;
  handleChange(...args: unknown[]): unknown;
  isCascadingMenuOpen: boolean;
  handleFeatureButtonClick(...args: unknown[]): unknown;
  onDelete?(...args: unknown[]): unknown;
  onClone(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const RuleRow2 = ({
  items,
  pathDict,
  originDict,
  initialOptions,
  inclusivePathsOfFeature,
  optionsBySelections,
  idOf,
  nameOf,
  descOf,
  handleChange,
  isCascadingMenuOpen,
  handleFeatureButtonClick,
  onDelete,
  onClone,
  classes,
  index,
}: RuleRow2Props) => {
  const onFeatureSelection = updateHandler(handleChange, R.view(selectedFeatureLens, items).updater, items, false);

  const handleFeatureSelection = R.curry((previouslySelected, selectedFeature) => {
    handleFeatureButtonClick();

    if (previouslySelected !== selectedFeature.value) {
      onFeatureSelection(selectedFeature);
    }
  });

  const selectedFeature = selectedFeatureReader(items);
  const selections = isNilOrEmpty(selectedFeature) ? [] : inclusivePathsOfFeature(selectedFeature);
  const numberOfLevels = 4;
  const numberOfLevelsV2 = 3;
  return [
    <div key={`rule-row-${index}`} className={classes.container}>
      {renderDeleteButton(index, onDelete, classes)}
      {!window.location.pathname.includes('/audience/') ? renderCloneButton(index, onClone, classes) : ``}

      {renderRowItems(
        selectedFeature,
        index,
        items,
        pathDict,
        originDict,
        isCascadingMenuOpen,
        handleChange,
        handleFeatureButtonClick,
        onFeatureSelection,
        classes,
      )}
    </div>,
    <Collapse
      in={isCascadingMenuOpen}
      key={`cascading-menu-${index}`}
      classes={{
        wrapperInner: classes.wrapperInner,
      }}
      collapsedHeight={isCascadingMenuOpen ? '260px' : undefined}
    >
      {isCascadingMenuOpen && (
        <BreadcrumbMenu
          labels={R.take(window.location.pathname === '/audience/new' ? numberOfLevelsV2 : numberOfLevels || FeatureLevelLabels)}
          selections={selections}
          initialOptions={initialOptions}
          optionsBySelections={optionsBySelections}
          idOf={idOf}
          nameOf={nameOf}
          descOf={descOf}
          classes={classes}
          onFeatureSelected={handleFeatureSelection(selectedFeature)}
        />
      )}
    </Collapse>,
  ];
};
