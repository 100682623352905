import { fetchStorefront as v1FetchStorefront } from 'app/api/bannerManagementV1/storefronts';
import { fetchViews as v1FetchViews } from 'app/api/bannerManagementV1/views';
import {
  createStorefront,
  deleteStorefront as apiDeleteStorefront,
  fetchStorefront as v2FetchStorefront,
  updateStorefront,
} from 'app/api/bannerManagementV2/storefronts';
import { deleteView as apiDeleteView, enableEngageForView, fetchViews as v2FetchViews } from 'app/api/bannerManagementV2/views';
import bmsVersions from 'app/api/bmsCommon/bmsVersions';
import { getCurrentUser } from 'app/api/users';
import types from 'app/ducks/user/types';
import { storefrontTypes } from 'app/features/BannerManagement/Storefronts/constants';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { IStorefront, IView } from 'app/types/BannerManagement';
import { IQueryParams } from 'app/types/IQueryParams';
import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';

const errorMsgMissingEntity = (entityType?: string, entityId?: number) => `entityType=${String(entityType)}; entityId=${String(entityId)}`;

// === STOREFRONTS ===

// TODO: Ensure that the storefront has no views before deleting
export const deleteStorefront = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  dispatch(actions.saveStorefrontStart());

  const {
    bannerManagement: {
      storefront: { storefront },
    },
  } = getState();
  try {
    const res = await apiDeleteStorefront(storefront.id);
    displaySuccess(res.msg || `Deleted storefront ${storefront.id}`);
    return dispatch(actions.saveStorefrontSuccess());
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return dispatch(actions.saveStorefrontFailed(err));
  }
};

export const getStorefront =
  (bmsVersion: string) => (storefrontId: number, entityType?: string, entityId?: number) => async (dispatch: DispatchFn) => {
    const useBmsV2 = bmsVersions.useV2(bmsVersion);

    dispatch(actions.getStorefrontStart());
    try {
      let storefront: IStorefront | null | undefined;
      if (useBmsV2) {
        storefront = await v2FetchStorefront(storefrontId, entityType);
      } else {
        if (!entityType || !entityId) {
          return dispatch(actions.getStorefrontFailed(`Error while retrieving storefront. ${errorMsgMissingEntity(entityType, entityId)}`));
        }
        storefront = await v1FetchStorefront(entityType, entityId, storefrontId);
      }

      if (storefront) {
        return dispatch(actions.getStorefrontSuccess(storefront));
      }
      return dispatch(actions.getStorefrontFailed(`No storefront with ID ${storefrontId}`));
    } catch (err) {
      return dispatch(actions.getStorefrontFailed(err));
    }
  };

export const saveStorefront = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  dispatch(actions.saveStorefrontStart());
  const {
    bannerManagement: {
      storefront: { storefront },
    },
  } = getState();
  const isUpdate = !!storefront.id;

  try {
    const res = isUpdate ? await updateStorefront(storefront.id, storefront) : await createStorefront(storefront);
    displaySuccess(res.msg || `Saved storefront ${storefront.id}`);

    if (!isUpdate) {
      const data = await getCurrentUser();
      dispatch({ type: types.INIT_USER_STATE, payload: data });
    }

    return dispatch(actions.saveStorefrontSuccess());
  } catch (err) {
    return dispatch(actions.saveStorefrontFailed(err));
  }
};

// === VIEWS ===

export const deleteView = (viewId: number) => async (dispatch: DispatchFn) => {
  dispatch(actions.deleteViewStart(viewId));
  try {
    const res = await apiDeleteView(viewId);
    displaySuccess(res.msg || `Deleted view ${viewId}`);
    return dispatch(actions.deleteViewSuccess(viewId));
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return dispatch(actions.deleteViewFailed(err));
  }
};

export const getViews = (bmsVersion: string) => (storefrontId: number, entityType?: string, entityId?: number, cmaQuery?: IQueryParams) => {
  const useBmsV2 = bmsVersions.useV2(bmsVersion);

  return async (dispatch: DispatchFn) => {
    dispatch(actions.getViewsStart());

    try {
      let views: Array<IView>;
      if (useBmsV2) {
        views = await v2FetchViews(storefrontId, entityType, entityId, cmaQuery);
      } else {
        if (!entityType || !entityId) {
          return dispatch(actions.getStorefrontFailed(`Error while retrieving storefront. ${errorMsgMissingEntity(entityType, entityId)}`));
        }
        views = await v1FetchViews(storefrontId, entityType, entityId, cmaQuery);
      }

      return dispatch(actions.getViewsSuccess(views));
    } catch (err) {
      return dispatch(actions.getViewsFailed(err));
    }
  };
};

export const enableEngage =
  (storefrontId: number, entityId: number, viewId: number) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    const {
      bannerManagement: { storefronts },
    } = getState();
    const entityType = storefronts.storefrontType === storefrontTypes.merchant ? storefrontTypes.merchant : storefrontTypes.category;

    if (storefrontId && entityId && viewId) {
      dispatch(actions.enableEngageStart(viewId));
      try {
        const { msg } = await enableEngageForView(entityType, entityId, storefrontId, viewId);
        dispatch(actions.enableEngageSuccess(viewId));
        return displaySuccess(msg);
      } catch (err) {
        dispatch(actions.enableEngageFailed(viewId));
        return displayError(err.error && err.error.msg);
      }
    }
  };
