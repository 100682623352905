import { withStyles } from '@material-ui/core/styles';
import { compose, withProps, withStateHandlers } from 'recompose';

import { styles } from './styles';
import { TagsEdit } from './TagsEdit';

const doSortTags = withProps(({ allTagNames, sortTags }) => ({
  allTagNames: sortTags !== false ? [...allTagNames].sort() : allTagNames,
}));

const handleState = withStateHandlers(({ tagText = '' }) => ({ tagText }), {
  handleChangeTagText: () => ev => ({
    tagText: ev.tagText,
  }),

  handleAddTag:
    ({ tagText }, props) =>
    () => {
      if (props.handleAddTag) {
        props.handleAddTag(tagText);
      }
      return { tagText: '' };
    },
  handleRemoveTag: (state, props) => tagId => {
    if (props.handleRemoveTag) {
      props.handleRemoveTag(tagId);
    }
  },
});

const enhance = compose(handleState, doSortTags, withStyles(styles, { withTheme: true }));

export default enhance(TagsEdit);
