import React from 'react';

import { Table as MaterialTable, TableBody, TableRow, TableCell } from '@material-ui/core';

import { isNeitherNilNorEmpty, memoize, R } from 'app/helpers/RamdaHelpers/helpers';
import Paper from 'app/midgarComponents/Paper';

import { SortableTableHeader } from './SortableTableHeader';
import { TableFooterWithPagination } from './TableFooterWithPagination';
import { TableTitleWithSearchBar } from './TableTitleWithSearchBar';
import ExpandableTableRow from '../ExpandableTableRow';

const noDataTable = memoize(() => (
  <TableBody>
    <TableRow>
      <TableCell>
        <i>* No matching record found. Clear search field to reset.</i>
      </TableCell>
    </TableRow>
  </TableBody>
));

const fillUpSpace = (numberOfRows, rowsPerPage, pageIndex, numberOfColumns) => {
  const rowsToFill = rowsPerPage - Math.min(rowsPerPage, numberOfRows - pageIndex * rowsPerPage);

  return (
    rowsToFill > 0 && (
      <TableRow
        style={{
          height: 49 * rowsToFill,
        }}
      >
        <TableCell colSpan={numberOfColumns} />
      </TableRow>
    )
  );
};

interface TableProps {
  title?: string;
  columns: object[];
  // sortOrder: PropTypes.string.isRequired,
  sortOrder?: string;
  // sortColumn: PropTypes.string.isRequired,
  sortColumn?: string;
  handleSort?(...args: unknown[]): unknown;
  rows: object[];
  // rowCount: PropTypes.number.isRequired,
  rowCount?: number;
  isFiltered?: boolean;
  // rowsPerPage: PropTypes.number.isRequired,
  rowsPerPage?: number;
  // rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  rowsPerPageOptions?: number[];
  // pageIndex: PropTypes.number.isRequired,
  pageIndex?: number;
  handleChangePage?(...args: unknown[]): unknown;
  handleChangeRowsPerPage?(...args: unknown[]): unknown;
  handleFilter?(...args: unknown[]): unknown;
  handleRowClick?(...args: unknown[]): unknown;
  customPaginationActions?(...args: unknown[]): unknown;
  handleRowExpansion?(...args: unknown[]): unknown;
  keepEmptySpace?: boolean;
  classes: Record<string, string>;
  onRowMount?(...args: unknown[]): unknown;
}

export const Table = ({
  title,
  columns,
  sortOrder,
  sortColumn,
  handleSort,
  rows,
  rowCount,
  isFiltered = false,
  rowsPerPage,
  rowsPerPageOptions,
  pageIndex,
  handleChangePage,
  handleChangeRowsPerPage,
  handleFilter,
  handleRowClick,
  customPaginationActions,
  handleRowExpansion,
  keepEmptySpace = false,
  onRowMount,
  classes,
}: TableProps) => {
  const numberOfRows = rows.length;
  const numberOfColumns = columns.length;

  const canExpandRow = isNeitherNilNorEmpty(handleRowExpansion);
  const canPaginate = R.all(isNeitherNilNorEmpty, [handleChangePage, handleChangeRowsPerPage]);
  const showTitleSection = isNeitherNilNorEmpty(title);

  return (
    <Paper className={classes.tableContainer}>
      {showTitleSection && <TableTitleWithSearchBar title={title} isFiltered={isFiltered} handleFilter={handleFilter} classes={classes} />}
      <section className={classes.tableWrapper}>
        <MaterialTable className={classes.table}>
          <SortableTableHeader
            columns={columns}
            sortOrder={sortOrder}
            sortColumn={sortColumn}
            handleSort={handleSort}
            canExpandRow={canExpandRow}
            classes={classes}
            // style={}
          />
          {R.isEmpty(rows) ? (
            noDataTable()
          ) : (
            <TableBody>
              {R.map(
                row => (
                  <ExpandableTableRow
                    canExpandRow={canExpandRow}
                    key={row.id}
                    id={row.id}
                    row={row}
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handleRowExpansion={handleRowExpansion}
                    onRowMount={onRowMount}
                    classes={classes}
                  />
                ),

                rows,
              )}

              {keepEmptySpace && fillUpSpace(numberOfRows, rowsPerPage, pageIndex, numberOfColumns)}
            </TableBody>
          )}

          {canPaginate && (
            <TableFooterWithPagination
              numberOfRows={rowCount}
              pageIndex={pageIndex}
              numberOfColumns={numberOfColumns}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              customPaginationActions={customPaginationActions}
              canExpandRow={canExpandRow}
            />
          )}
        </MaterialTable>
      </section>
    </Paper>
  );
};
