import { triggerTypes } from 'app/utilities/constants';
import { push2MediumId } from 'configs/channels/mediumIds';

const mapVariables = (variables: Array<{ name: string; defaultValue: string }>): { [key: string]: string } => {
  if (!Array.isArray(variables)) return {};

  const result: Record<string, string> = {};

  variables.forEach(variable => {
    if (variable.name && variable.defaultValue) {
      result[variable.name] = variable.defaultValue;
    }
  });

  return result;
};

const mapTemplatePayload = ({ selectedEvent, templateName, template, schema, variables, description, tags, user }) => {
  const eventName = selectedEvent && selectedEvent.eventName;
  let eventVariables = {};
  let fetureVariables = {};

  if (Array.isArray(variables)) {
    if (eventName) {
      eventVariables = { [eventName]: mapVariables(variables.filter(v => v.source === 'event')) };
    }

    fetureVariables = mapVariables(variables.filter(v => v.source !== 'event'));
  }

  return {
    name: templateName || '',
    schemaId: (schema && schema.schemaId) || '',
    template: template || '',
    description,
    tags: Array.isArray(tags) ? tags.map(tag => tag.name) : [],
    defaults: {
      event: eventVariables,
      features: fetureVariables,
    },

    createdBy: user?.email,
  };
};

const mapTemplateString = template => {
  if (!template) return '';

  if (typeof template === 'object') {
    return Object.entries(template)
      .reduce((acc, [key, value]) => `${acc} [${key}]:${value}`, '')
      .trim();
  }

  return template;
};

const mapEventName = values => {
  if (!values?.event) return '';

  return Object.keys(values.event)[0] || '';
};

const mapVariableDefaultValues = values => {
  if (!values) return { event: {}, features: {} };

  const eventName = mapEventName(values);

  return {
    event: values.event?.[eventName] || {},
    features: values.features || {},
  };
};

const mapTemplateResponse = response => {
  const data = (response && response.data) || {};

  return {
    id: data.templateId || '',
    name: data.namespace || data.name || '',
    template: mapTemplateString(data.template),
    defaults: mapVariableDefaultValues(data.defaults),
    eventName: mapEventName(data.defaults),
    schemaId: data.schemaId || '',
    tags: Array.isArray(data.tags) ? data.tags.map(tag => ({ id: tag, name: tag })) : [],
    description: data.description || '',
  };
};

const mapPush2CreativeToTemplate = creative => {
  const content = creative?.payload?.notification?.content || {};
  const deeplink = creative?.payload?.notification?.interactive?.click || {};
  const schemaId = content.schemaId || '';

  const template: Record<string, string> = {};

  Object.keys(content).forEach(contentKey => {
    if (contentKey !== 'schemaId') template[contentKey] = content[contentKey];
  });

  template['deeplink'] = deeplink.value;

  return {
    template,
    schemaId,
  };
};

const channelConfigs = {
  [push2MediumId]: {
    name: 'push',
    creative: 'push2Creative',
    creativeMapper: mapPush2CreativeToTemplate,
  },
};

const mapEvaluateTemplatePayload = state => {
  const {
    campaigns: { campaign },
    features,
    templateManagement: { currentTemplate },
  } = state;

  const {
    general: { criteria, variables, mediumId, triggerType },
  } = campaign;

  const channel = channelConfigs[mediumId] || {};
  const creative = campaign[channel.creative] || {};
  const creativeMapper = channel.creativeMapper;

  const triggerBasedEventName = triggerType === triggerTypes.eventsBased && criteria?.eventName;
  const schemaId = features.schemaId || (creativeMapper ? creativeMapper(creative).schemaId : '');

  const template =
    Object.keys(currentTemplate.template).length !== 0 ? currentTemplate.template : creativeMapper ? creativeMapper(creative).template : {};

  return {
    template,
    context: {
      event: triggerBasedEventName ? { [triggerBasedEventName]: mapVariables(variables?.events) } : {},
      features: mapVariables(variables?.features),
    },

    schemaId,
  };
};

const formatTemplateDescription = desc => {
  if (typeof desc !== 'string') return '';

  return desc.length > 100 ? desc.substring(0, 100) + '...' : desc;
};

const mapTemplatesListViewPayload = response => {
  const data = Array.isArray(response?.data) ? response.data : [];

  return data.map(({ templateId, namespace, tags, name, description, createdAt, createdBy }) => ({
    id: templateId || '',
    name: namespace || name || '',
    tags: tags || [],
    description: formatTemplateDescription(description),
    createdAt,
    createdBy: createdBy || '',
  }));
};

const mapTemplateSelection = (res, eventName = '') => {
  const data = res?.data;
  if (!Array.isArray(data)) {
    return [];
  }
  return data
    .filter(d => typeof d.template === 'string')
    .map(({ templateId, schemaId, template, namespace, defaults, name }) => ({
      name: namespace || name || '',
      id: templateId,
      template: mapTemplateString(template),
      schemaId,
      eventName: mapEventName(defaults),
      defaults: mapVariableDefaultValuesForSelection(defaults),
    }))
    .filter(d => d.eventName === eventName);
};
const mapVariableDefaultValuesForSelection = values => {
  if (!values || typeof values.event !== 'object') return { event: [], features: [] };
  const currEvent = values.event[mapEventName(values)];
  const event = (currEvent && Object.keys(currEvent).map(key => ({ [key]: currEvent[key] }))) || [];
  const features = Object.keys(values.features).map(key => ({ [key]: values.features[key] })) || [];
  return {
    event,
    features,
  };
};
export {
  mapVariables,
  mapTemplateResponse,
  mapTemplatePayload,
  mapEvaluateTemplatePayload,
  mapTemplateSelection,
  mapTemplatesListViewPayload,
};
