import React from 'react';

import { reduce, filter } from 'ramda';

const filterInputs = filter(i => i.required);

const setValidations = reduce((acc, i) => ({ ...acc, [i.name]: i.validity.valid }), {});

const formValidity = form => {
  const filteredInputs = filterInputs(form.elements);
  const validations = setValidations(filteredInputs);
  const valid = form.checkValidity();
  return {
    valid,
    validations,
  };
};

interface FormProps {
  children(...args: unknown[]): unknown;
}

export class Form extends React.PureComponent<FormProps> {
  state = {
    valid: false,
    validations: {},
  };

  setForm = form => {
    this.form = form;
    this.setState(state => ({ ...state, ...formValidity(this.form) }));
  };

  checkValidity = () => {
    this.setState(state => ({ ...state, ...formValidity(this.form) }));
  };

  render() {
    const { children } = this.props;

    return (
      <form ref={this.setForm} onChange={this.checkValidity}>
        {children({
          ...this.state,
        })}
      </form>
    );
  }
}
