import React from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { updateVariantsField } from 'app/ducks/bannerManagement/creative/actions';
import { Spinner as _Spinner } from 'app/midgarComponents';
import Toggle from 'app/midgarComponents/Toggle/BaseToggle';
import { sc } from 'app/styles';
import { IVariant, IViewItem } from 'app/types/BannerManagement';
import { Action } from 'app/types/state';
import { formatTimestamp } from 'app/utilities/date';

type Props = {
  readOnly?: boolean;
  saveVariantStatus?: (viewItem: IViewItem, variant: IVariant, status: boolean) => IViewItem | null | undefined; // TODO: Remove need for return value IViewItem?
  saveVariantStatusLoading?: Array<number>;
  timezone: string;
  updateVariantsField: (index: number, field: string, value: any) => Action;
  variant: Record<string, any>;
  variantKey: string;
  viewItem?: Record<string, any>;
};

class VariantInfoFields extends React.PureComponent<Props> {
  onChangeVariantState = async (status: boolean) => {
    const { saveVariantStatus, updateVariantsField, variant, variantKey, viewItem } = this.props;
    const prevStatus = variant.status;

    updateVariantsField(Number(variantKey), 'status', status ? 1 : 0);

    if (saveVariantStatus) {
      const resViewItem = await saveVariantStatus(viewItem || {}, variant, status);
      if (!resViewItem) {
        // The update failed
        updateVariantsField(Number(variantKey), 'status', prevStatus);
      }
    }
  };

  render() {
    const { readOnly, saveVariantStatus, saveVariantStatusLoading, timezone, variant } = this.props;

    const updatingStatus = !!saveVariantStatusLoading?.includes(variant?.id);

    return (
      <Container>
        {typeof saveVariantStatus === 'function' && (
          <StatusContainer>
            <StatusToggle active={variant.status} disabled={readOnly} onChange={this.onChangeVariantState} />

            {updatingStatus && <Spinner />}
          </StatusContainer>
        )}

        {variant && variant.id ? (
          <VariantInfo>
            <FieldGroup>
              <Label>Variant ID:</Label>
              <Field>{variant.id}</Field>
            </FieldGroup>

            {variant.catalogBannerId && (
              <FieldGroup>
                <Label>SF Banner ID:</Label>
                <Field>{variant.catalogBannerId}</Field>
              </FieldGroup>
            )}

            <FieldGroup>
              <Label>Created:</Label>
              <Field>{formatTimestamp(variant.createdAt, timezone, 'DD MMM YYYY')}</Field>
            </FieldGroup>

            <FieldGroup>
              <Label>Creator:</Label>
              <Field>{variant.createdBy}</Field>
            </FieldGroup>
          </VariantInfo>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      binder: { saveVariantStatusLoading },
      creative: { form },
    },

    user,
  },

  { variantKey },
) => ({
  saveVariantStatusLoading,
  timezone: user.timezone,
  variant: form.variants[variantKey] || {},
});

const mapDispatchToProps = { updateVariantsField };

export default connect(mapStateToProps, mapDispatchToProps)(VariantInfoFields);

const Container = styled.div`
  width: 100%;
`;

const FieldGroup = styled.div`
  margin-bottom: ${sc.gutter};
`;

const Field = styled.div`
  color: ${sc.subHeadingColor};
`;

const Label = styled(Field)`
  font-weight: 600;
  margin-bottom: ${sc.gutterSmallest};
`;

const Spinner = styled(_Spinner)`
  margin-bottom: ${sc.gutterSmallest};
  margin-left: ${sc.gutterSmall};
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StatusToggle = styled(Toggle)`
  width: 70px;
  margin-top: ${sc.gutterSmall};
`;

const VariantInfo = styled.div`
  width: 100%;
  margin-top: ${sc.gutterSmall};
  font-size: ${sc.fontSizeSmall};
`;
