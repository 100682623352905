import { DispatchFn, GetStateFn, Action } from 'app/types/state';

import * as actions from './actions';
import * as campaignActions from '../actions';

export const updateApiField =
  (field: {}) =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.updateApiField(field));

export const updateApiType =
  (apiType: {}) =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.updateApiType(apiType));

export const updateFacebookValues =
  (facebookValues: Array<string>) =>
  (dispatch: DispatchFn, getState: GetStateFn): Action => {
    const {
      features: {
        data: { allNames },
      },
    } = getState();

    if (Array.isArray(allNames) && allNames.length) {
      const formattedVariablesCollection = [];

      facebookValues.forEach(facebookValue => {
        const foundFeaturesVariable = allNames.find(featuresVariable => featuresVariable.name === facebookValue);

        if (foundFeaturesVariable) {
          formattedVariablesCollection.push({
            id: -foundFeaturesVariable.id,
            name: foundFeaturesVariable.name,
            defaultValue: foundFeaturesVariable.defaultValue || '',
            source: 'features',
            dataType: foundFeaturesVariable.data_type,
          });
        }
      });

      dispatch(campaignActions.updateFacebookValues(formattedVariablesCollection));
    }

    return dispatch(actions.updateFacebookValues(facebookValues));
  };
