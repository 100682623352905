import React from 'react';

import { branch, renderComponent, renderNothing } from 'recompose';

import EditWithDropdown from 'app/components/EditWithDropdown';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import FilterSelect from 'app/components/FilterSelect';
import ReadOnlyInputWithChipInputDialog from 'app/components/ReadOnlyInputWithChipInputDialog';
import ReadOnlyInputWithMultiSelectDialog from 'app/components/ReadOnlyInputWithMultiSelectDialog';
import ReadOnlyInputWithSingleSelectDialog from 'app/components/ReadOnlyInputWithSingleSelectDialog';
import RuleDateTimePicker from 'app/components/RuleDateTimePicker';
import RuleTextEdit from 'app/components/RuleTextEdit';
import {
  internalDataTypes,
  isNestedType,
  sortedPluralizedTimeUnits,
  specialOperators,
  timeWindowComponent,
} from 'app/helpers/FeatureMetadataHelpers/common';
import { arrayTypes } from 'app/helpers/FeatureMetadataHelpers/helpers';
import { fNot, isNeitherNilNorEmpty, isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

const {
  dateType,
  datetimeType,
  datetimeTypeV2,
  doubleType,
  numberType,
  stringType,
  booleanType,
  timeWindowType,
  timePeriodV2Type,
  numericRangeV2Type,
  bankCodeStringType,
} = internalDataTypes;

const { existsOperator, existsOperatorV2 } = specialOperators;

// helpers
const shortOptionListThreshold = 10;
const withMany = list => list.length > shortOptionListThreshold;
const withFew = fNot(withMany);

const Branch = R.curryN(3, branch);

const ifIsNestedType = Branch(({ dataType }) => isNestedType(dataType));

const testDataTypesAndOptions = (allowedDataTypes, optionTest) =>
  Branch(({ dataType, options }) => allowedDataTypes.includes(dataType) && R.both(isNeitherNilNorEmpty, optionTest)(options));

const testDataTypesOnly = allowedDataTypes =>
  Branch(({ dataType, options }) => allowedDataTypes.includes(dataType) && isNilOrEmpty(options));

const ifIsStringOrNumber = testDataTypesOnly([stringType, numberType, doubleType, bankCodeStringType]);
const ifIsBoolean = testDataTypesOnly([booleanType]);
const ifIsDate = testDataTypesOnly([dateType]);
const ifIsDateTime = testDataTypesOnly([datetimeType, datetimeTypeV2]);
const ifIsIntervalDateTime = testDataTypesOnly([timePeriodV2Type]);
const ifIsMultipleNumber = testDataTypesOnly([numericRangeV2Type]);
const ifIsTimeWindow = testDataTypesOnly([timeWindowType]);
const ifIsArray = testDataTypesOnly(arrayTypes);
const ifIsSingleSelectable = testDataTypesAndOptions([stringType], withFew);
const ifIsMultipleSelectable = testDataTypesAndOptions(arrayTypes, withFew);
const ifIsSingleSelectableWithManyOptions = testDataTypesAndOptions([stringType], withMany);
const ifIsMultipleSelectableWithManyOptions = testDataTypesAndOptions(arrayTypes, withMany);

// components
const textEdit = renderComponent(props => <RuleTextEdit {...props} />);

const temporalPickerFactory = ({ withTime = false }) =>
  renderComponent(({ id, label, value, onChange, classes }) => (
    <RuleDateTimePicker id={id} label={label} classes={classes} value={value} onChange={onChange} withTimePicker={withTime} />
  ));

const intervalTimePicker = renderComponent(
  ({ id, label, value = { startOfPeriod: undefined, endOfPeriod: undefined }, onChange, classes }) => {
    const handleChange = (type, e) => {
      value[type] = e;
      onChange(value);
    };
    return (
      <div style={{ 'flex-basis': 'min-content' }}>
        <RuleDateTimePicker
          id={id}
          label={'Starting Time'}
          classes={classes}
          value={value.startOfPeriod || undefined}
          onChange={e => handleChange('startOfPeriod', e)}
          withTimePicker={true}
        />

        <RuleDateTimePicker
          id={id}
          label={'Ending Time'}
          classes={classes}
          value={value.endOfPeriod || undefined}
          onChange={e => handleChange('endOfPeriod', e)}
          withTimePicker={true}
        />
      </div>
    );
  },
);

const multipleTextEdit = renderComponent(({ id, value = { startOfRange: undefined, endOfRange: undefined }, onChange, classes }) => {
  const handleChange = (type, e) => {
    value[type] = e;
    onChange(value);
  };
  return (
    <div style={{ display: 'grid', 'text-wrap': 'nowrap' }}>
      <RuleTextEdit
        id={id}
        label={'Start of Range'}
        classes={classes}
        value={value.startOfRange || undefined}
        onChange={e => handleChange('startOfRange', e)}
        withTimePicker={true}
      />

      <RuleTextEdit
        id={id}
        label={'End of Range'}
        classes={classes}
        value={value.endOfRange || undefined}
        onChange={e => handleChange('endOfRange', e)}
        withTimePicker={true}
      />
    </div>
  );
});

const datePicker = temporalPickerFactory({ withTime: false });
const dateTimePicker = temporalPickerFactory({ withTime: true });

const isInvalidTimeWindowValue = R.gte(0);
const timeWindowEdit = renderComponent(({ id, label, value, onChange, validator, classes }) => {
  const handleChange = ({ inputValue, optionValue }) => {
    const change = isNilOrEmpty(inputValue) ? '' : `${inputValue}${optionValue}`;
    onChange(change);
  };

  const { num: inputValue, unit: optionValue } = timeWindowComponent(value);

  return (
    <EditWithDropdown
      id={id}
      label={label}
      validator={validator}
      optionValue={optionValue}
      classes={classes}
      dataType="number"
      onChange={handleChange}
      options={sortedPluralizedTimeUnits()}
      inputValue={isInvalidTimeWindowValue(inputValue) ? '' : `${inputValue}`}
    />
  );
});

const singleSelect = renderComponent(({ id, label, classes, options, onChange, value = '' }) => (
  <FilterSelect id={id} label={label} classes={classes} options={options} onChange={onChange} value={value} />
));

const multiSelect = renderComponent(({ id, label, classes, options, stringifier, onChange, value }) => (
  <FilterMultiSelect
    id={id}
    label={label}
    classes={classes}
    options={options}
    stringifier={stringifier}
    onChange={onChange}
    value={value || []}
  />
));

const singleSelectWithDialog = renderComponent(({ id, label, classes, options, onChange, value }) => (
  <ReadOnlyInputWithSingleSelectDialog
    id={id}
    label={label}
    classes={classes}
    onChange={onChange}
    value={value}
    options={options}
    groupName={id}
    dialogTitle={`Select from ${options.length} choices:`}
  />
));

const singleSelectWithDialogV2 = renderComponent(({ id, label, classes, options, onChange, value }) => (
  <ReadOnlyInputWithSingleSelectDialog
    id={id}
    label={label}
    classes={classes}
    onChange={onChange}
    value={value}
    options={[true, false]}
    groupName={id}
    dialogTitle={`Select from ${options.length} choices:`}
  />
));

const multiSelectWithDialog = renderComponent(({ id, label, classes, options, stringifier, onChange, value }) => (
  <ReadOnlyInputWithMultiSelectDialog
    id={id}
    label={label}
    classes={classes}
    onChange={onChange}
    stringifier={stringifier}
    options={options}
    selection={value}
    dialogTitle={`Select from ${options.length} choices:`}
  />
));

const chipInputWithDialog = renderComponent(({ id, label, classes, stringifier, onChange, value }) => (
  <ReadOnlyInputWithChipInputDialog
    id={id}
    label={label}
    classes={classes}
    onChange={onChange}
    stringifier={stringifier}
    values={value}
    dialogTitle="Type and press [ENTER] to add"
  />
));

const testOperator = opPredicate => Branch(({ context: { selected: operator } = {} }) => opPredicate(operator));
const ifIsExistsOperator = testOperator(R.equals(existsOperator));
const ifIsExistsOperatorSmall = testOperator(R.equals(existsOperatorV2));

const ifShouldIgnore = R.compose(...R.map(R.applyTo(renderNothing), [ifIsNestedType, ifIsExistsOperator, ifIsExistsOperatorSmall]));
const defaultComponent = textEdit;

const RuleValueEdit = R.compose(
  ifShouldIgnore,
  ifIsStringOrNumber(textEdit),
  ifIsBoolean(singleSelectWithDialogV2),
  ifIsDate(datePicker),
  ifIsDateTime(dateTimePicker),
  ifIsIntervalDateTime(intervalTimePicker),
  ifIsMultipleNumber(multipleTextEdit),
  ifIsTimeWindow(timeWindowEdit),
  ifIsArray(chipInputWithDialog),
  ifIsSingleSelectable(singleSelect),
  ifIsMultipleSelectable(multiSelect),
  ifIsSingleSelectableWithManyOptions(singleSelectWithDialog),
  ifIsMultipleSelectableWithManyOptions(multiSelectWithDialog),
)(defaultComponent);

export default RuleValueEdit(<></>);
