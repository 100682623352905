import { fetchStorefronts as v1FetchStorefronts } from 'app/api/bannerManagementV1/storefronts';
import { fetchStorefronts as v2FetchStorefronts } from 'app/api/bannerManagementV2/storefronts';
import bmsVersions from 'app/api/bmsCommon/bmsVersions';
import { IStorefront } from 'app/types/BannerManagement';
import { IQueryParams } from 'app/types/IQueryParams';
import { DispatchFn, GetStateFn } from 'app/types/state';

import { CMAPagingParams, calculatePagingResults, mapPagingProps } from '../utils';
import * as actions from './actions';

export const getStorefronts =
  (bmsVersion: string) => (cmaQuery: IQueryParams | CMAPagingParams) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    const useBmsV2 = bmsVersions.useV2(bmsVersion);

    dispatch(actions.getStorefrontsStart());
    try {
      const query: any = mapPagingProps(cmaQuery);

      let storefronts: Array<IStorefront>;
      if (useBmsV2) {
        storefronts = await v2FetchStorefronts(query);
      } else {
        const { entityAssociatedWith, entityType, ...rest } = query;
        const queryV1 = {
          ...rest,
          entity_associated_with: entityAssociatedWith, // Query param name is different in V1 than it is in V2
        };
        storefronts = await v1FetchStorefronts(entityType, queryV1);
      }

      const paging = calculatePagingResults(storefronts, query);
      return dispatch(actions.getStorefrontsSuccess(storefronts, paging));
    } catch (err) {
      const errorStr = err.error && err.error.msg;
      return dispatch(actions.getStorefrontsFailed(errorStr));
    }
  };
