import { UserState } from 'app/types/typescript/State';

import { PAYPAY_CONSUMER } from '../apps/tenants/PAYPAY_CONSUMER';

const SELECTED_TENANT_KEY = 'map.country';

export const getSelectedTenant = () => localStorage.getItem(SELECTED_TENANT_KEY) || PAYPAY_CONSUMER;

export const setSelectedTenant = (tenant: string) => localStorage.setItem(SELECTED_TENANT_KEY, tenant);

/**
 * Return the tenants permitted for the given user.
 *
 * @param {UserState} user
 * @deprecated Remove once env -> tenant is completed
 */
export const permittedTenants = (user: UserState) => {
  if (!user.permittedTenants && user.env) {
    console.warn('Warning: Using legacy property user.env instead of user.permittedTenants');
  }
  return user.permittedTenants || user.env;
};
