import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { IPaging } from 'app/hocs/withReporting';

import * as types from './types';

export const getAll = () => ({
  type: types.GET_ALL,
});

export const getAllSuccess = (bannerCreatives: Array<IApiBannerCreative>, paging: IPaging) => ({
  type: types.GET_ALL_SUCCESS,
  payload: { bannerCreatives, paging },
});

export const getAllFailed = (error: { error: string }) => ({
  type: types.GET_ALL_FAILED,
  payload: { error: error.error },
});
