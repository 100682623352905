import React, { ChildrenArray, PureComponent } from 'react';

import cx from 'classnames';
import styled, { css } from 'styled-components';

import { sc } from 'app/styles';

export default class Modal extends PureComponent<{
  children: ChildrenArray<any>;
  className?: string;
  dataQaValue?: string;
  isOpen: boolean;
  onClose: (...args: Array<any>) => any;
}> {
  render() {
    const { children, className, dataQaValue, isOpen, onClose } = this.props;

    return (
      <Container className={cx('ci-modal', className)} data-qa="ci-modal" data-value={dataQaValue} isOpen={isOpen}>
        <Overlay onClick={onClose} className="ci-modal-overlay" />

        <InnerModal className="ci-modal-inner">{children}</InnerModal>
      </Container>
    );
  }
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;

  ${props =>
    props.isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
          transform: translate(-100%, -100%);
        `}
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`;

const InnerModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 202;

  width: 90%;
  height: 90%;
  margin: 0 auto;

  border-radius: 3px;
  background-color: ${sc.sectionBackgroundColor};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
`;
