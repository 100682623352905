import { isEmpty } from 'ramda';

import { isValid } from 'app/midgarComponents/PredicateBuilder/helper';
import { IBannerCampaignCreativeRaw, IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';

export const hasDuplicateBanner = (banners: Array<IBannerCampaignSpec | null | undefined>) => {
  const bannerIds = (banners || []).map(b => b?.id).filter(id => !!id);

  return bannerIds.length !== new Set(bannerIds).size;
};

export const validate = (state: IBannerCampaignCreativeRaw) => {
  const nonEmptyBanners = (state.banners || []).filter(b => !!b && !isEmpty(b));
  const hasBannerSpecs = nonEmptyBanners.length > 0;
  const isUserCriteriaValid = isValid(state?.bannerRealTimeUserCriteria?.userCriteriaJson);

  if (hasBannerSpecs) {
    const valid = !nonEmptyBanners.some(b => !b?.viewId || !b?.id) && !hasDuplicateBanner(state.banners);

    return {
      ...state,
      valid,
    };
  }

  const isValidSourceCreative = !!state.source?.creative;
  const nonEmptyTargets = (state.source?.targets || []).filter(t => !!t && !isEmpty(t));
  const isValidTargets = !nonEmptyTargets.some(t => !t?.storefront || (t?.views || []).length === 0);

  const valid = isValidSourceCreative && nonEmptyTargets.length > 0 && isValidTargets && isUserCriteriaValid;

  return {
    ...state,
    valid,
  };
};
