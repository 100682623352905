import React from 'react';

import { useSelector } from 'react-redux';

import { mapStateToVariantTypes } from 'app/ducks/bannerManagement/variantTypes/selectors';
import { Autocomplete } from 'app/midgarComponents';
import { IVariantType } from 'app/types/BannerManagement';

import { ErrorContainer } from './components';

const LABEL_PERSONALIZED = 'Widget Subtype';
const LABEL_VARIANT_TYPE = 'Variant Type';

type Props = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  hasWidgetSubtypes?: boolean;
  onChangeVariantType: (arg0: string) => void;
  required?: boolean;
  variantType?: IVariantType;
  widgetSubtypes: Array<IVariantType> | null | undefined;
  widgetSubtypesError: string | null | undefined;
  widgetSubtypesLoading: boolean | null | undefined;
};

export default ({
  className,
  disabled,
  error,
  hasWidgetSubtypes,
  onChangeVariantType,
  required,
  variantType,
  widgetSubtypes,
  widgetSubtypesError,
  widgetSubtypesLoading,
}: Props) => {
  const { variantTypes, variantTypesError, variantTypesLoading } = useSelector(mapStateToVariantTypes);

  const { fetchError, label, fetchLoading, suggestions } = hasWidgetSubtypes
    ? {
        fetchError: widgetSubtypesError,
        label: LABEL_PERSONALIZED,
        fetchLoading: widgetSubtypesLoading,
        suggestions: widgetSubtypes,
      }
    : {
        fetchError: variantTypesError,
        label: LABEL_VARIANT_TYPE,
        fetchLoading: variantTypesLoading,
        suggestions: variantTypes,
      };

  const variantTypeName = variantType?.name || '';

  return (
    <div className={className}>
      <Autocomplete
        disabled={disabled || !!fetchError || !(suggestions || []).length}
        error={error}
        id="variant-type"
        key={variantTypeName}
        label={label}
        loading={!!fetchLoading}
        name="variant-type"
        onSelect={onChangeVariantType}
        required={required}
        suggestions={suggestions || []}
        value={variantTypeName}
      />

      {fetchError && <ErrorContainer>Error while retrieving {hasWidgetSubtypes ? 'Widget Subtypes' : 'Variant Types'}</ErrorContainer>}
    </div>
  );
};
