import * as types from './types';
import { fetch } from '../../commonStates';
import { addItem, removeItem } from '../../helpers';
import { GET_DOWNLOAD_STATUS, GET_DOWNLOAD_STATUS_SUCCESS, GET_DOWNLOAD_STATUS_FAIL } from '../segments/types';

export const segmentInitState = {
  ...fetch,
  name: '',
  description: '',
  tags: [],
  loading: true,
};

const removeSegment = (curSegments, segmentIdToRemove) => (curSegments ? curSegments.filter(({ id }) => id !== segmentIdToRemove) : []);

const segment = (state = segmentInitState, { type, payload }) => {
  switch (type) {
    case types.GET_SEGMENT:
      return {
        ...state,
        loading: true,
      };

    case types.GET_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload.segment,
      };

    case types.GET_SEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.CALC_COUNT_LOADING:
      return {
        ...state,
        calculation: payload,
      };

    case types.CALC_COUNT_SUCCESS:
      return {
        ...state,
        calculation: payload,
      };

    case types.CALC_COUNT_FAILURE:
      return {
        ...state,
        calculation: payload,
      };

    case types.POST_SEGMENT:
      return {
        ...state,
        loading: true,
      };

    case types.POST_SEGMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        id: payload.segmentId,
      };

    case types.POST_SEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.NEW_SEGMENT:
      return { ...segmentInitState, loading: false };
    case types.SET_SEGMENT_FIELD:
      return {
        ...state,
        ...payload,
      };

    case types.ADD_TAG:
      return {
        ...state,
        tags: addItem(payload.tag)(state.tags),
      };

    case types.REMOVE_TAG:
      return {
        ...state,
        tags: removeItem(payload.tag)(state.tags),
      };

    case types.SET_SEGMENT_INCLUDED_RULES:
      return {
        ...state,
        type: 'RULE_BASED',
        includedRules: payload.ruleExpression,
        ruleData: payload.ruleData,
        includedRuleErrors: payload.errors,
      };

    case types.ADD_CSV:
      return {
        ...state,
        loading: true,
      };

    case types.ADD_CSV_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
        type: 'CSV_BASED',
        loading: false,
      };

    case types.ADD_CSV_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case types.RESET_SEGMENT:
      return segmentInitState;
    case types.REMOVE_SEGMENT:
      return {
        ...state,
        [payload.field]: removeSegment(state[payload.field], payload.segment.id),
      };

    case GET_DOWNLOAD_STATUS:
      return payload.id === state.id ? { ...state, state: 'LOADING' } : state;
    case GET_DOWNLOAD_STATUS_SUCCESS:
      return payload.id === state.id ? { ...state, ...payload } : state;
    case GET_DOWNLOAD_STATUS_FAIL:
      return payload.id === state.id ? { ...state, state: 'ERROR' } : state;
    default:
      return state;
  }
};

export default segment;
