import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { getRegularCashbackCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { cashbackPromoBannersOperations, userBudgetTokensOperations, stackableGroupsOperations } from 'app/ducks/cashbackPromo';
import { eventSchemaOperations } from 'app/ducks/cashbackPromo/eventSchema';
import * as CashbackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';
import { IStackableGroup, IUserBudgetToken } from 'app/features/CashbackPromoManagement/types';
import { Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

import { cashbackTypes } from '../../constants';
import { RegularCalculationContainer } from './Calculation/RegularCalculationContainer';
import { RegularFulfillmentContainer } from './Fulfillment/RegularFulfillmentContainer';
import { ValidationIcon, Dropdown, Error, Label, Row, Section, SpinnerWrapper } from '../../Creation/CreationCommonComponents';
import { ICashbackBannerCreative } from '../../types';

type Props = {
  banners: Array<ICashbackBannerCreative>;
  bannersLoading?: boolean;
  cashbackCreative: Record<string, any>;
  getCashbackBanners: (arg0: Record<string, string>) => unknown;
  getStackableGroups: () => unknown;
  getUserBudgetTokens: () => unknown;
  getAllEvents: () => any;
  stackableGroups: Array<IStackableGroup>;
  stackableGroupsLoading?: boolean;
  updateCashbackField: (...args: Array<any>) => any;
  userBudgetTokens: Array<IUserBudgetToken>;
  userBudgetTokensLoading?: boolean;
};

class Cashback extends PureComponent<Props> {
  componentDidMount = () => {
    const { cashbackCreative, getCashbackBanners, getStackableGroups, getUserBudgetTokens, getAllEvents, stackableGroups } = this.props;

    getStackableGroups();
    if (!stackableGroups?.length) getAllEvents();
    getUserBudgetTokens();

    const { userBudgetToken } = cashbackCreative || {};
    if (userBudgetToken && userBudgetToken.id) {
      getCashbackBanners({ userBudgetTokenId: userBudgetToken.id });
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { cashbackCreative, getCashbackBanners, updateCashbackField } = this.props;
    const { cashbackCreative: prevCashbackCreative } = prevProps;
    const { userBudgetToken } = cashbackCreative || {};
    const { userBudgetToken: prevUserBudgetToken } = prevCashbackCreative || {};

    if (userBudgetToken !== prevUserBudgetToken && userBudgetToken.id) {
      getCashbackBanners({ userBudgetTokenId: userBudgetToken.id });
      updateCashbackField({ cashbackPromoBanner: null });
    }
  }

  render() {
    const {
      banners,
      bannersLoading,
      cashbackCreative,
      stackableGroups,
      stackableGroupsLoading,
      updateCashbackField,
      userBudgetTokens,
      userBudgetTokensLoading,
    } = this.props;

    const { cashbackPromoBanner, stackableGroup, userBudgetToken } = cashbackCreative;

    const bannerDropdownDisabled = !userBudgetToken || !userBudgetToken.id;

    return (
      <>
        <Section>
          <Label>
            Configuration
            {userBudgetToken && stackableGroup ? (
              <ValidationIcon name="check-circle" size={22} color={sc.success} />
            ) : (
              <ValidationIcon name="warning" size={22} color={sc.warning} />
            )}
          </Label>

          {stackableGroupsLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <Dropdown
              label="Stackable Group"
              name="stackableGroup"
              onChange={val => updateCashbackField({ stackableGroup: val })}
              options={stackableGroups
                .filter(grp => grp.name.toLowerCase().indexOf(cashbackTypes.journey) === -1)
                .filter(grp => grp.managedBy === 'CMA')
                .sort((grpA, grpB) => grpA.priority - grpB.priority)
                .map(grp => ({ ...grp, label: grp.name }))}
              required
              value={stackableGroup || []}
            />
          )}

          {userBudgetTokensLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <Row>
              <Dropdown
                label="User Budget Token"
                name="userBudgetToken"
                onChange={val => updateCashbackField({ userBudgetToken: val })}
                options={userBudgetTokens.map(token => ({
                  ...token,
                  label: token.name,
                }))}
                required
                value={userBudgetToken || {}}
              />
            </Row>
          )}

          {bannersLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <>
              <Dropdown
                disabled={bannerDropdownDisabled}
                label="Cashback Promo Banner"
                name="cashbackPromoBanner"
                onChange={val => updateCashbackField({ cashbackPromoBanner: val })}
                options={banners.map(banner => ({
                  ...banner,
                  label: banner.title,
                }))}
                value={cashbackPromoBanner ? { ...cashbackPromoBanner, label: cashbackPromoBanner.title } : {}}
              />

              {!bannerDropdownDisabled && !banners.length && (
                <Error>No banners matching the token {userBudgetToken && userBudgetToken.name && `"${userBudgetToken.name}"`}</Error>
              )}
            </>
          )}
        </Section>

        <RegularCalculationContainer />
        <RegularFulfillmentContainer />
      </>
    );
  }
}

export default connect(
  state => {
    const banners = CashbackPromoConfigSelectors.getBanners(state);
    const stackableGroups = CashbackPromoConfigSelectors.getStackableGroups(state);
    const userBudgetTokens = CashbackPromoConfigSelectors.getUserBudgetTokens(state);
    const cashbackCreative = getRegularCashbackCreative(state);
    return {
      banners: banners.banners.filter(banner => banner.cashbackType === cashbackTypes.regular),
      bannersLoading: banners.loading,
      cashbackCreative,
      stackableGroups: stackableGroups.stackableGroups,
      stackableGroupsLoading: stackableGroups.loading,
      userBudgetTokens: userBudgetTokens.userBudgetTokens,
      userBudgetTokensLoading: userBudgetTokens.loading,
    };
  },
  {
    getCashbackBanners: cashbackPromoBannersOperations.getRegularCashbackBanners,
    getUserBudgetTokens: userBudgetTokensOperations.getUserBudgetTokens,
    getStackableGroups: stackableGroupsOperations.getStackableGroups,
    getAllEvents: eventSchemaOperations.getAllEvents,
    updateCashbackField: cashbackActions.updateCashbackField,
  },
)(Cashback);
