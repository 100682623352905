import { IQueryParams } from 'app/types/IQueryParams';
import { get } from 'app/utilities/http';

import endpoints from './endpoints';
import { IFeature } from './types';
import { ResultGetPaged } from '../types';

export const possibleValuesOfFeature = (id: number | string) =>
  get(endpoints.getFeature(id)).then(({ feature: { feature_value: values = [] } = {} }) => values);

export const getFeaturesPaged = async (params: IQueryParams): ResultGetPaged<IFeature> => {
  const { features } = await get(endpoints.getFeaturesPaged(params));

  // TODO: Until CMA is ready, simulate paging and search
  const { page = 0, size = 25, ...search } = params;

  const searched = Object.keys(search).reduce((acc, thisParam) => {
    if (search[thisParam].length) {
      return acc.filter(f => {
        if (thisParam === 'feature_id') {
          return f[thisParam] === Number(search[thisParam]);
        }
        return f[thisParam] && f[thisParam].toLowerCase().includes(search[thisParam].toLowerCase());
      });
    }
    return [...acc];
  }, features);

  const content = searched.filter((thisFeature, i) => i >= Number(page) * Number(size) && i < (Number(page) + 1) * Number(size));
  const totalElements = searched.length;

  return {
    content,
    first: Number(page) === 0,
    last: (Number(page) + 1) * Number(size) > totalElements,
    number: Number(page),
    totalElements,
    size,
  };
};
