import React, { PureComponent } from 'react';

import styled from 'styled-components';

import _Dropdown from 'app/midgarComponents/Dropdown';
import Icon from 'app/midgarComponents/Icon';
import { sc } from 'app/styles';

import { CONJUCTION_BUTTONS, isInvalidValue, FIELD_TYPES, EQUALS_TO_FIELD } from './helper';
import Operator from './Operator';
import PredicateInput from './PredicateInput';

type Props = {
  index: number;
  sourceEvent: any;
  rule: any;
  promoEventFields: Array<any>;
  updateRule: (...args: Array<any>) => any;
  deleteRule: (...args: Array<any>) => any;
  eventLabel: string;
};

export default class Rule extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '' };
  }
  getSupportedOperators = () => {
    const {
      rule: { fieldName },
      promoEventFields,
    } = this.props;
    for (let i = 0; i < promoEventFields.length; i += 1) {
      if (fieldName === promoEventFields[i].name) {
        if (fieldName === 'ekyc_completed') {
          return ['='];
        }
        if (fieldName === 'campaignExclusionTags' || fieldName === 'excludeMerchantTag') {
          return ['NONE'];
        }
        return promoEventFields[i].promoEventFieldType.operators
          .split(',')
          .map(op => op.trim())
          .filter(item => item !== 'NONE' || ['subPaymentMethods', 'merchantCampaignTags', 'storeCampaignTags'].includes(fieldName));
      }
    }
    return null;
  };

  handleChangeField = obj => {
    const { updateRule, rule } = this.props;
    updateRule({
      ...rule,
      ...obj,
    });
  };

  getPossibleValues = (fieldType = '', operator = '') => {
    const {
      rule: { fieldName },
      promoEventFields,
    } = this.props;
    if (operator === EQUALS_TO_FIELD) {
      return promoEventFields.reduce((acc, field) => {
        return field.promoEventFieldType.name === fieldType && fieldName !== field.name ? [...acc, field.name] : acc;
      }, []);
    }
    for (let i = 0; i < promoEventFields.length; i += 1) {
      if (fieldName === promoEventFields[i].name && promoEventFields[i].possibleValues) {
        return promoEventFields[i].possibleValues.split(',').map(op => op.trim());
      }
    }
    return null;
  };

  handlePredicateInputChange = changedInput => this.handleChangeField({ ruleValues: changedInput });

  render() {
    const { rule, promoEventFields, index, deleteRule, eventLabel, sourceEvent } = this.props;
    const { id, fieldName, fieldType, operator, ruleValues, conjuctor } = rule;
    let errorMessage = '';

    if (sourceEvent?.name === 'Transaction' && fieldName === 'storeId') {
      errorMessage = `storeId promo event must be in 'MID-SID' format`;
    }

    if (isInvalidValue(rule) && fieldType === FIELD_TYPES.string && rule.ruleValues) {
      errorMessage = 'Special Characters Single Quote and Double Quote are not allowed';
    }

    return (
      <Row $invalid={isInvalidValue(rule)} data-qa="predicate-builder-row">
        <DeleteBtn data-qa="predicated-builder-row-delete" onClick={() => deleteRule(id)} name="remove" color={sc.tertiary} size={'24'} />
        {index !== 0 && (
          <AndOr
            label="And/Or"
            size="50"
            value={conjuctor}
            options={CONJUCTION_BUTTONS}
            onChange={val => this.handleChangeField({ conjuctor: val })}
          />
        )}

        {errorMessage && (
          <Message>
            <Pill>{errorMessage}</Pill>
          </Message>
        )}

        {(index === 0 || conjuctor) && (
          <Dropdown
            id="eventRule"
            label={eventLabel}
            size="250"
            type="string"
            value={fieldName}
            options={promoEventFields.map(ev => ({ label: ev.name, ...ev }))}
            onChange={({ label, promoEventFieldType: { name } }) =>
              this.handleChangeField({ fieldName: label, operator: '', ruleValues: '', fieldType: name })
            }
          />
        )}

        {fieldName && (
          <Operator operator={operator} supportedOperators={this.getSupportedOperators()} handleChange={this.handleChangeField} />
        )}

        {fieldName && operator && (
          <InputWrapper data-qa="predicate-input">
            <PredicateInput
              label={operator === EQUALS_TO_FIELD ? eventLabel : fieldName}
              name={fieldName}
              operator={operator}
              type={fieldType}
              value={ruleValues}
              options={this.getPossibleValues(fieldType, operator)}
              onChange={this.handlePredicateInputChange}
            />
          </InputWrapper>
        )}
      </Row>
    );
  }
}

const DeleteBtn = styled(Icon)`
  position: absolute;
  top: -8px;
  right: -8px;
  border: 1px solid ${sc.tertiary};
  background: white;
`;

const AndOr = styled(_Dropdown)`
  min-width: 100px;
  margin-right: 1rem;
`;

const Dropdown = styled(_Dropdown)`
  min-width: 150px;
`;

const Row = styled.div`
  position: relative;
  padding: 1rem;
  background: ${sc.greyLightest};
  border: 1px solid ${sc.greyLighter};
  border-color: ${props => (props.$invalid ? sc.danger : sc.greyLighter)};
  margin: 1.5rem 0;
  display: flex;
  justify-content: 1rem;
  justify-items: flex-start;
}
`;

const InputWrapper = styled.span`
  display: block;
  margin-left: 1rem;
  width: -webkit-fill-available;
`;

const Pill = styled.span`
  background-color: ${sc.warning};
  color: ${sc.greyDarkest};
  border-radius: 4px;
  display: inline-block;
  justify-self: end;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: justify;
  width: inherit;
`;

const Message = styled.div`
  position: absolute;
  background: ${sc.greyLightest};
  border: 1px solid ${sc.greyLighter};
  border-color: ${props => (props.$invalid ? sc.danger : sc.greyLighter)};
  margin: -1.8rem 0;
  display: flex;
  justify-content: 1rem;
  justify-items: flex-start;
`;
