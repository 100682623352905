import { connect } from 'react-redux';

import { compose, lifecycle, withStateHandlers } from 'recompose';

import { segmentSelectors } from 'app/ducks/segments/segment';
import { audienceSelectors } from 'app/ducks/segments2/segment';

import { SegmentCount } from './SegmentCount';

const withLifeCyle = lifecycle({
  componentDidMount() {
    const { calcOnLoad, handleCalculate } = this.props;

    if (calcOnLoad) {
      handleCalculate();
    }
  },
});

const addStateHandlers = withStateHandlers(() => ({ hasCalculated: false }), {
  handleCalculate:
    (_, { calculate }) =>
    () => {
      const executed = calculate();
      return {
        hasCalculated: executed,
      };
    },
});

const mapStateToProps = state => {
  const selector = window.location.pathname.includes('/audience/') ? audienceSelectors : segmentSelectors;
  const segmentData = selector.readMe(state);
  return selector.counter(segmentData);
};

const enhance = compose(connect(mapStateToProps, {}), addStateHandlers, withLifeCyle);

export default enhance(SegmentCount);
