import React from 'react';

import { CampaignAttributesScope } from 'app/api/cashbackPromo';

import BannerContainer from './Banner';
import Fulfillment from './Fulfillment';
import Reverse from './Reverse';
import StackableContainer from './Stackable';
import { cashbackTypes } from '../../constants';
import CustomAttributes from '../../CustomAttributes';

export const CustomAttributesComponent = CustomAttributes(cashbackTypes.journey);

export const JourneyAttributesComponent = () => (
  <>
    <CustomAttributesComponent scope={CampaignAttributesScope.JourneyCampaign} />
    <StackableContainer />
    <BannerContainer />
    <Fulfillment />
    <Reverse />
  </>
);
