import React from 'react';

import { FormControl, Select as MaterialSelect, InputLabel, MenuItem } from '@material-ui/core';
import { isPlainObject } from 'ramda-adjunct';

import { R } from 'app/helpers/RamdaHelpers/helpers';

const toMenuItem = R.curry(({ selectedItemStyle, nonSelectedItemStyle }, selected, option) => {
  const [optionValue, optionLabel] = isPlainObject(option) ? [option.value, option.label] : [option];

  return (
    <MenuItem
      key={optionValue}
      value={optionValue}
      data-qa={optionValue}
      className={selected === optionValue ? selectedItemStyle : nonSelectedItemStyle}
    >
      {optionLabel || optionValue}
    </MenuItem>
  );
});

interface RuleSelectProps {
  id: string;
  options:
    | string
    | {
        value?: string;
        label?: string;
      }[];
  handleChange(...args: unknown[]): unknown;
  classes: Record<string, string>;
  label?: string;
  value?: string;
}

export const RuleSelect = ({ id, options, handleChange, classes, label = '', value = '' }: RuleSelectProps) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor={id}>{label}</InputLabel>

    <MaterialSelect
      autoWidth
      value={value}
      onChange={handleChange}
      inputProps={{
        name: id,
        id,
      }}
      data-qa="rule-select-option"
    >
      <MenuItem value="">
        <em>(None)</em>
      </MenuItem>

      {R.map(toMenuItem(classes, value), options)}
    </MaterialSelect>
  </FormControl>
);
