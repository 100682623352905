import React, { Component } from 'react';

import moment from 'moment';
import Picker from 'rc-calendar/lib/Picker';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import 'rc-time-picker/assets/index.css';
import 'rc-calendar/assets/index.css';

import { momentFromDateStr, momentToDateStr, momentToDateTimeString } from 'app/utilities/date';
import { defaultDateFormat, defaultDateTimeFormat } from 'configs/dateTime';

import TextField from '../TextField/';

export default class DateRangeField extends Component<{
  id: string;
  name: string;
  label: string;
  endValue: string;
  startValue: string;
  handleSelect: (...args: Array<any>) => any;
  setEndValue?: (...args: Array<any>) => any;
  setStartValue?: (...args: Array<any>) => any;
  className?: string;
  withTimePicker?: boolean;
  required?: boolean;
  disabled?: boolean;
  pickerDefaultStartValue?: string;
  pickerDefaultEndValue?: string;
}> {
  static defaultProps = {
    label: '',
    startValue: '',
    endValue: '',
  };

  onSelect = (range: Array<any>) => {
    const { handleSelect, setStartValue, setEndValue, withTimePicker } = this.props;

    if (range && Array.isArray(range)) {
      const momentToStr = withTimePicker ? momentToDateTimeString : momentToDateStr;
      typeof setStartValue === 'function' && setStartValue(momentToStr(range[0]));
      typeof setEndValue === 'function' && setEndValue(momentToStr(range[1]));
      handleSelect(range);
    }
  };

  render() {
    const {
      id,
      name,
      label,
      required,
      startValue,
      endValue,
      withTimePicker,
      className,
      disabled,
      pickerDefaultStartValue,
      pickerDefaultEndValue,
    } = this.props;

    return (
      <Picker
        animation="slide-up"
        calendar={
          <RangeCalendar
            showClear
            showToday={false}
            onSelect={this.onSelect}
            dateInputPlaceholder={['Start', 'End']}
            timePicker={withTimePicker ? <TimePickerPanel /> : null}
          />
        }
        value={dateRange(startValue || pickerDefaultStartValue, endValue || pickerDefaultEndValue)}
      >
        {() => (
          <TextField
            id={id}
            name={name}
            label={label}
            required={required}
            value={formatDateRange(startValue, endValue, withTimePicker)}
            className={className}
            onChange={() => {
              /* no-op */
            }}
            disabled={!!disabled}
          />
        )}
      </Picker>
    );
  }
}

const formatDateRange = (startValue: any, endValue: any, withTimePicker?: boolean): string => {
  if ((startValue && startValue.length) || (endValue && endValue.length)) {
    const format = withTimePicker ? defaultDateTimeFormat : defaultDateFormat;
    return `${moment(startValue).format(format)} - ${moment(endValue).format(format)}`;
  }

  return '';
};

const dateRange = (startDateStr: string, endDateStr: string) => [
  momentFromDateStr(startDateStr) || moment(new Date()),
  momentFromDateStr(endDateStr) || moment(new Date()),
];
