import React from 'react';

import styled from 'styled-components';

import Metadata from 'app/features/BannerManagement/common/Metadata';
import { Button as _Button } from 'app/midgarComponents';
import { sc } from 'app/styles';

type Props = {
  widgetConfig: Record<string, string>;
  onSave: () => (...args: Array<any>) => any;
  updateWidgetConfig: (arg0: Record<string, string>) => void;
};

export default class WidgetConfigTab extends React.PureComponent<Props> {
  render() {
    const { widgetConfig, onSave, updateWidgetConfig } = this.props;

    return (
      <Container>
        <Metadata metadata={widgetConfig} updateMetadata={updateWidgetConfig} />

        <Button type="primary" onClick={onSave}>
          Save
        </Button>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  font-size: ${sc.fontSizeSmall};
`;

const Button = styled(_Button)`
  margin-top: ${sc.gutterSmaller};
`;
