import { ICampaignAttribute, IMedium, IScope } from 'app/features/CashbackPromoManagement/types';

import * as types from './types';

export const getError = (error: Error) => ({
  type: types.GET_ERROR,
  payload: { error },
});

export const getInit = () => ({
  type: types.GET_INIT,
});

export const getSuccess = (scope: IScope, medium: IMedium, campaignAttributes: Array<ICampaignAttribute>) => ({
  type: types.GET_SUCCESS,
  payload: { campaignAttributes, medium, scope },
});
