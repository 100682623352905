import { compose, withHandlers } from 'recompose';

import { ListSelectableGroup } from './ListSelectableGroup';

const addHandlers = withHandlers({
  handleListClick:
    ({ handleClick }) =>
    listId =>
    itemId => {
      if (handleClick) {
        handleClick(listId, itemId);
      }
    },
});

const enhance = compose(addHandlers);

export default enhance(ListSelectableGroup);
