import { IPromoEvent } from 'app/features/CashbackPromoManagement/types';

import { Row, Name, Criteria } from './CriteriaComponents';
import Entry from './Entry';
import { IRuleCriterion } from '../types';
import { formatCriteriaString } from './formatCriteriaString';

type Props = {
  eventCriteria: string | null | undefined;
  eventCriteriaJson: Array<IRuleCriterion> | null | undefined;
  eventType: IPromoEvent;
  originalCriteria?: Record<string, any>;
};

export default ({ eventType, eventCriteria, eventCriteriaJson, originalCriteria }: Props) => {
  const eventCriteriaStr = eventCriteriaJson && eventCriteriaJson.length > 0 ? eventCriteriaJson : formatCriteriaString(eventCriteria);
  const originalEventCriteria = originalCriteria
    ? originalCriteria.eventCriteriaJson && originalCriteria.eventCriteriaJson.length > 0
      ? originalCriteria.eventCriteriaJson
      : formatCriteriaString(originalCriteria.eventCriteria)
    : null;

  return (
    <>
      <Entry
        title="Event Type"
        value={eventType ? eventType.displayName : 'N/A'}
        highlight={originalCriteria && (eventType || originalCriteria?.eventType) && originalCriteria?.eventType?.id !== eventType?.id}
      />

      <Row>
        <Name>Event Criteria</Name>
        <Criteria criteria={eventCriteriaStr || 'N/A'} originalCriteria={originalEventCriteria} />
      </Row>
    </>
  );
};
