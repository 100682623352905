import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { compose } from 'ramda';
import styled from 'styled-components';

import { inject, withAppConfig } from 'app/decorators';
import { binderActions, binderOperations } from 'app/ducks';
import { SideModal as _SideModal, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import GeneralTab from './GeneralTab';
import WidgetConfigTab from './WidgetConfigTab';

const TABS = {
  General: 'general',
  Capping: 'capping',
  WidgetConfig: 'widgetConfig',
};

type Props = {
  appConfig: IAppConfig;
  closeModal: (...args: Array<any>) => any;
  isOpen: boolean;
  spViewLoading: boolean;
  view: Record<string, any>;
  BinderActions: typeof binderActions;
  BinderOperations: typeof binderOperations;
};

type State = {
  selectedTab: 'general' | 'capping' | 'widgetConfig';
};

class SettingsModal extends PureComponent<Props, State> {
  state = {
    selectedTab: TABS.General,
  };

  render() {
    const { selectedTab } = this.state;

    const {
      appConfig,
      closeModal,
      isOpen,
      spViewLoading,
      view,
      BinderActions: { setSelectedViewModelType, updateViewField },
      BinderOperations: { updateWidgetConfig, togglePersonalization },
    } = this.props;

    const { useViewConfig } = getBannerCreativeConfig(appConfig);

    return (
      <SideModal isOpen={isOpen} onClose={closeModal}>
        <Header>
          <h3>Settings</h3>

          <CloseIcon size={18} name="remove" onClick={closeModal} />
        </Header>

        <Tabs>
          <Tab selected={selectedTab === TABS.General} onClick={() => this.setState({ selectedTab: TABS.General })}>
            General
          </Tab>

          {useViewConfig && (
            <Tab selected={selectedTab === TABS.WidgetConfig} onClick={() => this.setState({ selectedTab: TABS.WidgetConfig })}>
              Widget Config
            </Tab>
          )}
        </Tabs>

        {selectedTab === TABS.General && (
          <GeneralTab
            setSelectedViewModelType={setSelectedViewModelType}
            spViewLoading={spViewLoading}
            togglePersonalization={togglePersonalization}
            view={view}
          />
        )}

        {selectedTab === TABS.WidgetConfig && (
          <WidgetConfigTab
            onSave={updateWidgetConfig}
            updateWidgetConfig={widgetConfig => updateViewField({ widgetConfig })}
            widgetConfig={view.widgetConfig}
          />
        )}
      </SideModal>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder } }) => ({
  spViewLoading: binder.spViewLoading,
  view: binder.view.view,
});

export default compose(
  withAppConfig,
  connect(mapStateToProps),
  inject({
    BinderActions: binderActions,
    BinderOperations: binderOperations,
  }),
)(SettingsModal);

const CloseIcon = styled(Icon)`
  padding: ${sc.gutterSmaller};
  position: absolute;
  top: ${sc.gutterSmaller};
  right: ${sc.gutterSmaller};
  color: ${sc.headingColor};

  &:hover {
    color: ${sc.primary};
    cursor: pointer;
  }
`;

const Header = styled.div`
  padding-bottom: ${sc.gutter};

  & > h3 {
    color: ${sc.headingColor};
    letter-spacing: 0.4px;
  }
`;

const SideModal = styled(_SideModal)`
  width: 425px;

  & > .ci-side-modal-inner {
    padding: ${sc.gutter} ${sc.gutterLarger};
  }
`;

const Tab = styled.div`
  display: inline-block;
  padding: ${sc.gutterSmaller};
  border-bottom: 2px solid ${props => (props.selected ? sc.primary : 'transparent')};
  color: ${props => (props.selected ? sc.primary : sc.subHeadingColor)};
  font-weight: 600;
  font-size: ${sc.fontSizeSmall};
  margin-right: ${sc.gutterSmaller};
  cursor: pointer;

  ${props =>
    !props.selected &&
    `
    &:hover {
      border-color: ${sc.subHeadingColor};
    }
  `};
`;

const Tabs = styled.div`
  position: relative;
  margin-bottom: ${sc.gutterLarge};

  &:after {
    content: '';
    position: absolute;
    left: -${sc.gutterLarger};
    right: -${sc.gutterLarger};
    bottom: -1px;
    height: 1px;
    background-color: ${sc.sectionBorderColor};
  }
`;
