import { IPriorityType } from 'app/features/MasterPlan/constants';
import { ICampaignPriority } from 'app/types/ICampaignPriority';
import { ICampaignExecution } from 'app/types/MasterPlan';

import { IMPROMPTU, MPG } from './constants';

export const executionsComparer = (a: ICampaignExecution, b: ICampaignExecution): number => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;

  if (a.planType !== b.planType) {
    if (a.planType === IMPROMPTU) return 1;
    if (b.planType === IMPROMPTU) return -1;
  }

  const getPriority = (execution: ICampaignExecution): number =>
    execution.priority === null || execution.priority === undefined || Number.isNaN(execution.priority)
      ? Number.MAX_SAFE_INTEGER
      : execution.priority;

  const diffPriority = getPriority(a) - getPriority(b);
  if (diffPriority !== 0) return diffPriority;

  if (!a.createdAt && !b.createdAt) return 0;
  if (!a.createdAt) return 1;
  if (!b.createdAt) return -1;
  return a.createdAt.localeCompare(b.createdAt);
};

export const mergeExecution = (
  fromPriority: ICampaignExecution | null | undefined,
  execution: ICampaignExecution | null | undefined,
): ICampaignExecution => {
  if (!fromPriority) {
    return execution;
  }

  if (!execution) {
    return fromPriority;
  }

  if (fromPriority.id !== execution.id) {
    throw Error('IDs do not match. Cannot merge the executions');
  }

  return { ...fromPriority, ...execution, priorityType: fromPriority.priorityType };
};

export const mergeExecutions = (
  fromPriorities: Array<ICampaignExecution>,
  executions: Array<ICampaignExecution>,
): Array<ICampaignExecution> => {
  const fromPrioritiesById: Record<number, ICampaignExecution> = fromPriorities.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
  const executionsById: Record<number, ICampaignExecution> = executions.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
  const ids = Array.from(new Set([...fromPriorities.map(p => p.id), ...executions.map(e => e.id)]));

  return ids
    .map(id => mergeExecution(fromPrioritiesById[id], executionsById[id]))
    .sort(executionsComparer)
    .reduce(
      (acc, curr, i) => (curr.planType === MPG ? [...acc, curr] : [...acc, { ...curr, priority: i > 0 ? acc[i - 1].priority + 1 : 1 }]),
      [],
    );
};

export const priorityToExecution = (campaignPriority: ICampaignPriority, priorityType: IPriorityType): ICampaignExecution => {
  const { createdAt, dedupeStrategy, id, name, priority, isRecurring, pushType, pushSite } = campaignPriority;
  // TODO: Might need to set a property to flag the source (priorities or executions)
  return {
    id,
    name,
    priority,
    priorityType,
    createdAt,
    dedupeStrategy,
    isRecurring,
    pushType,
    pushSite,
  };
};
