import styled from 'styled-components';

import Chip from '../Chip';

export default styled(Chip)`
  position: relative;
  cursor: default;
  text-overflow: ellipsis;
  padding: 0 2rem 0 1rem;
  word-break: break-all;
  overflow: hidden;
  span {
    position: absolute;
    cursor: pointer;
    display: inline-block;
    margin-left: 0.75rem;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    right: 8px;
    top: 4px;
    &:hover,
    &:focus {
      background: hsla(0, 100%, 100%, 0.3);
    }
  }
  svg {
    font-size: 1rem;
    left: 2px;
    top: 2px;
    position: absolute;
  }
`;
