import React from 'react';

import { History } from 'history';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { compose } from 'ramda';
import styled from 'styled-components';

import { IApiViewItemForCreative } from 'app/api/bannerManagementV2/creatives/types';
import { BmsError } from 'app/api/bannerManagementV2/types';
import { withPermissions } from 'app/decorators';
import { creativeActions, creativeOperations } from 'app/ducks';
import { fetchActiveViewItems as opFetchActiveViewItems } from 'app/ducks/bannerManagement/creative/activeViewItems/operations';
import { toErrorString } from 'app/features/BannerManagement/utils';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { ConfirmModal, Page, Button, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IBannerCreative } from 'app/types/BannerManagement';
import { IsPermittedFn } from 'app/utilities/permissions';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';
import { bannerManagementEditPermissions } from 'configs/permissions';
import { BANNERS_CREATIVE } from 'configs/routes';

import { PageMain, StickyHeader } from '../../common/components';
import withBannerDetailContext from '../../hoc/withBannerDetailContext';
import CreativeEditor from '../CreativeEditor';
import { ICreativeValidation } from '../validation/types';
import withCreativeValidation from '../validation/withCreativeValidation';

interface Params {
  id: string;
}

type Props = ICreativeValidation & {
  activeViewItems: Array<IApiViewItemForCreative> | null | undefined;
  activeViewItemsError: BmsError | null | undefined;
  activeViewItemsLoading: boolean;
  appConfig: IAppConfig;
  creativeLoading: boolean;
  engageCategories: Array<any>;
  engageCategoriesLoading: boolean;
  fetchActiveViewItems: (arg0: number) => (...args: Array<any>) => any;
  fetchCreative: (id: number | string) => (...args: Array<any>) => any;
  form: IBannerCreative;
  history: History;
  isPermitted: IsPermittedFn;
  isSaving: boolean;
  match: RouteComponentProps<Params>['match'];
  resetCreativeForm: () => any;
  saveCreative: (redirect?: (...args: Array<any>) => any) => (...args: Array<any>) => any;
  widgetTypesError?: string;
  widgetTypesLoading: boolean;
};

type State = {
  isUpdateClicked: boolean;
  isWarningOpen: boolean;
};

class CreativeEdit extends React.PureComponent<Props, State> {
  state = {
    isUpdateClicked: false,
    isWarningOpen: false,
  };

  componentDidMount() {
    this.handleFetchCreative();

    const { fetchActiveViewItems } = this.props;
    const { id } = this.props.match.params;

    if (id && Number(id)) {
      fetchActiveViewItems(Number(id));
    } else {
      console.error('Edit Banner Creative: No creative ID');
    }
  }

  componentDidUpdate() {
    this.handleFetchCreative();

    const { isUpdateClicked } = this.state;
    const { activeViewItems, activeViewItemsError, activeViewItemsLoading, isSaving } = this.props;

    if (isUpdateClicked) {
      if (!activeViewItemsLoading) {
        this.setState({ isUpdateClicked: false });

        if (activeViewItemsError) {
          this.setState({ isWarningOpen: true });
        } else if ((activeViewItems || []).length > 0) {
          this.setState({ isWarningOpen: true });
        } else if (!isSaving) {
          this.handleSaveCreative();
        }
      }
    }
  }

  componentWillUnmount() {
    // TODO: Same is for Edit
    const { resetCreativeForm, updateDuplicateMetadataFound } = this.props;
    resetCreativeForm();
    updateDuplicateMetadataFound(false);
  }

  handleClickUpdate = () => {
    const { appConfig, creativeHasErrors, metaHasErrors, isSaving, setShouldValidate } = this.props;

    const { validateMeta = false } = getBannerCreativeConfig(appConfig);

    setShouldValidate(true);

    if (!isSaving) {
      if (creativeHasErrors()) {
        displayError('Some fields are invalid');
      } else if (validateMeta && metaHasErrors()) {
        displayError('Metadata value cannot be empty');
      } else {
        this.setState({ isUpdateClicked: true });
      }
    }
  };

  handleFetchCreative = () => {
    const { engageCategories, creativeLoading, fetchCreative, form, match } = this.props;

    // TODO: Fetch engage categories and the creative in parallel
    if ((engageCategories || []).length && match.params.id && !form.id && !creativeLoading) {
      fetchCreative(match.params.id);
    }
  };

  handleSaveCreative = () => {
    const { history, saveCreative } = this.props;
    saveCreative(() => history.push(BANNERS_CREATIVE));
  };

  render() {
    const {
      activeViewItems,
      activeViewItemsError,
      creativeFieldHasError,
      creativeHasErrors,
      creativeLoading,
      engageCategoriesLoading,
      form,
      isPermitted,
      isSaving,
      metaHasErrors,
      setShouldValidate,
      variantCategoryFieldHasError,
      variantCategoryHasErrors,
      variantFieldHasError,
      variantHasErrors,
      widgetTypesError,
      widgetTypesLoading,
      duplicateMetadataKeyFound,
    } = this.props;

    const { isUpdateClicked, isWarningOpen } = this.state;

    const title = form && form.name ? `Creative - ${form.name}` : 'Creative';

    return (
      <Page>
        <StickyHeader title={title}>
          <div>
            <Button type="primary" to={BANNERS_CREATIVE}>
              Back
            </Button>

            {isPermitted(bannerManagementEditPermissions) && (
              <UpdateButton
                data-qa="banner-creative-update-button"
                disabled={isSaving || creativeHasErrors() || duplicateMetadataKeyFound} // TODO: Add shouldValidate when validation from New is implemented
                onClick={this.handleClickUpdate}
                type="primary"
              >
                {isSaving || isUpdateClicked ? <Spinner color={sc.primary} /> : 'Update'}
              </UpdateButton>
            )}
          </div>
        </StickyHeader>

        <Container>
          {engageCategoriesLoading || creativeLoading ? (
            <Spinner color={sc.primary} />
          ) : (
            <CreativeEditor
              creativeFieldHasError={creativeFieldHasError}
              creativeHasErrors={creativeHasErrors}
              isEdit
              metaHasErrors={metaHasErrors}
              setShouldValidate={setShouldValidate}
              shouldValidate={true}
              variantCategoryFieldHasError={variantCategoryFieldHasError}
              variantCategoryHasErrors={variantCategoryHasErrors}
              variantFieldHasError={variantFieldHasError}
              variantHasErrors={variantHasErrors}
              widgetTypesError={widgetTypesError}
              widgetTypesLoading={widgetTypesLoading}
            />
          )}
        </Container>

        <ConfirmModal
          confirmText="Continue"
          isOpen={isWarningOpen}
          onClose={() => this.setState({ isWarningOpen: false })}
          onConfirm={() => {
            this.setState({ isWarningOpen: false });
            this.handleSaveCreative();
          }}
          title="Warning"
        >
          <WarningContainer>
            {(activeViewItems || []).length > 0 ? (
              <p>You are editing a live creative.</p>
            ) : !!activeViewItemsError ? (
              <>
                <p>Unable to determine whether this is a live creative due to the error:</p>

                <ErrorContainer>{toErrorString(activeViewItemsError)}</ErrorContainer>

                <p>Save anyways?</p>
              </>
            ) : (
              <p>Unable to determine whether this is a live creative due an unknown error:</p>
            )}

            <p>All banners created using the same creative ID will be deduped among themselves on a page.</p>
          </WarningContainer>
        </ConfirmModal>
      </Page>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { creative, duplicateMetadataKeyFound } }) => ({
  activeViewItems: creative.activeViewItems.viewItems,
  activeViewItemsError: creative.activeViewItems.error,
  activeViewItemsLoading: creative.activeViewItems.loading,
  creativeLoading: creative.fetchCreativeLoading,
  error: creative.error,
  form: creative.form,
  isSaving: creative.isSaving,
  duplicateMetadataKeyFound: creative.duplicateMetadataKeyFound,
});

const mapDispatchToProps = {
  fetchActiveViewItems: opFetchActiveViewItems,
  fetchCreative: creativeOperations.fetchCreative,
  resetCreativeForm: creativeActions.resetCreativeForm,
  saveCreative: creativeOperations.saveCreative,
  updateDuplicateMetadataFound: creativeActions.updateDuplicateMetadataFound,
};

export default compose(
  withBannerDetailContext,
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
  withCreativeValidation,
)(CreativeEdit);

const Container = styled(PageMain)`
  padding: ${sc.gutter};
`;

const ErrorContainer = styled.p`
  border: 1px solid ${sc.danger};
  color: ${sc.danger};
  padding: ${sc.gutterSmaller};
  white-space: normal;
`;

const UpdateButton = styled(Button)`
  margin-left: ${sc.gutterSmaller};
`;

const WarningContainer = styled.div`
  & > p {
    margin-bottom: ${sc.gutter};
  }
`;
