import React, { PureComponent } from 'react';

import Button from 'app/midgarComponents/Button';

import { NEW_EMPTY_CRITERIA } from './helper';
import Rule from './Rule';

type Props = {
  rules: any;
  sourceEvent: any;
  sourceFields: Array<any>;
  updateRules: (...args: Array<any>) => any;
  disabled?: boolean;
  eventLabel?: string;
};

export default class PredicateBuilder extends PureComponent<Props> {
  addRule = () => {
    const { rules, updateRules } = this.props;

    const newRules = [
      ...(rules || []),
      {
        ...NEW_EMPTY_CRITERIA,
        ...{ id: `rule-${Date.now().toString(36)}` },
      },
    ];

    updateRules(newRules);
  };

  updateRule = obj => {
    const { rules, updateRules } = this.props;
    const newRules = rules.map(st => (st.id !== obj.id ? st : obj));
    updateRules(newRules);
  };

  deleteRule = id => {
    const { rules, updateRules } = this.props;
    // edge case:
    // if the first rule is deleted, but there is another rule behind it, 2nd rule needs to remove conjuctor
    const newRules = rules
      .filter(st => st.id !== id)
      .map((st, index) =>
        index === 0
          ? {
              ...st,
              conjuctor: NEW_EMPTY_CRITERIA.conjuctor,
            }
          : st,
      );

    updateRules(newRules);
  };

  renderFieldsNotFoundMessage = () => <div data-qa="predicate-fields-not-found-message">This event doesn't have any rule.</div>;

  renderRules = () => {
    const { rules, sourceEvent, sourceFields, disabled = false, eventLabel = 'Select event field' } = this.props;

    return (
      <>
        {(rules || []).map((rule, index) => (
          <Rule
            index={index}
            sourceEvent={sourceEvent}
            promoEventFields={sourceFields}
            key={rule.id}
            rule={rule}
            updateRule={this.updateRule}
            deleteRule={this.deleteRule}
            eventLabel={eventLabel}
          />
        ))}

        <Button type="link" onClick={this.addRule} disabled={!sourceEvent || disabled} data-qa="predicate-add-rule-button">
          + Add Rule
        </Button>
      </>
    );
  };

  render() {
    const { sourceFields } = this.props;
    return sourceFields && sourceFields.length ? this.renderRules() : this.renderFieldsNotFoundMessage();
  }
}
