import React from 'react';

import { useSelector } from 'react-redux';

import { mapStateToWidgetTypes } from 'app/ducks/bannerManagement/widgetTypes/selectors';
import { Autocomplete } from 'app/midgarComponents';
import { ISuggestion } from 'app/types/utils';

import { ErrorContainer } from './components';

type Props = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  onChangeWidgetType: (arg0: string) => void;
  required?: boolean;
  value?: string;
};

export default ({ className, disabled, error, label, onChangeWidgetType, required, value }: Props) => {
  const { widgetTypes, widgetTypesError, widgetTypesLoading } = useSelector(mapStateToWidgetTypes);
  const widgetTypeSuggestions = (widgetTypes || []).map(type => ({ id: type.name, name: type.label }));

  return (
    <div className={className}>
      <Autocomplete
        className={className}
        disabled={disabled}
        error={error}
        id="widget-type"
        label={label || 'Widget Type'}
        loading={widgetTypesLoading}
        name="widgetType"
        onSelect={(sugg: ISuggestion) => onChangeWidgetType(sugg.id)}
        required={required}
        suggestions={widgetTypeSuggestions}
        value={value || ''}
      />

      {widgetTypesError && <ErrorContainer>{widgetTypesError}</ErrorContainer>}
    </div>
  );
};
