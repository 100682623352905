import React from 'react';

import { PartialSchedule } from 'app/features/Campaigns/Schedule/PartialSchedule';
import { triggerTypes, recurringTrigger } from 'app/utilities/constants';
import { IComplexChannelConfig } from 'configs/channels/complexConfig';
import { cashbackMediumId } from 'configs/channels/mediumIds';
import { CAMPAIGN_CASHBACK_CREATE, CAMPAIGN_CASHBACK_DRAFT } from 'configs/permissions';

import { cashbackTypes } from './constants';
import CustomAttributes from './CustomAttributes';
import { JourneyAttributesComponent } from './JourneyContainers/JourneyAttributes';
import { JourneyOverviewContainer } from './JourneyContainers/Overview';
import JourneyStages from './JourneyContainers/Stages';
import UserCriteria from './JourneyContainers/UserCriteria';
import RegularCashbackCreationContainer from './RegularContainers/Creation';
import { EnrichFieldsCriteriaContainer } from './RegularContainers/Criteria/EnrichFieldsCriteriaContainer';
import { RegularEventsCriteriaContainer } from './RegularContainers/Criteria/RegularEventsCriteriaContainer';
import RegularCashbackOverviewContainer from './RegularContainers/Overview';

const CustomAttributesComponent = CustomAttributes(cashbackTypes.regular);

const config = {
  createPermission: [CAMPAIGN_CASHBACK_CREATE],
  creative: 'cashbackCreative',
  defaultTrigger: triggerTypes.recurring,
  mediumId: cashbackMediumId,
  name: 'Cashback',
  permission: [CAMPAIGN_CASHBACK_CREATE, CAMPAIGN_CASHBACK_DRAFT],
  requiredFields: [],
  subChannels: [
    {
      icon: 'account',
      name: 'Cashback',
      type: cashbackTypes.regular,
      CustomAttributesComponent: () => <CustomAttributesComponent scope="campaign" />,
      EnrichFieldsCriteriaComponent: EnrichFieldsCriteriaContainer,
      EventsCriteriaComponent: RegularEventsCriteriaContainer,
      OverviewComponent: RegularCashbackOverviewContainer,
      PayoutStrategiesComponent: RegularCashbackCreationContainer,
      ScheduleComponent: PartialSchedule,
    },

    {
      defaultTrigger: triggerTypes.recurring,
      icon: 'map',
      name: 'Journey',
      type: cashbackTypes.journey,
      CustomAttributesComponent: JourneyAttributesComponent,
      JourneyStages,
      OverviewComponent: JourneyOverviewContainer,
      ScheduleComponent: PartialSchedule,
      UserCriteria,
    },
  ],

  triggers: [recurringTrigger],
} as IComplexChannelConfig;

export default config;
