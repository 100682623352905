import React, { PureComponent } from 'react';

import styled from 'styled-components';

import _Dropdown from 'app/midgarComponents/Dropdown';

type Props = {
  handleChange: (...args: Array<any>) => any;
  operator: string;
  supportedOperators: Array<string>;
};

export default class Operator extends PureComponent<Props> {
  render() {
    const { supportedOperators, operator, handleChange } = this.props;
    return (
      <Dropdown
        label="Operator"
        size="150"
        type="string"
        name="operator"
        value={operator}
        options={supportedOperators}
        onChange={val => handleChange({ operator: val, ruleValues: '' })}
      />
    );
  }
}

const Dropdown = styled(_Dropdown)`
  margin-left: 1rem;
`;
