import { IApiViewItemForCreative } from 'app/api/bannerManagementV2/creatives/types';
import { BmsError } from 'app/api/bannerManagementV2/types';
import { Action } from 'app/types/state';

import * as types from './types';

export type State = {
  error: BmsError | null | undefined;
  loading: boolean;
  viewItems: Array<IApiViewItemForCreative> | null | undefined;
};

const initialState = {
  error: null,
  loading: false,
  viewItems: null,
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.FETCH_ACTIVE_VIEW_ITEMS_ERROR:
      return {
        error: payload.error,
        loading: false,
        viewItems: null,
      };

    case types.FETCH_ACTIVE_VIEW_ITEMS_START:
      return {
        error: null,
        loading: true,
        viewItems: null,
      };

    case types.FETCH_ACTIVE_VIEW_ITEMS_SUCCESS:
      return {
        error: null,
        loading: false,
        viewItems: payload.viewItems,
      };

    default:
      return state;
  }
};
