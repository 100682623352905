import React from 'react';

import { connect } from 'react-redux';

import { compose } from 'recompose';

import { DrawerError } from 'app/components';
import { withPermissions } from 'app/decorators';
import { paymentCeilingsOperations } from 'app/ducks';
import { calculationTypes } from 'app/features/Cashback/constants';
import { Page, PageHeader } from 'app/midgarComponents';
import { IsPermittedFn } from 'app/utilities/permissions';
import { CASHBACK_MANAGEMENT_GLOBAL_LIMIT_CREATE } from 'configs/permissions';

import { IPaymentCeiling } from './types';
import {
  IconCancel,
  IconEdit,
  IconOk,
  PageMain,
  SimpleTable,
  SimpleTableBody,
  SimpleTableCell,
  SimpleTableHead,
  SimpleTableHeadCell,
  SimpleTableRow,
  Spinner,
  TableActionCell,
  TableInputCell,
} from '../common/EditableLimitsTable';

type Props = {
  error?: Error;
  getPaymentCeilings: () => any;
  isPermitted: IsPermittedFn;
  loading?: boolean;
  paymentCeilings: Array<IPaymentCeiling>;
  updatePaymentCeiling: (arg0: IPaymentCeiling) => any;
};

type State = {
  id: number | null | undefined;
  limit: number | null | undefined;
};

const MAX_VALID_LIMIT = 10000000000;

class Reporting extends React.PureComponent<Props, State> {
  state = {
    id: null,
    limit: null,
  };

  componentDidMount = () => {
    const { getPaymentCeilings } = this.props;
    getPaymentCeilings();
  };

  handleEditCancel = () => () => this.setState({ id: null, limit: null });

  handleEditOk = () => () => {
    if (!this.isValidLimit()) return false;
    const { paymentCeilings, updatePaymentCeiling } = this.props;
    const { id, limit } = this.state;
    const ceiling = paymentCeilings.find(c => c.id === id);
    if (ceiling) {
      updatePaymentCeiling({ ...ceiling, limit });
    }

    this.setState({ id: null, limit: null });
  };

  handleEditStart = (id: number) => () => {
    const { paymentCeilings } = this.props;
    const ceiling = paymentCeilings.find(c => c.id === id);
    const { limit = 0 } = ceiling || {};

    this.setState({
      id,
      limit,
    });
  };

  isValidLimit = () => {
    const { limit } = this.state;
    return !limit || (Number(limit) >= 1 && Number(limit) < MAX_VALID_LIMIT);
  };

  render() {
    const { error, isPermitted, loading, paymentCeilings } = this.props;
    const { id, limit } = this.state;
    const canAdd = isPermitted([CASHBACK_MANAGEMENT_GLOBAL_LIMIT_CREATE]);

    return (
      <Page>
        <PageHeader title="Global Payment Limits" />

        <PageMain>
          {error && <DrawerError error="Error during retrieval" errorDetails={error.message} defaultExpanded />}

          <SimpleTable>
            <SimpleTableHead>
              <SimpleTableRow>
                <SimpleTableHeadCell>Global Limit Name</SimpleTableHeadCell>

                <SimpleTableHeadCell>Limit Amount (%)</SimpleTableHeadCell>

                <SimpleTableHeadCell />
              </SimpleTableRow>
            </SimpleTableHead>

            {!loading && paymentCeilings && (
              <SimpleTableBody>
                {paymentCeilings
                  .filter(ceil => ceil.name !== calculationTypes.noreward)
                  .map(ceil => (
                    <SimpleTableRow key={ceil.id}>
                      <SimpleTableCell>{ceil.name}</SimpleTableCell>

                      <SimpleTableCell>
                        {ceil.id === id ? (
                          <TableInputCell
                            data-qa={`${ceil.name}-input`}
                            error={!this.isValidLimit()}
                            min={1}
                            type="number"
                            allowDecimal
                            value={limit || ''}
                            onChange={({ target: { value } }) => this.setState({ limit: value })}
                          />
                        ) : (
                          ceil.limit
                        )}
                      </SimpleTableCell>

                      <TableActionCell>
                        {canAdd &&
                          (ceil.id === id ? (
                            <>
                              <IconOk data-qa={`${ceil.name}-ok`} disabled={!this.isValidLimit()} onClick={this.handleEditOk()} />

                              <IconCancel data-qa={`${ceil.name}-cancel`} onClick={this.handleEditCancel()} />
                            </>
                          ) : (
                            <IconEdit data-qa={`${ceil.name}-edit`} onClick={this.handleEditStart(ceil.id)} title="Edit Limit" />
                          ))}
                      </TableActionCell>
                    </SimpleTableRow>
                  ))}
              </SimpleTableBody>
            )}
          </SimpleTable>

          {loading && <Spinner />}
        </PageMain>
      </Page>
    );
  }
}

export default compose(
  withPermissions,
  connect(
    ({
      cashbackPromoConfig: {
        paymentCeilings: { error, loading, paymentCeilings },
      },
    }) => ({
      error,
      loading,
      paymentCeilings,
    }),

    {
      getPaymentCeilings: paymentCeilingsOperations.getPaymentCeilings,
      updatePaymentCeiling: paymentCeilingsOperations.updatePaymentCeiling,
    },
  ),
)(Reporting);
