import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn } from 'app/types/state';
import * as fetch from 'app/utilities/http';

import * as actions from './actions';

const api = {
  getDeepLinks: '/pushconfig/deeplink',
};

const setId = () => Math.floor(Math.random() * 20000);

export const getDeepLinks = () => async (dispatch: DispatchFn) => {
  dispatch(actions.getDeepLinks());
  try {
    const deeplinks = await fetch.get(api.getDeepLinks);
    if (deeplinks && !!deeplinks.length) {
      const deeplinksWithId = deeplinks.map(x => ({ ...x, id: setId(), name: x.label }));
      dispatch(actions.getDeepLinksSuccess(deeplinksWithId));
      return deeplinksWithId;
    }
  } catch (err) {
    dispatch(actions.getDeepLinksFailed(err));
    displayError(err);
  }
};
