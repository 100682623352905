import { IStackableGroupCampaign } from 'app/features/CashbackPromoManagement/types';

import * as types from './types';
import { IFetch, fetch } from '../../../commonStates';

type State = IFetch & {
  campaigns: Array<IStackableGroupCampaign>;
  saving: boolean;
};

const initialState = {
  ...fetch,
  campaigns: [],
  saving: false,
};

export default (state: State = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case types.GET_ERROR:
      return {
        ...state,
        campaigns: [],
        error: payload.error,
        loading: false,
      };

    case types.GET_INIT:
      return {
        ...state,
        campaigns: [],
        error: null,
        loading: true,
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        campaigns: payload.campaigns,
        error: null,
        loading: false,
      };

    case types.SAVE_ERROR:
      return {
        ...state,
        error: payload.error,
        saving: false,
      };

    case types.SAVE_START:
      return {
        ...state,
        error: null,
        saving: true,
      };

    case types.SAVE_SUCCESS:
      return {
        ...state,
        error: null,
        saving: false,
      };

    case types.UPDATE:
      return {
        ...state,
        campaigns: payload.campaigns,
      };

    default:
      return state;
  }
};
