import React, { Component } from 'react';

import Calendar from 'rc-calendar';
import Picker from 'rc-calendar/lib/Picker';

import { momentFromDateStr, momentToDateStr } from 'app/utilities/date';

import TextField from '../TextField/';

export default class DateField extends Component<{
  id: string;
  name: string;
  label: string;
  value: string;
  handleSelect: (arg0: string) => any;
  className?: string;
}> {
  render() {
    const { id, name, label, value, className, handleSelect, ...rest } = this.props;

    return (
      <Picker
        animation="slide-up"
        calendar={<Calendar onSelect={m => handleSelect(momentToDateStr(m))} />}
        value={momentFromDateStr(value)}
      >
        {() => (
          <TextField
            id={id}
            name={name}
            label={label}
            value={value}
            className={className}
            onChange={() => {
              /* no-op */
            }}
            {...rest}
          />
        )}
      </Picker>
    );
  }
}
