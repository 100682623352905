import React from 'react';

import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { urlViewItemOverview } from 'app/features/BannerManagement/ViewItems/routeUtils';
import { Icon as _Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';

class Actions extends React.Component<{
  entityId?: number;
  entityType?: string;
  onBind: (arg0: number | null | undefined) => (...args: Array<any>) => any;
  onDelete: (...args: Array<any>) => any;
  onOpenPreview: (...args: Array<any>) => any;
  openMoveModal: (...args: Array<any>) => any;
  readOnly?: boolean;
  row: Record<string, any>;
  storefrontId: number;
  viewId: string | number;
}> {
  handleAction =
    (ev: Event) =>
    (cb: (...args: Array<any>) => any, ...params) => {
      ev.preventDefault();
      ev.stopPropagation();
      cb(...params);
    };

  render() {
    const { entityId, entityType, onBind, onDelete, onOpenPreview, openMoveModal, readOnly, row, storefrontId, viewId } = this.props;

    return (
      <Container>
        <Icon
          data-qa="actionAdd"
          disabled={readOnly}
          name="add-circle-outline"
          onClick={ev => !readOnly && this.handleAction(ev)(onBind, row.id)}
          size={18}
        />

        <Link to={urlViewItemOverview(row.id, Number(viewId), storefrontId, entityId, entityType)}>
          <Icon data-qa="actionEdit" name="edit" size={18} />
        </Link>

        <Icon
          data-qa="actionDelete"
          disabled={readOnly}
          name="delete"
          onClick={ev => !readOnly && this.handleAction(ev)(onDelete, row.id)}
          size={18}
        />

        <Icon
          data-qa="actionPreview"
          disabled={readOnly}
          name="camera"
          onClick={ev => this.handleAction(ev)(onOpenPreview, row)}
          size={18}
        />

        <Icon data-qa="actionMove" disabled={readOnly} name="sort" onClick={ev => this.handleAction(ev)(openMoveModal, row)} size={18} />
      </Container>
    );
  }
}

export default Actions;

const Container = styled.div`
  min-width: 120px;
`;

const Icon = styled(_Icon)`
  display: inline-block;
  color: ${sc.greyLight};
  padding: 2px;

  &:hover {
    color: ${props => (props.disabled ? '' : sc.primary)};
    cursor: pointer;
  }
`;
