import moment from 'moment';
import { isArray, isValidDate, isNotObject, isValidNumber } from 'ramda-adjunct';

import { timeZone } from '../AppEnvHelpers/helpers';
import { joinWithComma, fTrue, memoize, isOneOf, transformShape, R } from '../RamdaHelpers/helpers';

const VIRTUAL_ROOT = '_VirtualRoot_';
const metaDataVirtualRoot = [VIRTUAL_ROOT];

const FeatureMetadataKeyNames = {
  featureId: 'feature_id',
  featureFullName: 'feature_name',
  featureShortName: 'name',
  featureLabel: 'feature_label',
  featureDesc: 'feature_desc',
  featureClass: 'feature_class',
  featureValues: 'feature_value',
  vertical: 'vertical',
  category: 'category',
  dataType: 'data_type',
  subFeatureValues: 'nested_types',
};

// supported data types
const numberType = 'number';
const numberArrayType = `${numberType}s`;
const doubleType = 'double';
const stringType = 'string';
const stringArrayType = `${stringType}s`;
const dateType = 'date';
const datetimeType = 'datetime';
const timeWindowType = 'timeWindow';
const nestedType = 'nested';

const IntegerType = 'Integer';
const bankCodeStringType = 'BankCode';
const timePeriodV2Type = 'TimePeriod';
const numericRangeV2Type = 'NumericRange';
const stringV2Type = 'String';
const datetimeTypeV2 = 'DateTime';
const stringArrayTypeV2 = 'StringList';
const numberArrayTypeV2 = 'IntegerList';
const booleanType = 'Boolean';

const internalDataTypes = {
  numberType,
  numberArrayType,
  numberArrayTypeV2,
  doubleType,
  stringType,
  stringArrayType,
  stringArrayTypeV2,
  dateType,
  datetimeType,
  datetimeTypeV2,
  timePeriodV2Type,
  numericRangeV2Type,
  booleanType,
  stringV2Type,
  IntegerType,
  bankCodeStringType,
  timeWindowType,
  nestedType,
};

const isNestedType = R.equals(nestedType);

const remapDataType = R.propOr(stringType, R.PAPH, {
  Integer: numberType,
  Long: numberType,
  Double: doubleType,
  Date: dateType,
  DateTime: datetimeType,
  TimePeriod: datetimeType,
  NumericRange: numberType,
  String: stringType,
  Nested: nestedType,
  'List[String]': stringArrayType,
});

// points in time: BEFORE <- AGO <- LAST -> NOW <- NEXT -> AHEAD -> AFTER
const twBeforeOperator = 'BEFORE';
const twAgoOperator = 'AGO';
const twLastOperator = 'LAST';
const twNextOperator = 'NEXT';
const twAheadOperator = 'AHEAD';
const twAfterOperator = 'AFTER';

const containsAnyOperator = 'ContainsAny';
const inOperator = 'IN';
const notInOperator = 'NOT IN';
const existsOperator = 'EXISTS';
const existsOperatorV2 = 'exists';

const specialOperators = {
  twBeforeOperator,
  twAgoOperator,
  twLastOperator,
  twNextOperator,
  twAheadOperator,
  twAfterOperator,
  containsAnyOperator,
  inOperator,
  notInOperator,
  existsOperator,
  existsOperatorV2,
};

const timeWindowOperators = [twBeforeOperator, twAgoOperator, twLastOperator, twNextOperator, twAheadOperator, twAfterOperator];
const isTimeWindowOperator = isOneOf(timeWindowOperators);

const [isContainsAnyOperator, isInOperator, isExistsOperator, isNotInOperator, isExistsOperatorV2] = R.map(R.equals, [
  containsAnyOperator,
  inOperator,
  existsOperator,
  notInOperator,
  existsOperatorV2,
]);

const isSetRelationshipOperator = R.either(isContainsAnyOperator, isInOperator);

const valueLabelPair = ['value', 'label'];

const timeWindowComponent = expr => {
  const [, num = 0, unit = 'm'] = /^(\d+)(.*)+$/.exec(expr) || [];

  return { num, unit };
};
const minuteTimeUnit = 'minute';

// These are the same as ElasticSearch aggregation's time-match units, as documented at
// https://www.elastic.co/guide/en/elasticsearch/reference/current/common-options.html#date-math
const sortedTimeUnits = R.map(R.zipObj(valueLabelPair), [
  ['m', minuteTimeUnit],
  ['h', 'hour'],
  ['d', 'day'],
  ['w', 'week'],
  ['M', 'month'],
  ['y', 'year'],
]);

const sortedPluralizedTimeUnits = memoize(() =>
  R.map(
    R.evolve({
      label: a => `${a}(s)`,
    }),

    sortedTimeUnits,
  ),
);

const timeUnitDict = R.apply(R.zipObj, transformShape(R.map(R.pluck, valueLabelPair), sortedTimeUnits));
const timeUnitString = unit => R.propOr(minuteTimeUnit, unit, timeUnitDict);

const standardComparableOps = ['=', '>', '<', '>=', '<='];
const convertToNumber = value => Number(value);
const displayNumber = number => `${convertToNumber(number).toLocaleString()}`;

const operatorDepDataHandlerFactory = (caseHandlers, defaultHandler) => R.cond([...caseHandlers, [fTrue, defaultHandler]]);
const opDepWithExistsOpFactory = (pred, handlerA, handlerB) =>
  operatorDepDataHandlerFactory(
    [
      [pred, () => handlerA],
      [isExistsOperator, () => fTrue],
    ],
    () => handlerB,
  );

const opDependentDataHandlerFactory = (pred, handlerA, handlerB) => operatorDepDataHandlerFactory([[pred, () => handlerA]], () => handlerB);

const numberTypeOpsAndHandlers = {
  operators: [...standardComparableOps, inOperator, existsOperator],
  validator: opDepWithExistsOpFactory(isSetRelationshipOperator, isArray, (value: string) => isValidNumber(convertToNumber(value))),
  stringifier: opDependentDataHandlerFactory(isSetRelationshipOperator, joinWithComma, displayNumber),
};

const booleanTypeOpsAndHandlers = {
  operators: [...standardComparableOps, inOperator, existsOperator],
  validator: opDepWithExistsOpFactory(isSetRelationshipOperator, isArray, (value: string) => ['false', 'true'].includes(value)),
  stringifier: opDependentDataHandlerFactory(isSetRelationshipOperator, joinWithComma, displayNumber),
};

const stringTypeOpsAndHandlers = {
  operators: ['=', inOperator, existsOperator],
  validator: opDepWithExistsOpFactory(isSetRelationshipOperator, isArray, fTrue),
  stringifier: opDependentDataHandlerFactory(isSetRelationshipOperator, joinWithComma, R.identity),
};

const stringTypeOpsAndHandlersV2 = {
  operators: ['=', inOperator, existsOperator, existsOperatorV2],
  validator: opDepWithExistsOpFactory(isExistsOperatorV2, fTrue, () => true),
  stringifier: opDependentDataHandlerFactory(isSetRelationshipOperator, joinWithComma, R.identity),
};

const isValidDateObj = value => isValidDate(new Date(value.startOfPeriod)) && isValidDate(new Date(value.endOfPeriod));
const isValidNumberObj = value => isValidNumber(parseInt(value.startOfRange)) && isValidNumber(parseInt(value.endOfRange));

const dateTypeOpsAndHandlers = (dtFormat = 'MM/DD/YYYY HH:mm') => ({
  operators: [...standardComparableOps, ...timeWindowOperators, existsOperator],
  validator: opDepWithExistsOpFactory(isTimeWindowOperator, fTrue, value =>
    isNotObject(value) ? isValidDate(new Date(value)) : isValidDateObj(value),
  ),

  stringifier: opDependentDataHandlerFactory(
    isTimeWindowOperator,
    value => {
      const { num, unit } = timeWindowComponent(value);
      return `${num} ${timeUnitString(unit)}${num > 1 ? 's' : ''}`;
    },
    value => moment.unix(value).tz(timeZone()).format(dtFormat),
  ),
});

const dateTypeOpsAndHandlersV2 = (dtFormat = 'MM/DD/YYYY HH:mm') => ({
  ...dateTypeOpsAndHandlers,
  validator: opDepWithExistsOpFactory(isTimeWindowOperator, fTrue, value =>
    isNotObject(value) ? isValidDate(new Date(value)) : isValidDateObj(value),
  ),

  stringifier: opDependentDataHandlerFactory(
    isTimeWindowOperator,
    value => {
      const { num, unit } = timeWindowComponent(value);
      return `${num} ${timeUnitString(unit)}${num > 1 ? 's' : ''}`;
    },
    value =>
      `Start - ${moment.unix(value.startOfPeriod).tz(timeZone()).format(dtFormat)}, End -${moment
        .unix(value.endOfPeriod)
        .tz(timeZone())
        .format(dtFormat)}`,
  ),
});

const numericRangeTypeOpsAndHandlers = {
  operators: [...standardComparableOps, ...timeWindowOperators, existsOperator],
  validator: opDepWithExistsOpFactory(isSetRelationshipOperator, fTrue, value =>
    isNotObject(value) ? isValidNumber(value) : isValidNumberObj(value),
  ),

  stringifier: opDependentDataHandlerFactory(
    isSetRelationshipOperator,
    value => value,
    value => `Start - ${value.startOfRange}, End -${value.endOfRange}`,
  ),
};

const DataTypeAndOps = {
  [numberType]: numberTypeOpsAndHandlers,
  [IntegerType]: numberTypeOpsAndHandlers,
  [booleanType]: booleanTypeOpsAndHandlers,
  [doubleType]: {
    operators: [...standardComparableOps, existsOperator],
    validator: opDependentDataHandlerFactory(isExistsOperator, fTrue, value => isValidNumber(convertToNumber(value))),
    stringifier: R.always(displayNumber),
  },

  [dateType]: dateTypeOpsAndHandlers('MM/DD/YYYY'),
  [datetimeType]: dateTypeOpsAndHandlers(),
  [datetimeTypeV2]: dateTypeOpsAndHandlers(),
  [timePeriodV2Type]: dateTypeOpsAndHandlersV2('YYYY/MM/DD HH:mm:ss'),
  [numericRangeV2Type]: numericRangeTypeOpsAndHandlers,
  [stringV2Type]: stringTypeOpsAndHandlersV2,
  [stringType]: stringTypeOpsAndHandlers,
  [bankCodeStringType]: stringTypeOpsAndHandlers,
  [stringArrayType]: {
    operators: [containsAnyOperator, existsOperator],
    validator: opDependentDataHandlerFactory(isExistsOperator, fTrue, R.always(isArray)),
    stringifier: R.always(joinWithComma),
  },

  [stringArrayTypeV2]: {
    operators: [...standardComparableOps, containsAnyOperator, existsOperator],
    validator: opDependentDataHandlerFactory(isExistsOperator, fTrue, R.always(isArray)),
    stringifier: R.always(joinWithComma),
  },

  [numberArrayType]: {
    operators: [containsAnyOperator, existsOperator],
    validator: opDependentDataHandlerFactory(isExistsOperator, fTrue, R.always(isArray)),
    stringifier: R.always(joinWithComma),
  },

  [numberArrayTypeV2]: {
    operators: [...standardComparableOps, containsAnyOperator, existsOperator],
    validator: opDependentDataHandlerFactory(isExistsOperator, fTrue, value => value.filter(val => isNaN(Number(val))).length === 0),
    stringifier: R.always(joinWithComma),
  },
};

const sortAsc = R.sort(R.ascend(R.identity));

// Lenses, getters, setters
const dictNames = ['optionDict', 'pathDict', 'dataTypeDict', 'originDict'];
const [optionDictLens, pathDictLens, dataTypeDictLens, originDictLens] = R.map(R.lensProp, dictNames);

const readAndRemapDataType = R.pipe(R.prop(FeatureMetadataKeyNames.dataType), remapDataType);

export {
  VIRTUAL_ROOT,
  metaDataVirtualRoot,
  timeWindowComponent,
  sortedPluralizedTimeUnits,
  internalDataTypes,
  specialOperators,
  timeWindowOperators,
  isTimeWindowOperator,
  isSetRelationshipOperator,
  isInOperator,
  isNotInOperator,
  isExistsOperator,
  isExistsOperatorV2,
  isNestedType,
  FeatureMetadataKeyNames,
  remapDataType,
  DataTypeAndOps,
  readAndRemapDataType,
  dictNames,
  optionDictLens,
  pathDictLens,
  dataTypeDictLens,
  originDictLens,
  sortAsc,
  standardComparableOps,
  dateType,
  datetimeType,
  datetimeTypeV2,
};
