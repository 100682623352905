import { fetchAllCashbackBanners, updateCashbackBannerPriorties } from 'app/api/cashbackPromo';
import { cashbackTypes } from 'app/features/Cashback/constants';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn } from 'app/types/state';

import * as actions from './actions';

const getBannersWithTypes = async (cashbackType: string, query: Record<string, string>) => {
  const allBanners = (await fetchAllCashbackBanners(query)) || [];
  const bannersWithTypes = allBanners
    .filter(banner =>
      cashbackType === 'all' ? true : cashbackType === cashbackTypes.regular ? !!banner.userBudgetToken : !banner.userBudgetToken,
    )
    .map(banner => ({
      ...banner,
      cashbackType: banner.userBudgetToken ? cashbackTypes.regular : cashbackTypes.journey,
    }));

  return bannersWithTypes;
};

export const getRegularCashbackBanners = (query: Record<string, string>) => async (dispatch: DispatchFn) => {
  try {
    dispatch(actions.getInit());
    const cashbackBannersWithTypes = await getBannersWithTypes(cashbackTypes.regular, query);
    return dispatch(actions.getSuccess(cashbackBannersWithTypes));
  } catch (error) {
    displayError(error);
    return dispatch(actions.getError(error));
  }
};

export const getAllCashbackBanners = (isJourneyEnabled: boolean) => (query: Record<string, string>) => async (dispatch: DispatchFn) => {
  try {
    dispatch(actions.getInit());
    const cashbackBannersWithTypes = await getBannersWithTypes(cashbackTypes.regular, query);
    const journeyBannersWithTypes = isJourneyEnabled ? await getBannersWithTypes(cashbackTypes.journey, query) : [];
    return dispatch(actions.getSuccess([...cashbackBannersWithTypes, ...journeyBannersWithTypes]));
  } catch (error) {
    displayError(error);
    return dispatch(actions.getError(error));
  }
};

export const getJourneyBanners = (query: Record<string, string>) => async (dispatch: DispatchFn) => {
  try {
    dispatch(actions.getInit());
    const journeyBannersWithTypes = await getBannersWithTypes(cashbackTypes.journey, query);
    return dispatch(actions.getSuccess(journeyBannersWithTypes));
  } catch (error) {
    displayError(error);
    return dispatch(actions.getError(error));
  }
};

// this priority update only happens for regular cashback now
export const updateCashbackBannersPriorties = (priortiesMapping: Array<{ id: number; priority: number }>) => async () => {
  try {
    await updateCashbackBannerPriorties(priortiesMapping);
    displaySuccess('Cashback Priorties were successfully saved.');
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    displayError('Failed to save the priorties. Please try again.');
  }
};
