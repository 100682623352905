import { Action } from 'app/types/state';

import * as types from './types';

export const updateApiField = (field: {}): Action => ({
  type: types.UPDATE_API_FIELD,
  payload: { field },
});

export const updateApiType = (apiType: {}): Action => ({
  type: types.UPDATE_API_TYPE,
  payload: { apiType },
});

export const updateFacebookValues = (values: Array<string>): Action => ({
  type: types.UPDATE_FACEBOOK_VALUES,
  payload: { values },
});
