import { connect } from 'react-redux';

import { compose } from 'recompose';

import withPermissions from 'app/decorators/withPermissions';

const mapStateToProps = ({ user }) => ({ user });
export const userConnect = connect(mapStateToProps);
const enhance = compose(userConnect, withPermissions);

export const ConnectedUser = enhance(({ children, ...props }) => <>{children(props)}</>);
