import getCookie from 'app/utilities/getCookie';
import { PAYPAY_CONSUMER } from 'configs/apps/tenants/PAYPAY_CONSUMER';
import { tzJapan } from 'configs/dateTime';
import { getSelectedTenant } from 'configs/user';

const userName = () => getCookie('mapUserName');

const activeTenant = () => getSelectedTenant;

const timezones: Record<string, string> = {
  [PAYPAY_CONSUMER]: tzJapan,
};

const timeZone = () => timezones[activeTenant()()] || tzJapan;

export { userName, timeZone };
