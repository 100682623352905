import { IApiTemplate } from 'app/api/templates/types';
import { ITemplate } from 'app/types/Template';

const filterNulls = (obj: any): any =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null) acc[key] = obj[key];
    return acc;
  }, {});

export const fromApi = (apiTemplate: ITemplate): ITemplate => filterNulls(apiTemplate);

export const toApi = (reduxTemplate: ITemplate): IApiTemplate => ({
  content: reduxTemplate.content,
  description: reduxTemplate.description,
  id: reduxTemplate.id,
  mediumId: reduxTemplate.medium?.id || Number.MIN_SAFE_INTEGER,
  name: reduxTemplate.name,
  replyTo: reduxTemplate.replyTo,
  replyToName: reduxTemplate.replyToName,
  sendFrom: reduxTemplate.sendFrom,
  sendFromName: reduxTemplate.sendFromName,
  subject: reduxTemplate.subject,
  tagIds: reduxTemplate.tags.map(tag => tag.id || Number.MIN_SAFE_INTEGER),
});
