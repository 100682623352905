import { fetchCreative as apiFetchCreative, saveNewCreative, updateCreative } from 'app/api/bannerManagementV2/creatives';
import { fromApiCreative, toApiCreative } from 'app/api/bannerManagementV2/creatives/mappers';
import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { uploadLocationFile } from 'app/api/bannerManagementV2/locations';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { IBannerCreative } from 'app/types/BannerManagement';
import { DispatchFn, GetStateFn } from 'app/types/state';
import api from 'app/utilities/api';

import * as actions from './actions';

export const cloneCreative = (id: number | string) => async (dispatch: DispatchFn) => {
  dispatch(actions.populateCreativeFormStart());

  try {
    const creative = await apiFetchCreative(Number(id));
    return dispatch(actions.populateCreativeFormSuccess(creative));
  } catch (err) {
    dispatch(actions.populateCreativeFormError());
    return displayError(err.error && err.error.msg);
  }
};

export const fetchCreative = (id: number | string) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  dispatch(actions.fetchCreative());

  try {
    const creative: IApiBannerCreative = await apiFetchCreative(Number(id));
    const {
      bannerManagement: {
        engageCategories: { engageCategories },
      },
    } = getState();
    return dispatch(actions.fetchCreativeSuccess(fromApiCreative(engageCategories, creative)));
  } catch (err) {
    displayError(err.error && err.error.msg);
    return dispatch(actions.fetchCreativeError(err));
  }
};

export const saveCreative = (redirect?: (...args: Array<any>) => any) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      creative: { form: srcCreative },
    },

    user,
  } = getState();
  dispatch(actions.saveCreativeStart());

  let creative;
  try {
    creative = await uploadDependentFiles(srcCreative);
  } catch (e) {
    displayError(e.error && e.error.msg);
    return dispatch(actions.saveCreativeError(e));
  }

  creative = {
    ...toApiCreative(creative),
    createdBy: user.email,
  };

  try {
    const res = srcCreative.id ? await updateCreative(creative) : await saveNewCreative(creative);

    displaySuccess('Creative saved successfully');
    dispatch(actions.saveCreativeSuccess(res));

    if (typeof redirect === 'function') {
      return redirect();
    }

    return null;
  } catch (err) {
    displayError(err.error && err.error.msg);
    return dispatch(actions.saveCreativeError(err));
  }
};

// TODO: (Experimental) Used by Banner Spawn
export const saveCreativeFromBlob =
  (product: Record<string, any>, cb?: (...args: Array<any>) => any) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    const { user } = getState();
    dispatch(actions.saveCreativeStart());

    try {
      const body = {
        widgetType: 'smart-icon-list',
        createdBy: user.email,
        name: product.name || 'Name',
        platform: ['WEB', 'HTML5', 'ANDROID', 'IOS', 'WINDOWS'], // TODO: Get from selected widgetType (above)
        site: ['PAYTMMALL'], // TODO: Get from selected widgetType (above)
        variants: [
          {
            name: 'v1',
            landingPageType: 'list_grid',
            itemId: Number(product.gridId),
            image: product.imageUrl || '',
            appVersionFilter: [{}],
            categoryWeights2: [
              {
                categoryId: Number(product.categoryId),
                weight: 100,
              },
            ],

            locationFilter: null,
            labelDetails: {},
            trackingOnly: false,
          },
        ],
      };

      const res = await api.request('POST', '/ec/creative', { body });

      displaySuccess('Creative saved successfully');
      dispatch(actions.saveCreativeSuccess(res));
      cb && cb(res);

      return null;
    } catch (err) {
      displayError(err.error && err.error.msg);
      cb && cb({ error: err.error || {} });

      return dispatch(actions.saveCreativeError(err));
    }
  };

const uploadDependentFiles = async (creative: IBannerCreative) => {
  const variantKeys = Object.keys(creative?.variants || {});

  const { geoPromises } = variantKeys.reduce(
    (acc, variantKey) => {
      const variant = creative.variants[variantKey];

      if (variant.geoFile) {
        const uploadPromise = uploadLocationFile(variant.geoFile).then(({ id }) => ({ id, variantKey }));
        acc.geoPromises.push(uploadPromise);
      }
      return acc;
    },
    { geoPromises: [] },
  );

  const geoResults = await Promise.all(geoPromises);
  const res = Object.assign({}, creative);
  geoResults.forEach(({ id, variantKey }) => {
    if (id) res.variants[variantKey].latLongId = id;
  });
  return res;
};
