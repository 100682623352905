import { connect } from 'react-redux';

import { path } from 'ramda';

import * as operations from './operations';

const mapStateToProps = path(['campaigns', 'campaign', 'apiCreative']);

const apiConnect = connect(mapStateToProps, operations);

export const ConnectedApi = apiConnect(({ children, ...props }) => children && <>{children(props)}</>);
