import { ICategory } from 'app/types/Category';
import { Action } from 'app/types/state';

import { IFetch, baseFetch } from '../utils';
import * as types from './types';

export type State = IFetch & {
  engageCategories: Array<ICategory>;
};

const initialState: State = {
  ...baseFetch,
  engageCategories: [],
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ENGAGE_CATEGORIES_FAILED:
      return {
        ...state,
        error: payload.error,
        loading: false,
        engageCategories: [],
      };

    case types.GET_ENGAGE_CATEGORIES_START:
      return {
        ...state,
        error: null,
        loading: true,
        engageCategories: [],
      };

    case types.GET_ENGAGE_CATEGORIES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        engageCategories: payload.engageCategories,
      };

    default:
      return state;
  }
};
