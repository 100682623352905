import { IPaging } from 'app/hocs/withReporting';
import { IStorefront } from 'app/types/BannerManagement';
import { Action } from 'app/types/state';

import * as types from './types';
import { IFetch, fetch } from '../../commonStates';

export type State = IFetch & {
  paging: IPaging;
  storefronts: Array<IStorefront>;
  storefrontType?: string | null | undefined;
};

const initialState: State = {
  ...fetch,
  paging: {},
  storefronts: [],
  storefrontType: null,
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_STOREFRONTS_FAILED:
      return {
        ...state,
        error: payload.error,
        loading: false,
        storefronts: [],
      };

    case types.GET_STOREFRONTS_START:
      return {
        ...state,
        error: null,
        loading: true,
        storefronts: [],
      };

    case types.GET_STOREFRONTS_SUCCESS:
      return {
        error: null,
        loading: false,
        storefronts: payload.storefronts,
        paging: payload.paging,
      };

    case types.SET_STOREFRONT_TYPE:
      return {
        ...state,
        storefrontType: payload.storefrontType,
      };

    default:
      return state;
  }
};
