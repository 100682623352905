import bmsVersions from 'app/api/bmsCommon/bmsVersions';
import { findChannelSetup } from 'app/ducks/configStore/utils';
import { IAppConfig, IBannerCreativeConfig } from 'configs/apps/types';
import { channelNameBanner } from 'configs/channels/constants';

export const LEVEL_CREATIVE = 'creative' as IBannerCreativeConfig['widgetType']['level'];
export const LEVEL_VARIANT = 'variant' as IBannerCreativeConfig['widgetType']['level'];

const defaultConfig = {
  bmsVersion: bmsVersions.DEFAULT,
  widgetType: {
    level: LEVEL_CREATIVE,
  },
};

export const getBannerCreativeConfig = (appConfig: IAppConfig): IBannerCreativeConfig => {
  const channelSetup = findChannelSetup(appConfig, channelNameBanner);
  if (!channelSetup) {
    return defaultConfig;
  }

  return {
    ...defaultConfig,
    ...(channelSetup.creative || {}),
  };
};
