import React from 'react';

import { mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';

import SubFeatureRuleRow from '../../components/SubFeatureRuleRow';

const toRuleRow = R.curry(({ parentFeature, handleChange, classes }, rowItems, index) => (
  <SubFeatureRuleRow
    {...{
      index,
      classes,
      key: `sub-feature-row-${parentFeature}-${index}`,
      items: rowItems,
      onChange: change => handleChange(change, index),
    }}
  />
));

interface SubFeatureRuleGroupProps {
  parentFeature: string;
  // todo: specify shape here
  data: unknown[][];
  handleChange(...args: unknown[]): unknown;
  classes: {};
}

export const SubFeatureRuleGroup = ({ data, parentFeature, handleChange, classes }: SubFeatureRuleGroupProps) => (
  <div
    {...{
      classes: classes.container,
      style: {
        width: '100%',
        // todo: apply level and move this out
        marginLeft: '120px',
      },
    }}
  >
    {mapIndexed(toRuleRow({ parentFeature, classes, handleChange }), data)}
  </div>
);
