import { ICampaignAttributeValue } from 'app/features/CashbackPromoManagement/types';
import { ISchemaFieldWithValue } from 'app/types/Schema';

// TODO: We need converters for Paypay campaign attributes until a generic TypedAttributes component is created

export const asAttribute = (field: ISchemaFieldWithValue): ICampaignAttributeValue => ({
  customCampaignFieldType: {
    id: 1, // TODO: Required due to cashback-specific code
    name: field.fieldType,
  },

  defaultValue: field.defaultValue,
  fieldName: field.fieldName,
  fieldTitle: field.fieldLabel,
  id: field.fieldId,
  medium: 'cashback', // TODO: Hard-coded due to cashback-specific code
  optional: !field.isFieldRequired,
  // placeholder?: string,
  possibleValues: field.possibleFieldValues.join(','), // ?: string, // CSV
  scope: 'banner', // TODO: Hard-coded due to cashback-specific code
  value: field.value,
});

export const asField = (attr: ICampaignAttributeValue): ISchemaFieldWithValue => ({
  defaultValue: attr.defaultValue,
  fieldId: attr.id,
  fieldName: attr.fieldName,
  fieldTitle: attr.fieldTitle,
  fieldType: attr.customCampaignFieldType.name,
  fieldValues: attr.possibleValues ? attr.possibleValues.split(',').map(fieldValue => ({ fieldValue })) : [],
  isFieldRequired: !attr.optional,
  value: attr.value,
});
