import React from 'react';

import styled from 'styled-components';

import ProgressBar from 'app/midgarComponents/ProgressBar';
import {
  SimpleTable as Table,
  SimpleTableBody as TableBody,
  SimpleTableCell,
  SimpleTableHead as TableHead,
  SimpleTableRow as TableRow,
  SimpleTableHeadCell,
} from 'app/midgarComponents/SimpleTable';
import { sc } from 'app/styles';
import result from 'app/utilities/result';

import { IUploadResult } from '../types';

const renderName = uploadResult => {
  if (uploadResult.loading) return <ProgressBar />;
  return uploadResult.file.name;
};

const renderURL = uploadResult => {
  if (uploadResult.loading) return `Uploading ${uploadResult.file.name}`;
  if (uploadResult.error) return <Error>{uploadResult.error}</Error>;
  return uploadResult.url;
};

type Props = {
  uploadResults: Array<IUploadResult>;
};

export const UploadResultsTable = ({ uploadResults }: Props) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <FileHeader>File</FileHeader>
          <UrlHeader>URL</UrlHeader>
        </TableRow>
      </TableHead>

      <TableBody>
        {uploadResults.map(uploadResult => (
          <TableRow key={result('file.name', uploadResult)}>
            <FileCell>{renderName(uploadResult)}</FileCell>
            <UrlCell>{renderURL(uploadResult)}</UrlCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const FileCell = styled(SimpleTableCell)`
  font-size: ${sc.fontSizeSmaller};
  line-height: 1.33;
  color: ${sc.bodyColor};
`;

const UrlCell = styled(FileCell)`
  color: ${sc.headingColor};
`;

const UrlHeader = styled(SimpleTableHeadCell)`
  background-color: ${sc.sectionBackgroundColor};
  width: 80%;
  font-size: ${sc.fontSizeSmaller};
  color: ${sc.subHeadingColor};
`;

const FileHeader = styled(UrlHeader)`
  width: 20%;
`;

const Error = styled.section`
  color: ${sc.danger};
`;

const TableContainer = styled.section`
  max-height: 180px;
  overflow-y: auto;
`;
