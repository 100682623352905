import React, { useEffect, useRef } from 'react';

import { MenuList, MenuItem, ListItem, ListItemIcon, ListItemText, TextField, Grid } from '@material-ui/core';
import { Search as SearchIcon, Check as SelectionIcon, NotInterested as NotFoundIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Paper from 'app/midgarComponents/Paper';

import Tooltip from '../Tooltip';

const toMenuItem = (selected, groupIndex, classes, equals, idOf, nameOf, descOf, handleSelect, option, ref) => {
  const isSelected = equals(selected, option);
  const { selectedItemStyle, nonSelectedItemStyle } = classes;
  const key = idOf(option);

  return (
    <Tooltip key={key} title={descOf(option)} placement="left">
      <MenuItem
        selected={isSelected}
        data-qa={key}
        onClick={() => handleSelect(groupIndex, option)}
        ref={ref}
        className={isSelected ? selectedItemStyle : nonSelectedItemStyle}
        style={{
          whiteSpace: 'normal',
          wordBreak: 'break-all',
          height: 'fit-content',
          paddingRight: 0,
        }}
      >
        {isSelected && (
          <ListItemIcon
            style={{
              margin: 0,
              minWidth: 0,
            }}
          >
            <SelectionIcon />
          </ListItemIcon>
        )}

        <ListItemText
          disableTypography
          primary={nameOf(option)}
          style={{
            padding: isSelected ? '0 8px' : '0 16px 0 32px',
          }}
        />
      </MenuItem>
    </Tooltip>
  );
};

interface FilterMenuProps {
  id: string;
  options: unknown[];
  label: string;
  selected?: unknown;
  groupIndex: number;
  equals(...args: unknown[]): unknown;
  idOf(...args: unknown[]): unknown;
  nameOf(...args: unknown[]): unknown;
  descOf(...args: unknown[]): unknown;
  handleSelect(...args: unknown[]): unknown;
  handleFilter(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const FilterMenu = ({
  id,
  label,
  options,
  selected = '',
  groupIndex,
  equals,
  idOf,
  nameOf,
  descOf,
  handleSelect,
  handleFilter,
  classes,
}: FilterMenuProps) => {
  const itemRefs = useRef([options]);

  useEffect(() => {
    const selectedIndex = options.findIndex(option => equals(selected, option));
    if (selectedIndex !== -1 && itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex].focus();
    }
  }, [selected, options, equals]);

  return (
    <Grid item xs={3}>
      <Tooltip title="">
        <div
          className={classes.actions}
          style={{
            alignItems: 'flex-end',
            display: 'flex',
          }}
        >
          <SearchIcon
            style={{
              marginBottom: '0.5rem',
            }}
          />

          <TextField
            margin="dense"
            type="search"
            placeholder="Search..."
            id={id}
            label={label}
            onChange={handleFilter}
            style={{
              flexGrow: 1,
              marginTop: 0,
            }}
            className={classes.textField}
          />
        </div>
      </Tooltip>

      <Paper className={classes.root}>
        {options.length < 1 && (
          <ListItem disabled key="not-found">
            <ListItemIcon>
              <NotFoundIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${label} not found`}
              secondary="Clear search to see all"
              style={{
                padding: 0,
              }}
            />
          </ListItem>
        )}

        {options.length > 0 && (
          <MenuList dense disablePadding className={classes.menuList}>
            {options.map((option, index) => {
              const ref = el => {
                itemRefs.current[index] = el;
              };

              return toMenuItem(selected, groupIndex, classes, equals, idOf, nameOf, descOf, handleSelect, option, ref);
            })}
          </MenuList>
        )}
      </Paper>
    </Grid>
  );
};
