import { connect } from 'react-redux';

import { equals } from 'ramda';
import { compose, withHandlers, withProps, withState } from 'recompose';

import { IPaging, IPagingSelectorFunction } from './types';

const FIRST_PAGE_INDEX = 0;
const DEF_ROWS_PER_PAGE = 25;

const addState = withState(
  'prevOtherQueryParams',
  'setPrevOtherQueryParams',
  ({ pageIndex, rowsPerPage, ...prevOtherQueryParams }) => prevOtherQueryParams,
);

/**
 * Converts paging query parameters (`pageIndex` and `rowsPerPage`) to numbers.
 *
 * If either value is not a valid number, sets a default.
 */
const handlePagingParams = withProps(({ prevOtherQueryParams, pushQuery, queryParams, setPrevOtherQueryParams }) => {
  const { pageIndex: pageIndexStr, rowsPerPage: rowsPerPageStr, ...otherQueryParams } = queryParams;
  const rowsPerPage = rowsPerPageStr && !Number.isNaN(rowsPerPageStr) ? Number(rowsPerPageStr) : DEF_ROWS_PER_PAGE;

  // TODO: prevOtherQueryParams contains all the properties, so it has been set incorrectly at first. This may causes double fetching the first time
  let pageIndex;
  if (equals(prevOtherQueryParams, otherQueryParams)) {
    pageIndex = pageIndexStr && !Number.isNaN(pageIndexStr) ? Number(pageIndexStr) : FIRST_PAGE_INDEX;
  } else {
    pageIndex = FIRST_PAGE_INDEX;
    setPrevOtherQueryParams(otherQueryParams);
  }

  const newQueryParams = {
    ...otherQueryParams,
    pageIndex,
    rowsPerPage,
  };

  if (Number(pageIndexStr) !== pageIndex) {
    pushQuery(newQueryParams);
  }
  return { queryParams: newQueryParams };
});

/**
 * Connects to the Redux store to get the paging results from the latest retrieval.
 *
 * @param {IPagingSelectorFunction} pagingSelector
 */
const mapStateToProps =
  (pagingSelector: IPagingSelectorFunction) =>
  (state, { queryParams }): { paging: IPaging } => {
    const { totalElements: totalRows, first, last } = pagingSelector(state);
    const { pageIndex, rowsPerPage } = queryParams;
    return {
      paging: {
        first,
        last,
        pageIndex: Number(pageIndex),
        rowsPerPage: Number(rowsPerPage),
        totalRows,
      },
    };
  };

/**
 * Handlers for going to specific pages.
 */
const addPagingHandlers = withHandlers({
  goToFirstPage:
    ({ pushQuery, queryParams }) =>
    () =>
      pushQuery({
        ...queryParams,
        pageIndex: FIRST_PAGE_INDEX,
      }),

  goToNextPage:
    ({ pushQuery, queryParams }) =>
    () =>
      pushQuery({
        ...queryParams,
        pageIndex: Number(queryParams.pageIndex) + 1,
      }),

  goToPage:
    ({ pushQuery, queryParams }) =>
    (pageIndex: number) =>
      pushQuery({
        ...queryParams,
        pageIndex,
      }),

  goToPrevPage:
    ({ pushQuery, queryParams }) =>
    () =>
      pushQuery({
        ...queryParams,
        pageIndex: Math.max(Number(queryParams.pageIndex) - 1, 0),
      }),
});

/**
 * Maps the paging query parameter names to the names required by the API.
 */
const handleApiQueryParams = withProps(({ apiQueryParams: { pageIndex, rowsPerPage, ...rest } }) => {
  let pagingApiParams = {};
  const page = Number(pageIndex);
  if (!Number.isNaN(page)) pagingApiParams = { ...pagingApiParams, page };
  const size = Number(rowsPerPage);
  if (!Number.isNaN(size)) pagingApiParams = { ...pagingApiParams, size };
  return {
    apiQueryParams: {
      ...rest,
      ...pagingApiParams,
    },
  };
});

/**
 * Paging behaviour.
 *
 * Requires that the application store contains the total number of rows.
 * If not present in the store, the behaviour can't be guaranteed at this time.
 *
 * Injects the following properties:
 *
 *  - `goToFirstPage: () => void`
 *  - `goToNextPage: () => void`
 *  - `goToPage: number => mixed`
 *  - `goToPrevPage: () => void`
 *  - `paging: IPaging`
 *
 * Note that the above functions trigger an automatic reload.
 *
 * @param {PagingSelectorFunction} Function which extracts the current paging state from the application store.
 */
export default (pagingSelector: IPagingSelectorFunction) =>
  compose(addState, handlePagingParams, connect(mapStateToProps(pagingSelector)), addPagingHandlers, handleApiQueryParams);

export * from './types';
