import React from 'react';

import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { isNestedType, sortAsc } from 'app/helpers/FeatureMetadataHelpers/common';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { isNeitherNilNorEmpty, isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

import RuleSelect from '../../components/RuleSelect';
import RuleValueEdit from '../../components/RuleValueEdit';
import { StandaloneBreadcrumbLabel } from '../BreadcrumbButton';

const newId = R.curry((index, prefix) => `${prefix}-${index}`);

const isOneAndOnlyChoice = (selected, options) => isNeitherNilNorEmpty(selected) && (options || []).length < 2;

const emptyPromise = Promise.resolve([]);

const updateHandler = R.curry((handleChange, updater, oldState, isUserInput, change) =>
  handleChange(isUserInput, updater(change, oldState)),
);

interface toRuleItemProps {
  label: string;
  index: number;
  temporalPrefix: string;
  updater(...args: unknown[]): unknown;
  validator(...args: unknown[]): unknown;
  stringifier(...args: unknown[]): unknown;
  options?: string[];
  selected?: string;
  dataType?: string;
  handleChange(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

const toRuleItem = (
  context: toRuleItemProps,
  {
    label,
    name,
    index,
    temporalPrefix,
    updater,
    validator,
    stringifier,
    options,
    selected,
    dataType,
    handleChange,
    items: oldState,
    classes,
    expressionsDict,
  },

  selectedFeature,
) => {
  const displayName = expressionsDict[name]?.displayName;
  const updateOnChange = updateHandler(handleChange, updater, oldState);
  const getId = newId(index);
  const temporalId = getId(`${label}-sub-feature-${name}-${temporalPrefix}`);

  const componentWithContext = a => [{ selected }, a];
  const componentWithContextAndSubFeatureName = Component =>
    componentWithContext(
      <React.Fragment key={`${getId('sub-feature-row')}`}>
        <StandaloneBreadcrumbLabel content={displayName || name} />
        <Component />
      </React.Fragment>,
    );

  // TODO: improve all these condition detections here - right now they are not very robust.
  if (isNilOrEmpty(dataType)) {
    if (isOneAndOnlyChoice(selected, options)) {
      const stableId = getId(`${label}-sub-feature-${name}`);

      return componentWithContextAndSubFeatureName(() => (
        <TextField
          readOnly
          {...{
            label,
            context,
            margin: 'dense',
            className: classes.textField,
            key: stableId,
            id: stableId,
            value: selected,
            style: { maxWidth: 120 },
          }}
        />
      ));
    }

    return componentWithContextAndSubFeatureName(() => (
      <RuleSelect
        {...{
          label,
          classes,
          options,
          context,
          key: temporalId,
          id: temporalId,
          onChange: updateOnChange(false),
          ...(isNilOrEmpty(selected) ? {} : { selected }),
        }}
      />
    ));
  }

  const stableId = getId(`${label}-sub-feature-${name}`);

  return componentWithContext(
    <RuleValueEdit
      {...{
        promise: isNestedType(dataType) ? emptyPromise : R.propOr(emptyPromise, 'promise', options),
        onSuccess: values => ({
          options: sortAsc(values),
        }),

        onFailure: error => {
          // todo: should handle the case when ajax fails and we display too many error messages (for each row/sub-row)
          displayError(`Error retrieving possible values: ${error.message}`);
          R.propOr(
            () => {
              /* no-op */
            },
            'retry',
            options,
          )();

          return { options: undefined };
        },
        label,
        dataType,
        validator,
        stringifier,
        context,
        classes,
        key: stableId,
        id: stableId,
        value: selected,
        onChange: updateOnChange(true),
      }}
    />,
  );
};

const addRowItems = (selectedFeature, index, items, handleChange, classes, expressionsDict) => {
  const extra = {
    index,
    temporalPrefix: `${Date.now()}`,
    handleChange,
    classes,
    items,
    expressionsDict,
  };

  const mapper = (context, props) =>
    toRuleItem(
      context,
      {
        ...props,
        ...extra,
      },

      selectedFeature,
    );

  return R.last(R.mapAccum(mapper, {}, items));
};

const parentFeatureFrom = (items: { key: any }[]) => items[0].key;

interface SubFeatureRuleRowProps {
  index: number;
  items: object[];
  handleChange(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

const SubFeatureRuleRow = ({ items, handleChange, classes, index, expressionsDict }: SubFeatureRuleRowProps) => (
  <div
    {...{
      key: `rule-row-${index}`,
      className: classes.container,
    }}
  >
    {addRowItems(parentFeatureFrom(items), index, items, handleChange, classes, expressionsDict)}
  </div>
);

const mapStateToProps = state => ({
  expressionsDict: state.audience.audienceRules?.data.dicts.expressionsDict,
});

const ConnectedSubFeatureRuleRow = connect(mapStateToProps)(SubFeatureRuleRow);

// Named export to keep it consistent
export { ConnectedSubFeatureRuleRow as SubFeatureRuleRow };
