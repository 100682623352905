import { generateStageId } from 'app/ducks/campaigns/campaign/cashbackCreative/reducers';
import { IEdge, IStage } from 'app/types/Campaign';

import { getMappedStages } from './helpers';

const addIdOrderToStages = (mappedStages: { criteria: any; calculation: any }[]) =>
  mappedStages.reduce((acc, curr, index) => {
    const id = generateStageId() + index;
    return {
      ...acc,
      [id]: {
        ...curr,
        id,
        order: index,
      },
    };
  }, {});

export const fromApiJourneyCreative = (journeyCreative: { stages: IStage[]; edges: IEdge[] }) => {
  const { stages, edges, ...rest } = journeyCreative;
  const mappedStages = getMappedStages(stages, edges);
  const reduxStages = addIdOrderToStages(mappedStages);
  return {
    ...rest,
    stages: reduxStages,
  };
};
