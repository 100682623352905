import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { compose, withHandlers, withProps } from 'recompose';

import { setSelectedTenant } from 'configs/user';

import Layout from './Layout';
import navItems from './navItems.config';

const mapStateToProps = ({ user, config }) => ({ user, config });

const addProps = withProps(props => ({
  items: navItems(props.user.permissions, props.config)
    .filter(({ disable = false }) => !disable)
    .map(({ disable, ...otherProps }) => otherProps),
}));

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  addProps,
  withHandlers({
    handleChangeTenant: () => tenant => {
      setSelectedTenant(tenant);
      window.location.href = '/'; // TODO: Should this be history.location?
    },
  }),
);

export default enhance(Layout);
