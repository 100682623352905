import React from 'react';

import { connect } from 'react-redux';

import moment from 'moment';
import Calendar from 'rc-calendar';
import Picker from 'rc-calendar/lib/Picker';
import TimePanel from 'rc-time-picker/lib/Panel';

import { dateTimeSecFormat } from 'configs/dateTime';

import TextField from '../TextField/';

type Props = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  label: string;
  name: string;
  onChange: (arg0: moment) => void;
  required?: boolean;
  timezone: string;
  value: number; // epoch milliseconds
};

class DateTimeEpochField extends React.Component<Props> {
  now = moment();

  onChange = (m: moment) => {
    const { onChange } = this.props;
    onChange(m.valueOf());
  };

  render() {
    const { className, disabled, error, id, label, name, required, timezone, value, ...rest } = this.props;

    const m = moment(value).tz(timezone);
    const displayValue = (ts: moment | null | undefined) => (ts && ts.isValid() ? ts.format(dateTimeSecFormat) : '');

    return (
      <Picker
        animation="slide-up"
        calendar={
          <Calendar
            className={className}
            dateInputPlaceholder="Select a Date"
            defaultValue={this.now}
            format={dateTimeSecFormat} // format: string | Array<String>, // def = depends on whether you set timePicker and your locale. Use to format/parse date (without time) value to/from input. When an array is provided, all values are used for parsing and first value for display.
            // locale={cn ? zhCN : enUS} // locale: any, // Object calendar locale, def = import from 'rc-calendar/lib/locale/en_US'
            // onChange: moment => any, // called when a date is changed inside calendar (next year/next month/keyboard)
            // onClear: () => any, // called when a date is cleared from calendar
            // onOk: moment => any, // called when ok button is pressed, only if it's visible
            // onPanelChange: (moment, ICalendarMode) => any, // called when panel changed
            // onSelect: moment => any, // called when a date is selected from calendar
            // showOk: boolean, // whether has ok button in footer
            // showToday: boolean, // def = true; whether to show today button
            timePicker={<TimePanel />}
            // value: moment, // current value like input's value
          />
        }
        onChange={this.onChange}
        value={m && m.isValid() ? m : undefined}
      >
        {({ value: mVal }) => (
          <TextField
            className={className}
            disabled={disabled}
            error={error}
            id={id}
            label={label}
            name={name}
            onChange={() => {
              /* no-op */
            }}
            required={required}
            value={displayValue(mVal)}
            {...rest}
          />
        )}
      </Picker>
    );
  }
}

export default connect(({ user: { timezone } }, { timezone: propsTimezone }: { timezone?: string }) => ({
  timezone: propsTimezone || timezone,
}))(DateTimeEpochField);
