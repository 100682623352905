import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { Input as _Input } from 'app/midgarComponents';

type Props = {
  amount: number;
  updateCalculationField: (arg0: Record<string, string>) => any;
  cashbackCreative: any;
};

export default class Fixed extends PureComponent<Props> {
  render() {
    const { updateCalculationField, amount } = this.props;
    return (
      <Section data-qa="fixed-calculation">
        <InputRow>
          <Input
            required
            placeholder="Cashback Amount *"
            name="amount"
            value={amount}
            type="number"
            step="1"
            min={0}
            onChange={({ target: { value, name } }) => updateCalculationField({ [name]: value })}
          />
        </InputRow>
      </Section>
    );
  }
}

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
`;

const Input = styled(_Input)`
  max-width: 200px;
`;
const InputRow = styled.div`
  width: 100%;
  display: inline-flex;
  position: relative;

  &:hover {
    .tooltip {
      display: block;
    }
  }
`;
