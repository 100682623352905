import { Container, Item } from './styledComponents';

type Props = {
  container: boolean;
  item: boolean;
  wrap: string;
  direction: string;
  hide?: boolean;
};

export default ({ hide, container, item, wrap = 'wrap', direction = 'row', ...props }: Props) =>
  !hide && (
    <>
      {container && <Container wrap={wrap} direction={direction} {...props} />}
      {item && <Item {...props} />}
    </>
  );
