import { PureComponent } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { isObject } from 'ramda-adjunct';
import styled from 'styled-components';

import { currency } from 'app/features/Cashback/constants';
import { Input as _Input, Dropdown as _Dropdown, Button, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';

const amountTypes = [
  {
    id: 'percent',
    label: '%',
  },

  {
    id: 'fixed',
    label: 'Yen',
  },
];

type Props = {
  legs: Array<{
    amount: number;
    percentPeople: number;
    isAmountPercent: boolean;
    ceiling?: number;
  }>;

  scratchCard: (arg0: object) => any;
  updateCalculationField: (arg0: Record<string, string>) => any;
};

export default class Lottery extends PureComponent<Props> {
  handleAddMore = () => {
    const { legs, updateCalculationField } = this.props;
    const newLotteryArray = [
      ...legs,
      {
        amount: null,
        percentPeople: null,
        isAmountPercent: false, // ceiling is neede when isAmountPercent is true
      },
    ];

    updateCalculationField({
      legs: newLotteryArray,
    });
  };

  handleWholeNumberInputChange = ({ name, value }, index: number) => {
    const { legs, updateCalculationField } = this.props;
    legs[index][name] = value;
    updateCalculationField({
      legs: [...legs],
    });
  };

  handleAmoutTypeChange = (val, index: number) => {
    const { legs, updateCalculationField } = this.props;
    legs[index].isAmountPercent = val === amountTypes[0];
    if (legs[index].isAmountPercent) {
      legs[index].ceiling = null;
    } else {
      delete legs[index].ceiling;
    }
    updateCalculationField({
      legs: [...legs],
    });
  };

  handleDelete = obj => {
    const { legs, updateCalculationField } = this.props;
    const idx = legs.indexOf(obj);
    legs.splice(idx, 1);
    updateCalculationField({
      legs: [...legs],
    });
  };

  allotZeroToRest = () => {
    const { legs, updateCalculationField } = this.props;
    const restPeople = 100 - legs.reduce((acc, curr) => acc + (parseFloat(curr.percentPeople) || 0), 0);
    if (restPeople === 100) {
      updateCalculationField({
        legs: [
          {
            amount: 0,
            percentPeople: restPeople,
            isAmountPercent: false,
            ceiling: null,
          },
        ],
      });
    } else {
      updateCalculationField({
        legs: [
          ...legs,
          {
            amount: 0,
            percentPeople: restPeople,
            isAmountPercent: false,
            ceiling: null,
          },
        ],
      });
    }
  };

  onCheckBoxChange = () => {
    const { scratchCard, updateCalculationField } = this.props;
    let checkboxState = {};

    if (isObject(scratchCard) && Object.keys(scratchCard).length > 0) {
      checkboxState = {
        scratchCard: null,
      };
    } else {
      checkboxState = {
        scratchCard: {
          daysUntilExpires: 1,
        },
      };
    }

    updateCalculationField(checkboxState);
  };

  handleExpirationDaysInputChange = value => {
    const { updateCalculationField } = this.props;
    if (value > 0) {
      updateCalculationField({
        scratchCard: {
          daysUntilExpires: value,
        },
      });
    }
  };

  renderLotteryItem = (lotteryItem, index) => (
    <InputRow key={index}>
      <DeleteIcon name="remove" data-qa={`remove-button-${index}`} onClick={() => this.handleDelete(lotteryItem)} size="30" />
      <PercentInput
        name="percentPeople"
        className="sufficPercent"
        type="number"
        allowDecimal
        value={lotteryItem.percentPeople}
        min={0}
        max={100}
        onChange={({ target }) => this.handleWholeNumberInputChange(target, index)}
      />

      <Label data-qa="percent-people-get">% people will get </Label>
      <Input
        min={0}
        type="number"
        allowDecimal
        name="amount"
        value={lotteryItem.amount}
        onChange={({ target }) => this.handleWholeNumberInputChange(target, index)}
      />

      <Dropdown
        size="100"
        options={amountTypes}
        value={lotteryItem.isAmountPercent ? amountTypes[0] : amountTypes[1]}
        onChange={val => this.handleAmoutTypeChange(val, index)}
      />

      <Label data-qa="cashback-label">cashback</Label>
      {lotteryItem.isAmountPercent && (
        <>
          <Label>upto</Label>
          <Input
            min={1}
            type="number"
            name="ceiling"
            value={lotteryItem.ceiling}
            onChange={({ target }) => this.handleWholeNumberInputChange(target, index)}
          />

          <Label>{currency}</Label>
        </>
      )}
    </InputRow>
  );

  render() {
    const { legs, scratchCard = {} } = this.props;
    const allotedPeoplePercent = legs.reduce((acc, curr) => acc + (parseFloat(curr.percentPeople) || 0), 0);
    return (
      <>
        <Section>
          <LotteryWrapper>{legs.map(this.renderLotteryItem)}</LotteryWrapper>
          {allotedPeoplePercent < 100 && (
            <ActionsWrapper>
              <Button type="secondary" onClick={this.handleAddMore} data-qa="lottery-add-more">
                <Icon name="add" />
                Add more
              </Button>
              <Or> OR </Or>
              <Button type="secondary" onClick={this.allotZeroToRest} data-qa="lottery-remaining">
                <Icon name="add" />
                Allot remaining {100 - allotedPeoplePercent}% people to get 0 cashback.
              </Button>
            </ActionsWrapper>
          )}
        </Section>
        <div>
          <Checkbox
            data-qa="has-scratch-card"
            name="isScratchCardAvailable"
            checked={isObject(scratchCard) && Object.keys(scratchCard).length > 0}
            onClick={e => this.onCheckBoxChange()}
          />

          <span data-qa="scratch-card-label">Scratch Card Available</span>
        </div>
        {isObject(scratchCard) && Object.keys(scratchCard).length > 0 && (
          <DaysExpireWrapper>
            <span>Days until expires </span>
            <Input
              data-qa="daysUntilExpires"
              type="number"
              min={1}
              name="daysUntilExpires"
              value={scratchCard.daysUntilExpires}
              onChange={e => this.handleExpirationDaysInputChange(e.target.value)}
            />
          </DaysExpireWrapper>
        )}
      </>
    );
  }
}

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
`;

const ActionsWrapper = styled.div`
  display: inline-flex;
  justify-content: space-in-between;
`;

const Or = styled.div`
  margin: 1rem;
`;

const DeleteIcon = styled(Icon)`
  color: ${sc.tertiary};
  position: absolute;
  left: -2.5rem;
  top: calc(50% - 28px);
`;

const Label = styled.div`
  color: ${sc.grey};
  text-align: left;
  font-size: 16px;
  padding-top: 26px;
  margin: 0 4px;
`;

const Input = styled(_Input)`
  max-width: 100px;
  margin-right: 1rem;
  padding: 1rem;

  &.padded {
    margin-bottom: 2rem;
  }
`;

const Dropdown = styled(_Dropdown)`
  margin-right: 0.5rem;
`;

const LotteryWrapper = styled.div`
  margin: 1rem 0;
`;

const PercentInput = styled(Input)`
  &:after {
    content: '%';
  }
`;
const InputRow = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  min-height: 80px;

  &:hover {
    .tooltip {
      display: block;
    }
  }
`;

const DaysExpireWrapper = styled.div`
  margin: 1rem 3rem;
`;
