import React from 'react';

import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { map } from 'ramda';

const renderCell = column => <TableCell key={column.key}>{column.renderHeader ? column.renderHeader(column) : column.label}</TableCell>;

interface HeaderProps {
  columns?: {
    label?: string;
    renderHeader?(...args: unknown[]): unknown;
  }[];
  rowEditable?: boolean;
}

export const Header = ({ columns = [], rowEditable = true }: HeaderProps) => (
  <TableHead>
    <TableRow>
      {map(renderCell)(columns)}
      {rowEditable && <TableCell />}
    </TableRow>
  </TableHead>
);
