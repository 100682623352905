import { connect } from 'react-redux';

import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { getRegularCriteria } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { eventSchemaOperations } from 'app/ducks/cashbackPromo/eventSchema';
import { getPromoEvents } from 'app/ducks/cashbackPromo/selectors';

import { EventsCriteria } from '../../Criteria/EventsCriteria';

export const RegularEventsCriteriaContainer = connect(
  state => ({
    criteria: getRegularCriteria(state),
    promoEvents: getPromoEvents(state),
  }),

  {
    getAllEvents: eventSchemaOperations.getAllEvents,
    updateCriteria: cashbackActions.updateCashbackCriteria,
  },
)(EventsCriteria);
