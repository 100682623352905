import { connect } from 'react-redux';

import { lifecycle, compose } from 'recompose';

import { refreshFeatureMetadata, refreshSchemaMeta } from './operations';
import { readMe, isLoading, readCampaign } from './selectors';

const mapStateToProps = state => {
  const features = readMe(state);
  const campaign = readCampaign(state);

  return {
    features,
    campaign,
    isLoading: isLoading(features),
  };
};

const redux = connect(mapStateToProps, { refreshFeatureMetadata, refreshSchemaMeta });

const fetchFeatures = lifecycle({
  componentDidMount() {
    this.props.refreshFeatureMetadata();
  },
  componentDidUpdate(prevProps) {
    const {
      campaign: { criteria: newCriteria },
    } = this.props;
    const {
      campaign: { criteria: oldCriteria },
    } = prevProps;
    if (!newCriteria) {
      return;
    }
    if (!oldCriteria || newCriteria.eventName !== oldCriteria.eventName) {
      this.props.refreshSchemaMeta();
    }
  },
});

export const FeaturesConnect = compose(redux, fetchFeatures);

interface FeaturesProps {
  children?(...args: unknown[]): unknown;
}

const Features = ({ children, ...props }: FeaturesProps) => <>{children && children(props)}</>;

export default FeaturesConnect(Features);
