import { tryCatch } from 'ramda';

import { cloneSegment as clone, enhanceSegment, segmentTypeFlags } from 'app/features/Segments/utils';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import * as fetch from 'app/utilities/http';

import * as actions from './actions';
import * as selectors from './selectors';
import { mapIds } from '../../helpers';

const api = {
  get: '/segments/:id',
  post: '/segments',
  fileUpload: '/files/upload',
  countById: id => `/segments/${id}/size`,
  countByQuery: '/segments/size',
};

const handleError = ({ message: error }, context = 'An error occurred') => {
  const { debugMessage, message, status } = tryCatch(JSON.parse, () => ({ message: error }))(error);

  displayError(`${context}: ${debugMessage || message || status}`);
};

const fetchSegmentUsingCache = async (id, state) => {
  const {
    segments: {
      segments: { byId },
    },
  } = state;
  const segment = byId[id];
  return segment ? Promise.resolve(segment) : fetch.get(api.get.replace(':id', id));
};

const getSegment = id => async (dispatch, getState) => {
  dispatch(actions.get());
  try {
    const segment = await fetchSegmentUsingCache(id, getState());
    return dispatch(actions.getSuccess(segment));
  } catch (e) {
    displayError('Error getting segment');
    return dispatch(actions.getFail(e));
  }
};

const cloneSegment = (id, featureDicts) => async (dispatch, getState) => {
  dispatch(actions.get());
  try {
    const baseSegment = await fetchSegmentUsingCache(id, getState());
    const segment = enhanceSegment(clone(baseSegment), featureDicts);
    return dispatch(actions.getSuccess(segment));
  } catch (e) {
    displayError('Error cloning segment');
    return dispatch(actions.getFail(e));
  }
};

const newSegment = () => dispatch => dispatch(actions.newSegment());

const postSegment = history => async (dispatch, getState) => {
  const {
    segments: {
      segment: { type, name, description, tags, includedRules, csvUrl },
    },
  } = getState();

  dispatch(actions.post());

  const common = {
    type,
    tagIds: mapIds(tags),
    id: -1,
    name: name.trim(),
    description: description.trim(),
  };

  let segment = {
    ...common,
  };

  switch (type) {
    case segmentTypeFlags.CSV_BASED:
      segment = { ...segment, csvUrl };
      break;
    case segmentTypeFlags.RULE_BASED:
      segment = {
        ...segment,
        includedRules,
        features: includedRules[0].features,
      };

      break;
    default:
      break;
  }

  try {
    const { id } = await fetch.post(api.post, segment);
    displaySuccess('Audience created');
    dispatch(actions.postSuccess(id));
    return history.push(`/segments/${id}`);
  } catch (e) {
    handleError(e, 'Error creating segment');
    return dispatch(actions.postFail(e));
  }
};

const uploadFile = file => async dispatch => {
  dispatch(actions.addCsv());
  const csvName = file.get('file').name;
  try {
    const csv = await fetch.fileUpload(api.fileUpload, file);
    displaySuccess('CSV upload success');
    return dispatch(actions.addCsvSuccess({ csvUrl: csv.url, csvName }));
  } catch (e) {
    handleError(e, 'Error uploading CSV');
    return dispatch(actions.addCsvFail('Error loading CSV'));
  }
};

const setIncludedRules = (ruleExpression, ruleData, errors) => dispatch =>
  dispatch(actions.setIncludedRules(ruleExpression, ruleData, errors));

const readCurrentCalculation = getState => selectors.counter(selectors.readMe(getState()));

const countOfQuery = query => (dispatch, getState) => {
  const lastResult = readCurrentCalculation(getState);
  dispatch(actions.counting(lastResult));

  fetch
    .post(api.countByQuery, query)
    .then(count => dispatch(actions.countSuccess(count)))
    .catch(error => {
      dispatch(actions.countFailure(error));
    });
};

const countOfSegment = (segmentId: number) => (dispatch, getState) => {
  const lastResult = readCurrentCalculation(getState);
  dispatch(actions.counting(lastResult));

  fetch
    .get(api.countById(segmentId))
    .then(count => dispatch(actions.countSuccess(count)))
    .catch(error => {
      dispatch(actions.countFailure(error));
    });
};

const resetSegment = () => dispatch => dispatch(actions.resetSegment());

const addTag = tag => dispatch => dispatch(actions.addTag(tag));
const removeTag = tag => dispatch => dispatch(actions.removeTag(tag));

const removeSegment = (segment, field) => dispatch => dispatch(actions.removeSegment(segment, field));

export {
  getSegment,
  cloneSegment,
  newSegment,
  postSegment,
  uploadFile,
  setIncludedRules,
  countOfQuery,
  countOfSegment,
  resetSegment,
  addTag,
  removeTag,
  removeSegment,
};
