import React from 'react';

import { connect } from 'react-redux';

import { compose } from 'recompose';
import styled from 'styled-components';

import { creativeActions } from 'app/ducks';
import withLocations from 'app/features/BannerManagement/hoc/withLocations';
import { Pills } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action } from 'app/types/state';

type Props = {
  cities: Array<Record<string, any>>;
  excludedCities: Array<Record<string, any>>;
  excludedCitiesValue: string;
  excludedStates: Array<Record<string, any>>;
  excludedStatesValue: string;
  includedCities: Array<Record<string, any>>;
  includedCitiesValue: string;
  includedStates: Array<Record<string, any>>;
  includedStatesValue: string;
  readOnly: boolean;
  states: Array<Record<string, any>>;
  variantKey: string;
  variantLocations: Record<string, any>;
  updateVariantsLocationsField: (index: number, field: string, value: any) => Action;
};

class Locations extends React.PureComponent<Props> {
  addLocation = (key: number, field: string, location: Record<string, any>) => {
    const { updateVariantsLocationsField, variantLocations } = this.props;
    const locations = variantLocations[field] ? variantLocations[field].slice() : [];

    if (!locations.find(x => x.id === location.id)) {
      locations.push(location);
      updateVariantsLocationsField(key, field, locations);
    }
  };

  removeLocation = (key: number, field: string, location: Record<string, any>) => {
    const { updateVariantsLocationsField, variantLocations } = this.props;
    const locations = variantLocations[field] ? variantLocations[field].slice() : [];
    const idx = locations.findIndex(x => x.id === location.id);

    if (idx > -1) {
      locations.splice(idx, 1);
      updateVariantsLocationsField(key, field, locations);
    }
  };

  render() {
    const {
      cities,
      excludedCities,
      excludedCitiesValue,
      excludedStates,
      excludedStatesValue,
      includedCities,
      includedCitiesValue,
      includedStates,
      includedStatesValue,
      readOnly,
      states,
      variantKey,
      updateVariantsLocationsField,
    } = this.props;

    return (
      <Container>
        <Group>
          <InlineField>
            <Pills
              disabled={readOnly}
              id="includedCities"
              label="Included Cities"
              name="includedCities"
              onChange={ev => updateVariantsLocationsField(Number(variantKey), 'includedCitiesValue', ev.target.value)}
              onRemove={(suggestion: Record<string, any>) => this.removeLocation(variantKey, 'includedCities', suggestion)}
              onSelect={(suggestion: Record<string, any>) => this.addLocation(variantKey, 'includedCities', suggestion)}
              selected={includedCities}
              suggestions={cities}
              value={includedCitiesValue}
            />
          </InlineField>

          <InlineField>
            <Pills
              disabled={readOnly}
              id="excludedCities"
              label="Excluded Cities"
              name="excludedCities"
              onChange={ev => updateVariantsLocationsField(Number(variantKey), 'excludedCitiesValue', ev.target.value)}
              onRemove={(suggestion: Record<string, any>) => this.removeLocation(variantKey, 'excludedCities', suggestion)}
              onSelect={(suggestion: Record<string, any>) => this.addLocation(variantKey, 'excludedCities', suggestion)}
              selected={excludedCities}
              suggestions={cities}
              value={excludedCitiesValue}
            />
          </InlineField>
        </Group>

        <Group>
          <InlineField>
            <Pills
              disabled={readOnly}
              id="includedStates"
              label="Included States"
              name="includedStates"
              onChange={ev => updateVariantsLocationsField(Number(variantKey), 'includedStatesValue', ev.target.value)}
              onRemove={(suggestion: Record<string, any>) => this.removeLocation(variantKey, 'includedStates', suggestion)}
              onSelect={(suggestion: Record<string, any>) => this.addLocation(variantKey, 'includedStates', suggestion)}
              selected={includedStates}
              suggestions={states}
              value={includedStatesValue}
            />
          </InlineField>

          <InlineField>
            <Pills
              disabled={readOnly}
              id="excludedStates"
              label="Excluded States"
              name="excludedStates"
              onChange={ev => updateVariantsLocationsField(Number(variantKey), 'excludedStatesValue', ev.target.value)}
              onRemove={(suggestion: Record<string, any>) => this.removeLocation(variantKey, 'excludedStates', suggestion)}
              onSelect={(suggestion: Record<string, any>) => this.addLocation(variantKey, 'excludedStates', suggestion)}
              selected={excludedStates}
              suggestions={states}
              value={excludedStatesValue}
            />
          </InlineField>
        </Group>
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      creative: { form },
    },
  },

  { variantKey },
) => ({
  excludedCities: form.variants[variantKey].locations.excludedCities,
  excludedCitiesValue: form.variants[variantKey].locations.excludedCitiesValue,
  excludedStates: form.variants[variantKey].locations.excludedStates,
  excludedStatesValue: form.variants[variantKey].locations.excludedStatesValue,
  includedCities: form.variants[variantKey].locations.includedCities,
  includedCitiesValue: form.variants[variantKey].locations.includedCitiesValue,
  includedStates: form.variants[variantKey].locations.includedStates,
  includedStatesValue: form.variants[variantKey].locations.includedStatesValue,
  variantLocations: form.variants[variantKey].locations,
});

const mapDispatchToProps = {
  updateVariantsLocationsField: creativeActions.updateVariantsLocationsField,
};

export default compose(withLocations, connect(mapStateToProps, mapDispatchToProps))(Locations);

const Container = styled.div`
  margin-bottom: ${sc.gutter};
`;

const Group = styled.div`
  margin-bottom: ${sc.gutter};

  &:last-child {
    margin: 0;
  }
`;

const InlineField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 49%;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
`;
