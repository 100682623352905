import React from 'react';

import { compose } from 'ramda';

import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { withPermissions } from 'app/decorators';
import { IPaging } from 'app/hocs/withReporting';
import { defaultSearchAdaptor } from 'app/hocs/withReporting/withCreatedBySwitch';
import { Page, PageMain } from 'app/midgarComponents';
import { CreatedBySwitch as _CreatedBySwitch } from 'app/midgarComponents/OptionsSwitch/CreatedBySwitch';
import { CreateNewButton, Switches } from 'app/midgarComponents/ReportingPage';
import { IWidgetType } from 'app/types/BannerManagement';
import { IQueryParams } from 'app/types/IQueryParams';
import { IsPermittedFn } from 'app/utilities/permissions';
import { bannerManagementEditPermissions } from 'configs/permissions';

import ImagePreviewModal from '../../common/ImagePreviewModal';
import { StickyHeader } from '../../common/styles';
import withCreativesReporting, { BannerCreativesReportBody } from '../../common/withCreativesReporting';
import { searchParams } from '../../common/withCreativesReporting/search.config';
import { urlCreativeNew } from '../../routeUtils';

const CreatedBySwitch = defaultSearchAdaptor(searchParams.createdBy)(_CreatedBySwitch);

type Props = {
  allPlatforms: Array<string>;
  allSites: Array<string>;
  bannerCreatives: Array<IApiBannerCreative>;
  error: Record<string, any>;
  goToPage: (arg0: number) => unknown;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  isPermitted: IsPermittedFn;
  loading: boolean;
  onSelect?: (...args: Array<any>) => any;
  openPreviewModal?: (...args: Array<any>) => any;
  paging: IPaging;
  pushSearch: (...args: Array<any>) => any;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  search: IQueryParams;
  searchPhrase: string;
  selectedSearchType: string;
  timezone: string;
  userEmail: string;
  widgetTypes: Array<IWidgetType>;
  widgetTypesError: string | null | undefined;
  widgetTypesLoading: boolean;
};

type State = {
  selectedItem: Record<string, any>;
  isPreviewModalOpen: boolean;
};

class BannerCreativesReport extends React.PureComponent<Props, State> {
  state = {
    selectedItem: {},
    isPreviewModalOpen: false,
  };

  openPreviewModal = (viewItem: Record<string, any>) => {
    this.setState({
      selectedItem: viewItem,
      isPreviewModalOpen: true,
    });
  };

  render() {
    const { selectedItem, isPreviewModalOpen } = this.state;
    const { userEmail, search, pushSearch, isPermitted } = this.props;

    return (
      <Page>
        <StickyHeader title="Banner Creative">
          <Switches>
            <CreatedBySwitch userEmail={userEmail} search={search} pushSearch={pushSearch} />
          </Switches>

          {isPermitted(bannerManagementEditPermissions) && <CreateNewButton to={urlCreativeNew()} />}
        </StickyHeader>

        <PageMain>
          <BannerCreativesReportBody {...this.props} openPreviewModal={this.openPreviewModal} />
        </PageMain>

        <ImagePreviewModal
          isOpen={isPreviewModalOpen}
          closeModal={() => this.setState({ isPreviewModalOpen: false })}
          viewItem={selectedItem}
        />
      </Page>
    );
  }
}

export default compose(withPermissions, withCreativesReporting)(BannerCreativesReport);
