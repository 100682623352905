import { Action } from 'app/types/state';

import { IFetch, fetch } from '../commonStates';
import { IBannerStorefront } from './IBannerStorefront';
import * as types from './types';

export type State = IFetch & {
  permittedStorefronts: Array<IBannerStorefront> | null | undefined;
  storefronts: Array<IBannerStorefront> | null | undefined;
};

const initialState = {
  ...fetch,
  permittedStorefronts: null,
  storefronts: null,
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_STOREFRONTS_ERROR:
      return {
        ...initialState,
        error: payload.error,
      };

    case types.GET_STOREFRONTS_START:
      return {
        ...initialState,
        loading: true,
      };

    case types.GET_STOREFRONTS_SUCCESS:
      return {
        ...initialState,
        permittedStorefronts: payload.permittedStorefronts,
        storefronts: payload.storefronts,
      };

    default:
      return state;
  }
};
