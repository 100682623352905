import styled from 'styled-components';

import { sc } from 'app/styles';

const SimpleTable = styled.table`
  border-collapse: collapse;
`;

const SimpleTableBody = styled.tbody``;

const SimpleTableCell = styled.td`
  border-bottom: 1px solid ${sc.overlayLighter};
  padding: 0.5rem 1rem;
`;

const SimpleTableHead = styled.thead``;

const SimpleTableHeadCell = styled.th`
  border-bottom: 1px solid ${sc.overlayLighter};
  padding: 0.5rem 1rem;
  text-align: left;
`;

const SimpleTableRow = styled.tr``;

export { SimpleTable, SimpleTableBody, SimpleTableCell, SimpleTableHead, SimpleTableHeadCell, SimpleTableRow };
