import React from 'react';

import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const TableHeader = ({ children, className }: Props) => (
  <StyledHeader className={className}>
    <tr>{children}</tr>
  </StyledHeader>
);

const StyledHeader = styled.thead`
  height: 32px;
  background-color: hsla(218, 30%, 93%, 0.4);
  border-bottom: #e6ecf0;
`;
