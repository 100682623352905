import React from 'react';

import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import Loading from '../Loading';
import { StepperHelpers } from './helpers';

interface MapStepperProps {
  classes: {
    button?: string;
  };
  steps: {
    label?: string;
  }[];
  activeStep: number;
  Component(...args: unknown[]): unknown;
  goBack(...args: unknown[]): unknown;
  goNext(...args: unknown[]): unknown;
  onCancel(...args: unknown[]): unknown;
  onComplete(...args: unknown[]): unknown;
  loading: boolean;
}

export const MapStepper = ({ classes, steps, activeStep, Component, goBack, goNext, onCancel, onComplete, loading }: MapStepperProps) => (
  <section style={{ height: '100%' }}>
    <Stepper activeStep={activeStep} classes={{ root: classes.root }}>
      {steps.map(({ label }) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <section
      style={{
        minHeight: 'calc(100% - 8.8rem)',
        padding: '0 1rem',
        position: 'relative',
      }}
    >
      <Component />
    </section>
    <section className={classes.actions}>
      <Button onClick={onCancel}>Cancel</Button>
      <section>
        {!StepperHelpers.isFirstStep(activeStep) && (
          <Button className={classes.button} color="primary" onClick={goBack}>
            Back
          </Button>
        )}

        {StepperHelpers.isLastStep(activeStep, steps) ? (
          <Button
            variant="contained"
            color="primary"
            disabled={loading || StepperHelpers.isDisabled(activeStep, steps)}
            onClick={onComplete}
          >
            Create
          </Button>
        ) : (
          <Button variant="contained" disabled={StepperHelpers.isDisabled(activeStep, steps)} color="primary" onClick={goNext}>
            Next
          </Button>
        )}
      </section>
    </section>
    {loading ? (
      <section style={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '100%' }}>
        <Loading />
      </section>
    ) : null}
  </section>
);
