import { IStorefront } from 'app/types/BannerManagement';
import api from 'app/utilities/api';

import { IStoresQuery } from '../types';
import { DELETE_STOREFRONT, GET_STOREFRONT, GET_STOREFRONTS, POST_STOREFRONT, PUT_STOREFRONT } from './endpoints';

export const createStorefront = (storefront: IStorefront): Promise<{ msg: string }> => api.post(POST_STOREFRONT, { body: storefront });

export const deleteStorefront = (storefrontId: number): Promise<{ msg: string }> => api.delete(DELETE_STOREFRONT(storefrontId));

export const fetchStorefront = (storefrontId: number, entityType?: string): Promise<IStorefront | null | undefined> =>
  entityType ? api.get(GET_STOREFRONT(storefrontId), { query: { entityType } }) : api.get(GET_STOREFRONT(storefrontId));

export const fetchStorefronts = (query: IStoresQuery): Promise<Array<IStorefront>> => api.get(GET_STOREFRONTS, { query });

export const updateStorefront = (storefrontId: number, storefront: IStorefront): Promise<{ msg: string }> =>
  api.put(PUT_STOREFRONT(storefrontId), { body: storefront });
