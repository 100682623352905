import React, { ChildrenArray, PureComponent } from 'react';

import cx from 'classnames';
import styled from 'styled-components';

import { sc } from 'app/styles';

type Props = {
  isOpen: boolean;
  onClose: (...args: Array<any>) => any;
  className?: string;
  children: ChildrenArray<any>;
};

type Ref = { current: null | HTMLElement };

export default class SideModal extends PureComponent<Props> {
  wrapperRef: Ref;

  constructor(props: Props) {
    super(props);

    this.wrapperRef = React.createRef();

    document.addEventListener('mousedown', this.handleOutsideClick);
    document.addEventListener('touchstart', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
    document.removeEventListener('touchstart', this.handleOutsideClick);
  }

  handleOutsideClick = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { onClose } = this.props;
    const node = this.wrapperRef.current;

    if (node && !node.contains(ev.target)) {
      onClose();
    }
  };

  render() {
    const { className, isOpen, children } = this.props;

    return (
      <Container ref={this.wrapperRef} isOpen={isOpen} className={cx('ci-side-modal', className)}>
        <InnerModal className="ci-side-modal-inner">{children}</InnerModal>
      </Container>
    );
  }
}

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;

  transition: transform 200ms ease-in-out;
  border-left: 1px solid ${sc.sectionBorderColor};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  transform: translate(${props => (props.isOpen ? '0' : '100%')}, 0);
  z-index: 200;
`;

const InnerModal = styled.div`
  width: 100%;
  height: 100%;
  padding: ${sc.gutter};

  background-color: #fff;
`;
