import React, { PureComponent } from 'react';

import styled from 'styled-components';

import _Icon from 'app/midgarComponents/Icon';
import TextField from 'app/midgarComponents/TextField';
import { sc } from 'app/styles';

type Props = {
  className?: string;
  name: string;
  nameError?: boolean;
  onAdd: () => void;
  onDelete: () => void;
  onChange: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
  readOnly: boolean;
  showAdd?: boolean;
  showDelete?: boolean;
  value: string;
};

export default class KeyValueGroup extends PureComponent<Props> {
  render() {
    const { className, name, nameError, onAdd, onDelete, onChange, readOnly, showAdd, showDelete, value } = this.props;

    return (
      <Container className={className}>
        <KeyField type="text" name="key" label="Key" value={name || ''} onChange={onChange} disabled={readOnly} error={nameError} />

        <ValueField type="text" name="value" label="Value" value={value || ''} onChange={onChange} disabled={readOnly} />

        {showAdd && <Icon size={25} name="add" onClick={() => !readOnly && onAdd()} disabled={readOnly} />}

        {showDelete && <Icon size={20} name="remove" onClick={() => !readOnly && onDelete()} disabled={readOnly} />}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const KeyField = styled(TextField)`
  width: 28%;
  margin-right: ${sc.gutterSmall};
`;

const ValueField = styled(TextField)`
  fkex-grow: 1;
`;

const Icon = styled(_Icon)`
  color: ${sc.subHeadingColor};
  margin: 0 ${sc.gutter};
  width: 1rem;

  &:hover {
    color: ${props => !props.disabled && sc.primary};
    cursor: pointer;
  }
`;
