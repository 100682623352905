import { combineReducers } from 'redux';

import apiCreative, { ConnectedApi } from './apiCreative';
import bannerCreative from './bannerCreative';
import cashbackCreative from './cashbackCreative';
import defaultCreative from './defaultCreative';
import emailCreative, { ConnectedEmail } from './emailCreative';
import * as campaignOperations from './operations';
import promoCreative from './promoCreative';
import push2Creative, { ConnectedPush2 } from './push2Creative';
import general from './reducers';
import TimelineNotificationCreative, { ConnectedTimelineNotification } from './TimelineNotificationCreative';
import * as campaignTypes from './types';

export { campaignOperations, campaignTypes, ConnectedEmail, ConnectedPush2, ConnectedTimelineNotification, ConnectedApi };

export default combineReducers({
  general,
  bannerCreative,
  emailCreative,
  push2Creative,
  TimelineNotificationCreative,
  apiCreative,
  promoCreative,
  defaultCreative,
  cashbackCreative,
});
