import { divide, multiply, compose } from 'ramda';
import { isValidNumber } from 'ramda-adjunct';

const formatNumber = (n: number) => (isValidNumber(n) ? n.toLocaleString('en-US') : '');

const round = (decimals: number) => (number: number) => number.toFixed(decimals);

const appendPercent = (string: string) => `${string}%`;

const asPercentStr = (numerator: number, denominator: number) =>
  compose(appendPercent, formatNumber, parseFloat, round(2), multiply(100), divide(Number(numerator) || 0))(Number(denominator) || 1);

export { asPercentStr, formatNumber };
