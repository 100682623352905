import { connect } from 'react-redux';

import { path } from 'ramda';

import * as operations from './operations';

const mapStateToProps = path(['campaigns', 'campaign', 'emailCreative']);

const emailConnect = connect(mapStateToProps, operations);

export const ConnectedEmail = emailConnect(({ children, ...props }) => children && <>{children(props)}</>);
