import styled from 'styled-components';

import { sc } from 'app/styles';

export const Tab = styled.button.attrs(({ handleChange, value }) => ({
  onClick: e => {
    e.stopPropagation();
    handleChange(value);
  },
}))`
  font-size: ${sc.fontSizeSmall};
  border: none;
  height: 100%;
  border-bottom: 2px solid ${({ selected }) => (selected ? sc.primary : sc.sectionWhiteColor)};
  color: ${({ selected }) => (selected ? sc.primary : sc.subHeadingColor)};
  font-weight: 500;
  background-color: ${sc.sectionWhiteColor};
  margin-right: ${props => props.spacing}em;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:not(:focus):hover {
    border-bottom: 2px solid ${sc.headingColor};
    color: ${sc.headingColor};
  }
`;
