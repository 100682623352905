import { ILocation } from 'app/types/BannerManagement';
import { Action } from 'app/types/state';

import { IFetch, baseFetch } from '../utils';
import * as types from './types';

export type State = IFetch & {
  cities: Array<ILocation>;
  states: Array<ILocation>;
};

const initialState: State = {
  ...baseFetch,
  cities: [],
  states: [],
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_LOCATIONS_FAILED:
      return {
        ...state,
        cities: [],
        error: payload.error,
        loading: false,
        state: [],
      };

    case types.GET_LOCATIONS_START:
      return {
        ...state,
        cities: [],
        error: null,
        loading: true,
        states: [],
      };

    case types.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        cities: payload.cities,
        error: null,
        loading: false,
        states: payload.states,
      };

    default:
      return state;
  }
};
