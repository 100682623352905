// import update from 'immutability-helper';

import { IFetch, fetch } from 'app/ducks/commonStates';
import { IPaging } from 'app/hocs/withReporting';
import { Action } from 'app/types/state';

import * as types from './types';
import { ICashbackBannerCreative } from '../../../features/Cashback/types';

export type State = IFetch & {
  banners: Array<ICashbackBannerCreative>;
  paging: IPaging;
};

const initialState = {
  ...fetch,
  banners: [],
  paging: {},
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ERROR:
      return {
        ...state,
        banners: [],
        error: payload.error,
        loading: false,
        paging: {},
      };

    case types.GET_INIT:
      return {
        ...state,
        banners: [],
        error: null,
        loading: true,
        paging: {},
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        banners: payload.banners,
        error: null,
        loading: false,
        paging: payload.paging,
      };

    default:
      return state;
  }
};
