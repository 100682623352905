import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { ImageContainer as _ImageContainer } from 'app/midgarComponents';

export default class PreviewColumn extends PureComponent<{
  row: Record<string, any>;
  openPreviewModal?: (...args: Array<any>) => any;
}> {
  onClick = () => {
    const { row, openPreviewModal } = this.props;

    if (typeof openPreviewModal === 'function') {
      openPreviewModal(row);
    }
  };

  render() {
    const { row } = this.props;

    if (!row) {
      return null;
    }

    const imageUrl = row.variants[0].image && row.variants[0].image.indexOf('http') > -1 ? row.variants[0].image : null;

    return <ImageContainer width={130} height={75} imageUrl={imageUrl} emptyMessage="No Image" onClick={this.onClick} />;
  }
}

const ImageContainer = styled(_ImageContainer)`
  & > div {
    background-position: top center;
  }
`;
