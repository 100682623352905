import { withProps, withStateHandlers, compose } from 'recompose';

import { filterBy } from 'app/helpers/RamdaHelpers/helpers';

import { FilterSelect } from './FilterSelect';

const addProps = withProps({
  // anchorPosition: {
  //   top: positionTop,
  //   left: positionLeft,
  // },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },

  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
});

const addStateHandlers = withStateHandlers(
  ({ value, options }) => ({
    value,
    options,
    isDropdownOpen: false,
  }),

  {
    handleSelect:
      (_, { onChange, value, options }) =>
      (event, newValue) => {
        if (newValue !== value) {
          onChange(newValue);
        }

        return {
          value: newValue,
          options,
          isDropdownOpen: false,
        };
      },
    handleOpen: () => () => ({
      isDropdownOpen: true,
    }),

    handleClose:
      (_, { options }) =>
      () => ({
        options,
        isDropdownOpen: false,
      }),

    handleFilter:
      (_, { options: allOptions }) =>
      ({ target: { value } }) => ({
        options: filterBy(value, allOptions),
      }),
  },
);

const enhance = compose(addProps, addStateHandlers);

export default enhance(FilterSelect);
