import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import * as CampaignsSelectors from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { paymentCeilingsOperations } from 'app/ducks/cashbackPromo/paymentCeilings';
import * as CashbackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';
import { stackCeilingByCalcType } from 'app/features/Cashback/constants';
import { IPaymentCeiling } from 'app/features/CashbackPromoManagement/types';
import { Icon, Spinner as _Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

type Props = {
  error: string;
  calculationType: string;
  loadingPaymentCeiling: boolean;
  getPaymentCeilings: (...args: Array<any>) => any;
  updateStackCeiling: (...args: Array<any>) => any;
  stackCeiling: IPaymentCeiling;
  paymentCeilings: Array<IPaymentCeiling>;
};

class GlobalLimit extends PureComponent<Props> {
  componentDidMount = () => {
    const { getPaymentCeilings, stackCeiling, updateStackCeiling, loadingPaymentCeiling } = this.props;
    getPaymentCeilings();
    if (!stackCeiling && !loadingPaymentCeiling) {
      const stackCeilingNew = this.getAssociatedStackCeiling();
      if (stackCeilingNew) {
        updateStackCeiling(stackCeilingNew);
      }
    }
  };

  componentDidUpdate() {
    const { stackCeiling, updateStackCeiling, loadingPaymentCeiling } = this.props;
    if (!stackCeiling && !loadingPaymentCeiling) {
      const stackCeilingNew = this.getAssociatedStackCeiling();
      if (stackCeilingNew) {
        updateStackCeiling(stackCeilingNew);
      }
    }
  }

  getAssociatedStackCeiling = () => {
    const { paymentCeilings, calculationType } = this.props;
    return (paymentCeilings && paymentCeilings.find(ceiling => ceiling.name === stackCeilingByCalcType[calculationType])) || null;
  };

  render() {
    const { stackCeiling, error } = this.props;

    return (
      <Fyi>
        {error ? (
          <Error>
            <Icon name="cancel" />
            Failed to fetch global payment limit. Please try again.
          </Error>
        ) : (
          <>
            <Icon name="lightbulb-outline" />
            Global Limit: {stackCeiling ? `${stackCeiling.limit}%` : <Spinner />}
          </>
        )}
      </Fyi>
    );
  }
}

export const RegularGlobalLimitContainer = connect(
  state => {
    const paymentCeilings = CashbackPromoConfigSelectors.getPaymentCeilings(state);
    const stackCeiling = CampaignsSelectors.getRegularStackCeiling(state);
    const calculationType = CampaignsSelectors.getRegularCalculationType(state);
    return {
      stackCeiling,
      paymentCeilings: paymentCeilings.paymentCeilings,
      error: paymentCeilings.error,
      loadingPaymentCeiling: paymentCeilings.loading,
      calculationType,
    };
  },
  {
    updateStackCeiling: cashbackActions.updateCashbackStackCeiling,
    getPaymentCeilings: paymentCeilingsOperations.getPaymentCeilings,
  },
)(GlobalLimit);

export const JourneyGlobalLimitContainer = connect(
  (state, { stageNum }) => {
    const paymentCeilings = CashbackPromoConfigSelectors.getPaymentCeilings(state);
    const stackCeiling = CampaignsSelectors.getJourneyStackCeiling(state, stageNum);
    const calculationType = CampaignsSelectors.getJourneyCalculationType(state, stageNum);
    return {
      stackCeiling,
      paymentCeilings: paymentCeilings.paymentCeilings,
      error: paymentCeilings.error,
      loadingPaymentCeiling: paymentCeilings.loading,
      calculationType,
    };
  },
  (dispatch, { stageNum }) => ({
    updateStackCeiling: payload => dispatch(cashbackActions.updateJourneyStackCeiling({ ...payload, stageNum })),
    getPaymentCeilings: () => dispatch(paymentCeilingsOperations.getPaymentCeilings()),
  }),
)(GlobalLimit);

const Fyi = styled.div`
  padding: 1rem 2rem 1rem 0;
  width: 50%;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: ${sc.fontSizeSmall};
  color: ${sc.secondary};
`;

const Spinner = styled(_Spinner)`
  width: 1rem;
  display: inline;
  margin: 0 1rem;
`;

const Error = styled.div`
  color: ${sc.danger};
`;
