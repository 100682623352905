import styled from 'styled-components';

import { sc } from 'app/styles';

import Tab from './Tab';

const TabContainer = styled.section`
  height: 40px;
  display: flex;
  border-bottom: 1px solid ${sc.sectionBorderColor};
  margin-bottom: ${sc.gutterSmall};
`;

interface TabsProps {
  tabs: {
    label?: string;
  }[];
  value: number;
  onChange(...args: unknown[]): unknown;
  spacing?: number;
  className?: string;
}

export const Tabs = ({ tabs, value, onChange, spacing = 2.5, className, ...props }: TabsProps) => (
  <TabContainer className={className}>
    {tabs.map((t, i) => (
      <Tab {...props} key={t.label} selected={value === i} value={i} handleChange={onChange} spacing={spacing}>
        {t.label}
      </Tab>
    ))}
  </TabContainer>
);
