import React, { Component } from 'react';

import TableRow from '@material-ui/core/TableRow';
import { map } from 'ramda';

import { ColumnSwitch } from './ColumnSwitch';
import { EditActions } from './EditActions';

const renderColumn = props => column => <ColumnSwitch {...props} key={column.key} column={column} />;

interface InternalRowProps {
  columns?: unknown[];
  rowEditable?: boolean; //eslint-disable-line;
}

const InternalRow = ({ columns = [], rowEditable = true, ...props }: InternalRowProps) => (
  <TableRow>
    {map(renderColumn(props))(columns)}
    {rowEditable && <EditActions {...props} />}
  </TableRow>
);

interface RowProps {
  row: object;
  onChange?(...args: unknown[]): unknown; //eslint-disable-line;
  onSave?(...args: unknown[]): unknown;
  editState?: boolean;
}

export class Row extends Component<RowProps> {
  constructor(props) {
    super(props);
    this.state = {
      clonedRow: { ...props.row },
      editState: props.editState || false,
    };
  }

  handleChange = ev => {
    const { clonedRow } = this.state;
    const { onChange } = this.props;

    const newRow = {
      ...clonedRow,
      ...ev,
    };

    return onChange ? onChange(newRow) : this.setState(prevState => ({ ...prevState, clonedRow: newRow }));
  };

  handleSave = () => {
    const { clonedRow } = this.state;
    const { onSave } = this.props;

    this.setState(state => ({ ...state, editState: false }));
    onSave(clonedRow);
  };

  handleEdit = () => {
    this.setState(state => ({ ...state, editState: true }));
  };

  handleCancel = () => {
    const { row } = this.props;
    this.setState(() => ({ editState: false, clonedRow: row }));
  };

  render() {
    return (
      <InternalRow
        {...this.props}
        {...this.state}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onEdit={this.handleEdit}
        onCancel={this.handleCancel}
      />
    );
  }
}
