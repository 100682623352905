import { getCurrentUser } from 'app/api/users';
import { setSelectedTenant, getSelectedTenant } from 'configs/user';

import types from './types';

export const getUserInfo = () => async dispatch => {
  try {
    const data = await getCurrentUser();
    dispatch({ type: types.INIT_USER_STATE, payload: data });
    const { permittedTenants, email } = data;
    localStorage.setItem('email', email || '');
    const prevTenant = getSelectedTenant();
    if (!prevTenant || !permittedTenants.includes(prevTenant)) {
      const tenant = permittedTenants.length ? permittedTenants[0] : undefined;
      setSelectedTenant(tenant || '');
    }

    const currentTenant = getSelectedTenant();
    dispatch({ type: types.SET_CURRENT_TENANT, payload: currentTenant });
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return (window.location.href = `${window.origin}/auth-error`);
    }
  }
};
