import { fetchAllPromoEvents, fetchCustomerPromoEvent } from 'app/api/cashbackPromo';

import * as actions from './actions';

const getAllEvents = () => async dispatch => {
  try {
    fetchAllPromoEvents()
      .then(data => dispatch(actions.getAllEvents(data)))
      .catch(err => console.error(err));
  } catch (e) {
    console.error('Error occured while fetching promo events');
  }
};

const getCustomerEvent = () => async dispatch => {
  try {
    dispatch(actions.initLoadingCustomerEvent());
    fetchCustomerPromoEvent()
      .then(data => dispatch(actions.getCustomerEvent(data)))
      .catch(err => console.error(err));
  } catch (e) {
    console.error('Error occured while fetching customer event');
  }
};

export { getAllEvents, getCustomerEvent };
