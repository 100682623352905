import React from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { Separator } from 'app/features/BannerManagement/common/components';
import { ICreativeCategoryWeight } from 'app/types/BannerManagement';

import CategoryGroup from './CategoryGroup';

type Props = {
  readOnly: boolean;
  shouldValidate: boolean;
  variantCategoryFieldHasError: (variantKey: string, categoryKey: string, fieldName: string) => boolean;
  variantCategoryHasErrors: (variantKey: string, categoryKey: string) => boolean;
  variantEngageCategories: Record<string, ICreativeCategoryWeight>;
  variantKey: string;
};

class EngageCategories extends React.PureComponent<Props> {
  render() {
    const { readOnly, shouldValidate, variantCategoryFieldHasError, variantCategoryHasErrors, variantEngageCategories, variantKey } =
      this.props;

    const categoryKeys = Object.keys(variantEngageCategories);

    return (
      <Container>
        <Separator>
          <span>Engage Categories</span>
        </Separator>

        {categoryKeys.map((categoryKey: string, idx: number) => (
          <CategoryGroup
            categoryKey={categoryKey}
            isLast={idx === categoryKeys.length - 1}
            key={`variants-${variantKey}-engage-category-${categoryKey}`}
            readOnly={readOnly}
            shouldValidate={shouldValidate}
            variantCategoryFieldHasError={variantCategoryFieldHasError}
            variantCategoryHasErrors={variantCategoryHasErrors}
            variantKey={variantKey}
          />
        ))}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      creative: { form },
    },
  },

  { variantKey },
) => ({
  variantEngageCategories: form.variants[variantKey].engageCategories,
});

export default connect(mapStateToProps)(EngageCategories);

const Container = styled.div`
  position: relative;
`;
