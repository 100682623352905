import styled from 'styled-components';

import {
  Icon as _Icon,
  Input as _Input,
  PageMain as _PageMain,
  SimpleTable as _SimpleTable,
  SimpleTableBody,
  SimpleTableCell as _SimpleTableCell,
  SimpleTableHead as _SimpleTableHead,
  SimpleTableHeadCell as _SimpleTableHeadCell,
  SimpleTableRow,
  Spinner as BaseSpinner,
} from 'app/midgarComponents';
import { sc } from 'app/styles';

const Spinner = () => (
  <SpinnerWrapper>
    <BaseSpinner />
  </SpinnerWrapper>
);

const Icon = styled(_Icon)`
  :hover {
    cursor: pointer;
  }
`;

const IconCancel = styled(Icon).attrs(() => ({
  name: 'remove',
}))`
  color: ${sc.danger};
`;

const IconEdit = styled(Icon).attrs(() => ({
  name: 'edit',
}))`
  color: ${sc.greyLight};
  :hover {
    color: ${sc.greyDark};
  }
`;

const IconOk = styled(Icon).attrs(() => ({
  name: 'checkmark',
}))`
  color: ${props => (props && props.disabled ? sc.greyLight : sc.success)};
  cursor: ${props => (props && props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props && props.disabled ? 'none' : 'auto')};
`;

const PageMain = styled(_PageMain)`
  min-height: -webkit-fill-available;
`;

const SimpleTable = styled(_SimpleTable)`
  width: 50%;
  min-width: 600px;
  font-size: ${sc.fontSizeSmall};
  color: ${sc.bodyColor};
  line-height: 1.5;
  border-bottom: 1px solid ${sc.sectionBorderColor};
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
`;

const SimpleTableCell = styled(_SimpleTableCell)`
  padding: 1rem;
`;

const SimpleTableHead = styled(_SimpleTableHead)`
  background: ${sc.sectionBackgroundColor};
  padding: 1rem 0;
  border-bottom: 1px solid ${sc.sectionBorderColor};
`;

const SimpleTableHeadCell = styled(_SimpleTableHeadCell)`
  padding: 1.2rem 1rem 1.2rem 1rem;
  text-align: left;
  font-weight: bold;
`;

const SimpleTableRowAdd = styled(SimpleTableRow)`
  background: ${sc.greyLighter};
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  & td {
    padding: 10px 12px;
  }
`;

const SpinnerWrapper = styled.div`
  width: 250px;
  margin: 1rem 1rem 0 0;
  height: 70px;
  padding: 2rem;
`;

const TableActionCell = styled(SimpleTableCell)`
  width: 5rem;
`;

const TableInputCell = styled(_Input)`
  margin-bottom: 0;
  padding: 0;
  width: 8rem;
`;

export {
  Icon,
  IconCancel,
  IconEdit,
  IconOk,
  PageMain,
  SimpleTable,
  SimpleTableBody,
  SimpleTableCell,
  SimpleTableHead,
  SimpleTableHeadCell,
  SimpleTableRow,
  SimpleTableRowAdd,
  Spinner,
  TableActionCell,
  TableInputCell,
};
