import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';
import { gaTypes as mockGaTypes } from '../creative/mock';

export const fetchGaTypes = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      gaTypes: { loading, gaTypes: prevGaTypes },
    },
  } = getState();

  if (loading || (prevGaTypes || []).length > 0) {
    return;
  }

  dispatch(actions.getGaTypesStart());

  try {
    // No API for GA types yet, so use mock
    // const gaTypes = await apiFetchSlotGroupTypes();
    return dispatch(actions.getGaTypesSuccess(mockGaTypes));
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getGaTypesFailed(errorStr));
  }
};
