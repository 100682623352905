import { fetchUsersPaged, removeUser } from 'app/api/users';
import { IQueryParams } from 'app/types/IQueryParams';
import { Action, DispatchFn } from 'app/types/state';

import * as actions from './actions';

export const getUsersPaged =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn): Promise<Action> => {
    dispatch(actions.getAll());
    try {
      const { content: users, ...rest } = await fetchUsersPaged(params);

      return dispatch(actions.getAllSuccess(users, rest));
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };

export const deleteUser =
  (userEmail: string) =>
  async (dispatch: DispatchFn): Promise<Action> => {
    try {
      return removeUser({ email: userEmail })
        .then(() => dispatch(actions.deleteUser(userEmail)))
        .catch(err => dispatch(actions.getAllFail(err)));
    } catch (err) {
      return dispatch(actions.getAllFail(err));
    }
  };
