import * as types from './types';
import { fetch } from '../../commonStates';
import { addItem, removeItem } from '../../helpers';

const templateInitState = {
  ...fetch,
  errorDetails: null, // TODO: Merge into commonStates once vetted
  name: '',
  description: '',
  content: '',
  subject: '',
  sendFrom: '',
  sendFromName: '',
  replyTo: '',
  replyToName: '',
  tags: [],
};

const template = (state = templateInitState, { type, payload }) => {
  switch (type) {
    case types.GET_TEMPLATE:
      return {
        ...state,
        loading: true,
      };

    case types.GET_TEMPLATE_SUCCESS:
      return {
        ...templateInitState,
        loading: false,
        ...payload.template,
      };

    case types.GET_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
        errorDetails: payload.errorDetails,
      };

    case types.POST_TEMPLATE:
      return {
        ...state,
        loading: true,
      };

    case types.POST_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        id: payload.templateId,
      };

    case types.POST_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
        errorDetails: payload.errorDetails,
      };

    case types.SET_ERROR:
      return {
        ...state,
        error: payload.error,
        errorDetails: payload.errorDetails,
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        errorDetails: null,
      };

    case types.NEW_TEMPLATE:
      return {
        ...templateInitState,
        id: payload.id,
        medium: payload.medium,
      };

    case types.ADD_TAG:
      return {
        ...state,
        tags: addItem(payload.tag)(state.tags),
      };

    case types.REMOVE_TAG:
      return {
        ...state,
        tags: removeItem(payload.tag)(state.tags),
      };

    case types.SET_TEMPLATE_FIELD:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default template;
