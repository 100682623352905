import { isEmpty } from 'ramda';

import { IDropdownOption } from 'app/midgarComponents/Dropdown';
import { IViewItem } from 'app/types/BannerManagement';
import { ISuggestion } from 'app/types/utils';
import { formatDateTime, parseDateTimeUtc } from 'app/utilities/date';
import {
  SUPER_ADMIN,
  BANNER_MANAGEMENT_STOREFRONT_EDIT_ALL,
  BANNER_MANAGEMENT_READ_ONLY,
  BANNER_MANAGEMENT_EDIT,
} from 'configs/permissions';
import { __DEV__ } from 'configs/settings';

export const asDropdownOptionsWithAll = (names: Array<string> | null | undefined, allText = 'ALL'): Array<IDropdownOption> => {
  const rawOptions = (names || []).map(name => ({ id: name, label: name }));
  return rawOptions.length > 1 ? [{ id: '', label: allText }, ...rawOptions] : rawOptions;
};

export const asPillSuggestionsWithAll = (names: Array<string> | null | undefined, allText = 'ALL'): Array<ISuggestion> => {
  const rawOptions = (names || []).map(name => ({ id: name, name }));
  return rawOptions.length > 1 ? [{ id: '', name: allText }, ...rawOptions] : rawOptions;
};

export const formatTimestamp = (ts: string, timezone?: string): number | string =>
  timezone ? formatDateTime(parseDateTimeUtc(ts), timezone, 'Do MMM YYYY') : ts;

export const isTimestamp = (key: string): boolean => key === 'createdAt' || key === 'updatedAt';

export const formatValue = (value: any): string => {
  if (!value || isEmpty(value)) {
    return 'N/A';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }

  return value;
};

export const genTableColumn = (label: string, key: string | number, customComponent?: (arg0: any, arg1: IViewItem) => any) => ({
  key,
  label,
  customComponent,
});

export const isReadOnlyStorefront = (user: Record<string, any>) => () => {
  const { permissions } = user;

  if (__DEV__) {
    return false;
  }

  if (permissions.includes(BANNER_MANAGEMENT_READ_ONLY) && !permissions.includes(BANNER_MANAGEMENT_EDIT)) {
    return true;
  }

  if (permissions.includes(BANNER_MANAGEMENT_STOREFRONT_EDIT_ALL) || permissions.includes(SUPER_ADMIN)) {
    return false;
  }

  return false;
};
