import React from 'react';

import { connect } from 'react-redux';

import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { getJourneyStackableGroup } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { stackableGroupsOperations } from 'app/ducks/cashbackPromo';
import * as CashbackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';
import { Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

import { Label, Section, SpinnerWrapper, Dropdown, ValidationIcon } from '../../Creation/CreationCommonComponents';

type Props = {
  getStackableGroups: () => void;
  journeyStackableGroup: { label: string };
  stackableGroups: Array<{ label: string }>;
  stackableGroupsLoading: boolean;
  updateJourneyField: any;
};

export class StackableComponent extends React.Component<Props> {
  componentDidMount() {
    const { getStackableGroups } = this.props;
    getStackableGroups();
  }

  render() {
    const { journeyStackableGroup, stackableGroups, stackableGroupsLoading, updateJourneyField } = this.props;
    return (
      <Section>
        <Label>
          Stackable Group
          {journeyStackableGroup ? (
            <ValidationIcon name="check-circle" size={22} color={sc.success} />
          ) : (
            <ValidationIcon name="warning" size={22} color={sc.warning} />
          )}
        </Label>
        {stackableGroupsLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <Dropdown
            label="Stackable Group"
            name="stackableGroup"
            onChange={val => updateJourneyField({ stackableGroup: val })}
            options={stackableGroups.map(grp => ({ ...grp, label: grp.name }))}
            required
            value={journeyStackableGroup || []}
          />
        )}
      </Section>
    );
  }
}

const StackableContainer = connect(
  state => {
    const stackableGroups = CashbackPromoConfigSelectors.getStackableGroups(state);
    return {
      stackableGroups: stackableGroups.stackableGroups
        .filter(group => group.name.toLowerCase().indexOf('journey') > -1) // filter out journey funded group
        .filter(group => group.managedBy === 'CMA')
        .sort((grpA, grpB) => grpA.priority - grpB.priority),
      stackableGroupsLoading: stackableGroups.loading,
      journeyStackableGroup: getJourneyStackableGroup(state),
    };
  },
  {
    getStackableGroups: stackableGroupsOperations.getStackableGroups,
    updateJourneyField: cashbackActions.updateJourneyField,
  },
)(StackableComponent);

export default StackableContainer;
