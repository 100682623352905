import { IMedium } from 'app/types/Medium';
import { ITag } from 'app/types/Tag';
import { ITemplate } from 'app/types/Template';

import * as types from './types';

const get = () => ({
  type: types.GET_TEMPLATE,
});

const getSuccess = (template: ITemplate) => ({
  type: types.GET_TEMPLATE_SUCCESS,
  payload: { template },
});

const getFail = (error: string, errorDetails?: string) => ({
  type: types.GET_TEMPLATE_FAIL,
  payload: { error, errorDetails },
});

const post = () => ({
  type: types.POST_TEMPLATE,
});

const postSuccess = (templateId: ITemplate['id']) => ({
  type: types.POST_TEMPLATE_SUCCESS,
  payload: { templateId },
});

const postFail = (error: string, errorDetails?: string) => ({
  type: types.POST_TEMPLATE_FAIL,
  payload: { error, errorDetails },
});

const setError = (error: string, errorDetails?: string) => ({
  type: types.SET_ERROR,
  payload: { error, errorDetails },
});

const clearError = () => ({
  type: types.CLEAR_ERROR,
});

const newTemplate = (params: { id?: ITemplate['id']; medium?: ITemplate['medium'] } = {}) => ({
  type: types.NEW_TEMPLATE,
  payload: { id: params.id, medium: params.medium },
});

const setName = (name: ITemplate['name']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { name },
});

const setDescription = (description: ITemplate['description']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { description },
});

const setContent = (content: ITemplate['content']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { content },
});

const setMediumId = (mediumId: IMedium['id']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { mediumId },
});

const setSubject = (subject: ITemplate['subject']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { subject },
});

const setSendFrom = (sendFrom: ITemplate['sendFrom']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { sendFrom },
});

const setSendFromName = (sendFromName: ITemplate['sendFromName']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { sendFromName },
});

const setReplyTo = (replyTo: ITemplate['replyTo']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { replyTo },
});

const setReplyToName = (replyToName: ITemplate['replyToName']) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { replyToName },
});

const addTag = (tag: ITag) => ({
  type: types.ADD_TAG,
  payload: { tag },
});

const removeTag = (tag: ITag) => ({
  type: types.REMOVE_TAG,
  payload: { tag },
});

export {
  get,
  getSuccess,
  getFail,
  post,
  postSuccess,
  postFail,
  setError,
  clearError,
  newTemplate,
  setName,
  setDescription,
  setContent,
  setMediumId,
  setSubject,
  setSendFrom,
  setSendFromName,
  setReplyTo,
  setReplyToName,
  addTag,
  removeTag,
};
