import { withStateHandlers, compose } from 'recompose';

import { retainUserInputIfNecessary2 } from 'app/helpers/FeatureMetadataHelpers/helpers';

import { RuleRow2 } from './RuleRow2';

const addStateHandlers = withStateHandlers(
  ({ items = [] }) => ({
    items,
    isCascadingMenuOpen: false,
  }),

  {
    handleChange:
      ({ items: oldValue }, { onChange }) =>
      (isUserInput, newValue) => {
        const change = isUserInput ? newValue : retainUserInputIfNecessary2(oldValue, newValue);

        onChange(change);
        return { items: change };
      },
    handleFeatureButtonClick:
      ({ isCascadingMenuOpen }) =>
      () => ({
        isCascadingMenuOpen: !isCascadingMenuOpen,
      }),
  },
);

const enhance = compose(addStateHandlers);

export default enhance(RuleRow2);
