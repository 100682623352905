import * as types from './types';
import { IFetch, fetch } from '../../../commonStates';

type State = IFetch & {
  events: Array<string>;
};

const initialState = {
  ...fetch,
  events: [],
};

export default (state: State = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case types.GET_ERROR:
      return {
        ...state,
        events: [],
        error: payload.error,
        loading: false,
      };

    case types.GET_INIT:
      return {
        ...state,
        events: [],
        error: null,
        loading: true,
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        events: payload.events,
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};
