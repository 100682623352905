import React from 'react';

import { connect } from 'react-redux';

import { compose } from 'recompose';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { withPermissions } from 'app/decorators';
import { userBudgetTokensOperations } from 'app/ducks';
import { Button, Page, PageHeader } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IsPermittedFn } from 'app/utilities/permissions';
import { CASHBACK_MANAGEMENT_USER_BUDGET_TOKEN_CREATE } from 'configs/permissions';

import { IUserBudgetToken } from './types';
import {
  PageMain,
  SimpleTable,
  SimpleTableBody,
  SimpleTableCell,
  SimpleTableHead,
  SimpleTableHeadCell,
  SimpleTableRow,
  SimpleTableRowAdd,
  Spinner,
  TableInputCell as _TableInputCell,
} from '../common/EditableLimitsTable';

const MAX_JAVA_INTEGER = 2147483647;

type Props = {
  addUserBudgetToken: (arg0: IUserBudgetToken) => any;
  error?: Error;
  getUserBudgetTokens: () => any;
  isPermitted: IsPermittedFn;
  loading?: boolean;
  userBudgetTokens: Array<IUserBudgetToken>;
};

type State = {
  limit: number | null | undefined;
  name: string | null | undefined;
};

class Reporting extends React.PureComponent<Props, State> {
  state = {
    limit: null,
    name: null,
  };

  componentDidMount = () => {
    const { getUserBudgetTokens } = this.props;
    getUserBudgetTokens();
  };

  handleAddRowChange = event => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      [name]: value,
    });
  };

  handleLimitChange = event => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      [name]: value,
    });
  };

  handleAddRowSave = () => {
    const { addUserBudgetToken } = this.props;
    const { name, limit } = this.state;
    addUserBudgetToken({
      name,
      limit,
    });

    this.setState({
      limit: null,
      name: null,
    });
  };

  isValid = () => {
    const { limit, name } = this.state;
    return name && limit && this.isValidLimit() && this.isValidName();
  };

  isValidLimit = () => {
    const { limit } = this.state;
    return !limit || (Number(limit) > 0 && Number(limit) < MAX_JAVA_INTEGER);
  };

  isValidName = () => {
    const { userBudgetTokens } = this.props;
    const { name } = this.state;
    return !userBudgetTokens.find(t => t.name === name);
  };

  render() {
    const { error, isPermitted, loading, userBudgetTokens } = this.props;
    const { limit, name } = this.state;
    const canAdd = isPermitted([CASHBACK_MANAGEMENT_USER_BUDGET_TOKEN_CREATE]);

    return (
      <Page>
        <PageHeader title="User Budget Tokens" />

        <PageMain>
          {error && <DrawerError error="Error during retrieval" errorDetails={error.message} defaultExpanded />}

          <SimpleTable>
            <SimpleTableHead>
              <SimpleTableRow>
                <SimpleTableHeadCell>User Budget Token Name</SimpleTableHeadCell>

                <SimpleTableHeadCell>Value</SimpleTableHeadCell>

                <SimpleTableHeadCell />
              </SimpleTableRow>
            </SimpleTableHead>

            {canAdd && (
              <SimpleTableRowAdd>
                <SimpleTableCell>
                  <TableInputCell
                    error={!this.isValidName()}
                    name="name"
                    onChange={this.handleAddRowChange}
                    placeholder="Name"
                    value={name || ''}
                  />
                </SimpleTableCell>

                <SimpleTableCell>
                  <TableInputCell
                    error={!this.isValidLimit()}
                    name="limit"
                    onChange={this.handleLimitChange}
                    placeholder="Limit in Yen"
                    type="number"
                    value={limit || ''}
                  />
                </SimpleTableCell>

                <SimpleTableCell>
                  <Button disabled={!this.isValid()} onClick={this.handleAddRowSave} type="primary">
                    Save
                  </Button>
                </SimpleTableCell>
              </SimpleTableRowAdd>
            )}

            {!loading && (
              <SimpleTableBody>
                {userBudgetTokens.map(token => (
                  <SimpleTableRow key={token.id}>
                    <SimpleTableCell>
                      <Token>ID: {token.id}</Token>
                      {token.name}
                    </SimpleTableCell>

                    <SimpleTableCell>{token.limit}</SimpleTableCell>

                    <SimpleTableCell />
                  </SimpleTableRow>
                ))}
              </SimpleTableBody>
            )}
          </SimpleTable>

          {loading && <Spinner />}
        </PageMain>
      </Page>
    );
  }
}

export default compose(
  withPermissions,
  connect(
    ({
      cashbackPromoConfig: {
        userBudgetTokens: { error, loading, userBudgetTokens },
      },
    }) => ({
      error,
      loading,
      userBudgetTokens,
    }),

    {
      addUserBudgetToken: userBudgetTokensOperations.addUserBudgetToken,
      getUserBudgetTokens: userBudgetTokensOperations.getUserBudgetTokens,
    },
  ),
)(Reporting);

const Token = styled.span`
  color: ${sc.greyLight};
  margin-right: 1rem;
`;

const TableInputCell = styled(_TableInputCell)`
  width: 100%;
  height: 40px;
  padding: 10px;
`;
