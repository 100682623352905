import { IView } from 'app/types/BannerManagement';
import api from 'app/utilities/api';

import { BIND_CREATIVE_TO_VIEWS, GET_CREATIVE, GET_CREATIVES, GET_CREATIVE_VIEW_ITEMS, POST_CREATIVE, PUT_CREATIVE } from './endpoints';
import { IApiBannerCreative, IApiViewItemForCreative } from './types';

export const bindCreativeToViews = async (
  creative: IApiBannerCreative,
  views: Array<IView>,
  validFrom?: string,
  validUpto?: string,
): Promise<{ bindings: Array<{ bannerId: number; viewId: number; viewItemId: number; viewItemVariantId: number }> }> =>
  api.post(BIND_CREATIVE_TO_VIEWS(creative.id), {
    body: {
      validFrom,
      validUpto,
      viewIds: views.map(v => v.id),
    },
  });

export const fetchCreative = (creativeId: number): Promise<IApiBannerCreative> => api.get(GET_CREATIVE(creativeId));

export const fetchCreatives = (query: Record<string, any>): Promise<{ creatives: Array<IApiBannerCreative>; totalElements: number }> =>
  api.get(GET_CREATIVES, { query }).then(({ creatives, totalElements }) => ({ creatives, totalElements }));

export const fetchViewItemsForCreative = (creativeId: number, activeOnly = true): Promise<{ viewItems: Array<IApiViewItemForCreative> }> =>
  api.get(GET_CREATIVE_VIEW_ITEMS(creativeId), { query: { active_only: activeOnly } });

export const saveNewCreative = (creative: IApiBannerCreative): Promise<any> => api.post(POST_CREATIVE, { body: creative });

export const updateCreative = (creative: IApiBannerCreative): Promise<any> => api.put(PUT_CREATIVE(creative.id), { body: creative });
