import { equals } from 'ramda';

import { CampaignAttributesScope } from 'app/api/cashbackPromo';
import { getCampaignAttributes as getCashbackCampaignAttributes } from 'app/ducks/cashbackPromo/campaignAttributes/operations';
import { getCampaignAttributes as selectCampaignAttributes } from 'app/ducks/cashbackPromo/campaignAttributes/selectors';
import { cashbackTypes } from 'app/features/Cashback/constants';
import { IMedium, IScope } from 'app/features/CashbackPromoManagement/types';
import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';
import {
  getRegularCashbackCreative,
  getJourneyCreative,
  getJourneyStageAttributes as getJourneyStageAttributesSelectors,
} from './selectors';

export const getJourneyStageAttributes = (stageNum: string) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const scope = CampaignAttributesScope.JourneyStage;
  const medium = 'cashback';
  try {
    let configCustomAttributes = selectCampaignAttributes(scope, medium, getState());
    if (!configCustomAttributes) {
      await getCashbackCampaignAttributes(scope, medium)(dispatch, getState);
      configCustomAttributes = selectCampaignAttributes(scope, medium, getState());
    }

    const customStageAttributes = getJourneyStageAttributesSelectors(getState(), stageNum);
    if (!customStageAttributes || !customStageAttributes.length) {
      return dispatch(
        actions.updateJourneyStageAttributes({
          stageNum,
          customStageAttributes: configCustomAttributes,
        }),
      );
    }
  } catch (err) {
    console.error(err);
  }
};

export const getCampaignAttributes =
  (cashbackType: string) => (scope: IScope, medium: IMedium) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    try {
      let configCustomAttributes = selectCampaignAttributes(scope, medium, getState());
      if (!configCustomAttributes) {
        await getCashbackCampaignAttributes(scope, medium)(dispatch, getState);
        configCustomAttributes = selectCampaignAttributes(scope, medium, getState());
      }

      const { customCampaignAttributes } =
        cashbackType === cashbackTypes.regular || !cashbackType ? getRegularCashbackCreative(getState()) : getJourneyCreative(getState());

      if (!customCampaignAttributes || !customCampaignAttributes.length) {
        // we shouldn't update campaign attributes during normal fetch, too many side effects
        return dispatch(
          cashbackType === cashbackTypes.regular || !cashbackType
            ? actions.updateCashbackField({
                customCampaignAttributes: configCustomAttributes,
              })
            : actions.updateJourneyField({
                customCampaignAttributes: configCustomAttributes,
              }),
        );
      }

      // For now, do a simple check to see whether any configured attributes have been added or removed since the campaign was saved.
      const customAttrIds = new Set((customCampaignAttributes || []).map(({ id }) => id));
      const configAttrIds = new Set((configCustomAttributes || []).map(({ id }) => id));
      if (!equals(customAttrIds, configAttrIds)) {
        // TODO: Merge campaign's custom attributes and the raw attributes (in case there have been any changes since the campaign was created)
        // eslint-disable-next-line no-console
        console.warn(
          'cashbackCreativeOperations.getCampaignAttributes',
          'TODO: Merge',
          'configCustomAttributes',
          configCustomAttributes,
          'customCampaignAttributes',
          customCampaignAttributes,
        );
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      return dispatch(
        cashbackType === cashbackTypes.regular || !cashbackType
          ? actions.updateCashbackField({ error })
          : actions.updateJourneyField({ error }),
      );
    }
  };
