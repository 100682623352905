export default (cname: string) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies: Record<string, string> = decodedCookie.split(';').reduce((agg, c) => {
    const inner = c.trim().split('=');
    return {
      ...agg,
      [inner[0]]: inner[1],
    };
  }, {});
  return cookies[cname] ? cookies[cname] : '';
};
