import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { compose } from 'ramda';
import styled from 'styled-components';

import { withAppConfig } from 'app/decorators';
import { Spinner as _Spinner } from 'app/midgarComponents';
import { BaseToggle } from 'app/midgarComponents/Toggle';
import { sc } from 'app/styles';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

class GeneralTab extends PureComponent<{
  appConfig: IAppConfig;
  isPersonalizedSaving: boolean;
  spViewLoading: boolean;
  togglePersonalization: (arg0: boolean) => any;
  view: Record<string, any>;
}> {
  onPersonalizationToggle = (status: boolean) => {
    const { togglePersonalization } = this.props;
    togglePersonalization(status);
  };

  render() {
    const { appConfig, isPersonalizedSaving, spViewLoading, view } = this.props;

    const { usePersonalization } = getBannerCreativeConfig(appConfig);

    return (
      <Container>
        <FieldGroup>
          <Group>
            <Label>ID</Label>
            <Field>{view.id}</Field>
          </Group>

          <Group>
            <Label>Status</Label>
            <Field>{view.status ? 'Active' : 'Inactive'}</Field>
          </Group>
        </FieldGroup>

        <FieldGroup>
          <Group>
            <Label>Widget Type</Label>
            <Field>{view.widgetType}</Field>
          </Group>

          {usePersonalization && (
            <Group>
              <Label>Personalization</Label>

              <PersonalizationToggle
                active={!!view.isPersonalized}
                disabled={spViewLoading || isPersonalizedSaving}
                onChange={this.onPersonalizationToggle}
              />

              {isPersonalizedSaving && <Spinner size={12} />}
            </Group>
          )}
        </FieldGroup>
      </Container>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder } }) => ({
  isPersonalizedSaving: binder.isPersonalizedSaving,
});

export default compose(withAppConfig, connect(mapStateToProps))(GeneralTab);

const FieldGroup = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
`;

const Container = styled.div`
  position: relative;
  font-size: ${sc.fontSizeSmall};
`;

const PersonalizationToggle = styled(BaseToggle)`
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 20px;
`;

const Spinner = styled(_Spinner)`
  display: inline-block;
  margin-left: ${sc.gutterSmaller};
`;

const Group = styled.div`
  margin-bottom: ${sc.gutterSmall};
`;

const Label = styled.div`
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmaller};
  margin-bottom: ${sc.gutterSmallest};
`;

const Field = styled.div`
  color: ${sc.bodyColor};
  font-weight: 600;
`;
