import React from 'react';

import { TableRow, TableCell, Collapse } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import classnames from 'classnames';

import { addComponentPropsIf } from 'app/helpers/ComponentHelpers/helpers';
import { isNeitherNilNorEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

const rowValue = value => (R.isNil(value) ? '' : typeof value === 'boolean' ? String(value) : value);

const toTableCell = R.curry((row, classes, { key, isNumeric, customComponent, style }) => (
  <TableCell
    key={key}
    numeric={isNumeric}
    className={classes.tableCell}
    {...addComponentPropsIf(isNeitherNilNorEmpty(style), { style })}
    padding="normal"
  >
    {isNeitherNilNorEmpty(customComponent) ? customComponent(key, row) : rowValue(row[key])}
  </TableCell>
));

interface ExpandableTableRowProps {
  canExpandRow: boolean;
  id: number | string;
  row: Record<string, any>;
  columns: object[];
  handleRowClick?(...args: unknown[]): unknown;
  handleRowExpansion?(...args: unknown[]): unknown;
  expanded: boolean;
  handleExpandClick(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const ExpandableTableRow = ({
  canExpandRow,
  id,
  row,
  columns,
  handleRowClick,
  handleRowExpansion,
  expanded,
  handleExpandClick,
  classes,
}: ExpandableTableRowProps) => [
  <TableRow
    data-qa="table-row"
    key={id}
    {...addComponentPropsIf(isNeitherNilNorEmpty(handleRowClick), {
      onClick: event => {
        handleRowClick(row, event);
      },
      hover: true,
      style: {
        cursor: 'pointer',
      },
    })}
  >
    {R.map(toTableCell(row, classes), columns)}
    {canExpandRow && (
      <TableCell>
        <ExpandMoreIcon
          className={classnames(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
        />
      </TableCell>
    )}
  </TableRow>,
  canExpandRow && (
    <Collapse
      key={`${id}-expand`}
      timeout="auto"
      // component={'p'}
      in={expanded}
      unmountOnExit
    >
      {handleRowExpansion(row)}
    </Collapse>
  ),
];
