import React from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { ConfirmModal, Dropdown as _Dropdown } from 'app/midgarComponents';
import sc from 'app/styles/config';
import { ISlot, ISlotGroupType } from 'app/types/BannerManagement';

import ErrorContainer from './ErrorContainer';
import { IBulkActionError } from './types';

type Props = {
  bulkActionError?: IBulkActionError;
  bulkActionInProgress: boolean;
  onClose: () => void;
  onConfirm: (targetSlotId: number, targetSlotGroupType: ISlotGroupType) => void;
  slotGroupTypes: Array<ISlotGroupType>;
  slots: Array<ISlot>;
};

type State = {
  targetSlotGroupType?: ISlotGroupType;
  targetSlotId?: number;
};

class ConfirmMoveModal extends React.PureComponent<Props, State> {
  state = {};

  onConfirm = () => {
    const { onConfirm } = this.props;
    const { targetSlotGroupType, targetSlotId } = this.state;
    onConfirm(targetSlotId, targetSlotGroupType);
  };

  render() {
    const { bulkActionError, bulkActionInProgress, onClose, slotGroupTypes, slots } = this.props;

    const { targetSlotGroupType, targetSlotId } = this.state;

    const isValidTarget = targetSlotGroupType && targetSlotId;

    return (
      <ConfirmModal
        confirmText="Move"
        disabled={!isValidTarget || bulkActionInProgress || !!bulkActionError}
        isOpen
        onClose={onClose}
        onConfirm={this.onConfirm}
        title="Bulk Move"
      >
        <div>Move banners to:</div>

        <Dropdown
          error={!targetSlotId}
          label="Slot ID"
          onChange={id => this.setState({ targetSlotId: id })}
          options={slots.map(({ id }) => String(id))}
          required
          value={targetSlotId || ''}
        />

        <Dropdown
          error={!targetSlotGroupType}
          label="Slot Group"
          onChange={slotGroupType => this.setState({ targetSlotGroupType: slotGroupType })}
          options={slotGroupTypes.map(({ label, value }) => ({ id: value, label }))}
          required
          value={{ id: (targetSlotGroupType || {}).value, label: (targetSlotGroupType || {}).label }}
        />

        <StatusContainer>
          {bulkActionInProgress && <>Moving...</>}

          {bulkActionError && <ErrorContainer bulkActionError={bulkActionError} />}
        </StatusContainer>
      </ConfirmModal>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder, slotGroupTypes } }) => ({
  bulkActionError: binder.bulkActionError,
  bulkActionInProgress: binder.bulkActionInProgress,
  slotGroupTypes: slotGroupTypes.slotGroupTypes,
  slots: binder.view.slots,
});

export default connect(mapStateToProps)(ConfirmMoveModal);

const Dropdown = styled(_Dropdown)`
  margin-bottom: ${sc.gutter};
  width: 10rem;
`;

const StatusContainer = styled.div`
  min-height: 5rem;
`;
