import { combineReducers } from 'redux';

import segment, { segmentActions, segmentOperations, segmentSelectors } from './segment';
import segments, { segmentsOperations, segmentsSelectors } from './segments';

export { segmentsOperations, segmentsSelectors, segmentActions, segmentOperations, segmentSelectors };

export default combineReducers({
  segment,
  segments,
});
