import update from 'immutability-helper';

import { IFetch, fetch } from 'app/ducks/commonStates';
import { cashbackTypes } from 'app/features/Cashback/constants';
import { Action } from 'app/types/state';

import * as types from './types';
import { ICashbackBannerCreative } from '../../../features/Cashback/types';

type State = IFetch & {
  cashbackType: string;
  banner: ICashbackBannerCreative | null | undefined;
  saving: boolean;
};

const newBanner: ICashbackBannerCreative = {
  additionalAttributes: [],
  customBannerAttributes: {},
  priority: null,
  deeplink: '',
  endDate: '',
  id: -1,
  label: '',
  startDate: '',
  title: '',
};

const initialState = {
  ...fetch,
  cashbackType: '',
  banner: null,
  saving: false,
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ERROR:
      return {
        ...state,
        banner: null,
        error: payload.error,
        loading: false,
      };

    case types.GET_INIT:
      return {
        ...state,
        banner: null,
        error: null,
        loading: true,
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        cashbackType: payload.banner.cashbackType,
        banner: payload.banner,
        error: null,
        loading: false,
      };

    case types.NEW:
      return {
        ...state,
        cashbackType: cashbackTypes.regular,
        banner: { ...newBanner },
      };

    case types.SAVE_END:
      return {
        ...state,
        saving: false,
      };

    case types.SAVE_START:
      return {
        ...state,
        saving: true,
      };

    case types.SET_ADDITIONAL_ATTRIBUTES:
      return update(state, {
        banner: {
          additionalAttributes: { $set: payload.attributes },
        },
      });

    case types.SET_ATTR_FIELD:
      return update(state, {
        banner: {
          customBannerAttributes: { $merge: payload.obj },
        },
      });

    case types.SET_FIELD:
      return update(state, {
        banner: { $merge: payload.obj },
      });

    case types.UPDATE_TYPE:
      return {
        ...state,
        cashbackType: payload.cashbackType,
        banner: { ...newBanner },
        error: null,
        loading: false,
        saving: false,
      };

    default:
      return state;
  }
};
