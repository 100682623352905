import React, { PureComponent } from 'react';

import { FormikErrors } from 'formik';
import document from 'global/document';
import styled, { css } from 'styled-components';

import { displayInfo } from 'app/helpers/NotificationHelpers/helpers';
import { sc } from 'app/styles';
import { ISuggestion } from 'app/types/utils';

import Button from '../Button';
import FieldError from '../FieldError';
import Icon from '../Icon';
import Spinner from '../Spinner';

const ANIMATION_TIMEOUT = 200;

type Props = {
  className?: string;
  disabled?: boolean;
  isLimited?: boolean;
  error?: boolean | string | FormikErrors<{ id: string }>;
  id: string;
  label: string;
  limit?: number;
  loading?: boolean;
  name: string;
  onChange: (ev: React.SyntheticEvent<HTMLInputElement>) => void;
  onEnter?: (ev?: string) => void;
  onFocus?: (ev?: Event) => void;
  onRemove: (suggestion: ISuggestion) => void;
  onSelect?: (suggestion: ISuggestion) => void;
  required?: boolean;
  selected: Array<ISuggestion>;
  suggestions: Array<ISuggestion>;
  suggestionTagTypeMapper?: any;
  suggestionTagTypeColors?: any;
  value: string;
};

type State = {
  active: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  focused: boolean;
  isOpen: boolean;
  removeId: number | null;
  limit: number | null;
};

export default class Pills extends PureComponent<Props, State> {
  tagColorMapper = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      active: !!(props.value && props.value.length),
      focused: false,
      isOpen: false,
      removeId: null,
      containerRef: React.createRef(),
      limit: props.limit ?? null,
    };

    document.addEventListener('mousedown', this.handleOutsideClick as EventListener);
    document.addEventListener('touchstart', this.handleOutsideClick as EventListener);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick as EventListener);
    document.removeEventListener('touchstart', this.handleOutsideClick as EventListener);
  }

  onFocus = (ev: Event) => {
    const { onFocus, disabled } = this.props;

    if (!disabled) {
      this.setState({
        active: true,
        focused: true,
        isOpen: true,
      });

      if (typeof onFocus === 'function') {
        onFocus(ev);
      }
    }
  };

  onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const { onEnter } = this.props;
    if (ev.key === 'Enter' && onEnter) {
      onEnter((ev.target as HTMLInputElement).value);
      this.setState({ focused: true });
    }
  };

  onBlur = () => {
    const { value } = this.props;

    if (!value || !value.length) {
      return this.setState({ active: false, focused: false });
    }

    return this.setState({ focused: false });
  };

  onSelect = (suggestion: ISuggestion) => {
    const { onSelect } = this.props;
    onSelect?.(suggestion);
    this.setState({ isOpen: false });
    this.props.onChange({ target: { value: '' } });
  };

  onRemove = (suggestion: ISuggestion) => {
    const { onRemove } = this.props;

    this.setState({ removeId: suggestion.name });

    setTimeout(() => {
      onRemove(suggestion);
      this.setState({ removeId: null });
    }, ANIMATION_TIMEOUT);
  };

  handleOutsideClick = (ev: MouseEvent | TouchEvent) => {
    const { containerRef } = this.state;

    if (containerRef.current && !containerRef.current.contains(ev.target as Node)) {
      this.setState({ isOpen: false });
    }
  };

  renderSelected() {
    const { removeId } = this.state;
    const { selected, disabled, isLimited, suggestionTagTypeMapper, suggestionTagTypeColors } = this.props;

    if (!selected || !selected.length) {
      return null;
    }

    const copyToClipboard = (name: string) => {
      navigator.clipboard.writeText(name);
      return displayInfo(`${name} Copied!`);
    };

    return (
      <SelectedContainer data-qa="selected-suggestions-container">
        {selected.map((suggestion: ISuggestion) => (
          <SelectedSuggestion
            key={`selected-${suggestion.name}`}
            $remove={removeId === suggestion.name}
            isLimited={isLimited}
            onClick={() => isLimited && copyToClipboard(suggestion.name)}
          >
            {suggestionTagTypeMapper && (
              <SuggestionTag color={(suggestionTagTypeColors || [])[suggestion.type || suggestion.audienceType]}>
                {suggestionTagTypeMapper[suggestion.type || suggestion.audienceType]}
              </SuggestionTag>
            )}

            <span title={isLimited ? 'Tap to copy to clipboard' : ''} data-qa="selected-suggestion-text">
              {suggestion.name}
            </span>
            {!isLimited ? (
              <RemoveIcon
                name="remove"
                size={14}
                onClick={() => this.onRemove(suggestion)}
                color={sc.headingColor}
                disabled={!!disabled}
                data-qa="pill-remove"
              />
            ) : (
              ''
            )}
          </SelectedSuggestion>
        ))}
      </SelectedContainer>
    );
  }

  render() {
    const { active, containerRef, focused, isOpen, limit } = this.state;

    const {
      className,
      disabled,
      error,
      id,
      name,
      label,
      loading,
      onChange,
      required,
      selected,
      suggestions,
      value,
      suggestionTagTypeMapper,
      suggestionTagTypeColors,
      showShowMore = false,
    } = this.props;

    const showMore = e => {
      const { limit } = this.state;
      e.preventDefault();
      e.target.blur();
      if (suggestions.length < limit + 20) {
        this.setState({ limit: suggestions.length });
      }
      this.setState({ limit: limit + 20 });
    };

    return (
      <>
        <Container ref={containerRef} className={className} disabled={!!disabled} $error={!!error} $focused={focused || isOpen}>
          {this.renderSelected()}

          <InputContainer>
            <InnerInput
              id={id}
              type="text"
              name={name}
              value={value}
              onChange={onChange}
              required={!!required}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
              autoComplete="off"
            />

            <Label required={!!required} $active={active}>
              {label}
            </Label>
          </InputContainer>
          <Suggestions data-qa={`${name}-options`} $isOpen={isOpen || value}>
            {suggestions && !!suggestions.length ? (
              <>
                {loading && <StyledSpinner />}
                {suggestionsFilter(suggestions, value, selected, limit, showShowMore).map((suggestion: ISuggestion) => (
                  <li key={`pill-suggestion-${suggestion.name}`}>
                    <Suggestion onClick={() => this.onSelect(suggestion)}>
                      {suggestionTagTypeMapper && (
                        <SuggestionTag color={(suggestionTagTypeColors || [])[suggestion.type || suggestion.audienceType]}>
                          {suggestionTagTypeMapper[suggestion.type || suggestion.audienceType]}
                        </SuggestionTag>
                      )}

                      {suggestion.name}
                    </Suggestion>
                  </li>
                ))}

                {showShowMore && !value && suggestions.length > limit && (
                  <ButtonContainer>
                    <Button data-qa="show more" onClick={showMore}>
                      Show more
                    </Button>
                  </ButtonContainer>
                )}
              </>
            ) : (
              loading && <StyledSpinner />
            )}
          </Suggestions>
        </Container>
        <FieldError error={error} />
      </>
    );
  }
}

const suggestionsFilter = (
  arr: Array<Record<string, any>>,
  value?: string,
  selected: Array<Record<string, any>> = [],
  limit?: number,
  showShowMore = false,
) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  if (showShowMore) {
    arr.sort((a, b) => {
      if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
        return -1;
      }
      if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  const result = arr
    .filter(x => !selected.find(y => x.name === y.name))
    .filter(x => x.name.toLowerCase().indexOf((trimmedValue || '').toLowerCase()) > -1)
    .sort(a => (a.name.startsWith(trimmedValue) ? 1 : -1));

  if (!value && limit) {
    return result.length >= limit ? result.sort((a, b) => (a.name.startsWith(trimmedValue) ? 1 : -1)).slice(0, limit) : result;
  }

  return result;
};

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid ${props => (props.$focused ? sc.primary : props.$error ? sc.danger : sc.sectionBorderColor)};
  transition: border 150ms ease-in-out;

  opacity: ${props => (props.disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  background: white;
`;

const InnerInput = styled.input`
  position: relative;
  width: 100%;
  height: 55px;
  border: none;
  padding: ${sc.gutter} ${sc.gutterSmall} ${sc.gutterSmallest};

  font-size: ${sc.fontSizeSmall};
  font-weight: bold;
  z-index: 10;
`;

const InputContainer = styled.div`
  position: relative;
`;

const Label = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: ${sc.gutterSmall};

  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmall};
  pointer-events: none;

  transform: translate(0, -50%);
  transition: all 100ms ease-out;
  z-index: 11;

  ${props =>
    props.required
      ? css`
          &:after {
            content: ' *';
          }
        `
      : ''}

  ${props =>
    props.$active
      ? css`
          top: 30%;
          font-size: ${sc.fontSizeSmaller};
        `
      : ''}
`;

const RemoveIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${sc.gutterSmaller};
  transform: translate(0, -50%);
  cursor: pointer;

  &:hover {
    color: #000 !important;
  }
`;

const SelectedContainer = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  padding: ${sc.gutterSmallest} 0 0 ${sc.gutterSmallest};
`;

const SelectedSuggestion = styled.li`
  position: relative;
  display: inline-block;
  padding: ${sc.gutterSmaller} ${sc.gutterLarger} ${sc.gutterSmaller} ${sc.gutterSmallest};
  margin: ${sc.gutterSmallest};
  opacity: ${props => (props.$remove ? 0 : 1)};

  color: ${sc.headingColor};
  font-size: ${props => (!props.isLimited ? sc.fontSizeSmaller : sc.fontSizeSmall)};
  background-color: ${sc.greyLightest};

  border: 1px solid ${sc.greyLighter};
  border-radius: 5px;
  transition: all ${ANIMATION_TIMEOUT}ms ease-in-out;
  transform: scale(${props => (props.$remove ? 0.95 : 1)});

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${props => (!props.isLimited ? '350' : '576')}px;
`;

const Suggestion = styled.div`
  padding: ${sc.gutterSmall};
  font-size: ${sc.fontSizeSmall};
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;

  &:hover {
    cursor: pointer;
    background-color: ${sc.greyLightest};
  }
`;

const SuggestionTag = styled.span`
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #${props => props.color};
  color: #${props => props.color};
  background: #${props => props.color}11;
  margin: 0 5px 0 0;
`;

const Suggestions = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 100%);

  list-style: none;
  margin: 0;
  padding: 0;
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  max-height: 300px;
  overflow-y: scroll;

  border: 1px solid ${sc.greyLighter};
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  z-index: 12;

  & > li {
    border-bottom: 1px solid ${sc.greyLighter};

    &:last-child {
      border-bottom: none;
    }
  }
`;

const StyledSpinner = styled(Spinner)`
  padding: ${sc.gutter};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0px;
`;
