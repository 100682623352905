import React, { PureComponent } from 'react';

import styled from 'styled-components';

import { Input as _Input, Tooltip } from 'app/midgarComponents';

import MaxPayout from './components/MaxPayout';
import NearestLimit from './components/NearestLimit';

type Props = {
  percentage: number;
  updateCalculationField: (arg0: Record<string, number>) => any;
  handlePaymentMethodValue: (target: object) => any;
  stageNum?: string;
  roundDownToNearestAmount: number;
  selectedPayments: string;
  percentageType: string;
};

export default class Proportional extends PureComponent<Props> {
  render() {
    const {
      percentage,
      updateCalculationField,
      handlePaymentMethodValue,
      percentageType,
      stageNum = '',
      roundDownToNearestAmount,
      allowedPaymentMethods,
    } = this.props;

    return (
      <Section data-qa="proportional-section">
        {percentageType === 'GLOBAL' ? (
          <>
            <Label>Multiplier</Label>
            <InputRow className="space-between">
              <Input
                required
                allowDecimal
                placeholder="Multiplier *"
                type="number"
                name="percentage"
                value={percentage}
                min={0}
                onChange={({ target: { name, value } }) => updateCalculationField({ [name]: value })}
              />

              <Tooltip className="tooltip">
                Percentage of transaction amount to be dispensed back as cashback. For example: 5 value gives 5% cashback
              </Tooltip>
            </InputRow>
          </>
        ) : (
          allowedPaymentMethods?.map(paymentMethods => {
            return (
              <>
                <Label>{paymentMethods.name}</Label>
                {paymentMethods.name && (
                  <InputRow className="space-between">
                    <Input
                      required
                      allowDecimal
                      placeholder="Multiplier *"
                      type="number"
                      name={paymentMethods.name}
                      value={paymentMethods.percentage}
                      min={0}
                      onChange={({ target }) => handlePaymentMethodValue(target)}
                    />

                    <Tooltip className="tooltip">
                      Percentage of transaction amount to be dispensed back as cashback. For example: 5 value gives 5% cashback
                    </Tooltip>
                  </InputRow>
                )}
              </>
            );
          })
        )}

        <Label>Maximum cashback</Label>
        <InputRow className="space-between">
          <MaxPayout size={'300px'} onChangeFn={updateCalculationField} stageNum={stageNum} />
          <Tooltip className="tooltip">Maximum amount of cashback allowed per transaction. For example: 100 Yen.</Tooltip>
        </InputRow>
        <Label>Round down nearest amount</Label>
        <InputRow className="space-between">
          <NearestLimit size={'300px'} onChangeFn={updateCalculationField} roundDownToNearestAmount={roundDownToNearestAmount} />
          <Tooltip className="tooltip">Round down transaction value to 200 amount for cashback calculation.</Tooltip>
        </InputRow>
      </Section>
    );
  }
}

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
`;

const Input = styled(_Input)`
  max-width: 200px;
`;
const Label = styled.div`
  padding: 0px 0px 5px 0px;
  font-size: 14px;
`;

const InputRow = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  &:hover {
    .tooltip {
      display: block;
    }
  }
`;
