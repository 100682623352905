import React from 'react';

import styled from 'styled-components';

import { sc } from 'app/styles';

type Props = {
  active: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange: (arg0: boolean) => unknown;
};

export default ({ active, className, disabled, id, onChange }: Props) => {
  const onClick = () => !disabled && onChange(!active);

  return (
    <Container className={className} disabled={disabled}>
      <InputControl $active={active} data-qa={`${id ? `${id}-` : ''}ui-toggle`} data-value={active} onClick={onClick} />
    </Container>
  );
};

const Container = styled.div`
  width: 60px;
  height: 32px;

  opacity: ${props => (props.disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const InputControl = styled.div`
  width: 100%;
  height: 100%;
  padding: 4px;

  cursor: pointer;
  user-select: none;

  border: 1px solid ${props => (props.$active ? sc.success : sc.sectionBorderColor)};
  border-radius: 32px;
  background-color: ${props => (props.$active ? sc.success : sc.sectionBackgroundColor)};
  transition: all 0.4s ease;

  &:active {
    box-shadow: ${props => (props.$active ? 'none' : `inset 0 0 0 2em ${sc.greyLighter}`)};

    &::after {
      margin-left: ${props => (props.$active ? '-.8em' : '')};
      padding-right: 0.8em;
    }
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    left: ${props => (props.active ? '50%' : 0)};
    width: 50%;
    height: 100%;

    border-radius: 2em;
    background-color: ${sc.sectionBackgroundColor};
    box-sizing: initial;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  }
`;
