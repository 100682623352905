import { ISlotGroupType, IVariant, IVariantType, IViewItem } from 'app/types/BannerManagement';
import api from 'app/utilities/api';

import {
  GET_LANDING_TYPES,
  GET_SLOT_GROUP_TYPES,
  GET_TAGS,
  GET_VARIANT_TYPES,
  GET_VIEW_ITEM,
  PUT_VARIANT,
  PUT_VARIANT_STATUS,
  POST_VIEW_ITEM,
  PUT_VIEW_ITEM,
} from './endpoints';
import { fromApiSlotGroup } from './mappers';
import { IApiNewViewItem, IApiViewItem, IApiViewItemVariant, ILandingPageTypesResult } from './types';

export const fetchLandingPageTypes = (): Promise<ILandingPageTypesResult> => api.get(GET_LANDING_TYPES);

export const fetchSlotGroupTypes = (): Promise<Array<ISlotGroupType>> =>
  api.get(GET_SLOT_GROUP_TYPES).then(({ types }) => types.map(fromApiSlotGroup));

export const fetchVariantTypes = (widgetType?: string): Promise<Array<IVariantType>> =>
  widgetType ? api.get(GET_VARIANT_TYPES, { query: { widgetType } }) : api.get(GET_VARIANT_TYPES);

export const fetchViewItem = (viewItemId: number): Promise<IApiViewItem> => api.get(GET_VIEW_ITEM(viewItemId));

export const fetchTags = (): Promise<Array<string>> => api.get(GET_TAGS).then(({ tags }) => tags);

export const saveNewViewItem = (newViewItem: IApiNewViewItem): Promise<{ id: number }> =>
  api.post(POST_VIEW_ITEM, {
    body: { ...newViewItem },
  });

export const updateVariant = (viewItemId: number, updatedVariant: IApiViewItemVariant): Promise<void> =>
  api.put(PUT_VARIANT(viewItemId), {
    body: { variants: [updatedVariant] },
  });

/**
 * @returns Success: string message from BMS
 *          Fail: HTTP 400
 */
export const updateVariantStatus = (viewItem: IViewItem, variant: IVariant, status: 0 | 1): Promise<{ msg: string }> =>
  api.put(PUT_VARIANT_STATUS(viewItem.id || 0), {
    body: {
      variants: [
        {
          id: variant.id,
          status,
        },
      ],
    },
  });

export const updateViewItem = (viewItem: IViewItem | null | undefined, viewItemId: number): Promise<{ msg: string }> =>
  api.put(PUT_VIEW_ITEM(viewItemId), {
    body: { ...(viewItem || {}) },
  });
