import React, { PureComponent } from 'react';

import { dissoc } from 'ramda';

import { ISearchDef } from 'app/hocs/withReporting/withSearch/types';
import { TextField } from 'app/midgarComponents';
import { IQueryParams } from 'app/types/IQueryParams';

type Props = {
  pushSearch: (arg0: IQueryParams) => void;
  search: IQueryParams;
};

export default (searchDef: ISearchDef) => {
  class Wrapper extends PureComponent<
    Props,
    {
      value: string;
    }
  > {
    constructor(props: Props) {
      super(props);
      this.state = {
        value: props.search[searchDef.name] || '',
      };
    }

    updateState = (value: any) => {
      this.setState({
        value: value || '',
      });
    };

    componentDidUpdate(prevProps: Props) {
      const prevSearch = prevProps.search;
      const { search } = this.props;
      if (search !== prevSearch) {
        this.updateState(search[searchDef.name]);
      }
    }

    onChange = (ev: React.SyntheticEvent<HTMLInputElement>) => {
      const { pushSearch, search } = this.props;
      if (typeof ev.target.value === 'string' && !ev.target.value.trim().length) {
        pushSearch(dissoc(searchDef.name, search));
      }
      this.updateState(ev.target.value);
    };

    onKeyDown = (ev: React.SyntheticEvent<HTMLInputElement>) => {
      const { pushSearch, search } = this.props;
      const { value } = this.state;

      if (ev.keyCode === 13 && typeof value === 'string' && !!value.trim().length) {
        pushSearch({ ...search, [searchDef.name]: value });
      }
    };

    render() {
      const { value } = this.state;
      return (
        <TextField
          id={searchDef.name}
          name={searchDef.name}
          label={searchDef.label || ''}
          type="search"
          value={value}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
        />
      );
    }
  }

  return Wrapper;
};
