import { unixTimeWithMillis } from 'app/helpers/RamdaHelpers/helpers';

import * as types from './types';

const initState = {
  data: {
    dicts: {},
    seed: [],
    allNames: [],
    byName: {},
  },

  eventId: undefined,
  eventName: '',
  realTimeEvents: [],
  schemaId: null,
  loading: undefined,
  error: undefined,
  lastUpdate: undefined,
};

const audienceRules = (state = initState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_SEGMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_ALL_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
        error: undefined,
        lastUpdate: unixTimeWithMillis(),
      };

    case types.GET_ALL_RULES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default audienceRules;
